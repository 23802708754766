import React, { useState, useEffect, useCallback } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button,
  Divider,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Save as SaveIcon,
  Phone as PhoneIcon,
} from "@mui/icons-material";

import FileSaver from "file-saver";

import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import he from "he";
import { bytesToHex } from "../../helpers/StringUtils";
import { EncodeUTF16LE } from "../../helpers/StringUtils";
import { SettingsMenuTypesByName } from "../../enums/SettingsMenuTypes";
import { SettingsStructureTypesByName } from "../../enums/SettingsStructureTypes";
import SettingSlider from "./SettingSlider";
import SettingSirenMenu from "./SettingSirenMenu";
import IconButtonState from "../IconButtonState";
import { getSettingsInfo } from "./SettingsInfoCache";
import { useBackend } from "../../context/BackendContext";
import { flattenSettings, loadValue, saveValue } from "./StructureLoader";
import LabeledSwitch from "../LabeledSwitch";

export default function SettingsEditor({ chosenAlarm, settingsData, data }) {
  const { i18n, t } = useTranslation();
  const backend = useBackend();
  const [canMenu, setCanMenu] = useState(null);
  const [chosenLevel1, setChosenLevel1] = useState(null);
  const [chosenLevel2, setChosenLevel2] = useState(null);
  const [settingsInfo, setSettingsInfo] = useState(null);
  const [prebuiltSettings, setPrebuiltSettings] = useState([]);
  const [isAlarmAvailable, setIsAlarmAvailable] = useState(false);
  const [level1Index, setLevel1Index] = useState(0);
  const [level2Index, setLevel2Index] = useState(0);
  const [level3Index, setLevel3Index] = useState(0);
  const [timeChannelIndex, setTimeChannelIndex] = useState(null);

  useEffect(() => {
    getSettingsInfo(backend, false).then((result) => setSettingsInfo(result));
  }, [backend]);

  useEffect(() => {
    if (!settingsInfo) return;

    let alarmMenu = Object.values(settingsInfo.AlarmMenus).find(
      (am) => am.AlarmId === chosenAlarm
    );
    if (!alarmMenu) {
      setIsAlarmAvailable(false);
      return;
    }
    setIsAlarmAvailable(true);

    let rootMenu = settingsInfo.Menus[alarmMenu.RootMenuId];
    let canMenu = rootMenu.Children.find(
      (child) =>
        child.Type === SettingsMenuTypesByName["VehicleCanCodeMenuItem"]
    );
    setCanMenu(canMenu);

    let settingsArray = flattenSettings(settingsInfo, chosenAlarm);
    setPrebuiltSettings(settingsArray);
  }, [settingsInfo, chosenAlarm]);

  const handleLevel1Click = (child) => {
    setChosenLevel1(child);
    setChosenLevel2(null);
  };

  const handleLevel2Click = (child) => {
    setTimeChannelIndex(null);
    setChosenLevel2(child);
  };

  const renderLevel1 = useCallback(() => {
    if (!settingsInfo || !chosenAlarm) {
      return <></>;
    }

    let alarmMenu = Object.values(settingsInfo.AlarmMenus).find(
      (am) => am.AlarmId === chosenAlarm
    );
    let rootMenu = settingsInfo.Menus[alarmMenu.RootMenuId];
    let reducedLevel = rootMenu.Children.filter(
      (c) => c.Type !== SettingsMenuTypesByName.VehicleCanCodeMenuItem
    )
      .sort((a, b) => (a.Order > b.Order ? 1 : -1))
      .reduce((acc, child, index) => {
        let titleTranslationId = child.TitleTranslationId;
        if (!titleTranslationId && child.PrototypeId) {
          titleTranslationId =
            settingsInfo.Menus[child.PrototypeId].TitleTranslationId;
        }
        acc.push(
          <ListItem
            button
            key={child.Id}
            onClick={() => {
              handleLevel1Click(child);
              setLevel1Index(index);
            }}
          >
            <ListItemText
              primary={`${index + 1}. ${
                settingsInfo.Translations[i18n.language][titleTranslationId]
                  .Text
              }`}
            />
          </ListItem>
        );
        return acc;
      }, []);

    return (
      <List style={{ minWidth: "30ch", maxWidth: "30ch" }}>
        {reducedLevel.map((element, index) => (
          <div key={element.key}>
            {element}
            {index === reducedLevel.length - 1 ? (
              <></>
            ) : (
              <Divider
                orientation="horizontal"
                style={{ marginTop: "8px", marginBottom: "8px" }}
              />
            )}
          </div>
        ))}
      </List>
    );
  }, [chosenAlarm, settingsInfo, i18n]);

  const renderTimeChannels = (prototypeMenu, timeChannelIndex) => {
    if (prototypeMenu === null || prototypeMenu === undefined) return <></>;

    let acc = [];
    for (let i = 0; i < 10; i++) {
      acc.push(
        <div key={i}>
          <ListItem
            button
            key={i}
            onClick={() => {
              setLevel2Index(i);
              setTimeChannelIndex(i);
            }}
          >
            <ListItemText
              primary={`${level1Index + 1}.${i + 1} TCH ${i + 1}`}
            />
            {timeChannelIndex === i ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          {timeChannelIndex === i ? (
            prototypeMenu.Children.map((child, index) => {
              let titleTranslationId = child.TitleTranslationId;
              if (!titleTranslationId && child.PrototypeId) {
                titleTranslationId =
                  settingsInfo.Menus[child.PrototypeId].TitleTranslationId;
              }
              return (
                <ListItem
                  button
                  key={child.Id * 100 + index}
                  onClick={() => {
                    setChosenLevel2(child);
                    setLevel3Index(index);
                  }}
                >
                  <ListItemText
                    primary={`${level1Index + 1}.${level2Index + 1}.${
                      index + 1
                    } ${
                      settingsInfo.Translations[i18n.language][
                        titleTranslationId
                      ].Text
                    }`}
                  />
                </ListItem>
              );
            })
          ) : (
            <></>
          )}
        </div>
      );
    }

    return (
      <List style={{ minWidth: "40ch", maxWidth: "40ch", marginLeft: "8px" }}>
        {acc}
      </List>
    );
  };

  const renderLevel2 = useCallback(() => {
    if (!settingsInfo || !chosenLevel1 || !chosenLevel1.PrototypeId) {
      return <></>;
    }

    let prototypeMenu = settingsInfo.Menus[chosenLevel1.PrototypeId];
    if (chosenLevel1.Type === SettingsMenuTypesByName["Array"]) {
      return renderTimeChannels(prototypeMenu, timeChannelIndex);
    }

    let reducedLevel = prototypeMenu.Children?.sort((a, b) =>
      a.Order > b.Order ? 1 : -1
    ).map((child, index) => {
      let titleTranslationId = child.TitleTranslationId;
      if (!titleTranslationId && child.PrototypeId) {
        titleTranslationId =
          settingsInfo.Menus[child.PrototypeId].TitleTranslationId;
      }
      return (
        <ListItem
          button
          key={child.Id}
          onClick={() => {
            handleLevel2Click(child);
            setLevel2Index(index);
          }}
        >
          <ListItemText
            primary={`${level1Index + 1}.${index + 1} ${
              settingsInfo.Translations[i18n.language][titleTranslationId].Text
            }`}
          />
        </ListItem>
      );
    });

    return (
      <List style={{ minWidth: "40ch", maxWidth: "40ch", marginLeft: "8px" }}>
        {reducedLevel.map((element, index) => (
          <div key={element.key}>
            {element}
            {index === reducedLevel.length - 1 ? (
              <></>
            ) : (
              <Divider
                orientation="horizontal"
                style={{ marginTop: "8px", marginBottom: "8px" }}
              />
            )}
          </div>
        ))}
      </List>
    );
  }, [chosenLevel1, timeChannelIndex, settingsInfo, i18n]);

  const renderSettingsSwitch = useCallback(
    (data, menu, structure) => {
      if (!structure) structure = prebuiltSettings[menu.StructureId];
      const handleChange = (e) => {
        let value = e.target.checked ? 1 : 0;
        saveValue(prebuiltSettings, data, structure, value);
      };

      let dxsValue = loadValue(prebuiltSettings, data, structure);
      if (menu.SwitchItem) {
        let labelOn =
          settingsInfo.Translations[i18n.language][
            menu.SwitchItem.OnTitleTranslationId
          ]?.Text;
        if (!labelOn && menu.SwitchItem.OnTitle)
          labelOn = menu.SwitchItem.OnTitle;
        let labelOff =
          settingsInfo.Translations[i18n.language][
            menu.SwitchItem.OffTitleTranslationId
          ]?.Text;
        if (!labelOff && menu.SwitchItem.OffTitle)
          labelOff = menu.SwitchItem.OffTitle;
        return (
          <LabeledSwitch
            defaultChecked={dxsValue !== 0}
            onChange={handleChange}
            color="primary"
            onTitle={labelOn}
            offTitle={labelOff}
          />
        );
      } else {
        return (
          <Switch
            defaultChecked={dxsValue !== 0}
            onChange={handleChange}
            color="primary"
          />
        );
      }
    },
    [data, prebuiltSettings, settingsInfo]
  );

  const renderSettingsSlider = useCallback(
    (data, menu, structure) => {
      if (!structure) structure = prebuiltSettings[menu.StructureId];
      let unitLabel = "";
      let spacedUnitLabel = "";
      let minValue = 0,
        maxValue = 255,
        minLabel = "",
        maxLabel = "",
        step = 1;

      let item = {};
      if (menu.IntegerItem) {
        item = menu.IntegerItem;
      } else if (menu.FloatAsIntItem) {
        item = menu.FloatAsIntItem;
      }
      let factor = item.Factor ? item.Factor : 1;
      minValue = (item.MinValue ? item.MinValue : 0) / factor;
      maxValue = (item.MaxValue ? item.MaxValue : 255) / factor;
      step = item.Step ? item.Step : 1;

      if (item.UnitsTranslationId) {
        unitLabel =
          settingsInfo.Translations[i18n.language][item.UnitsTranslationId]
            ?.Text;
        spacedUnitLabel = " " + unitLabel;
      }

      minLabel = `${minValue * factor}${spacedUnitLabel}`;
      maxLabel = `${maxValue * factor}${spacedUnitLabel}`;

      const marks = [
        {
          value: minValue,
          label: minLabel,
        },
        {
          value: maxValue,
          label: maxLabel,
        },
      ];

      let dxsValue = loadValue(prebuiltSettings, data, structure);
      const handleChange = (e, value) => {
        saveValue(prebuiltSettings, data, structure, value);
      };

      return (
        <SettingSlider
          defaultValue={dxsValue}
          step={step}
          marks={marks}
          min={minValue}
          max={maxValue}
          onChangeCommitted={handleChange}
          valueLabelFormat={(value, index) => {
            if (step < 1) {
              return `${(value * factor).toFixed(2)}`;
            } else {
              return value * factor;
            }
          }}
        />
      );
    },
    [settingsInfo, prebuiltSettings]
  );

  const renderSirenRingtoneMenuItem = useCallback(
    (data, menu, structure) => {
      if (!structure) structure = prebuiltSettings[menu.StructureId];

      let dxsValue = loadValue(prebuiltSettings, data, structure);
      const handleChange = (e, value) => {
        saveValue(prebuiltSettings, data, structure, value);
      };

      return (
        <SettingSirenMenu defaultValue={dxsValue} handleChange={handleChange} />
      );
    },
    [settingsInfo, prebuiltSettings]
  );

  const renderSettingsSelect = (data, menu, structure) => {
    if (!structure) structure = prebuiltSettings[menu.StructureId];
    const handleChange = (e, object) => {
      saveValue(prebuiltSettings, data, structure, e.target.value);
    };

    let dxsValue = loadValue(prebuiltSettings, data, structure);
    let options = [];
    if (menu.OptionsItem) {
      let item = menu.OptionsItem;
      if (item.Value2TranslationId) {
        options = Object.keys(item.Value2TranslationId)
          .sort()
          .map((k) => (
            <MenuItem key={k} value={k}>
              {
                settingsInfo.Translations[i18n.language][
                  item.Value2TranslationId[k]
                ]?.Text
              }
            </MenuItem>
          ));
      }
    }

    return (
      <Select defaultValue={dxsValue} onChange={handleChange}>
        {options}
      </Select>
    );
  };

  const renderTextElement = (data, menu, structure) => {
    if (!structure) structure = prebuiltSettings[menu.StructureId];
    const handleChange = (e) => {
      let value = e.target.value;
      saveValue(prebuiltSettings, data, structure, value);
    };

    let length = 0;
    if (menu.TextItem) {
      let item = menu.TextItem;
      length = item.MaxLength;
    }

    if (length === 0 || length === null || length === undefined) {
      length = 32;
    }

    let dxsValue = loadValue(prebuiltSettings, data, structure);
    return (
      <InputMask
        mask={"*".repeat(length)}
        formatChars={{ "*": "[^\u0000]" }}
        defaultValue={dxsValue}
        onChange={handleChange}
        maskChar={""}
      >
        {() => <TextField />}
      </InputMask>
    );
  };

  const renderSettingsTime = (data, menu, structure) => {
    if (!structure) structure = prebuiltSettings[menu.StructureId];
    let dxsValue = loadValue(prebuiltSettings, data, structure);
    let firstByte = dxsValue >> 8;
    let firstByteText = firstByte.toString().padStart(2, "0");
    let secondByte = dxsValue & 0xff;
    let secondByteText = secondByte.toString().padStart(2, "0");
    let defaultValue = `${firstByteText}:${secondByteText}`;

    const handleChange = (e) => {
      let value = e.target.value;
      let tokens = value.split(":");
      let firstByte = parseInt(tokens[0], 10);
      let secondByte = parseInt(tokens[1], 10);
      let fullValue = (firstByte << 8) | secondByte;
      saveValue(prebuiltSettings, data, structure, fullValue);
    };

    return (
      <InputMask
        mask="99:99"
        defaultValue={defaultValue}
        onChange={handleChange}
      >
        {() => <TextField />}
      </InputMask>
    );
  };

  const renderAutoStartScheduleMenuItemRow = (data, menu) => {
    return (
      <div>
        {renderSettingsTime(data, menu)}
        {renderSettingsSwitch(data, menu)}
      </div>
    );
  };

  const renderAutoStartScheduleMenuItem = (data, menu) => {
    return (
      <div>
        {renderAutoStartScheduleMenuItemRow(data, menu)}
        {renderAutoStartScheduleMenuItemRow(data, menu)}
      </div>
    );
  };

  const renderSettingsElement = useCallback(
    (data, menu, structure) => {
      if (!structure) structure = prebuiltSettings[menu.StructureId];
      if (
        chosenLevel1.Type === SettingsMenuTypesByName["Array"] &&
        menu.StructureId
      ) {
        let blocksSettings = prebuiltSettings[chosenLevel1.StructureId];
        let timeChannelBlock =
          settingsInfo.Structures[blocksSettings.PrototypeId];
        let cleanSettings = timeChannelBlock.Children.find(
          (c) => c.Id === menu.StructureId
        );
        if (cleanSettings.Mapper === SettingsStructureTypesByName.Instance) {
          let prevSettings = cleanSettings;
          cleanSettings = settingsInfo.Structures[prevSettings.PrototypeId];
          structure = {
            ...cleanSettings,
            StartByte:
              cleanSettings.StartByte +
              blocksSettings.StartByte +
              level2Index * blocksSettings.LengthByte +
              prevSettings.StartByte,
          };
        } else {
          structure = {
            ...cleanSettings,
            StartByte:
              cleanSettings.StartByte +
              blocksSettings.StartByte +
              level2Index * blocksSettings.LengthByte,
          };
        }
      }

      let result = <div />;
      switch (menu.Type) {
        case SettingsMenuTypesByName["Composite"]:
          alert(`Unexpected composite type for menu ${menu.Name}`);
          break;
        case SettingsMenuTypesByName["SwitchMenuItem"]:
          result = renderSettingsSwitch(data, menu, structure);
          break;
        case SettingsMenuTypesByName["IntegerMenuItem"]:
          result = renderSettingsSlider(data, menu, structure);
          break;
        case SettingsMenuTypesByName["FloatAsIntMenuItem"]:
          result = renderSettingsSlider(data, menu, structure);
          break;
        case SettingsMenuTypesByName["OptionMenuItem"]:
          result = renderSettingsSelect(data, menu, structure);
          break;
        case SettingsMenuTypesByName["TextMenuItem"]:
          result = renderTextElement(data, menu, structure);
          break;
        case SettingsMenuTypesByName["SirenRingtoneMenuItem"]:
          result = renderSirenRingtoneMenuItem(data, menu, structure);
          break;
        case SettingsMenuTypesByName["VehicleCanCodeMenuItem"]:
          alert(`Unexpected VehicleCanCodeMenuItem type ${menu.Name}`);
          break;
        case SettingsMenuTypesByName["TimeMenuItem"]:
          result = renderSettingsTime(data, menu, structure);
          break;
        case SettingsMenuTypesByName["SystemOutputMenuItem"]:
          alert(`Unexpected SystemOutputMenuItem type ${menu.Name}`);
          break;
        case SettingsMenuTypesByName["SystemInputMenuItem"]:
          alert(`Unexpected SystemInputMenuItem type ${menu.Name}`);
          break;
        case SettingsMenuTypesByName["SystemOutputSwitchMenuItem"]:
          alert(`Unexpected SystemOutputSwitchMenuItem type ${menu.Name}`);
          break;
        case SettingsMenuTypesByName["AutoStartScheduleMenuItem"]:
          result = renderAutoStartScheduleMenuItem(data, menu, structure);
          break;
        case SettingsMenuTypesByName["VoltageCalibrationMenuItem"]:
          result = t("Voltage calibration not supported");
          break;
        case SettingsMenuTypesByName["Inputs"]:
          alert(`Unexpected Inputs type ${menu.Name}`);
          break;
        case SettingsMenuTypesByName["Outputs"]:
          alert(`Unexpected Outputs type ${menu.Name}`);
          break;
        case SettingsMenuTypesByName["Array"]:
          alert(`Unexpected Array type ${menu.Name}`);
          break;
        case SettingsMenuTypesByName["Title"]:
          result = (
            <Typography style={{ textAlign: "center" }}>
              <b>
                {
                  settingsInfo.Translations[i18n.language][
                    menu.TitleTranslationId
                  ]?.Text
                }
              </b>
            </Typography>
          );
          break;
        case SettingsMenuTypesByName["TimeChannel_channels"]:
          alert(`Unexpected TimeChannel_channels type ${menu.Name}`);
          break;
        case SettingsMenuTypesByName["Instance"]:
          let prototype = settingsInfo.Menus[menu.PrototypeId];
          return renderSettingsElement(data, prototype, structure);
        case SettingsMenuTypesByName["Phones"]:
          alert(`Unexpected Phones type ${menu.Name}`);
          break;
        default:
          alert(`Unknown menu type ${menu.Type}`);
      }

      return { finalMenu: menu, result };
    },
    [
      data,
      settingsInfo,
      prebuiltSettings,
      chosenLevel1,
      chosenLevel2,
      level2Index,
      level3Index,
      i18n.language,
    ]
  );

  const getMapInputOutputCell = (structure, channelId, bit) => {
    let customStructure = {
      ...structure,
      Mapper: SettingsStructureTypesByName["UInt"],
      StartByte: structure.StartByte + 4 * channelId,
    };
    const handleChange = (e, value) => {
      let dxsValue = loadValue(prebuiltSettings, data, customStructure);
      let mask = 1 << bit;
      if (value) {
        dxsValue |= mask;
      } else {
        dxsValue &= ~mask;
      }
      saveValue(prebuiltSettings, data, customStructure, dxsValue);
      return 1;
    };

    let dxsValue = loadValue(prebuiltSettings, data, customStructure);
    let mapValue = (dxsValue >> bit) % 2;
    return (
      <IconButtonState
        defaultState={mapValue}
        onClick={handleChange}
        tags={["", "X"]}
      />
    );
  };

  const renderInputElement = (structure, channelId, bit) => {
    return getMapInputOutputCell(structure, channelId, bit);
  };

  const renderOutputElement = (structure, channelId, bit) => {
    return getMapInputOutputCell(structure, channelId, bit);
  };

  const loadFromSelectFunctionArrays = (
    selectStructure,
    functionStructure,
    channelId,
    bit
  ) => {
    let count = selectStructure.Count;
    for (var i = 0; i < count; i++) {
      let customSelectStructure = {
        ...selectStructure,
        Mapper: SettingsStructureTypesByName["UByte"],
        StartByte: selectStructure.StartByte + i,
      };
      let dxsValue = loadValue(prebuiltSettings, data, customSelectStructure);
      if (dxsValue === bit + 1) {
        let customFunctionStructure = {
          ...functionStructure,
          Mapper: SettingsStructureTypesByName["UByte"],
          StartByte: functionStructure.StartByte + i,
        };
        let dxsValue2 = loadValue(
          prebuiltSettings,
          data,
          customFunctionStructure
        );
        if (dxsValue2 === channelId + 1) {
          return dxsValue2;
        }
      }
    }
    return 0;
  };

  const saveFromSelectFunctionArrays = (
    selectStructure,
    functionStructure,
    channelId,
    bit
  ) => {
    let count = selectStructure.Count;
    for (var i = 0; i < count; i++) {
      let customSelectStructure = {
        ...selectStructure,
        Mapper: SettingsStructureTypesByName["UByte"],
        StartByte: selectStructure.StartByte + i,
      };
      let dxsValue = loadValue(prebuiltSettings, data, customSelectStructure);
      if (dxsValue === 0) {
        let customFunctionStructure = {
          ...functionStructure,
          Mapper: SettingsStructureTypesByName["UByte"],
          StartByte: functionStructure.StartByte + i,
        };
        saveValue(prebuiltSettings, data, customSelectStructure, bit + 1);
        saveValue(
          prebuiltSettings,
          data,
          customFunctionStructure,
          channelId + 1
        );
        return 1;
      }
    }
    alert("Too much functions");
    return 0;
  };

  const removeFromSelectFunctionArrays = (
    selectStructure,
    functionStructure,
    channelId,
    bit
  ) => {
    let count = selectStructure.Count;
    for (var i = 0; i < count; i++) {
      let customSelectStructure = {
        ...selectStructure,
        Mapper: SettingsStructureTypesByName["UByte"],
        StartByte: selectStructure.StartByte + i,
      };
      let dxsValue = loadValue(prebuiltSettings, data, customSelectStructure);
      if (dxsValue === bit + 1) {
        let customFunctionStructure = {
          ...functionStructure,
          Mapper: SettingsStructureTypesByName["UByte"],
          StartByte: functionStructure.StartByte + i,
        };
        let dxsValue2 = loadValue(
          prebuiltSettings,
          data,
          customFunctionStructure
        );
        if (dxsValue2 === channelId + 1) {
          saveValue(prebuiltSettings, data, customSelectStructure, 0);
          saveValue(prebuiltSettings, data, customFunctionStructure, 0);
        }
      }
    }
    return 0;
  };

  const getAfter38MapInputOutputCell = (
    selectStructure,
    functionStructure,
    channelId,
    bit
  ) => {
    const handleChange = (e, value) => {
      let dxsValue = loadFromSelectFunctionArrays(
        selectStructure,
        functionStructure,
        channelId,
        bit
      );
      if (dxsValue) {
        removeFromSelectFunctionArrays(
          selectStructure,
          functionStructure,
          channelId,
          bit
        );
      } else {
        if (
          !saveFromSelectFunctionArrays(
            selectStructure,
            functionStructure,
            channelId,
            bit
          )
        ) {
          return 0;
        }
      }
      return 1;
    };

    let dxsValue = loadFromSelectFunctionArrays(
      selectStructure,
      functionStructure,
      channelId,
      bit
    );
    let mapValue = dxsValue ? 1 : 0;
    return (
      <IconButtonState
        defaultState={mapValue}
        onClick={handleChange}
        tags={["", "X"]}
      />
    );
  };

  const renderAfter38InputElement = (
    selectStructure,
    functionStructure,
    channelId,
    bit
  ) => {
    return getAfter38MapInputOutputCell(
      selectStructure,
      functionStructure,
      channelId,
      bit
    );
  };

  const renderAfter38OutputElement = (
    selectStructure,
    functionStructure,
    channelId,
    bit
  ) => {
    return getAfter38MapInputOutputCell(
      selectStructure,
      functionStructure,
      channelId,
      bit
    );
  };

  const renderInputs = (inputsStructure) => {
    let alarmMenu = Object.values(settingsInfo.AlarmMenus).find(
      (am) => am.AlarmId === chosenAlarm
    );
    let channels = settingsInfo.Channels[alarmMenu.ChannelsId];
    let ios = channels.Ios.filter((i) => i.Type === 1);
    let logics = settingsInfo.Logics[channels.InputLogics];

    let after38SelectStructure = Object.values(prebuiltSettings).filter(
      (s) => s.Name === "Input_Select"
    )?.[0];
    let after38FunctionStructure = Object.values(prebuiltSettings).filter(
      (s) => s.Name === "Input_Function_Select"
    )?.[0];

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {ios.map((io, i) => {
              let ncMenu = null;
              if (io.NcStructureId) {
                ncMenu = {
                  Id: 32,
                  Name: `NO_NC_SWITCH_${i}`,
                  Type: 2,
                  SwitchItem: { OnTitle: "NC", OffTitle: "NO" },
                };
              }
              let name = he.decode(io.Name);
              if (io.DefaultPolarity === 1) name += "-";
              if (io.DefaultPolarity === 2) name += "+";
              return (
                <TableCell key={`IN_${i}`} align="center">
                  {name}
                  {ncMenu ? (
                    renderSettingsSwitch(
                      data,
                      ncMenu,
                      prebuiltSettings[io.NcStructureId]
                    )
                  ) : (
                    <></>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {logics.Channels.map((logic, index) => (
            <TableRow key={index} size="small">
              <TableCell align="center">
                {
                  settingsInfo.Translations[i18n.language][
                    logic.NameTranslationId
                  ]?.Text
                }
              </TableCell>
              {ios.map((io, i) => {
                if (
                  logic.ChannelId > 37 &&
                  after38SelectStructure &&
                  after38FunctionStructure
                ) {
                  return (
                    <TableCell
                      key={io.Id}
                      style={{ background: "#dedede" }}
                      align="center"
                    >
                      {renderAfter38InputElement(
                        after38SelectStructure,
                        after38FunctionStructure,
                        logic.ChannelId,
                        io.Number
                      )}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell key={io.Id} align="center">
                      {renderInputElement(
                        inputsStructure,
                        logic.ChannelId,
                        io.Number
                      )}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderOutputs = (outputsStructure) => {
    let alarmMenu = Object.values(settingsInfo.AlarmMenus).find(
      (am) => am.AlarmId === chosenAlarm
    );
    let channels = settingsInfo.Channels[alarmMenu.ChannelsId];
    let ios = channels.Ios.filter((i) => i.Type === 2);
    let logics = settingsInfo.Logics[channels.OutputLogics];

    let after38SelectStructure = Object.values(prebuiltSettings).filter(
      (s) => s.Name === "Channel_Select"
    )?.[0];
    let after38FunctionStructure = Object.values(prebuiltSettings).filter(
      (s) => s.Name === "Function_Select"
    )?.[0];

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {ios.map((io, i) => {
              let polarityMenu = null;
              if (io.PolarityStructureId) {
                polarityMenu = {
                  Id: 32,
                  Name: `POLARITY_SWITCH_${i}`,
                  Type: 2,
                  SwitchItem: { OnTitle: "+", OffTitle: "-" },
                };
              }
              return (
                <TableCell key={`OUT_${i}`} align="center">
                  {he.decode(io.Name)}
                  {polarityMenu ? (
                    renderSettingsSwitch(
                      data,
                      polarityMenu,
                      prebuiltSettings[io.PolarityStructureId]
                    )
                  ) : (
                    <></>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {logics.Channels.map((logic, index) => (
            <TableRow key={index} size="small">
              <TableCell align="center">
                {
                  settingsInfo.Translations[i18n.language][
                    logic.NameTranslationId
                  ]?.Text
                }
              </TableCell>
              {ios.map((io, i) => {
                if (
                  logic.ChannelId > 37 &&
                  after38SelectStructure &&
                  after38FunctionStructure
                ) {
                  return (
                    <TableCell
                      key={io.Id}
                      style={{ background: "#dedede" }}
                      align="center"
                    >
                      {renderAfter38OutputElement(
                        after38SelectStructure,
                        after38FunctionStructure,
                        logic.ChannelId,
                        io.Number
                      )}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell key={io.Id} align="center">
                      {renderOutputElement(
                        outputsStructure,
                        logic.ChannelId,
                        io.Number
                      )}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const getMapCallRow = (structure, index, bit) => {
    let customStructure = {
      ...structure,
      Mapper: SettingsStructureTypesByName.Bit,
      StartByte: structure.StartByte + index,
      StartBit: bit,
    };
    const handleChange = (e, value) => {
      let dxsValue = loadValue(prebuiltSettings, data, customStructure);
      let mask = 1 << bit;
      if (value) {
        dxsValue |= mask;
      } else {
        dxsValue &= ~mask;
      }
      saveValue(prebuiltSettings, data, customStructure, dxsValue);
      return 1;
    };

    let dxsValue = loadValue(prebuiltSettings, data, customStructure);
    return (
      <IconButtonState
        defaultState={dxsValue}
        onClick={handleChange}
        tags={["", <PhoneIcon />]}
      />
    );
  };

  const getPhoneRowElement = (structure, index, bit) => {
    return getMapCallRow(structure, index, bit);
  };

  const renderPhones = (menu) => {
    let phones = menu.Children;
    let prototype = settingsInfo.Menus[phones[0].PrototypeId];
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {phones.map((phone, i) => {
              let translationId = phone.TitleTranslationId;
              if (!translationId) {
                translationId =
                  settingsInfo.Menus[phone.PrototypeId].TitleTranslationId;
              }
              return (
                <TableCell key={phone.Id} align="center">
                  {translationId
                    ? he.decode(
                        settingsInfo.Translations[i18n.language][translationId]
                          ?.Text
                      )
                    : ""}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {prototype.Children.map((fun, index) => (
            <TableRow size="small">
              <TableCell align="center">
                {
                  settingsInfo.Translations[i18n.language][
                    fun.TitleTranslationId
                  ]?.Text
                }
              </TableCell>
              {phones.map((phone, i) => {
                return (
                  <TableCell key={phone.Id} align="center">
                    {phone.StructureId ? (
                      getPhoneRowElement(
                        prebuiltSettings[fun.StructureId],
                        fun.Index,
                        i
                      )
                    ) : (
                      <></>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderTimeChannelChannels = useCallback(
    (structure) => {
      let alarmMenu = Object.values(settingsInfo.AlarmMenus).find(
        (am) => am.AlarmId === chosenAlarm
      );
      let channels = settingsInfo.Channels[alarmMenu.ChannelsId];
      let ios = channels.Ios.filter((i) => i.Type === 2);

      const handleChange = (e, number) => {
        let value = e.target.checked ? 1 : 0;

        let dxsValue = loadValue(prebuiltSettings, data, structure);
        let mask = 1 << number;
        if (value) {
          dxsValue |= mask;
        } else {
          dxsValue &= ~mask;
        }
        saveValue(prebuiltSettings, data, structure, dxsValue);
      };

      let dxsValue = loadValue(prebuiltSettings, data, structure);
      return (
        <List style={{ minWidth: "60ch", marginLeft: "8px", width: "65ch" }}>
          {ios.map((io) => {
            let dxsValueBit = (dxsValue >> io.Number) % 2;
            return (
              <div key={level2Index * 100 + io.Id}>
                <Checkbox
                  defaultChecked={dxsValueBit !== 0}
                  onChange={(e) => handleChange(e, io.Number)}
                />
                {io.Name}
              </div>
            );
          })}
        </List>
      );
    },
    [chosenLevel1, level2Index, settingsInfo]
  );

  const renderLevel3 = useCallback(() => {
    if (!settingsInfo || !chosenLevel2) {
      return <></>;
    }

    if (chosenLevel2.Type === SettingsMenuTypesByName["Inputs"]) {
      return renderInputs(prebuiltSettings[chosenLevel2.StructureId]);
    } else if (chosenLevel2.Type === SettingsMenuTypesByName["Outputs"]) {
      return renderOutputs(prebuiltSettings[chosenLevel2.StructureId]);
    } else if (
      chosenLevel2.Type === SettingsMenuTypesByName["TimeChannel_channels"]
    ) {
      let blocksSettings = prebuiltSettings[chosenLevel1.StructureId];
      let timeChannelBlock =
        settingsInfo.Structures[blocksSettings.PrototypeId];
      let cleanSettings = timeChannelBlock.Children.find(
        (c) => c.Id === chosenLevel2.StructureId
      );
      let structure = {
        ...cleanSettings,
        Mapper: SettingsStructureTypesByName.UInt,
        StartByte:
          cleanSettings.StartByte +
          blocksSettings.StartByte +
          level2Index * blocksSettings.LengthByte,
      };
      return renderTimeChannelChannels(structure);
    }

    if (!chosenLevel2.PrototypeId) {
      return <></>;
    }
    let prototypeMenu = settingsInfo.Menus[chosenLevel2.PrototypeId];
    if (
      prototypeMenu.Children?.some(
        (c) => c.Type === SettingsMenuTypesByName["Phones"]
      )
    ) {
      return renderPhones(prototypeMenu);
    }

    let indexShift = 0;
    let elements = prototypeMenu.Children?.sort((a, b) =>
      a.Order > b.Order ? 1 : -1
    )?.map((child, index) => {
      if (child.Privacy === 1) return null;

      let titleTranslationId = child.TitleTranslationId;
      if (!titleTranslationId && child.PrototypeId) {
        titleTranslationId =
          settingsInfo.Menus[child.PrototypeId].TitleTranslationId;
      }

      let number = "";
      if (timeChannelIndex !== null) {
        number = `${level1Index + 1}.${level2Index + 1}.${level3Index + 1}.${
          index + 1 + indexShift
        }`;
      } else {
        number = `${level1Index + 1}.${level2Index + 1}.${
          index + 1 + indexShift
        }`;
      }

      let { finalMenu, result } = renderSettingsElement(data, child, null);
      let title = `${number} ${he.decode(
        settingsInfo.Translations[i18n.language][titleTranslationId].Text
      )}`;
      if (finalMenu.Type === SettingsMenuTypesByName.SwitchMenuItem) {
        return (
          <div
            key={`${child.Id}-${number}`}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ListItemText primary={title} />
            {result}
          </div>
        );
      } else if (finalMenu.Type === SettingsMenuTypesByName.Title) {
        indexShift -= 1;
        return (
          <div key={`${child.Id}-${number}`} style={{ width: "100%" }}>
            {result}
          </div>
        );
      } else {
        return (
          <div key={`${child.Id}-${number}`} style={{ width: "100%" }}>
            <ListItemText primary={title} />
            {result}
          </div>
        );
      }
    });

    return (
      <List style={{ minWidth: "60ch", marginLeft: "8px", width: "65ch" }}>
        {elements.map((element, index) => (
          <div key={element ? element.key : index}>
            {element}
            {index === elements.length - 1 || element === null ? (
              <></>
            ) : (
              <Divider
                orientation="horizontal"
                style={{ marginTop: "8px", marginBottom: "8px" }}
              />
            )}
          </div>
        ))}
      </List>
    );
  }, [
    chosenLevel2,
    level2Index,
    level3Index,
    timeChannelIndex,
    settingsInfo,
    i18n,
  ]);

  const handleSaveClick = () => {
    try {
      var serializedData = bytesToHex(data);
    } catch {
      alert("Byte to hex serializing error");
    }

    var dxsObject = {
      ...settingsData,
      settings: serializedData,
    };
    const infoData = new Blob([EncodeUTF16LE(JSON.stringify(dxsObject))], {
      type: "text/plain;charset=UTF-16LE;",
    });
    FileSaver.saveAs(infoData, "settingsEditorResult.dxs");
  };

  const renderCarModelElement = (data) => {
    let alarmMenu = Object.values(settingsInfo.AlarmMenus).find(
      (am) => am.AlarmId === chosenAlarm
    );
    let rootMenu = settingsInfo.Menus[alarmMenu.RootMenuId];
    let reducedLevel = rootMenu.Children.filter(
      (c) => c.Type === SettingsMenuTypesByName.VehicleCanCodeMenuItem
    );
    if (!reducedLevel) return <></>;
    let canCodeMenu = reducedLevel[0];
    let canCodeStructure = prebuiltSettings[canCodeMenu.StructureId];

    let dxsValue = loadValue(prebuiltSettings, data, canCodeStructure);
    if (
      !dxsValue ||
      dxsValue[0] === undefined ||
      dxsValue[1] === undefined ||
      dxsValue[2] === undefined
    )
      return <></>;
    let firstByte = dxsValue[0];
    let firstByteText = firstByte.toString(16).padStart(2, "0");
    let secondByte = dxsValue[1];
    let secondByteText = secondByte.toString(16).padStart(2, "0");
    let defaultValue = `${firstByteText}${secondByteText}`;
    let carInfo = dxsValue[2];

    const handleChange = (e) => {
      let value = e.target.value;
      let newFirstByte = parseInt(value.substring(0, 2), 16);
      let newSecondByte = parseInt(value.substring(2, 4), 16);
      let fullValue = [newFirstByte, newSecondByte, carInfo];
      saveValue(prebuiltSettings, data, canCodeStructure, fullValue);
    };

    return (
      <InputMask
        mask={"9999"}
        defaultValue={defaultValue}
        onChange={handleChange}
      >
        {() => <TextField />}
      </InputMask>
    );
  };

  return settingsInfo && data && data.length ? (
    isAlarmAvailable ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            flex: "0 1 auto",
            padding: "24px",
            paddingBottom: "0px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {canMenu ? (
              <div>
                <ListItemText
                  primary={t("Car model")}
                  style={{ paddingRight: "24px" }}
                />
                {renderCarModelElement(data)}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ paddingLeft: "16px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSaveClick()}
              >
                <SaveIcon />
                {t("Save")}
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{
            flex: "1 1 auto",
            padding: "24px",
            paddingTop: "0px",
            display: "flex",
            flexDirection: "row",
            overflow: "scroll",
          }}
        >
          <div style={{ padding: "16px", overflowY: "auto" }}>
            {renderLevel1()}
          </div>
          <div style={{ padding: "16px", overflowY: "auto" }}>
            {renderLevel2()}
          </div>
          <div style={{ padding: "16px", overflowY: "auto" }}>
            {renderLevel3()}
          </div>
        </div>
      </div>
    ) : (
      <Typography>Alarm not found</Typography>
    )
  ) : (
    <></>
  );
}
