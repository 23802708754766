import {
    Badge
} from "@mui/material";

import styles from "./styles.css";

const StyledBadge = (props) => {
    return <Badge className={styles.badge} {...props} />;
}

export {
    StyledBadge
}
