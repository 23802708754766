import {
  Home as HomeIcon,
  People as PeopleIcon,
  Settings as ConfigsIcon,
  FormatListBulleted as LogsIcon,
  Dvr as MountsIcon,
  VpnKey as ClonesIcon,
  NotificationsActive as PushesIcon,
  Work as EditSettingsIcon,
  Visibility as VisibilityIcon,
  HeadsetMic as TechsupportRequestsIcon,
  ChatBubble as ChatPageIcon,
  Pageview as PackageSearchIcon,
  Timeline as StatisticsIcon,
  GetApp as ManageVersionsIcon,
  Feedback as FeedbackIcon,
  AccountBox as ManageAccountIcon,
  Email as MailingIcon,
  DirectionsCar as CarBrandsIcon,
  DeveloperBoard as DevicesIcon,
  AccountTree as MenuEditorIcon,
  Translate as TranslationsIcon,
  Build as AutohelperLogsIcon,
  ShoppingCart as OrderRequestsIcon,
  Assignment as ArticlesIcon,
  ContactPage as OpenManualRequestsIcon,
} from "@mui/icons-material";

import { UserPermissions, UserRolesStringToInt } from "./enums/UserRoles";

function generateSidebarPageList(t, iconStyle) {
  var structure = [
    {
      id: 0,
      label: t("Main page"),
      link: "/app/dashboard",
      icon: <HomeIcon style={iconStyle} />,
      comment: "",
    },
  ];
  structure.push({
    id: 16,
    label: t("Manage account"),
    link: "/app/manageAccount",
    icon: <ManageAccountIcon style={iconStyle} />,
    comment: t("Manage account comment"),
  });

  var permissions =
    JSON.parse(localStorage.getItem("permissions") || "[]") || [];

  if (permissions.includes(UserPermissions.USERS_LIST)) {
    structure.push({
      id: 1,
      label: t("Users"),
      link: "/app/manageUsers",
      icon: <PeopleIcon style={iconStyle} />,
      comment: t("Manage users page comment"),
    });
  }

  if (
    permissions.includes(UserPermissions.GET_OWN_USERGROUP) ||
    permissions.includes(UserPermissions.GET_ANY_USERGROUP)
  ) {
    structure.push({
      id: 9,
      label: t("Usergroup"),
      link: "/app/usergroupPage",
      icon: <VisibilityIcon style={iconStyle} />,
      comment: t("Usergroup page comment"),
    });
  }

  if (permissions.includes(UserPermissions.LIST_TECHSUPPORT_REQUESTS)) {
    structure.push({
      id: 10,
      label: t("Techsupport requests"),
      link: "/app/techsupportRequests",
      icon: <TechsupportRequestsIcon style={iconStyle} />,
      comment: t("Techsupport requests page comment"),
    });
  }

  if (permissions.includes(UserPermissions.CHAT_ANY)) {
    structure.push({
      id: 11,
      label: t("Chat rooms page"),
      link: "/app/chatRoomsPage",
      icon: <ChatPageIcon style={iconStyle} />,
      comment: t("Chat rooms page comment"),
    });
  }

  if (permissions.includes(UserPermissions.OPEN_MANUAL_REQUESTS_FULL)) {
    structure.push({
      id: 26,
      label: t("Open manuals"),
      link: "/app/openManuals",
      icon: <OpenManualRequestsIcon style={iconStyle} />,
      comment: t("Open manuals"),
    });
  }

  if (
    permissions.includes(UserPermissions.CONFIGURATIONS_LIST_ALL) ||
    permissions.includes(UserPermissions.CONFIGURATIONS_EDIT_OWN)
  ) {
    structure.push({
      id: 2,
      label: t("Configurations"),
      link: "/app/configsQuestions",
      icon: <ConfigsIcon style={iconStyle} />,
      comment: t("Configurations page comment"),
    });
  }

  if (permissions.includes(UserPermissions.MOUNTS_LIST)) {
    structure.push({
      id: 5,
      label: t("Fast mounts"),
      link: "/app/fastMounts",
      icon: <MountsIcon style={iconStyle} />,
      comment: t("Fast mounts page comment"),
    });
  }

  if (permissions.includes(UserPermissions.LOGS_LIST)) {
    structure.push({
      id: 4,
      label: t("Mount events"),
      link: "/app/events",
      icon: <LogsIcon style={iconStyle} />,
      comment: t("Mount event page comment"),
    });
  }

  if (permissions.includes(UserPermissions.CLONE_LIST)) {
    structure.push({
      id: 6,
      label: t("Clones"),
      link: "/app/clones",
      icon: <ClonesIcon style={iconStyle} />,
      comment: t("Clone page comment"),
    });
  }

  let sendPushesAllowed = permissions.includes(UserPermissions.SEND_PUSHES);
  let sendPushElement = {
    id: 7,
    label: "Push",
    link: "/app/pushes",
    icon: <PushesIcon style={iconStyle} />,
    comment: t("Pushes page comment"),
  };
  let mailingAllowed = permissions.includes(UserPermissions.MAILING);
  let mailingElement = {
    id: 17,
    label: "E-mail",
    link: "/app/mailing",
    icon: <MailingIcon style={iconStyle} />,
    comment: t("Mailing"),
  };
  let autohelperAllowed = permissions.includes(
    UserPermissions.MANAGE_AUTOHELPER
  );
  let autohelperElement = {
    id: 23,
    label: t("Autohelper"),
    link: "/app/autohelperLogs",
    icon: <AutohelperLogsIcon style={iconStyle} />,
    comment: t("Autohelper"),
  };
  let elementCount = [
    sendPushesAllowed,
    mailingAllowed,
    autohelperAllowed,
  ].filter((c) => c).length;
  let elementsRoot = structure;
  if (elementCount > 1) {
    let root = {
      id: 18,
      label: t("Mailing"),
      link: "",
      icon: <MailingIcon style={iconStyle} />,
      comment: t("Mailing"),
      children: [],
    };
    structure.push(root);
    elementsRoot = root.children;
  }
  if (sendPushesAllowed) {
    elementsRoot.push(sendPushElement);
  }
  if (mailingAllowed) {
    elementsRoot.push(mailingElement);
  }
  if (autohelperAllowed) {
    elementsRoot.push(autohelperElement);
  }

  if (permissions.includes(UserPermissions.SETTINGS_LIST)) {
    structure.push({
      id: 8,
      label: t("Settings editor"),
      link: "/app/settingsEditorPage",
      icon: <EditSettingsIcon style={iconStyle} />,
      comment: t("Settings editor page comment"),
    });
  }

  structure.push({
    id: 12,
    label: t("Package search"),
    link: "/app/packageSearch",
    icon: <PackageSearchIcon style={iconStyle} />,
    comment: t("Package search"),
  });

  if (permissions.includes(UserPermissions.ADVANCED_STATISTICS)) {
    if (
      localStorage.getItem("role") === UserRolesStringToInt.ANALYST.toString()
    ) {
      structure.splice(2, 0, {
        id: 13,
        label: t("Statistics"),
        link: "/app/statistics",
        icon: <StatisticsIcon style={iconStyle} />,
        comment: t("Statistics comment"),
      });
    } else {
      structure.push({
        id: 13,
        label: t("Statistics"),
        link: "/app/statistics",
        icon: <StatisticsIcon style={iconStyle} />,
        comment: t("Statistics comment"),
      });
    }
  }

  if (permissions.includes(UserPermissions.UPDATE_CHANNEL_WRITE)) {
    structure.push({
      id: 14,
      label: t("Manage versions"),
      link: "/app/manageUpdateChannelFiles",
      icon: <ManageVersionsIcon style={iconStyle} />,
      comment: t("Manage versions"),
    });
  }

  if (permissions.includes(UserPermissions.FEEDBACKS_LIST_ALL)) {
    structure.push({
      id: 15,
      label: t("Feedbacks"),
      link: "/app/feedbacks",
      icon: <FeedbackIcon style={iconStyle} />,
      comment: t("Feedbacks"),
    });
  }

  if (permissions.includes(UserPermissions.MANAGE_BRANDS)) {
    structure.push({
      id: 19,
      label: t("Car brands"),
      link: "/app/carBrands",
      icon: <CarBrandsIcon style={iconStyle} />,
      comment: t("Car brands"),
    });
  }

  if (permissions.includes(UserPermissions.MANAGE_DEVICES)) {
    structure.push({
      id: 20,
      label: t("Devices"),
      link: "/app/devices",
      icon: <DevicesIcon style={iconStyle} />,
      comment: t("Devices"),
    });
  }

  if (permissions.includes(UserPermissions.MANAGE_STRUCTURES)) {
    structure.push({
      id: 21,
      label: t("Menu editor"),
      link: "/app/menuEditor",
      icon: <MenuEditorIcon style={iconStyle} />,
      comment: t("Menu editor"),
    });
  }

  if (permissions.includes(UserPermissions.MANAGE_TRANSLATIONS)) {
    structure.push({
      id: 22,
      label: t("Translations"),
      link: "/app/translations",
      icon: <TranslationsIcon style={iconStyle} />,
      comment: t("Translations"),
    });
  }

  if (permissions.includes(UserPermissions.ORDER_REQUESTS_LIST)) {
    structure.push({
      id: 24,
      label: t("Order requests"),
      link: "/app/orderRequests",
      icon: <OrderRequestsIcon style={iconStyle} />,
      comment: t("Order requests"),
    });
  }

  if (permissions.includes(UserPermissions.MANAGE_ARTICLES)) {
    structure.push({
      id: 25,
      label: t("Articles"),
      link: "/app/articles",
      icon: <ArticlesIcon style={iconStyle} />,
      comment: t("Articles"),
    });
  }

  return structure;
}

export { generateSidebarPageList };
