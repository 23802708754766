import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
} from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";
import { formatDate } from "../helpers/StringUtils";
import FirmwareList from "./FirmwareList";
import { useTranslation } from "react-i18next";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import { UserPermissions } from "../enums/UserRoles";
import { DialogActionTypes, useDialogContext } from "../context/DialogContext";

export default function PackageSearcher({ deviceId, setError }) {
  const { t } = useTranslation();
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const [isInitializing, setIsInitializing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState({});
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  var permissions =
    JSON.parse(localStorage.getItem("permissions") || "[]") || [];
  var hideLogsLink = !permissions.includes(UserPermissions.LOGS_LIST);
  var hideConfigLink = !permissions.includes(
    UserPermissions.VIEW_DEVICE_LAST_CONFIG
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  const sendRequest = useCallback(
    (deviceId) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      setIsLoading(true);
      setError("");
      backend.bckPackagesFind(deviceId).then((json) => {
        --updatesLockCounter.current;
        setIsLoading(false);
        if (lastRequestId.current === id) {
          if (json.code === 0) {
            setInfo(json.content);
          } else if (json.code === ResponseCode.ACCESS_DENIED) {
            setError(t("AUTH_ERROR"));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        }

        if (isInitializing) {
          setIsInitializing(false);
        }
      });
    },
    [backend, isInitializing, setError, t]
  );

  useEffect(() => {
    if (deviceId) {
      sendRequest(deviceId);
    }
  }, [deviceId, sendRequest]);

  const onOpenLastConfiguration = (deviceId) => {
    setIsLoading(true);
    backend.bckLogsGetLastConfiguration(deviceId).then((json) => {
      setIsLoading(false);
      if (json.code === 0) {
        let response = json.content;
        if (response.data && response.deviceName) {
          let dxsObject = {
            code: "",
            id_car: "",
            year: "",
            car_type: "",
            reg_number: "",
            installer: "",
            owner: "",
            install_date: "",
            comment: "",
            settings: response.data,
          };

          dialogDispatch({
            type: DialogActionTypes.SettingsEditorDialogOpen,
            data: dxsObject,
            alarmName: response.deviceName,
          });
        } else {
          dialogDispatch({
            type: DialogActionTypes.SimpleOutputDialogOpen,
            title: t("Last configuration"),
            text: t("No data"),
            textLabel: "",
            noTextField: true,
          });
        }
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "8px" }}
        >
          <CircularProgress size={64} />
        </div>
      ) : (
        <div>
          {info ? (
            <>
              {info.component ? (
                <div style={{ marginTop: "24px" }}>
                  <Typography variant="h5">{info.component.name}</Typography>
                  {info.component.accountId ? (
                    <Typography variant="body2">
                      ID: {info.component.accountId}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {hideLogsLink ? (
                    <></>
                  ) : (
                    <Typography variant="body2">
                      {t("Events")}:{" "}
                      {info.component.anyLogs ? (
                        <Link
                          component="button"
                          style={{
                            fontSize: "inherit",
                            verticalAlign: "baseline",
                          }}
                          onClick={() =>
                            window.open(
                              `#/app/events?deviceId=${info.component.accountId}`,
                              "_blank"
                            )
                          }
                        >
                          {t("Open")}
                        </Link>
                      ) : (
                        t("No data")
                      )}
                    </Typography>
                  )}
                  {hideConfigLink ? (
                    <></>
                  ) : (
                    <Typography variant="body2">
                      {t("Last configuration")}:{" "}
                      {info.component.anyLogs ? (
                        <Link
                          component="button"
                          style={{
                            fontSize: "inherit",
                            verticalAlign: "baseline",
                          }}
                          onClick={() =>
                            onOpenLastConfiguration(info.component.accountId)
                          }
                        >
                          {t("Open")}
                        </Link>
                      ) : (
                        t("No data")
                      )}
                    </Typography>
                  )}
                  <Typography variant="body2">
                    {t("Serial number")}: {info.component.serial}
                  </Typography>
                  {info.component.factoryDate ? (
                    <Typography variant="body2">
                      {t("Creation time")}:{" "}
                      {formatDate(info.component.factoryDate)}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  <Typography variant="body2">
                    <b>{t("Firmware version")}:</b>
                  </Typography>
                  <FirmwareList list={info.component.firmwares} />
                </div>
              ) : (
                <></>
              )}
              {info.package ? (
                <div style={{ marginTop: "24px" }}>
                  <Typography variant="h5">
                    {t("Package")} {info.package.name}
                  </Typography>
                  <Typography variant="body2">
                    {t("Serial number")}: {info.package.serial}
                  </Typography>
                  <Typography variant="body2">
                    {t("Creation time")}: {formatDate(info.package.packageDate)}
                  </Typography>
                  <Table size="small">
                    <TableHead>
                      <TableRow key="header">
                        <TableCell key={1} style={{ fontWeight: "bold" }}>
                          {t("Name/Title")}
                        </TableCell>
                        <TableCell key={2} style={{ fontWeight: "bold" }}>
                          {t("Serial number")}
                        </TableCell>
                        <TableCell key={3} style={{ fontWeight: "bold" }}>
                          {t("Firmware version")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {info.package.components &&
                        info.package.components.map((component) => (
                          <React.Fragment key={component.serial}>
                            <TableRow>
                              <TableCell>{component.name}</TableCell>
                              <TableCell>{component.serial}</TableCell>
                              <TableCell>
                                <FirmwareList list={component.firmwares} />
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>No data</>
          )}
        </div>
      )}
    </>
  );
}
