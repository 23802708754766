import React, { useState, useEffect, useRef, useCallback } from "react";

import { Grid, Typography, Button, Paper } from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import { useBackend } from "../context/BackendContext";
import LoadingOverlay from "react-loading-overlay-ts";
import { ResponseCode } from "../enums/ResponseCode";
import { LanguageConverter } from "../enums/LanguageConverter";
import CarMountingPointGuideTypesTable from "../components/Tables/CarMountingPointGuideTypesTable";
import { DialogActionTypes, useDialogContext } from "../context/DialogContext";

export default function CarMountingPointGuideTypes() {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [tableData, setTableData] = useState([]);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshTable = useCallback(
    (lang) => {
      setIsLoading(true);
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend.bckCarsMountingPointGuideTypeGet(lang).then((json) => {
        setIsLoading(false);
        --updatesLockCounter.current;
        if (lastRequestId.current === id) {
          if (json.code === 0) {
            setTableData((prev) => {
              if (updatesLockCounter.current === 0) {
                json.content.sort((a, b) => a.order - b.order);
                return json.content;
              } else {
                return prev;
              }
            });
          } else if (json.code === ResponseCode.ACCESS_DENIED) {
            setError(t("AUTH_ERROR"));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        }
      });
    },
    [t, backend]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    refreshTable(LanguageConverter[i18n.language]);
  }, [i18n.language]);

  const handleAddType = () => {
    backend
      .bckCarsMountingPointGuideTypeCreateOrUpdate(i18n.language, {
        id: null,
        order: 0,
      })
      .then((json) => {
        if (json.content) {
          setTableData((prev) => {
            let next = [{ id: json.content, order: 0 }, ...prev];
            next.sort((a, b) => a.order - b.order);
            return next;
          });
        }
      });
  };

  const handleDelete = (type) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete type?"),
      handleConfirm: () => {
        backend.bckCarsMountingPointGuideTypeRemove(type.id).then((json) => {
          if (json.content) {
            setTableData((prev) => {
              let next = [...prev];
              let targetIndex = next.findIndex((r) => r.id === type.id);
              next.splice(targetIndex, 1);
              return next;
            });
          }
        });
      },
    });
  };

  const handleEditName = (type) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newName) => {
        setTableData((prev) => {
          var foundIndex = prev.findIndex((t) => t.id === type.id);
          prev[foundIndex].name = newName;
          return [...prev];
        });
        backend.bckCarsMountingPointGuideTypeCreateOrUpdate(i18n.language, {
          id: type.id,
          name: newName,
        });
      },
      initialValue: type.name,
      textLabel: t("Name"),
      title: t("Name"),
    });
  };

  const handleEditOrder = (type) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newOrder) => {
        setTableData((prev) => {
          var foundIndex = prev.findIndex((t) => t.id === type.id);
          prev[foundIndex].order = newOrder;
          var newArray = [...prev];
          newArray.sort((a, b) => a.order - b.order);
          return newArray;
        });
        backend.bckCarsMountingPointGuideTypeCreateOrUpdate(i18n.language, {
          id: type.id,
          order: newOrder,
        });
      },
      initialValue: "" + type.order,
      textLabel: t("Order"),
      title: t("Order"),
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 4);
      },
    });
  };

  return (
    <LoadingOverlay active={isLoading} spinner>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("Guide types")}
          </Typography>
          <Grid item container direction="row">
            <Grid item>
              <Button
                onClick={handleAddType}
                variant="contained"
                color="primary"
              >
                {t("Add")}
              </Button>
            </Grid>
          </Grid>
          <Typography color="secondary">{error}</Typography>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            <CarMountingPointGuideTypesTable
              data={tableData}
              handleDelete={handleDelete}
              handleEditName={handleEditName}
              handleEditOrder={handleEditOrder}
            />
          </div>
        </Grid>
      </Paper>
    </LoadingOverlay>
  );
}
