import React from "react";

import { Grid, Button } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import {
  DialogActionTypes,
  useDialogContext,
} from "../../context/DialogContext";
import SettingsColorsTable from "../../components/Tables/SettingsColorsTable";

export default function Colors({ data, setData, setError }) {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t } = useTranslation();

  const saveColor = (newColor) => {
    backend.bckSettingsCreateOrUpdateColor(newColor).then((json) => {
      if (json.code === 0) {
        newColor.Id = json.content;
        setData((prev) => {
          let newColors = { ...prev.Colors };
          newColors[newColor.Id] = newColor;
          let next = { ...prev, Colors: newColors };
          return next;
        });
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleAddColor = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        let newColor = {
          Id: null,
          Name: name,
          Alpha: 0,
          Red: 0,
          Green: 0,
          Blue: 0,
        };
        saveColor(newColor);
      },
      initialValue: "",
      textLabel: t("Name"),
      title: t("Add color"),
    });
  };

  const handleRemoveColor = (color) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove color?", { title: color.Name }),
      handleConfirm: () => {
        backend.bckSettingsRemoveColor(color.Id).then((json) => {
          if (json.code === 0) {
            setData((prev) => {
              let newColor = { ...prev.Colors };
              delete newColor[color.Id];
              let next = { ...prev, Colors: newColor };
              return next;
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const updateColor = (color, callback) => {
    let newColor = { ...color };
    callback(newColor);
    saveColor(newColor);
  };

  const handleEditName = (color) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        updateColor(color, (newColor) => {
          newColor.Name = name;
        });
      },
      initialValue: color.Name,
      textLabel: t("Name"),
      title: t("Name"),
    });
  };

  const handleEditColor = (color) => {
    dialogDispatch({
      type: DialogActionTypes.ColorDialogOpen,
      handleConfirm: (colorValue) => {
        updateColor(color, (newColor) => {
          newColor.Red = colorValue.r;
          newColor.Green = colorValue.g;
          newColor.Blue = colorValue.b;
          newColor.Alpha = Math.floor(colorValue.a * 255);
        });
      },
    });
  };

  return (
    <>
      <Grid item>
        <Button onClick={handleAddColor} variant="contained" color="primary">
          {t("Add color")}
        </Button>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "70vh",
          marginTop: "16px",
        }}
      >
        <SettingsColorsTable
          settingInfo={data}
          data={Object.values(data.Colors)}
          handleEditColor={handleEditColor}
          handleEditName={handleEditName}
          handleRemoveColor={handleRemoveColor}
        />
      </div>
    </>
  );
}
