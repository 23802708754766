import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    Clear as DeleteIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";

export default function UpdateChannelUsersTable({ data, handleDeleteClick }) {
    const { t } = useTranslation();
    const keys = ["ID", t("Login"), t("Full name"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((u) => (
                    <TableRow key={u.id}>
                        <TableCell>{u.id}</TableCell>
                        <TableCell>{u.login}</TableCell>
                        <TableCell>{u.name}</TableCell>
                        <TableCell>
                            <IconButton
                                style={{ color: '#AA0000' }}
                                onClick={() => { handleDeleteClick(u); }} >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
