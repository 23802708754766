import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField
} from "@mui/material";

import { useTranslation } from "react-i18next";

export default function EditVersionDialog({initialValue, initialValueInt, isOpened, handleConfirm, handleClose }) {
    const { t } = useTranslation();
    const [textValue, setTextValue] = useState("");
    const [intValue, setIntValue] = useState(0);

    useEffect(() => {
        setTextValue(initialValue);
        setIntValue(initialValueInt);
    }, [isOpened, initialValue, initialValueInt]);

    const handleVersionChange = (e) => {
        let sliced = e.target.value.slice(0, 64);
        if (sliced) {
            let parsed = parseInt(sliced, 10);
            if (parsed) {
                setIntValue(parsed);
            } else {
                setIntValue(0);
            }
        } else {
            setIntValue("");
        }
    }

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{t("Version")}</DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        label={t("Version")}
                        value={textValue}
                        onChange={e => setTextValue(e.target.value.slice(0, 64))}
                        style={{ maxWidth: "90ch", marginTop: "12px" }}
                    />
                    <TextField
                        label={t("Version")}
                        value={intValue}
                        onChange={handleVersionChange}
                        style={{ maxWidth: "90ch", marginTop: "12px" }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={textValue === undefined || textValue === null || textValue.trim() === "" || (!intValue || intValue <= 0)} onClick={() => { handleConfirm(textValue, intValue); }} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
