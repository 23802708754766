import React, { useState, useEffect } from "react";

import { Tabs, Tab } from "@mui/material";

import Configurations from "./Configurations";
import Questions from "./Questions"
import { UserPermissions } from "../enums/UserRoles"

import { useTranslation } from "react-i18next"

export default function ConfigsQuestions() {
    const { t } = useTranslation();
    const [tabValue, setTabValue] = useState(0);
    const [questionsAllowed, setQuestionsAllowed] = useState(false);

    useEffect(() => {
        var permissions = JSON.parse(localStorage.getItem("permissions") || "[]") || [];
        if (permissions.includes(UserPermissions.QUESTIONS_MANAGE)) {
            setQuestionsAllowed(true);
        }
    }, []);

    const handleSetTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Tabs value={tabValue} onChange={handleSetTab}>
                <Tab label={t("Configurations")} />
                {questionsAllowed ? <Tab label={t("Checklist")} /> : ""}
            </Tabs>
            {
                tabValue === 0 ? <Configurations /> : <Questions />
            }
        </>
    );
}
