import React, { useState, useEffect } from "react";

import {
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import ChartTable from "./ChartTable";
import ChartMap from "./ChartMap";
import SelectAddressObjectDialog from "../Dialogs/SelectAddressObjectDialog";
import SelectUserDialog from "../Dialogs/SelectUserDialog";
import { LanguageConverter } from "../../enums/LanguageConverter";

import Chart from "react-apexcharts";
import { formatDate } from "../../helpers/StringUtils";

import FileSaver from "file-saver";
import { TechsupportRequestStatuses } from "../../enums/TechsupportRequestStatuses";
import { TechsupportRequestTags } from "../../enums/TechsupportRequestTags";
import { useBackend } from "../../context/BackendContext";
import { DatePicker } from "@mui/x-date-pickers";
import ChipsArray from "../ChipsArray";

const PlotTypes = {
  TABLE: { id: 0, name: "Table" },
  LINE_PLOT: { id: 1, name: "Line plot" },
  MAP: { id: 2, name: "Map" },
};

const StatisticTypes = {
  TOP_DEVICES: {
    id: 0,
    name: "Top devices",
    plotTypes: ["TABLE"],
    devicesFilterRequired: false,
    showDevicesFilter: true,
    showCarsFilter: false,
    showLoginFilter: false,
    showDownloadButton: false,
    showSearchText: false,
    showSearchTags: false,
    showSearchLocation: true,
  },
  TOP_DEVICES_DYNAMICS: {
    id: 1,
    name: "Top devices dynamics",
    plotTypes: ["TABLE", "LINE_PLOT"],
    devicesFilterRequired: true,
    showDevicesFilter: true,
    showCarsFilter: false,
    showLoginFilter: false,
    showDownloadButton: false,
    showSearchText: false,
    showSearchTags: false,
    showSearchLocation: true,
  },
  TOP_CARS: {
    id: 2,
    name: "Top cars",
    plotTypes: ["TABLE"],
    devicesFilterRequired: false,
    showDevicesFilter: false,
    showCarsFilter: true,
    showLoginFilter: false,
    showDownloadButton: false,
    showSearchText: false,
    showSearchTags: false,
    showSearchLocation: true,
  },
  TOP_CARS_DYNAMICS: {
    id: 3,
    name: "Top cars dynamics",
    plotTypes: ["TABLE", "LINE_PLOT"],
    devicesFilterRequired: false,
    showDevicesFilter: false,
    showCarsFilter: true,
    showLoginFilter: false,
    showDownloadButton: false,
    showSearchText: false,
    showSearchTags: false,
    showSearchLocation: true,
  },
  USER_DEVICES: {
    id: 4,
    name: "User devices",
    plotTypes: ["TABLE", "MAP"],
    devicesFilterRequired: false,
    showDevicesFilter: false,
    showCarsFilter: false,
    showLoginFilter: true,
    showDownloadButton: true,
    showSearchText: false,
    showSearchTags: false,
    showSearchLocation: true,
  },
  TECHSUPPORT_REQUESTS: {
    id: 5,
    name: "Techsupport requests",
    plotTypes: ["TABLE", "MAP"],
    devicesFilterRequired: false,
    showDevicesFilter: true,
    showCarsFilter: false,
    showLoginFilter: false,
    showDownloadButton: true,
    showSearchText: true,
    showSearchTags: true,
    showSearchLocation: true,
  },
  AUTH_COUNTS: {
    id: 6,
    name: "Auth counts",
    plotTypes: ["TABLE"],
    devicesFilterRequired: false,
    showDevicesFilter: false,
    showCarsFilter: false,
    showLoginFilter: false,
    showDownloadButton: false,
    showSearchText: false,
    showSearchTags: false,
    showSearchLocation: false,
  },
  AUTH_COUNTS_DYNAMICS: {
    id: 7,
    name: "Auth counts dynamics",
    plotTypes: ["TABLE", "LINE_PLOT"],
    devicesFilterRequired: false,
    showDevicesFilter: false,
    showCarsFilter: false,
    showLoginFilter: false,
    showDownloadButton: false,
    showSearchText: false,
    showSearchTags: false,
    showSearchLocation: false,
  },
  AUTH_COUNTS_UNIQUE_USERS_DYNAMICS: {
    id: 8,
    name: "Auth counts unique users dynamics",
    plotTypes: ["TABLE", "LINE_PLOT"],
    devicesFilterRequired: false,
    showDevicesFilter: false,
    showCarsFilter: false,
    showLoginFilter: false,
    showDownloadButton: false,
    showSearchText: false,
    showSearchTags: false,
    showSearchLocation: false,
  },
};

export default function StatisticsPanel() {
  const { t, i18n } = useTranslation();
  const backend = useBackend();
  const [error, setError] = useState("");
  const [statisticsType, setStatisticsType] = useState("TOP_DEVICES");
  const [renderType, setRenderType] = useState("TABLE");
  const [originalData, setOriginalData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(() => {
    let now = new Date();
    now.setDate(now.getDate() - 2);
    return now;
  });
  const [selectedDateTill, setSelectedDateTill] = React.useState(() => {
    let now = new Date();
    now.setDate(now.getDate() - 1);
    return now;
  });
  const [deviceTypeList, setDeviceTypeList] = useState([
    { id: -2, name: t("Loading") },
  ]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [carBrandList, setCarBrandList] = useState([
    { id: -2, name: t("Loading") },
  ]);
  const [carBrands, setCarBrands] = useState([]);
  const [carModelList, setCarModelList] = useState([
    { id: -2, name: t("Loading") },
  ]);
  const [carModels, setCarModels] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [isAddressDialogOpened, setIsAddressDialogOpened] = useState(false);
  const [selectedLocationLevel, setSelectedLocationLevel] = useState(0);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isAddUserOpened, setIsAddUserOpened] = useState(false);
  const [searchLogin, setSearchLogin] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTags, setSearchTags] = useState([]);

  const handleAddUserClose = () => {
    setIsAddUserOpened(false);
  };

  const handleAddUserConfirmed = (selectedUser) => {
    setIsAddUserOpened(false);
    setSearchLogin((prev) => [selectedUser.login, ...prev]);
  };

  const handleAddressDialogClose = () => {
    setIsAddressDialogOpened(false);
  };

  const handleAddressDialogConfirm = (value, level) => {
    setIsAddressDialogOpened(false);
    if (selectedLocations.some((v) => v === value)) return;

    if (level === selectedLocationLevel) {
      setSelectedLocations((prev) => [value, ...prev]);
    } else {
      setSelectedLocations((prev) => [value]);
      setSelectedLocationLevel(level);
    }
  };

  const onDevicesChanged = (...params) => {
    setDeviceTypes(params[1]);
  };

  const onCarBrandsChanged = (...params) => {
    setCarModels([]);
    setCarModelList([]);
    setCarBrands(params[1]);

    if (params[1].length === 1) {
      backend.bckCarsListModels(params[1][0].id, false, true).then((json) => {
        if (json.code === 0) {
          setCarModelList(json.content);
        }
      });
    }
  };

  const onCarModelChanged = (...params) => {
    setCarModels(params[1]);
  };

  const convertCountsToTable = (data) => {
    if (data.length === 0) {
      setTableData([[], []]);
      return;
    }
    let updated = [[], []];
    updated[0].push("");
    updated[1].push(t("Count"));
    data.forEach((el) => {
      updated[0].push(el.name);
      updated[1].push(el.count);
    });
    setTableData(updated);
  };

  const convertCountsToTableDynamics = (data) => {
    if (data === null || data.length === 0) {
      setTableData([[], []]);
      return;
    }

    let updated = [];
    let dates = [];
    let names = [];
    data.forEach((el) => {
      dates.push(el.date);
    });
    data[0].alarmsCount.data.forEach((el) => {
      names.push(el.name);
    });
    updated.push([
      "",
      ...dates.map((d) => new Date(d).toISOString().slice(0, 10)),
    ]);
    names.forEach((name) => {
      updated.push([name, ...new Array(dates.length)]);
    });
    data.forEach((dateElement) => {
      let dateIndex = dates.indexOf(dateElement.date);
      if (dateIndex === -1) return;
      dateElement.alarmsCount.data.forEach((alarmElement) => {
        let alarmIndex = names.indexOf(alarmElement.name);
        if (dateIndex === -1) return;
        updated[alarmIndex + 1][dateIndex + 1] = alarmElement.count;
      });
    });
    setTableData(updated);
  };

  const convertUserDevicesToTable = (data) => {
    if (data === null || data.length === 0) {
      setTableData([[], []]);
      return;
    }
    let updated = [];
    let headers = [
      t("Login"),
      t("Full name"),
      t("Phone"),
      t("Date"),
      t("Device"),
      t("Device ID"),
      t("Coordinates"),
      t("Address"),
    ];
    updated.push(headers);
    data.forEach((el) => {
      let row = [];
      row.push(el.login);
      row.push(el.fio);
      row.push(el.phone);
      row.push(formatDate(el.time));
      row.push(el.deviceName);
      row.push(el.deviceId);
      row.push(el.coord);
      row.push(el.address);
      updated.push(row);
    });
    setTableData(updated);
  };

  const convertTechsupportRequestsToTable = (data) => {
    if (data === null || data.length === 0) {
      setTableData([[], []]);
      return;
    }
    let updated = [];
    let headers = [
      t("Login"),
      t("Full name"),
      t("Phone"),
      t("Creation time"),
      t("Status"),
      t("Device ID"),
      t("Device"),
      t("Firmware"),
      t("Description"),
      t("Request history"),
    ];
    updated.push(headers);
    data.forEach((el) => {
      let row = [];
      row.push(el.login);
      row.push(el.fio);
      row.push(el.phone);
      row.push(formatDate(el.creationTime));
      row.push(t(TechsupportRequestStatuses[el.status]));
      row.push(el.deviceId);
      row.push(el.deviceName);
      row.push(el.firwmareVersion);
      row.push(el.description);
      row.push(el.supportLog);
      updated.push(row);
    });
    setTableData(updated);
  };

  const handleDownloadPress = (e) => {
    let stringedData = tableData
      .map((row) => row.map((v) => '"' + v + '"').join(";"))
      .join("\r\n");
    const infoData = new Blob([stringedData], {
      type: "text/csv;charset=UTF-8;",
    });
    let name = t(StatisticTypes[statisticsType].name);
    FileSaver.saveAs(infoData, name + ".csv");
  };

  const handleSearchPress = (e) => {
    if (
      StatisticTypes[statisticsType].devicesFilterRequired &&
      deviceTypes.length === 0
    ) {
      setError(t("Please select at least one device type"));
      return;
    }

    setInProgress(true);
    setError("");
    let cleanDateFrom = new Date(
      Date.UTC(
        selectedDateFrom.getUTCFullYear(),
        selectedDateFrom.getUTCMonth(),
        selectedDateFrom.getUTCDate(),
        0,
        0,
        0,
        0
      )
    );
    let cleanDateTill = new Date(
      Date.UTC(
        selectedDateTill.getUTCFullYear(),
        selectedDateTill.getUTCMonth(),
        selectedDateTill.getUTCDate(),
        0,
        0,
        0,
        0
      )
    );
    let mappedBrands = carBrands.map((cb) => cb.id);
    let mappedModels = carModels.map((cm) => cm.id);

    switch (statisticsType) {
      case "TOP_DEVICES":
        backend
          .bckStatsTopAlarms(
            cleanDateFrom,
            cleanDateTill,
            deviceTypes,
            selectedLocations
          )
          .then((json) => {
            setInProgress(false);
            if (json.code === 0) {
              setOriginalData(null);
              convertCountsToTable(json.content.data);
            } else {
              console.log(json.message);
            }
          });
        break;
      case "TOP_CARS":
        backend
          .bckStatsTopCars(
            cleanDateFrom,
            cleanDateTill,
            mappedBrands,
            mappedModels,
            selectedLocations,
            LanguageConverter[i18n.language]
          )
          .then((json) => {
            setInProgress(false);
            if (json.code === 0) {
              setOriginalData(null);
              convertCountsToTable(json.content.data);
            } else {
              console.log(json.message);
            }
          });
        break;
      case "TOP_DEVICES_DYNAMICS":
        backend
          .bckStatsTopAlarmsDynamics(
            cleanDateFrom,
            cleanDateTill,
            deviceTypes,
            selectedLocations
          )
          .then((json) => {
            setInProgress(false);
            if (json.code === 0) {
              setOriginalData(null);
              convertCountsToTableDynamics(json.content.data);
            } else {
              console.log(json.message);
            }
          });
        break;
      case "TOP_CARS_DYNAMICS":
        backend
          .bckStatsTopCarsDynamics(
            cleanDateFrom,
            cleanDateTill,
            mappedBrands,
            mappedModels,
            selectedLocations,
            LanguageConverter[i18n.language]
          )
          .then((json) => {
            setInProgress(false);
            if (json.code === 0) {
              setOriginalData(null);
              convertCountsToTableDynamics(json.content.data);
            } else {
              console.log(json.message);
            }
          });
        break;
      case "USER_DEVICES":
        backend
          .bckStatsUsersDevices(
            cleanDateFrom,
            cleanDateTill,
            searchLogin,
            selectedLocations
          )
          .then((json) => {
            setInProgress(false);
            if (json.code === 0) {
              setOriginalData(json.content.data);
              convertUserDevicesToTable(json.content.data);
            } else {
              console.log(json.message);
            }
          });
        break;
      case "TECHSUPPORT_REQUESTS":
        backend
          .bckStatsTechsupportRequests(
            cleanDateFrom,
            cleanDateTill,
            searchText,
            deviceTypes,
            selectedLocations,
            searchTags
          )
          .then((json) => {
            setInProgress(false);
            if (json.code === 0) {
              setOriginalData(json.content.data);
              convertTechsupportRequestsToTable(json.content.data);
            } else {
              console.log(json.message);
            }
          });
        break;
      case "AUTH_COUNTS":
        backend
          .bckStatsAuthCounts(cleanDateFrom, cleanDateTill)
          .then((json) => {
            setInProgress(false);
            if (json.code === 0) {
              setOriginalData(null);
              convertCountsToTable(json.content.data);
            } else {
              console.log(json.message);
            }
          });
        break;
      case "AUTH_COUNTS_DYNAMICS":
        backend
          .bckStatsAuthCountsDynamics(cleanDateFrom, cleanDateTill)
          .then((json) => {
            setInProgress(false);
            if (json.code === 0) {
              setOriginalData(null);
              convertCountsToTableDynamics(json.content.data);
            } else {
              console.log(json.message);
            }
          });
        break;
      case "AUTH_COUNTS_UNIQUE_USERS_DYNAMICS":
        backend
          .bckStatsAuthCountsUniqueUsersDynamics(cleanDateFrom, cleanDateTill)
          .then((json) => {
            setInProgress(false);
            if (json.code === 0) {
              setOriginalData(null);
              convertCountsToTableDynamics(json.content.data);
            } else {
              console.log(json.message);
            }
          });
        break;
      default:
        break;
    }

    return 0;
  };

  useEffect(() => {
    backend.bckDevicesList("", false, false).then((json) => {
      if (json.code === 0) {
        var listElements = json.content.map((alarm) => alarm.name);
        listElements = [...new Set(listElements)];
        setDeviceTypeList(listElements);
      }
    });
    backend.bckAddressGetCountries().then((json) => {
      if (json.code === 0) {
        setCountriesList(json.content);
      }
    });
    backend.bckCarsListBrands(true, true, false).then((json) => {
      if (json.code === 0) {
        setCarBrandList(json.content);
      }
    });
  }, []);

  const onStatisticsTypeChange = (e) => {
    let newType = e.target.value;
    setStatisticsType(newType);
    setRenderType(StatisticTypes[newType].plotTypes[0]);
    setDeviceTypes([]);
    setCarBrands([]);
    setCarModels([]);
    setSearchLogin([]);
    setSearchTags([]);
    setTableData(null);
  };

  const onRenderTypeChange = (e) => {
    setRenderType(e.target.value);
  };

  const renderTable = (data) => {
    if (statisticsType === "USER_DEVICES") {
      let renderedData = [];
      data.forEach((val, i) => {
        renderedData.push([...val]);
        if (i === 0) {
          renderedData[i].splice(1, 2);
          renderedData[i].splice(3, 1);
          renderedData[i][0] = t("User");
        } else {
          renderedData[i][0] = (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography>{val[0]}</Typography>
              <Typography
                variant="body2"
                noWrap
                style={{ fontStyle: "italic" }}
              >
                {val[1]}
              </Typography>
              <Typography
                variant="body2"
                noWrap
                style={{ fontStyle: "italic" }}
              >
                {val[2]}
              </Typography>
            </div>
          );
          renderedData[i].splice(1, 2);
          renderedData[i][2] = (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography>{val[4]}</Typography>
              <Typography
                variant="body2"
                noWrap
                style={{ fontStyle: "italic" }}
              >
                {val[5]}
              </Typography>
            </div>
          );
          renderedData[i].splice(3, 1);
        }
      });
      return <ChartTable data={renderedData} />;
    } else if (statisticsType === "TECHSUPPORT_REQUESTS") {
      let renderedData = [];
      data.forEach((val, i) => {
        renderedData.push([...val]);
        if (i === 0) {
          renderedData[i].splice(1, 2);
          renderedData[i][0] = t("User");
          renderedData[i].splice(4, 2);
          renderedData[i][3] = t("Device");
        } else {
          renderedData[i][0] = (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography>{val[0]}</Typography>
              <Typography
                variant="body2"
                noWrap
                style={{ fontStyle: "italic" }}
              >
                {val[1]}
              </Typography>
              <Typography
                variant="body2"
                noWrap
                style={{ fontStyle: "italic" }}
              >
                {val[2]}
              </Typography>
            </div>
          );
          renderedData[i].splice(1, 2);
          renderedData[i][3] = (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography>{val[5]}</Typography>
              <Typography
                variant="body2"
                noWrap
                style={{ fontStyle: "italic" }}
              >
                {val[6]}
              </Typography>
              <Typography
                variant="body2"
                noWrap
                style={{ fontStyle: "italic" }}
              >
                {val[7]}
              </Typography>
            </div>
          );
          renderedData[i].splice(4, 2);
          renderedData[i][5] = <pre>{val[9]}</pre>;
        }
      });
      return <ChartTable data={renderedData} />;
    } else {
      return <ChartTable data={data} />;
    }
  };

  const renderLinePlot = (data) => {
    let series = [];
    data.forEach((row, i) => {
      if (i === 0) return;
      series.push({
        name: row[0],
        data: row.slice(1, row.length),
      });
    });
    let options = {
      chart: {
        type: "line",
        zoom: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: data[0].slice(1, data[0].length),
      },
    };
    return <Chart options={options} series={series} type="line" height={600} />;
  };

  const renderMap = (data) => {
    return <ChartMap data={data} />;
  };

  const renderData = (data, originalData) => {
    switch (renderType) {
      case "TABLE":
        return renderTable(data);
      case "LINE_PLOT":
        return renderLinePlot(data);
      case "MAP":
        return renderMap(originalData);
      default:
        break;
    }
  };

  const onTagsChanged = (...params) => {
    setSearchTags(params[1]);
  };

  return (
    <Paper
      style={{
        overflow: "auto",
        padding: "24px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container direction="column" style={{ marginBottom: "16px" }}>
        <Grid item>
          <div>
            <FormControl
              style={{
                minWidth: "25ch",
                marginBottom: "16px",
                marginRight: "16px",
              }}
            >
              <InputLabel>{t("Statistics type")}</InputLabel>
              <Select
                value={statisticsType}
                label={t("Statistics type")}
                onChange={onStatisticsTypeChange}
              >
                {Object.keys(StatisticTypes).map((key) => (
                  <MenuItem key={key} value={key}>
                    {t(StatisticTypes[key].name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              style={{
                minWidth: "25ch",
                marginBottom: "16px",
                marginRight: "16px",
              }}
            >
              <InputLabel>{t("Render type")}</InputLabel>
              <Select
                value={renderType}
                label={t("Render type")}
                onChange={onRenderTypeChange}
              >
                {StatisticTypes[statisticsType].plotTypes.map((key) => (
                  <MenuItem key={key} value={key}>
                    {t(PlotTypes[key].name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginRight: "16px", marginBottom: "16px" }}>
              <DatePicker
                label={t("From")}
                format="yyyy-MM-dd"
                value={selectedDateFrom}
                onChange={setSelectedDateFrom}
              />
            </div>
            <div style={{ marginRight: "16px", marginBottom: "16px" }}>
              <DatePicker
                label={t("Till")}
                format="yyyy-MM-dd"
                value={selectedDateTill}
                onChange={setSelectedDateTill}
              />
            </div>
          </div>
          {StatisticTypes[statisticsType].showDevicesFilter ? (
            <div style={{ width: "64ch", marginBottom: "16px" }}>
              <Autocomplete
                multiple
                size="small"
                options={deviceTypeList}
                getOptionLabel={(option) => option ?? "<No name>"}
                onChange={onDevicesChanged}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Device")}
                    placeholder={t("Device")}
                  />
                )}
              />
            </div>
          ) : (
            <></>
          )}
          {StatisticTypes[statisticsType].showCarsFilter ? (
            <div style={{ marginBottom: "32px", display: "flex" }}>
              <Autocomplete
                style={{ width: "48ch", marginRight: "16px" }}
                multiple
                size="small"
                value={carBrands}
                options={carBrandList}
                onChange={onCarBrandsChanged}
                getOptionLabel={(option) => option?.name ?? "<No name>"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Car brand")}
                    placeholder={t("Car brand")}
                  />
                )}
              />
              <Autocomplete
                style={{ width: "48ch", marginRight: "16px" }}
                multiple
                size="small"
                disabled={carBrands.length > 1}
                value={carModels}
                options={carModelList}
                onChange={onCarModelChanged}
                getOptionLabel={(option) => option?.name ?? "<No name>"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Car model")}
                    placeholder={t("Car model")}
                  />
                )}
              />
            </div>
          ) : (
            <></>
          )}
          {StatisticTypes[statisticsType].showLoginFilter ? (
            <div
              style={{
                width: "64ch",
                marginBottom: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ChipsArray
                value={searchLogin}
                placeholder={t("Login")}
                onChange={(e, value) => {
                  setSearchLogin(value);
                }}
                style={{ width: "48ch" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsAddUserOpened(true);
                }}
              >
                {t("Select")}
              </Button>
            </div>
          ) : (
            <></>
          )}
          {StatisticTypes[statisticsType].showSearchText ? (
            <div style={{ width: "64ch", marginBottom: "16px" }}>
              <TextField
                value={searchText}
                onChange={(e) => setSearchText(e.target.value.slice(0, 32))}
                label={t("Search text")}
                placeholder={t("Search text")}
                style={{ marginRight: "16px" }}
              />
            </div>
          ) : (
            <></>
          )}
          {StatisticTypes[statisticsType].showSearchTags ? (
            <div style={{ width: "64ch", marginBottom: "16px" }}>
              <Autocomplete
                style={{ marginRight: "12px", marginTop: "8px", width: "48ch" }}
                multiple
                size="small"
                value={searchTags}
                options={Object.keys(TechsupportRequestTags).map((k) =>
                  parseInt(k)
                )}
                onChange={onTagsChanged}
                getOptionLabel={(option) => t(TechsupportRequestTags[option])}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Tags")}
                    placeholder={t("Tags")}
                    variant="outlined"
                  />
                )}
              />
            </div>
          ) : (
            <></>
          )}
          {StatisticTypes[statisticsType].showSearchLocation ? (
            <div
              style={{
                width: "64ch",
                marginBottom: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ChipsArray
                value={selectedLocations}
                placeholder={t("Location")}
                onChange={(e, value) => {
                  setSelectedLocations(value);
                }}
                style={{ width: "48ch", marginRight: "16px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsAddressDialogOpened(true);
                }}
                style={{ marginRight: "16px" }}
              >
                {t("Select")}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </Grid>

        <Typography>{error}</Typography>
        <Grid container item spacing={2}>
          <Grid item>
            <Button
              onClick={handleSearchPress}
              disabled={
                inProgress ||
                !(selectedDateFrom && selectedDateTill) ||
                selectedDateTill <= selectedDateFrom
              }
              variant="contained"
              color="primary"
              style={{ marginRight: "16px" }}
            >
              {t("Search")}
            </Button>
            {StatisticTypes[statisticsType].showDownloadButton ? (
              <Button
                onClick={handleDownloadPress}
                disabled={
                  !tableData ||
                  tableData.length === 0 ||
                  tableData[0].length === 0
                }
                variant="contained"
                color="primary"
              >
                {t("Download")}
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
      <div>
        {tableData ? (
          tableData[0].length === 0 ? (
            <Typography style={{ margin: "16px" }}>{t("No data")}</Typography>
          ) : (
            <div>
              <Typography variant="h6">
                {t(StatisticTypes[statisticsType].name)}
              </Typography>
              {renderData(tableData, originalData)}
            </div>
          )
        ) : (
          <></>
        )}
      </div>

      <SelectAddressObjectDialog
        isOpened={isAddressDialogOpened}
        countriesList={countriesList}
        handleClose={handleAddressDialogClose}
        handleConfirm={handleAddressDialogConfirm}
      />

      <SelectUserDialog
        isOpened={isAddUserOpened}
        handleConfirm={handleAddUserConfirmed}
        handleClose={handleAddUserClose}
      />
    </Paper>
  );
}
