import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
} from "@mui/material";

import { useTranslation } from "react-i18next"

import CarModelsTableRow from "./CarModelsTableRow";

export default function CarModelsTable({ data, handleShowCarModelImage, buildModelEquipments, handleRemoveModel, handleUploadImage, handleTitleClick, handleYearsClick, handleTypeClick, handleUploadMountingMap, handleDeleteMountingMap }) {
    const { t } = useTranslation();

    var keys = [" ", "#", t("Car model"), t("Years"), t("Type"), t("Mounting maps"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(model => (
                    <CarModelsTableRow
                        key={model.id}
                        model={model}
                        handleShowCarModelImage={handleShowCarModelImage}
                        buildModelEquipments={buildModelEquipments}
                        handleRemoveModel={handleRemoveModel}
                        handleUploadImage={handleUploadImage}
                        handleTitleClick={handleTitleClick}
                        handleYearsClick={handleYearsClick}
                        handleTypeClick={handleTypeClick}
                        handleUploadMountingMap={handleUploadMountingMap}
                        handleDeleteMountingMap={handleDeleteMountingMap}
                    />
                ))}
            </TableBody>
        </Table>
    );
}
