import React from "react";

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LanguageConverter } from "../enums/LanguageConverter";

const languageMenuItems = Object.keys(LanguageConverter).map(k => <MenuItem key={k} value={k.toUpperCase()}>
    {k.toUpperCase()}
</MenuItem>);

export default function LanguageSelect({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <FormControl>
            <InputLabel>{label !== undefined ? label : t("Language")}</InputLabel>
            <Select
                label={label !== undefined ? label : t("Language")}
                {...props}
            >
                {
                    languageMenuItems
                }
            </Select>
        </FormControl>
    );
}
