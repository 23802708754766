import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField
} from "@mui/material";

import { useTranslation } from "react-i18next"

export default function CreateQuestionPartDialog({ isOpened, part, handleConfirm, handleClose }) {
    const { t } = useTranslation();
    const [name, setName] = useState("");

    useEffect(() => {
        setName(part.name);
    }, [part]);

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{t("Checklist part")}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("Name/Title")}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={name === undefined || name === null || name.trim() === ""} onClick={() => { handleConfirm(part.id, name); }} color="primary" autoFocus>
                    {t("Save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
