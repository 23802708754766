import { Divider, Link, Paper, Typography } from "@mui/material";
import React, { useState, useEffect, createRef } from "react";
import { useTranslation } from "react-i18next";

import L from 'leaflet';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster'
import { formatDate } from "../../helpers/StringUtils";

const defaultBounds = [[61, 65], [39, 23]];

export default function ChartMap({ data }) {
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [currentBounds, setCurrentBounds] = useState(L.latLngBounds(defaultBounds));
    const [currentPoints, setCurrentPoints] = useState([]);
    const [currentPointsMarkers, setCurrentPointsMarkers] = useState([]);
    const [currentPointsMarkersRefs, setCurrentPointsMarkersRefs] = useState([]);
    const [map, setMap] = useState(null);

    function MapEventHandler({ mapUpdateStartHandler, mapUpdateEndHandler }) {
        const map = useMapEvents({
            zoomend: () => {
                if (mapUpdateEndHandler) mapUpdateEndHandler(map);
            },
            moveend: () => {
                if (mapUpdateEndHandler) mapUpdateEndHandler(map);
            },
            zoomstart: () => {
                if (mapUpdateStartHandler) mapUpdateStartHandler(map);
            },
            movestart: () => {
                if (mapUpdateStartHandler) mapUpdateStartHandler(map);
            }
        });
        return null;
    };

    const updateCurrentPointsMarkers = (currentPoints) => {
        if (currentPoints && currentPoints.length > 0) {
            let divIcon = L.divIcon({
                className: 'custom-div-icon',
                html: "<div style='background-color:#346eeb;color:#44b700;width:16px;height:16px;border-radius:50%;border: 2px solid #FFFFFF' />",
                iconSize: [16, 16],
                iconAnchor: [8, 8]
            });
            let markers = [];
            let markersRefs = [];
            currentPoints.forEach((row, i) => {
                let ref = createRef();
                let element = <Marker key={row.id} ref={ref} position={[row.coordLat, row.coordLng]} icon={divIcon} />;
                markers.push(element);
                markersRefs.push(ref);
            });
            setCurrentPointsMarkers(markers);
            setCurrentPointsMarkersRefs(markersRefs);
        } else {
            setCurrentPointsMarkers(<></>);
        }
    };

    useEffect(() => {
        const updatePoints = (data, bounds) => {
            let filteredData = data;
            if (bounds) {
                filteredData = data.filter((row) => {
                    if (!(row.coordLat && row.coordLng)) return false;
                    return bounds.contains([row.coordLat, row.coordLng]);
                });
            }
            filteredData = filteredData.slice(0, 10000).filter((row, i) => {
                return row.coordLat && row.coordLng;
            });

            if (filteredData.length >= 10000 - 1) {
                setError(t("Too much points on map"));
            } else {
                setError("");
            }

            setCurrentPoints(filteredData);
            updateCurrentPointsMarkers(filteredData);
        };

        updatePoints(data, currentBounds);
    }, [t, data, currentBounds]);

    const mapUpdateStartHandler = (map) => {
    };

    const mapUpdateEndHandler = (map) => {
        setCurrentBounds(map.getBounds());
    };

    const MarkMarkerOrCluster = (element, mark) => {
        if (element) {
            let currentChild = element;
            while (!currentChild._icon && currentChild.__parent) currentChild = currentChild.__parent;
            let icon = currentChild._icon;
            if (icon) {
                if (currentChild === element) {
                    icon.children[0].style.background = mark ? "#f20d0d" : "#346eeb";
                } else {
                    if (mark) {
                        icon.style.backgroundColor = "#ffa8a8";
                        icon.children[0].style.backgroundColor = "#f20d0d";
                    } else {
                        icon.style.backgroundColor = null;
                        icon.children[0].style.backgroundColor = null;
                    }
                }
            }
        }
    };

    const handleDivMouseClick = (e, row) => {
        map.setView([row.coordLat, row.coordLng], 15);
    };

    const handleDivMouseEnter = (e, row) => {
        let id = row.id;
        let markerIndex = currentPointsMarkers.findIndex(m => m.key === id.toString());
        let newSelected = currentPointsMarkersRefs[markerIndex].current;
        MarkMarkerOrCluster(newSelected, 1);
    };

    const handleDivMouseLeave = (e, row) => {
        let id = row.id;
        let markerIndex = currentPointsMarkers.findIndex(m => m.key === id.toString());
        let newSelected = currentPointsMarkersRefs[markerIndex].current;
        MarkMarkerOrCluster(newSelected, 0);
    };

    const handleLoginClick = (login) => {
        window.open(`#/app/events?login=${login}`, '_blank');
    };

    const handleDeviceIdClick = (deviceId) => {
        window.open(`#/app/events?deviceId=${deviceId}`, '_blank');
    };

    return (
        <div style={{ position: "relative" }}>
            <MapContainer
                ref={setMap}
                bounds={defaultBounds}
                scrollWheelZoom={true}
                style={{ height: "85vh" }}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MapEventHandler mapUpdateStartHandler={mapUpdateStartHandler} mapUpdateEndHandler={mapUpdateEndHandler} />
                <MarkerClusterGroup chunkedLoading={true}>
                    {currentPointsMarkers}
                </MarkerClusterGroup>
            </MapContainer>
            <Paper style={{ position: "absolute", top: "10px", left: "60px", maxWidth: "25%", maxHeight: "75%", zIndex: "1000", padding: "16px", overflow: "auto" }}>
                <Typography variant="caption" style={{ position: "absolute", top: "2px", left: "16px", color: "#ee0000" }}>{error}</Typography>
                <Typography>{t("Found")} {currentPoints ? currentPoints.length : 0}/{data ? data.length : 0}</Typography>
                <Divider />
                {
                    currentPoints && currentPoints.length > 0 ? currentPoints.map((row, i) => {
                        return <div key={row.id}
                            onMouseEnter={(e) => handleDivMouseEnter(e, row)}
                            onMouseLeave={(e) => handleDivMouseLeave(e, row)}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div onClick={(e) => handleDivMouseClick(e, row)}
                                    style={{ marginTop: "2px", backgroundColor: '#346eeb', width: '16px', height: '16px', borderRadius: '50%', border: '2px solid #FFFFFF' }} />
                                <div>
                                    <Link
                                        style={{ fontSize: "inherit", wordWrap: "break-word", rightMargin: "auto", textAlign: "left" }}
                                        onClick={() => handleLoginClick(row.login)}
                                    >
                                        {row.login}
                                    </Link>
                                    <br />
                                    <Link
                                        style={{ fontSize: "inherit", wordWrap: "break-word", rightMargin: "auto", textAlign: "left" }}
                                        onClick={() => handleDeviceIdClick(row.deviceId)}
                                    >
                                        {row.deviceId}
                                    </Link>
                                    <Typography variant="caption"> {row.deviceName}</Typography>
                                    <br />
                                    <Typography variant="caption"> {formatDate(row.time ? row.time : row.creationTime)}</Typography>
                                </div>
                            </div>
                            <Divider />
                        </div>
                    }) : <Typography>{t("No data")}</Typography>
                }
            </Paper>
        </div>
    );
}
