import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Grid,
  Typography,
  IconButton,
  Paper,
  Button,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { Edit as EditIcon, Publish as UploadIcon } from "@mui/icons-material";
import { LanguageConverter } from "../enums/LanguageConverter";
import { SubsystemFirmwareTypes } from "../enums/SubsystemFirmwareTypes";
import {
  DeviceFeatureTypes,
  DeviceFeatureTypesByName,
} from "../enums/DeviceFeatureTypes";
import { DigitalBuses } from "../enums/DigitalBuses";
import SubsystemEquipmentsAccordion from "../components/SubsystemEquipmentsAccordion";
import ReactFileReader from "react-file-reader";
import SubsystemFirmwaresTable from "../components/Tables/SubsystemFirmwaresTable";
import { useBackend } from "../context/BackendContext";
import { SubsystemTypes } from "../enums/SubsystemTypes";
import { ResponseCode } from "../enums/ResponseCode";
import { formatDate, stringIndexTrim } from "../helpers/StringUtils";

export default function SubsystemFull({ subsystemId }) {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [brandsList, setBrandsList] = useState([]);
  const [subsystemsList, setSubsystemsList] = useState([]);
  const [originalFullInfo, setOriginalFullInfo] = useState({
    canBlock: false,
    immobKeyless: false,
  });
  const [fullInfo, setFullInfo] = useState({
    canBlock: false,
    immobKeyless: false,
  });
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshInfo = useCallback(
    (subsystemId) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckDevicesGetSubsystemInfo(
          subsystemId,
          LanguageConverter[i18n.language]
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              if (updatesLockCounter.current === 0) {
                setFullInfo(json.content);
                setOriginalFullInfo(JSON.parse(JSON.stringify(json.content)));
              }
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }
        });
    },
    [t, i18n.language, backend]
  );

  useEffect(() => {
    backend.bckCarsListBrands(false, true, false).then((json) => {
      setBrandsList(json.content);
    });
    backend.bckDevicesList("", false, false).then((json) => {
      setSubsystemsList(
        json.content.sort((a, b) => (a.name > b.name ? 1 : -1))
      );
    });
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    setError("");
    if (subsystemId) {
      refreshInfo(subsystemId);
    } else {
      var params = queryString.parse(location.search);
      if (params.id) {
        refreshInfo(params.id);
      } else {
        setError("Subsystem ID is not set");
      }
    }
  }, [subsystemId, location, i18n.language]);

  const sendUpdateRequest = (info) => {
    ++updatesLockCounter.current;
    let id = GenerateInteger();
    lastRequestId.current = id;
    let canEquipmentsIds = info.canEquipments
      ? info.canEquipments.map((ce) => ce.id)
      : null;
    let autostartEquipmentsIds = info.autostartEquipments
      ? info.autostartEquipments.map((ce) => ce.id)
      : null;
    backend
      .bckDevicesCreateOrUpdateSubsystem(
        info.id,
        info.name,
        info.type,
        info.title,
        canEquipmentsIds,
        autostartEquipmentsIds,
        info.features,
        info.digitalBuses,
        info.creationTime
      )
      .then((json) => {
        --updatesLockCounter.current;
        if (json.code === 0) {
          setFullInfo(info);
          setTabValue(0);
          refreshInfo(subsystemId);
        } else if (json.code === 3) {
          setError(json.message);
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
  };

  const handleEditFeatures = () => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: fullInfo.features,
      label: t("Features"),
      title: t("Features"),
      options: Object.keys(DeviceFeatureTypes).map((t) => parseInt(t)),
      getOptionLabel: (option) => t(DeviceFeatureTypes[option]),
      multiple: true,
      handleConfirm: (features) => {
        setFullInfo({ ...fullInfo, features: features });
      },
    });
  };

  const handleEditDigitalBuses = () => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: fullInfo.digitalBuses,
      label: t("Interfaces"),
      title: t("Interfaces"),
      options: Object.keys(DigitalBuses).map((t) => parseInt(t)),
      getOptionLabel: (option) => t(DigitalBuses[option]),
      multiple: true,
      handleConfirm: (digitalBuses) => {
        setFullInfo({ ...fullInfo, digitalBuses: digitalBuses });
      },
    });
  };

  const handleEditTitle = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (title) => {
        setFullInfo({ ...fullInfo, title: title });
      },
      initialValue: fullInfo.title,
      textLabel: t("Title"),
      title: t("Title"),
    });
  };

  const handleEditCreationTime = () => {
    dialogDispatch({
      type: DialogActionTypes.DateTimeDialogOpen,
      handleConfirm: (dateTime) => {
        setFullInfo({ ...fullInfo, creationTime: dateTime?.toJSON() });
      },
      initialValue: Date.parse(fullInfo.creationTime),
      title: t("Creation time"),
    });
  };

  const handleEditName = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        setFullInfo({ ...fullInfo, name: name });
      },
      initialValue: fullInfo.name,
      textLabel: t("Name"),
      title: t("Name"),
    });
  };

  const handleEditType = () => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: fullInfo.type,
      label: t("Type"),
      title: t("Type"),
      options: Object.keys(SubsystemTypes).map((t) => parseInt(t)),
      getOptionLabel: (option) => t(SubsystemTypes[option]),
      handleConfirm: (type) => {
        if (type) {
          setFullInfo({ ...fullInfo, type: type });
        }
      },
    });
  };

  const handleSaveClick = () => {
    setOriginalFullInfo(JSON.parse(JSON.stringify(fullInfo)));
    sendUpdateRequest(fullInfo);
  };

  const isInfoDifferent = (info1, info2) => {
    return (
      JSON.stringify({ ...info1, firmwares: null }) !==
      JSON.stringify({ ...info2, firmwares: null })
    );
  };

  const handleClickAddCanEquipment = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleSelectEquipmentDialogOpen,
      handleConfirm: (brand, model, equipment) => {
        setFullInfo((prev) => {
          let next = { ...prev };
          next.canEquipments.push({
            id: equipment.id,
            brandName: brand.name,
            modelName: model.name,
            name: equipment.name,
          });
          return next;
        });
      },
      brandsList: brandsList,
    });
  };

  const handleClickDeleteCanEquipment = (equipment) => {
    setFullInfo((prev) => {
      let next = { ...prev };
      next.canEquipments = next.canEquipments.filter((ce) => ce != equipment);
      return next;
    });
  };

  const handleClickAddAutostartEquipment = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleSelectEquipmentDialogOpen,
      handleConfirm: (brand, model, equipment) => {
        setFullInfo((prev) => {
          let next = { ...prev };
          next.autostartEquipments.push({
            id: equipment.id,
            brandName: brand.name,
            modelName: model.name,
            name: equipment.name,
          });
          return next;
        });
      },
      brandsList: brandsList,
    });
  };

  const handleClickDeleteAutostartEquipment = (equipment) => {
    setFullInfo((prev) => {
      let next = { ...prev };
      next.autostartEquipments = next.autostartEquipments.filter(
        (ce) => ce != equipment
      );
      return next;
    });
  };

  const handleUploadImage = (file) => {
    if (fullInfo.imageUrl) {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Overwrite image?"),
        handleConfirm: () => {
          handleUploadImageAccepted(file);
        },
      });
    } else {
      handleUploadImageAccepted(file);
    }
  };

  const handleUploadImageAccepted = (file) => {
    if (file.size > 16 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    backend.bckFilesUploadSubsystemImage(fullInfo.id, file).then((json) => {
      if (json.code === 0) {
        let newInfo = { ...fullInfo, imageUrl: json.content };
        setFullInfo(newInfo);
        setOriginalFullInfo(JSON.parse(JSON.stringify(newInfo)));
      } else {
        setError(json.message);
      }
    });
  };

  const handleClickCopyCan = () => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: subsystemsList[0],
      label: t("Copy from..."),
      title: t("Copy from..."),
      options: subsystemsList,
      getOptionLabel: (option) => option?.name,
      multiple: false,
      handleConfirm: (value) => {
        backend
          .bckDevicesGetSubsystemInfo(
            value.id,
            LanguageConverter[i18n.language]
          )
          .then((json) => {
            if (json.code === 0) {
              setFullInfo({
                ...fullInfo,
                canEquipments: json.content.canEquipments,
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleClickCopyAutostart = () => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: subsystemsList[0],
      label: t("Copy from..."),
      title: t("Copy from..."),
      options: subsystemsList,
      getOptionLabel: (option) => option?.name,
      multiple: false,
      handleConfirm: (value) => {
        backend
          .bckDevicesGetSubsystemInfo(
            value.id,
            LanguageConverter[i18n.language]
          )
          .then((json) => {
            if (json.code === 0) {
              setFullInfo({
                ...fullInfo,
                autostartEquipments: json.content.autostartEquipments,
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleFirmwarePublishChanged = (firmware, value) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Change publication status?"),
      handleConfirm: () => {
        backend
          .bckDevicesCreateOrUpdateSubsystemFirmware(
            firmware.id,
            null,
            null,
            null,
            null,
            value,
            null
          )
          .then((json) => {
            if (json.code === 0) {
              setFullInfo((prev) => {
                let next = { ...prev };
                let target = next.firmwares.find((r) => r.id === firmware.id);
                target.isPublished = value;
                target.publishTime = new Date();
                return next;
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleRemoveFirmware = (firmware) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete firmware?"),
      handleConfirm: () => {
        backend.bckDevicesRemoveSubsystemFirmware(firmware.id).then((json) => {
          if (json.code === 0) {
            setFullInfo((prev) => {
              let next = { ...prev };
              let foundIndex = next.firmwares.findIndex(
                (x) => x.id === firmware.id
              );
              if (foundIndex !== -1) {
                next.firmwares.splice(foundIndex, 1);
              }
              return next;
            });
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const handleTypeClick = (firmware) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleSelectDialogOpen,
      initialValue: firmware.type,
      label: t("Type"),
      title: t("Type"),
      menuItems: Object.keys(SubsystemFirmwareTypes).map((i) => (
        <MenuItem key={i} value={i}>
          {t(SubsystemFirmwareTypes[i])}
        </MenuItem>
      )),
      handleConfirm: (value) => {
        backend
          .bckDevicesCreateOrUpdateSubsystemFirmware(
            firmware.id,
            null,
            value,
            null,
            null,
            null,
            null
          )
          .then((json) => {
            if (json.code === 0) {
              setFullInfo((prev) => {
                let next = { ...prev };
                let target = next.firmwares.find((r) => r.id === firmware.id);
                target.type = value;
                return next;
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const updateFirmwareVersion = (firmware, version) => {
    backend
      .bckDevicesCreateOrUpdateSubsystemFirmware(
        firmware.id,
        null,
        null,
        null,
        null,
        null,
        version
      )
      .then((json) => {
        if (json.code === 0) {
          setFullInfo((prev) => {
            let next = { ...prev };
            let target = next.firmwares.find((r) => r.id === firmware.id);
            target.version = version;
            return next;
          });
        } else if (json.code === 3) {
          setError(json.message);
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
  };

  const handleVersionClick = (firmware) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      initialValue: firmware.version,
      textLabel: t("Version"),
      title: t("Version"),
      maxLength: 9,
      handleConfirm: (version) => {
        updateFirmwareVersion(firmware, version);
      },
    });
  };

  const handleDescriptionClick = (firmware) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      initialValue: firmware.description,
      textLabel: t("Description"),
      title: t("Description"),
      lines: 5,
      handleConfirm: (description) => {
        backend
          .bckDevicesCreateOrUpdateSubsystemFirmware(
            firmware.id,
            null,
            null,
            description,
            LanguageConverter[i18n.language],
            null,
            null
          )
          .then((json) => {
            if (json.code === 0) {
              setFullInfo((prev) => {
                let next = { ...prev };
                let target = next.firmwares.find((r) => r.id === firmware.id);
                target.description = description;
                return next;
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleAddFirmware = () => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Add firmware") + "?",
      handleConfirm: () => {
        backend
          .bckDevicesCreateOrUpdateSubsystemFirmware(
            null,
            fullInfo.id,
            null,
            null,
            null,
            false,
            null
          )
          .then((json) => {
            if (json.code === 0) {
              setFullInfo((prev) => {
                let next = { ...prev };
                next.firmwares.push({
                  creationTime: new Date().toISOString(),
                  filename: null,
                  id: json.content,
                  isPublished: false,
                  publishTime: null,
                  version: null,
                  type: 3,
                });
                return next;
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleDownloadFirmwareClick = (firmware) => {
    window.open(firmware.fileUrl, `_blank`);
  };

  const handleUploadFirmwareSelect = (firmware, file) => {
    if (file.size > 512 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    let extension = file.name.substring(file.name.lastIndexOf("."));
    if (extension === ".enc") {
      handleUploadFirmwareEnc(firmware, file);
    } else if (extension === ".pld") {
      handleUploadFirmwarePld(firmware, file);
    } else if (extension === ".mld") {
      handleUploadFirmwareMld(firmware, file);
    } else {
      handleUploadFirmwareUnknown(firmware, file);
    }
  };

  const handleUploadFirmwareEnc = (firmware, file) => {
    let blob = file.slice(0, 24);
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      let header = event.target.result;
      let deviceName = stringIndexTrim(header.substring(0, 16).trim(), "\0");
      let version = stringIndexTrim(header.substring(16, 24).trim(), "\0");
      handleUploadFirmwareKnownFormat(firmware, file, version, deviceName);
    });
    reader.readAsText(blob);
  };

  const handleUploadFirmwarePld = (firmware, file) => {
    let blob = file.slice(0, 24);
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      let header = event.target.result;
      let version = stringIndexTrim(header.substring(0, 8).trim(), "\0");
      let deviceName = stringIndexTrim(header.substring(8, 24).trim(), "\0");
      handleUploadFirmwareKnownFormat(firmware, file, version, deviceName);
    });
    reader.readAsText(blob);
  };

  const handleUploadFirmwareMld = (firmware, file) => {
    let blob = file.slice(0, 21);
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      let header = event.target.result;
      let version = stringIndexTrim(header.substring(1, 9).trim(), "\0");
      let deviceName = stringIndexTrim(header.substring(9, 21).trim(), "\0");
      handleUploadFirmwareKnownFormat(firmware, file, version, deviceName);
    });
    reader.readAsText(blob);
  };

  const handleUploadFirmwareKnownFormat = (
    firmware,
    file,
    version,
    deviceName
  ) => {
    if (fullInfo.name !== deviceName) {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Firmware device name mismatch", {
          fileDeviceName: deviceName,
          currentDeviceName: fullInfo.name,
        }),
        handleConfirm: () => {
          handleUploadFirmwareConfirm(firmware, file, version);
        },
      });
    } else if (version !== firmware.version) {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Firmware version mismatch", {
          fileVersion: version,
          recordVersion: firmware.version,
        }),
        handleConfirm: () => {
          handleUploadFirmwareConfirm(firmware, file, version);
        },
      });
    } else {
      handleUploadFirmwareConfirm(firmware, file, version);
    }
  };

  const handleUploadFirmwareUnknown = (firmware, file) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Upload firmware with unknown format?"),
      handleConfirm: () => {
        handleUploadFirmwareConfirm(firmware, file, null);
      },
    });
  };

  const handleUploadFirmwareConfirm = (firmware, file, version) => {
    backend.bckFilesUploadSubsystemFirmware(firmware.id, file).then((json) => {
      if (json.code === 0) {
        setFullInfo((prev) => {
          let next = { ...prev };
          let target = next.firmwares.find((r) => r.id === firmware.id);
          if (target) {
            let filename = json.content.substring(
              json.content.lastIndexOf("/") + 1
            );
            target.fileUrl = json.content;
            target.filename = filename;
            if (version) {
              if (version.indexOf("VER") === 0) {
                version = version.substr(3);
              }
              updateFirmwareVersion(firmware, version);
            }
          }
          return next;
        });
      } else {
        setError(json.message);
      }
    });
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Grid item container direction="row" spacing={2}>
            <Grid item>
              <Typography variant="h5" color="textSecondary">
                {t("Subsystem")}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSaveClick}
                disabled={!isInfoDifferent(originalFullInfo, fullInfo)}
                variant="contained"
                color="primary"
              >
                {t("Save")}
              </Button>
            </Grid>
          </Grid>
          <Typography color="secondary">{error}</Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {fullInfo.imageUrl ? (
              <img src={fullInfo.imageUrl} alt="" />
            ) : (
              <>{t("No image")}</>
            )}
            <ReactFileReader
              handleFiles={(files) => handleUploadImage(files[0])}
              fileTypes={["*.*"]}
            >
              <IconButton color="primary">
                <UploadIcon />
              </IconButton>
            </ReactFileReader>
          </div>
          <div style={{ marginTop: "16px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>{t("Title")}: </b>
                {fullInfo.title}
              </Typography>
              <IconButton color="primary" onClick={handleEditTitle}>
                <EditIcon />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>{t("Name")}: </b>
                {fullInfo.name}
              </Typography>
              <IconButton color="primary" onClick={handleEditName}>
                <EditIcon />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>{t("Type")}: </b>
                {t(SubsystemTypes[fullInfo.type])}
              </Typography>
              <IconButton color="primary" onClick={handleEditType}>
                <EditIcon />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>{t("Creation time")}: </b>
                {formatDate(fullInfo.creationTime)}
              </Typography>
              <IconButton color="primary" onClick={handleEditCreationTime}>
                <EditIcon />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>{t("Features")}: </b>
                {fullInfo.features
                  ?.map((f) => t(DeviceFeatureTypes[f]))
                  .join(",")}
              </Typography>
              <IconButton color="primary" onClick={handleEditFeatures}>
                <EditIcon />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>{t("Interfaces")}: </b>
                {fullInfo.digitalBuses?.map((f) => DigitalBuses[f]).join(",")}
              </Typography>
              <IconButton color="primary" onClick={handleEditDigitalBuses}>
                <EditIcon />
              </IconButton>
            </div>
          </div>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
          >
            {
              <Tab
                label={t("Firmwares")}
                value={0}
                style={
                  fullInfo.features?.some(
                    (f) => f === DeviceFeatureTypesByName.FIRMWARE_UPDATE
                  )
                    ? {}
                    : { display: "none" }
                }
              />
            }
            {
              <Tab
                label={t("CAN block")}
                value={1}
                style={
                  fullInfo.features?.some(
                    (f) => f === DeviceFeatureTypesByName.CAN_BLOCK
                  )
                    ? {}
                    : { display: "none" }
                }
              />
            }
            {
              <Tab
                label={t("Autostart support")}
                value={2}
                style={
                  fullInfo.features?.some(
                    (f) => f === DeviceFeatureTypesByName.AUTOSTART
                  )
                    ? {}
                    : { display: "none" }
                }
              />
            }
          </Tabs>
          {tabValue === 0 ? (
            <Grid item>
              <Button
                onClick={handleAddFirmware}
                variant="contained"
                color="primary"
                style={{ marginTop: "16px" }}
              >
                {t("Add firmware")}
              </Button>
              <SubsystemFirmwaresTable
                data={
                  fullInfo.firmwares
                    ? fullInfo.firmwares.sort((a, b) =>
                        Date.parse(a.creationTime) < Date.parse(b.creationTime)
                          ? 1
                          : -1
                      )
                    : []
                }
                handlePublishChanged={handleFirmwarePublishChanged}
                handleRemoveFirmware={handleRemoveFirmware}
                handleTypeClick={handleTypeClick}
                handleDownloadClick={handleDownloadFirmwareClick}
                handleUploadSelect={handleUploadFirmwareSelect}
                handleVersionClick={handleVersionClick}
                handleDescriptionClick={handleDescriptionClick}
              />
            </Grid>
          ) : tabValue === 1 ? (
            <div style={{ maxWidth: "100%", marginBottom: "16px" }}>
              <SubsystemEquipmentsAccordion
                title={t("CAN block")}
                data={fullInfo.canEquipments}
                noEquipmentText={t("No equipments")}
                onClickAdd={handleClickAddCanEquipment}
                onClickDelete={handleClickDeleteCanEquipment}
                onClickCopy={handleClickCopyCan}
              />
            </div>
          ) : (
            <div style={{ maxWidth: "100%", marginBottom: "16px" }}>
              <SubsystemEquipmentsAccordion
                title={t("Autostart support")}
                data={fullInfo.autostartEquipments}
                noEquipmentText={t("No equipments")}
                onClickAdd={handleClickAddAutostartEquipment}
                onClickDelete={handleClickDeleteAutostartEquipment}
                onClickCopy={handleClickCopyAutostart}
              />
            </div>
          )}
        </Grid>
      </Paper>
    </>
  );
}
