import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select
} from "@mui/material";

import { useTranslation } from "react-i18next"

export default function CreateQuestionSubpartDialog({ isOpened, parts, subpart, handleConfirm, handleClose }) {
    const { t } = useTranslation();

    const [name, setName] = useState("");
    const [parentPartId, setParentPartId] = useState(-1);
    const [partsList, setPartsList] = useState([]);

    useEffect(() => {
        setName(subpart.name);
        setParentPartId(subpart.parentPartId);
    }, [subpart]);

    useEffect(() => {
        var listElements = parts.map(part => <option key={part.id} value={part.id}>{part.name}</option>)
        if (listElements.length === 0) {
            setParentPartId(-2);
            setPartsList([<option key={-2} value={-2}>{t("No available parts")}</option>]);
        } else {
            listElements.unshift(<option key={-1} value={-1}></option>)
            setPartsList(listElements);
        }
    }, [parts, t]);

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{t("Checklist subpart")}</DialogTitle>
            <DialogContent>
                <FormControl>
                    <InputLabel htmlFor="car-equipment-select-label">{t("Parent part")}</InputLabel>
                    <Select
                        native
                        label={t("Parent part")}
                        value={parentPartId}
                        onChange={(e) => setParentPartId(e.target.value)}
                        inputProps={{
                            id: "car-equipment-select-label",
                        }}
                    >
                        {partsList}
                    </Select>
                </FormControl>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("Name/Title")}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={name === undefined || name === null || name.trim() === "" || parentPartId < 0} onClick={() => { handleConfirm(subpart.id, name, parentPartId); }} color="primary" autoFocus>
                    {t("Save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
