import React, { useState, useEffect } from "react";

import { Tabs, Tab } from "@mui/material";

import { useTranslation } from "react-i18next";
import { UserPermissions } from "../../enums/UserRoles";
import OpenManualRequests from "./OpenManualRequests";
import OpenManuals from "./OpenManuals";

export default function OpenManualsRoot() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [manualsAllowed, setManualsAllowed] = useState(false);

  useEffect(() => {
    var permissions =
      JSON.parse(localStorage.getItem("permissions") || "[]") || [];
    if (permissions.includes(UserPermissions.OPEN_MANUALS_FULL)) {
      setManualsAllowed(true);
    }
  }, []);

  const handleSetTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs value={tabValue} onChange={handleSetTab}>
        <Tab label={t("Open manuals requests")} />
        {manualsAllowed ? <Tab label={t("Open manuals")} /> : ""}
      </Tabs>
      {tabValue === 0 ? <OpenManualRequests /> : <OpenManuals />}
    </>
  );
}
