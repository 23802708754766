import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@mui/material";

import { useTranslation } from "react-i18next"

export default function ConfirmationDialog({ isOpened, userMessage, handleConfirm, handleClose, closeText, confirmText }) {
    const { t } = useTranslation();

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t("Confirm action")}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {userMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    { closeText ? closeText : t("No")}
                </Button>
                <Button onClick={() => { handleClose(); handleConfirm(); }} color="primary" autoFocus>
                    { confirmText ? confirmText : t("Yes")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
