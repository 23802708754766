const ru = {
  translation: {
    Login: "Логин",
    Password: "Пароль",
    "Password mismatch": "Пароли несовпадают",
    "Password reset link sent": "Ссылка на сброс пароля отправлена",
    "Password changed": "Пароль был успешно изменен",
    "Log in": "Вход",
    Register: "Зарегистрируйтесь",
    "New password": "Новый пароль",
    "Repeat new password": "Повторите новый пароль",
    "Change password": "Изменить пароль",
    Back: "Назад",
    "Password reset": "Забыли пароль?",
    "Welcome!": "Добро пожаловать!",
    "Sign up": "Создание аккаунта",
    "Full name": "Полное имя",
    Email: "Адрес эл. почты",
    "Repeat password": "Повторите пароль",
    "Create account": "Создать аккаунт",
    "Site Copyrights": '© 2021 АО "Пандора". Все права защищены.',
    "Sign up error": "Ошибка создания аккаунта",
    NOT_CONFIRMED: "Не подтвержден",
    ADMINISTRATOR: "Администратор",
    TECH_SUPPORT: "Тех поддержка",
    MANAGEMENT: "Менеджер конфигураций",
    API_USER: "Установщик",
    TECH_SPEC: "Тех специалист",
    AUTH_ERROR: "Ошибка авторизации",
    REQUEST_ERROR: "Ошибка выполнения запроса",
    WRONG_LOGIN: "Неверный логин и/или пароль",
    NOT_CONFIRMED_LOGIN:
      "Вы не сможете войти в систему, пока не активируете учетную запись",
    SIGN_IN_SUCCESS:
      "Пользователь создан. Ссылка для активации выслана на указанный адрес эл. почты",
    SIGN_IN_ERROR: "Ошибка при обработке запроса на создание пользователя",
    EMPTY_CREDENTIALS: "Не задан один из требуемых параметров",
    "Main page": "Главная",
    Users: "Пользователи",
    Configurations: "Конфигурации",
    "Fast mounts": "Быстрые монтажи",
    Clones: "Клоны",
    Pushes: "PUSH-уведомления",
    Account: "Учетная запись",
    Logout: "Выход",
    "Users count": "Число пользователей",
    "Registered users": "Авторизации (последние 60 дней)",
    "Users offline": "Оффлайн",
    Coefficient: "Коэффициент",
    Statistics: "Статистика",
    "Delete user?": "Удалить пользователя {{user}}?",
    "Change user role?": "Изменить роль пользователя {{user}}?",
    "Manage users": "Управление пользователями",
    "Users table": "Таблица пользователей",
    "Confirm action": "Подтвердить действие",
    Yes: "Да",
    No: "Нет",
    Role: "Роль",
    Cancel: "Отмена",
    Create: "Создать",
    "Enter user credentials": "Введите реквизиты пользователя",
    "Create user warning":
      "ВНИМАНИЕ: В целях безопасности пользователь по возможности должен сам создавать пользователя через окно входа.",
    "Delete configuration?": "Удалить конфигурацию {{configuration}}?",
    "Configurations table": "Таблица конфигураций",
    "Car brand": "Марка",
    "Any (fem)": "Любая",
    "Car model": "Модель",
    Alarm: "Сигнализация",
    "Reset parameters": "Сбросить параметры",
    "Auto update": "Автоматическое обновление",
    "Page size": "Размер страницы",
    Search: "Поиск",
    "Name/Title": "Название",
    Car: "Авто",
    Author: "Автор",
    Configuration: "Конфигурация",
    "Creation date": "Дата добавления",
    "Change date": "Дата изменения",
    "Loading questions": "Загрузка опросного листа...",
    Loading: "Загрузка...",
    "Brand not selected": "Не выбрана марка",
    "DXS file not loaded": "DXS файл не загружен",
    "File is too large": "Слишком большой размер файла",
    "File already specified": "Файл {{file}} уже указан",
    "Unknown DXS file format":
      "Файл DXS имеет неизвестный формат: узел settings не найден",
    "Unknown DXS file format/encoding":
      "Файл имеет неверный формат либо неверную кодировку (ожидается UCS-2/UTF-16)",
    "No available models": "Нет доступных моделей",
    "Model not selected": "Не выбрана модель",
    "No available equipments": "Нет доступных комплектаций",
    "Short description": "Краткое описание",
    "Available to everyone": "Доступна всем в приложении",
    Equipment: "Комплектация",
    Language: "Язык",
    Checklist: "Опросный лист",
    "Configuration file": "Файл конфигурации",
    Upload: "Загрузить",
    Data: "Данные",
    "Mounting map": "Карта установки",
    "Map not loaded": "Карта не загружена",
    Open: "Открыть",
    Images: "Изображения",
    "No image": "Нет изображения",
    "Full description": "Полное описание",
    Download: "Скачать",
    Delete: "Удалить",
    Save: "Сохранить",
    Instructions: "Инструкции",
    "Check question": "Контрольный вопрос",
    Answers: "Варианты ответа",
    "Answer already exists": "Вариант ответа с таким значением уже существует",
    "No available subparts": "Нет доступных подразделов",
    "Checklist question": "Вопрос опросного листа",
    "Parent subpart": "Родительский подраздел",
    "Expected result": "Ожидаемый результат",
    Answer: "Ответ",
    Value: "Значение",
    Add: "Добавить",
    "Checklist part": "Раздел опросного листа",
    "No available parts": "Нет доступных разделов",
    "Checklist subpart": "Подраздел опросного листа",
    "Parent part": "Родительский раздел",
    "Delete part?": "Удалить раздел {{part}}?",
    "Delete subpart?": "Удалить подраздел {{subpart}}?",
    "Delete question?": "Удалить вопрос {{question}}?",
    "Mount in progress": "Монтаж в процессе",
    "Mount finished": "Монтаж завершен",
    "Mount interrupted": "Монтаж прерван",
    "Mounts table": "Таблица быстрых монтажей",
    User: "Пользователь",
    Status: "Статус",
    Comment: "Комментарий",
    "Comment on mount": "Комментарий к установке",
    "Any (mas)": "Любой",
    "Mount started": "Начало монтажа",
    Installer: "Установщик",
    Phone: "Телефон",
    "Mount time": "Время монтажа",
    Check: "Проверка",
    "Source configuration": "Исходные настройки",
    "Mount configuration": "Настройки монтажа",
    "Installer feedback": "Отзыв установщика",
    Change: "Изменить",
    "Mount log": "Лог установки",
    "Manager feedback": "Отзыв менеджера",
    "Mount event": "Событие монтажа",
    "Mount events": "События монтажа",
    Events: "События",
    Device: "Устройство",
    Title: "Заголовок",
    Event: "Событие",
    "Process type": "Тип процесса",
    "Event time": "Время события",
    View: "Просмотр",
    "View event data": "Просмотр данных лога",
    "Start time": "Время начала",
    Time: "Время",
    Algorithm: "Алгоритм",
    "Application version": "Версия приложения",
    "Decrypted clone 4096": "Расшифр.данные(4096) из AS",
    "Result wout zeroes": "result без конечных нулей",
    "Decrypted clone 4128": "Расшифр.данные(4128) из устройства",
    "Clone logs": "Логи клона",
    "Clone table": "Таблица клонов",
    "Device ID": "ID устройства",
    "Send PUSH notification": "Отправка PUSH-уведомлений",
    "PUSH notification table": "Таблица PUSH уведомлений",
    "Send PUSH": "Отправка PUSH",
    "Send time (UTC)": "Время отправки (UTC)",
    Sender: "Отправитель",
    "Newsletter type": "Тип рассылки",
    Recipients: "Получатели",
    "Message type": "Тип сообщения",
    Notification: "Уведомление",
    "Click link": "Ссылка при нажатии",
    "Image link": "Ссылка на картинку",
    "No answer": "Нет ответа",
    "Mount checklist": "Опросный лист монтажа ID {{mountId}}",
    "No questions in checklist": "В опросном листе нет вопросов",
    "Message sent": "Сообщение отправлено",
    "Sending PUSH": "Отправка PUSH уведомления",
    "Notification type": "Тип уведомления",
    "Tech support": "Тех поддержка",
    News: "Новость",
    Video: "Видео",
    Warning: "Предупреждение",
    Group: "Группа",
    "Recipient login": "Логин получателя",
    "Recipient group": "Группа-получатель",
    Result: "Результат",
    Send: "Отправить",
    "Manage account": "Настройки аккаунта",
    "Active password": "Действующий пароль",
    "Mount logs table": "Таблица событий монтажа",
    Schemes: "Схемы",
    "Schemes table": "Таблица схем",
    "Add scheme": "Добавить схему",
    "Address not detected": "Адрес еще не был определен",
    Address: "Адрес",
    Map: "Карта",
    "Duplicate configuration?": "Копировать конфигурацию {{configuration}}?",
    "Reset password token already issued":
      "Токен сброса пароля уже запрашивался. С момента последнего запроса должно пройти не менее 5 минут.",
    "Login already exists": "Логин уже существует",
    "Email is already in use": "Адрес эл. почты уже используется",
    "Max login length - 16": "Максимальная длина логина - 16",
    "Min login length - 3": "Минимальная длина логина - 3",
    "Spaces are not allowed in login": "Логин не должен содержать пробелов",
    "Max password length - 16": "Максимальная длина пароля - 16",
    "Min password length - 6": "Минимальная длина пароля - 6",
    "Spaces are not allowed in password": "Пароль не должен содержать пробелов",
    "Max name length - 16": "Максимальная длина имени - 16",
    "Min name length - 4": "Минимальная длина имени - 4",
    "Invalid email format": "Неверный формат эл. почты",
    "User not found": "Пользователь не найден",
    "Settings editor": "Редактор настроек",
    Retries: "Осталось попыток",
    "Settings editor unavailable":
      "Редактор настроек недоступен. Вы можете скачать файл и просмотреть его вручную.",
    Close: "Закрыть",
    "Edit usergroup": "Редактировать группу видимости",
    "Use usergroup filtering": "Использовать фильтрацию по группе",
    "Uses application": "Использует приложение",
    "User already exists": "Пользователь {{user}} уже существует",
    Usergroup: "Группа пользователей",
    "Login or email": "Логин или адрес эл. почты",
    "Techsupport requests": "Заявки на техподдержку",
    Description: "Описание",
    "New (fem)": "Новая",
    "In work": "В работе",
    Waiting: "В ожидании",
    Closed: "Закрыта",
    Control: "Управление",
    "Start work": "Начать работу",
    "Creation time": "Дата создания",
    "Close request": "Закрыть заявку",
    Company: "Компания",
    "Request info": "Информация о заявке",
    Request: "Заявка",
    "Techsupport requests table": "Таблица заявок на техподдержку",
    "Closed by user": "Закрыта пользователем",
    "Cancelled by user": "Отменена пользователем",
    Issue: "Проблема",
    Delayed: "Отложена",
    "Field must be populated": "Поле должно быть заполнено",
    Delay: "Отложить",
    "Work start": "Начало работы",
    "Work end": "Конец работы",
    Opened: "Открыта",
    Continue: "Продолжить",
    "Request history": "История заявки",
    "Operator comment": "Комментарий оператора",
    "Manage users page comment":
      "Управление и просмотр пользователей админ панели",
    "Usergroup page comment": "Список установщиков, закрепленных за вами",
    "Techsupport requests page comment":
      "Работа с заявками установщиков на техподдержку",
    "Configurations page comment":
      "Управление и просмотр ваших конфигураций для быстрого монтажа",
    "Fast mounts page comment":
      "Окно мониторинга быстрого монтажа сигнализации",
    "Mount event page comment": "Мониторинг действий установщиков при монтаже",
    "Clone page comment": "Мониторинг процедуры Clone",
    "Pushes page comment": "Рассылка уведомлений для установщиков",
    "Settings editor page comment":
      "Просмотр и редактирование настроек сигнализации",
    "Configuration warning tooltip":
      "У конфигурации отсутствует по крайней мере одно из следующих полей - название, модель авто, id владельца конфигурации, файл конфигурации, ссылка на монтажную карту, либо не включен флаг доступности",
    "Techsupport request switch confirmation":
      "С данной заявкой работал другой человек. Вы действительно хотите ее взять в работу?",
    "Chat page": "Чат",
    "Chat rooms page": "Чат с техподдержкой",
    "Last update": "Последнее обновление",
    Message: "Сообщение",
    "Chat room users writing": "{{users}} набирают текст...",
    "Chat room user writing": "{{users}} набирает текст...",
    "(last 20 minutes)": "(последние 20 минут)",
    "Package search": "Поиск изделий",
    "Serial number": "Серийный номер",
    "Firmware version": "Версия прошивки",
    Package: "Комплект",
    "Chat rooms page comment": "Работа в чат-комнатах с установщиками",
    "Registration date": "Дата регистрации",
    "Last online": "Последний онлайн",
    "User info tooltip": "Информация о пользователе",
    "User logs tooltip": "Логи пользователя",
    Unknown: "Неизвестно",
    "Clone differences": "Различия Clone",
    Present: "Присутствуют",
    "Not present": "Отсутствуют",
    "Unread messages only": "Только непрочитанные сообщения",
    "Please fix invalid configurations":
      "Пожалуйста, исправьте недоработанные конфигурации",
    "Please fix invalid configurations (full message)":
      "У вас есть недоработанные конфигурации (с восклицательным знаком) в количестве {{count}}. Просим их исправить как можно скорее",
    "Search by login/phone": "Поиск по логину/телефону",
    "Add usergroup user": "Добавить пользователя в группу",
    "Usergroup list dialog header": "Список ответственных лиц",
    "No users": "Нет пользователей",
    Completeness: "Завершенность",
    Completed: "Завершено",
    "Not completed": "Не завершено",
    MOUNT_FEEDBACK: "Отзыв о монтаже",
    SIGNAL_SERVICE_LOGS: "Сервисные логи",
    SETTINGS: "Настройки",
    CRAWLING: "Бесключевой обход",
    CLONE: "Процедура Clone",
    FIRMWARE: "Обновление ПО",
    "User activated": "Пользователь активирован",
    "Unknown error": "Неизвестная ошибка",
    "Token validation failed": "Ошибка проверки токена",
    API_USER_LOGIN:
      "У данной учетной записи нет доступа к панели администрирования",
    "General information": "Основная информация",
    Security: "Безопасность",
    "Account information": "Учетные данные",
    Verification: "Проверка",
    Approved: "Подтвержден",
    "Change user membership status?":
      "Изменить статус пользователя {{user}} в данной группе?",
    "Delete user from group?": "Удалить пользователя {{user}} из группы?",
    "Password reset comment":
      "Для восстановления пароля вам на почту будет отправлено письмо",
    ADMIN_PANEL_MANAGER: "Руководитель админ панели",
    TECHSUPPORT_MANAGER: "Руководитель тех поддержки",
    TECH_SPEC_SENIOR: "Старший тех специалист",
    "Statistics comment":
      "Продвинутая статистика и графики по приложению Pandora Specialist",
    From: "От",
    Till: "До",
    "Top devices": "Популярные устройства",
    "Statistics type": "Тип статистики",
    Load: "Загрузить",
    Location: "Расположение",
    Select: "Выбрать",
    Country: "Страна",
    State: "Область",
    Locality: "Нас. пункт",
    Clear: "Очистить",
    "No options": "Нет опций",
    "No data": "Нет данных",
    "Render type": "Тип отображения",
    Table: "Таблица",
    "Top devices dynamics": "Динамика популярных устройств",
    "Line plot": "Линейный график",
    "Please select at least one device type":
      "Пожалуйста, выберите хотя бы один тип устройства",
    Count: "Количество",
    "Top cars": "Популярные ТС",
    "Top cars dynamics": "Динамика популярных ТС",
    "User devices": "Устройства по пользователям",
    Date: "Дата",
    Coordinates: "Координаты",
    ANALYST: "Аналитик",
    "Search text": "Поиск по тексту",
    Found: "Найдено",
    "Too much points on map": "Слишком много точек на карте",
    CAN: "CAN",
    Fault: "Брак",
    Other: "Другое",
    Tags: "Тэги",
    "Specify no more than 3 tags": "Укажите не больше 3ех тэгов",
    "Only mine": "Только мои",
    "Factory fault": "Производственный брак",
    "Construction fault": "Конструктивный брак",
    Crawling: "Обход",
    Autostart: "Автозапуск",
    Algorithms: "Алгоритмы",
    "Application / Network service": "Приложение/Интернет сервис",
    "Pandora Specialist": "Пандора СПЕЦ",
    "Alarm Studio": "Alarm Studio",
    "Mounting maps": "Карты монтажа",
    Manual: "Инструкции",
    "Human factor": "Человеческий фактор",
    Feedback: "Пожелания",
    Clone: "Клон",
    "Client type": "Тип клиента",
    SYSTEM_CHECK: "Проверка системы",
    Sent: "Отправлен",
    "Not sent": "Не отправлен",
    Error: "Ошибка",
    "Push types": "Типы PUSH",
    "PUSH preferences": "Настройки PUSH",
    Chat: "Чат",
    "Preferred brands": "Предпочитаемые бренды",
    "Preferred brands (detected)": "Предпочитаемые бренды (автоопределение)",
    "Preferred models": "Предпочитаемые модели",
    Preferences: "Предпочтения",
    "Edit preferences": "Изменить предпочтения",
    "Manage versions": "Управление версиями",
    Version: "Версия",
    "Update channel": "Канал обновлений",
    "Is published": "Опубликован",
    Filename: "Имя файла",
    "Add version": "Добавить версию",
    "Is actual version": "Актуальная",
    "Manage update channels": "Управление каналами обновлений",
    "Access level": "Доступность",
    "Channel type": "Тип канала",
    "Manage update channel users": "Управление пользователями каналов",
    "This channel is public and available to everyone":
      "Этот канал открытый и доступен всем",
    "Copy version to...": "Скопировать версию в...",
    "No available channels": "Нет доступных каналов",
    Automobiles: "Автомобили",
    MainText1Header: "Монтаж сигнализаций Pandora просто и понятно",
    MainText1:
      "С помощью Pandora Spec Вы можете установить сигнализацию Pandora в несколько шагов - обновим ПО, выберем нужную конфигурацию настроек, проверим систему.",
    MainText2Header: "Опыт профессионалов",
    MainText2:
      "Самая масштабная  база поддерживаемых автомобилей и тысячи конфигураций от профессионалов помогут настроить сигнализацию под ваши нужды и потребности.",
    MainText3Header: "Всегда на связи",
    MainText3:
      "Мы всегда готовы прийти вам на помощь - в сервисе вы можете оставить заявку на обратный звонок или написать напрямую в чате.Наши специалисты обязательно Вам помогут.",
    "Log in to account": "Вход в учетную запись",
    "Need registration?": "Нужна регистрация?",
    "Already registered?": "Уже зарегистрировались?",
    "Try logging in": "Выполните вход",
    "Specialist for Android": "Pandora Спец для Android",
    "Specialist for PC": "Pandora Спец для ПК",
    "Download for Android": "Скачать для Android",
    "Download for Huawei": "Скачать для Huawei",
    "Download for Windows": "Скачать для Windows",
    "Download for Mac": "Скачать для Mac",
    "Our applications": "Наши приложения",
    "Model code": "Код модели",
    "CAN block": "Блокировка по CAN",
    "Immobilizer bypass": "Бесключевой автозапуск",
    Parameter: "Параметр",
    "Executable commands": "Исполняемые команды",
    "Readable statuses": "Считываемые статусы",
    Supported: "Поддерживается",
    "Not supported": "Не поддерживается",
    "Pc downloads description":
      "Pandora Спец - незаменимый помощник для профессионального монтажа охранных систем Pandora. Здесь вы сможете: обновить прошивки, обучить автозапуску, изменить настройки и проверить работоспособность системы",
    "Android downloads description":
      "С данной платформы проект начинался и пока версия под Android имеет самый богатый функционал - широкий набор средств для монтажа и диагностики и передовые средства для техпомощи и связи с нашими техспециалистами",
    "Android Version": "Версия Android",
    "iOS Version": "Версия iOS",
    "Desktop Version": "Версия Desktop",
    "Feedbacks table": "Таблица отзывов",
    Feedbacks: "Отзывы",
    Platform: "Платформа",
    MAIN_CONFIGURATION_HEADER:
      "Конфигурации - готовые настройки и монтажные карты",
    "Elements not found": "Элементов с заданными параметрами не найдено",
    MAIN_AUTOMOBILES_HEADER: "Автомобили - статусы и поддерживаемые функции",
    "Not available": "Не доступна",
    "Manage account comment":
      "Поменять реквизиты вашего аккаунта, пароль, предпочтения и т.д.",
    "Auth counts": "Число сессий",
    "Auth counts dynamics": "Динамика числа сессий",
    "Auth counts unique users dynamics":
      "Динамика числа сессий уникальных пользователей",
    Mailing: "Рассылка",
    "Send mailing": "Отправка рассылки",
    All: "Все",
    "In progress": "В процессе",
    Done: "Готово",
    "Brands table": "Таблица брендов",
    "Car brands": "Бренды авто",
    "Models table": "Таблица моделей",
    "Equipments table": "Таблица комплектаций",
    "Add brand": "Добавить бренд",
    "Remove brand?": "Удалить бренд {{title}}?",
    "This brand has models. Remove them first":
      "У этого бренда есть модели. Сначала удалите их",
    "Overwrite brand image?": "Перезаписать изображение бренда?",
    "Add model": "Добавить модель",
    "Remove model?": "Удалить модель {{title}}?",
    "This model has equipments. Remove them first":
      "У этой модели есть комплектации. Сначала удалите их",
    "Overwrite model image?": "Перезаписать изображение модели?",
    "Add equipment": "Добавить комплектацию",
    "Remove equipment?": "Удалить комплектацию {{title}}?",
    Years: "Годы",
    "CAN block devices": "Сигнализации с поддержкой CAN блокировки",
    "CAN block prompt": "Подсказка к CAN блокировке",
    "Keyless autostart": "Бесключевой автозапуск",
    "Keyless autostart devices":
      "Сигнализации с поддержкой бесключевого автозапуска",
    "Keyless autostart prompt": "Подсказка к бесключевому автозапуску",
    "There is no models for this brand": "У этого бренда нет моделей",
    "There is no equipments for this model": "У этой модели нет комплектаций",
    "Add device": "Добавить устройство",
    "Remove device?": "Удалить устройство {{title}}?",
    "Devices table": "Таблица устройств",
    Devices: "Устройства",
    "There are no subsystems in this device": "В этом устройстве нет подсистем",
    UNKNOWN: "Неизвестно",
    ALARM: "Сигнализация",
    TRINKET: "Брелок",
    BEACON: "Маяк",
    NAVIGATION_MODULE: "Навигационный модуль",
    TOKEN: "Метка",
    BLOCK_RELAY: "Реле блокировки",
    IMMOBILIZER: "Иммобилайзер",
    PANDORA_BAND: "Pandora Band",
    RMD_RHM: "RMD/RHM",
    BMW_BYPASS: "BMW bypass",
    GPS_RECEIVER: "GPS приемник",
    SIREN: "Сирена",
    DMS_DOOR_SENSOR: "Датчик двери DMS",
    HM_HOOD_LOCK_CONTROL_MODULE: "HM модуль управления замком капота",
    IMMOBILZER_BYPASS: "Обходчик иммобилайзера",
    VOLUME_SENSOR: "Датчик объема",
    CHARGER: "Зарядное устройство",
    CLOCK: "Часы",
    Type: "Тип",
    "Overwrite image?": "Перезаписать изображение?",
    Subsystems: "Подсистемы",
    Subsystem: "Подсистема",
    "Remove subsystem?": "Удалить подсистему {{title}}?",
    "Add subsystem": "Добавить подсистему",
    Name: "Название",
    "CAN support": "Поддержка CAN",
    "Autostart support": "Поддержка автозапуска",
    "No equipments": "Нет комплектаций",
    "Subsystems table": "Таблица подсистем",
    "Copy from...": "Скопировать с...",
    "Back to subsystems": "Назад к подсистемам",
    "Publish time": "Время публикации",
    Firmwares: "Прошивки",
    "Add firmware": "Добавить прошивку",
    "Delete firmware?": "Удалить прошивку?",
    "Change publication status?": "Изменить статус публикации?",
    SPEC_SYSTEM_REQUIREMENTS_WINDOWS: `Требования:
Операционная система Windows 7/8/10(64 - битная)
Процессор с тактовой частотой 1 ГГц
RAM 1024 Мб
HDD 200 Мб свободного места
Видеокарта с поддержкой OpenGL/Direct3D`,
    Requirements: "Требования",
    "Voltage calibration not supported":
      "Регулировка напряжения не поддерживается",
    Structures: "Структуры",
    Features: "Функции",
    FIRMWARE_UPDATE: "Обновление прошивки",
    KEYLESS: "Бесключевой обход",
    CAN_BLOCK: "Блокировка по CAN",
    ALARM_SETTINGS: "Настройки сигнализации",
    DYNAMIC_DEVICE_TABLE: "Динамическая таблица устройств",
    AUTOSTART: "Автозапуск",
    "Add structure": "Добавить структуру",
    "Remove structure?": "Удалить структуру {{title}}?",
    "Menu editor": "Редактор меню",
    Menus: "Меню",
    "Add menu": "Добавить меню",
    "Remove menu?": "Удалить меню {{title}}?",
    SPEC_SYSTEM_REQUIREMENTS_MAC: ``,
    Restore: "Восстановить",
    Generate: "Сгенерировать",
    "Translations table": "Таблица переводов",
    "Add translation": "Добавить перевод",
    Translations: "Переводы",
    Text: "Текст",
    "Add logic": "Добавить логику",
    Logics: "Логики",
    "Remove logic?": "Удалить логику {{title}}?",
    Channel: "Канал",
    "Add logic channel": "Добавить канал логики",
    "Remove logic channel?": "Удалить канал логики {{number}}?",
    Number: "Номер",
    Channels: "Каналы",
    "Add channel": "Добавить канал",
    "Remove channel?": "Удалить канал {{title}}?",
    "Add channel IO": "Добавить IO для канала",
    "Remove channel IO?": "Удалить IO {{title}}?",
    "Input logics": "Входные логики",
    "Output logics": "Выходные логики",
    "Default polarity": "Полярность по-умолчанию",
    Wireless: "Беспроводной",
    "Polarity structure": "Структура полярности",
    "NC structure": "Структура NC",
    Wires: "Провода",
    Input: "Ввод",
    Output: "Вывод",
    "Alarm menus": "Меню устройств",
    "Add alarm menu": "Добавить меню устройства",
    Menu: "Меню",
    Structure: "Структура",
    "Remove alarm menu?": "Удалить меню устройства {{id}}?",
    "Add wire": "Добавить провод",
    Color: "Цвет",
    "Add wire color": "Добавить цвета провода",
    "Add color": "Добавить цвет",
    "Wire colors": "Цвета проводов",
    Colors: "Цвета",
    "Remove wire?": "Удалить провод {{title}}?",
    "Pin number": "Номер пина",
    "Remove wire color?": "Удалить цвета провода {{title}}?",
    "Remove color?": "Удалить цвет {{title}}?",
    "Autohelper logs table": "Таблица логов автопомощника",
    Action: "Действие",
    Autohelper: "Автопомощник",
    "Clone tips table": "Таблица подсказок для клона",
    "Add clone tip": "Добавить подсказку для клона",
    "Clone tips": "Подсказки для клона",
    "CAN brand": "CAN бренд",
    "CAN model": "CAN модель",
    "Subsystem has no published firmwares":
      "У подсистемы нет опубликованных прошивок",
    "Remove clone tip?": "Удалить подсказку клона {{id}}?",
    "Download from Google Play/AppGallery":
      "Хотите установить приложение из каталога Google Play Store / AppGallery?",
    "Download from Google Play": "Загрузить из Google Play",
    "Download from AppGallery": "Загрузить из AppGallery",
    BLUETOOTH: "Bluetooth модуль",
    ALARM_BASE: "Основная программа сигнализации",
    CHARGER_BASE: "Основная программа зарядки",
    WATCH_BASE: "Основная программа часов",
    WATCH_INTERFACE: "Программа интерфейса часов",
    "Car alarm features": "Функции подсистем в автомобилях",
    DELETED: "Удален",
    Deleted: "Удален",
    "User was deleted": "Пользователь был удален {{date}}",
    BLUETOOTH_WATCHES: "Bluetooth модуль часов",
    Filter: "Фильтр",
    To: "В",
    "Duplicate car subsystem features":
      "Дублировать функции подсистем в автомобилях",
    Functions: "Функции",
    "Statuses and commands": "Статусы и команды",
    "Order requests": "Бизнес заявки",
    "Order requests table": "Таблица бизнес заявок",
    "Order request topics table": "Таблица тем бизнес заявок",
    "Add topic": "Добавить тему",
    Order: "Порядок",
    "Delete topic?": "Удалить тему {{id}}?",
    Topic: "Тема",
    Articles: "Статьи",
    Offer: "Предложение",
    Published: "Опубликован",
    Draft: "Черновик",
    "Add article": "Добавить статью",
    TEXT: "Текст",
    LINK: "Ссылка",
    IMAGE: "Изображение",
    BUTTON: "Кнопка",
    "Delete article?": "Удалить статью {{title}}?",
    Elements: "Элементы",
    Preview: "Предпросмотр",
    Properties: "Свойства",
    "Delete element?": "Удалить элемент?",
    "Include data": "Включить данные",
    SALES_MANAGER: "Менеджер по продажам",
    SPUTNIK_SPECIALIST: "Специалист SPUTNIK",
    Languages: "Языки",
    "Fill required fields": "Заполните обязательные поля: {{fields}}",
    Filters: "Фильтры",
    Archived: "Архив",
    "Change article status?":
      "Изменить статус статьи с {{oldStatus}} на {{newStatus}}?",
    "Save successfull": "Успешно сохранено",
    "You can't edit this article": "Вы не можете редактировать эту статью",
    Editor: "Редактор",
    Article: "Статья",
    "Show all": "Показать все",
    FUEL_SENSOR: "Топливный датчик",
    "Remove alias?": "Удалить связь {{id}}?",
    "Alarm aliases table": "Таблица связей подустройств",
    Alias: "Другое название",
    "Add alias": "Добавить связь",
    CAR: "Легковой",
    TRUCK: "Грузовой",
    VIDEO: "Видео",
    "Alternative url": "Альтернативный URL",
    VOICE_UPDATE_NORMAL: "Обновление голоса в основном режиме",
    VOICE_UPDATE_LOADER: "Обновление голоса в режиме загрузчика",
    "Upload firmware with unknown format?":
      "Выбранный файл содержит данные в неизвестном формате. Вы уверены что хотите его загрузить?",
    "Firmware version mismatch":
      "Выбранный файл содержит прошивку с версией [{{fileVersion}}], но в записи указана версия [{{recordVersion}}]. Вы уверены что хотите его загрузить?",
    "Firmware device name mismatch":
      "Выбранный файл содержит прошивку для устройства [{{fileDeviceName}}], но выбранно устройство [{{currentDeviceName}}]. Вы уверены что хотите его загрузить?",
    BMW_BYPASS_MAIN: "Основная программа BMW bypass",
    "Filename is too long":
      "Слишком длинное имя файла (макс. длина - 100 символов)",
    "Delete mounting map?": "Удалить монтажную карту {{name}}?",
    "Only with models": "Только с моделями",
    "Only with equipments": "Только с комплектациями",
    "Only with subsystems": "Только с подсистемами",
    "Only with firmwares": "Только с прошивками",
    "Equipments by CAN": "Комплектации по CAN",
    "Sort by firmware date": "Сортировать по дате прошивки",
    "Last firmware date": "Дата последней прошивки",
    "Send activation email": "Отправить письмо активации",
    "Remote mount session started": "Сессия удаленного монтажа запущена",
    "Remote mount session stopped": "Сессия удаленного монтажа завершена",
    "Device information requested": "Запрос информации об устройстве",
    "Device information received": "Информация об устройстве получена",
    "Device settings requested": "Запрос чтения настроек с устройства",
    "Device settings reading in progress":
      "Выполняется чтение настроек с устройства...",
    "Device settings received": "Настройки устройства получены",
    "Click to view": "Нажмите чтобы просмотреть",
    "Write settings request sent": "Запрос записи настроек на устройство",
    "Write settings request in progress":
      "Выполняется запись натроек на устройство...",
    "Settings written to device": "Настройки записаны на устройство",
    "Unknown mount event": "Неизвестное событие монтажа",
    "Send mount event": "Отправить событие монтажа",
    "Device information request": "Запрос информации об устройстве",
    "Device settings request": "Запрос настроек устройства",
    "Write settings request": "Запрос записи настроек на устройство",
    "Remote mount": "Удаленный монтаж",
    "Reboot request": "Запрос перезагрузки устройства",
    "Processing start": "Обработка сессии начата",
    "Processing stop": "Обработка сессии закончена",
    REMOTE_SYNCHRONIZATION: "Удаленная синхронизация",
    "Mount synchronization request": "Запрос удаленной синхронизации",
    "Mounting points": "Описание подключений",
    "Edit types": "Редактировать типы",
    "Guide types": "Тип руководства",
    "Delete type?": "Удалить тип?",
    "Delete guide?": "Удалить руководство?",
    Subtitle: "Подзаголовок",
    "Add new empty image?": "Добавить новое пустое изображение?",
    "Delete image?": "Удалить изображение?",
    "Mounting schemes": "Схемы",
    "Add new empty scheme?": "Добавить новую пустую схему?",
    "Delete mounting scheme?": "Удалить схему подключения?",
    "Add new empty map?": "Добавить новую пустую карту?",
    Interfaces: "Интерфейсы",
    "Open manuals requests": "Заявки народных карт",
    Editing: "Редактирование",
    Review: "На рассмотрении",
    "Review failed": "Отклонен",
    Revision: "Доработка",
    Vehicle: "Транспортное средство",
    Approve: "Принять",
    "To revision": "На доработку",
    Decline: "Отклонить",
    Files: "Файлы",
    "Add new file?": "Добавить новый файл?",
    "Delete file?": "Удалить файл?",
    "Request title is empty": "Не указан заголовок заявки",
    "Request description is empty": "Не указано описание заявки",
    "Request brand is not specified": "Не выбран бренд автомобиля из списка",
    "Request model is not specified": "Не выбрана модель автомобиля из списка",
    "Request equipment is not specified":
      "Не выбрана комплектация автомобиля из списка",
    "There are file records without uploaded files":
      "Есть записи файлов в которые не были загружены данные",
    "There are file records without description":
      "Есть записи файлов без описания",
    "Request is not finished": "Заявка не завершена",
    "Approve request?": "Принять заявку?",
    "Open manuals": "Народные карты",
    "Open manual": "Народная карта",
    "Copy to open manuals": "Скопировать в народные карты",
    "Delete open manual?": "Удалить народную карту?",
    Hidden: "Скрыт",
    "Send file": "Отправить файл",
    "Send image?": "Отправить изображение {{filename}}?",
    "Send file?": "Отправить файл {{filename}}?",
    "Last configuration": "Последние настройки",
    "Functions may vary":
      "Заявленный функционал может отсутствовать или различаться для данной модели, в зависимости от года выпуска и комплектации",
  },
};

export { ru };
