import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    ShortText as ViewMoreIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next"

import { formatDate } from "../../helpers/StringUtils"
import { useDialogContext, DialogActionTypes } from "../../context/DialogContext";

export default function MailingsTableComponent({ data }) {
    const dialogDispatch = useDialogContext();
    const { t } = useTranslation();

    var keys = ["ID", t("Send time (UTC)"), t("Status"), t("Sender"), t("Recipients"), t("Title"), t("Notification")];
    const onClickViewMore = (mailing) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleOutputDialogOpen,
            title: "",
            text: mailing.body,
            textLabel: "",
            noTextField: true
        });
    };

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(mailing => (
                    <TableRow key={mailing.id}>
                        <TableCell>{mailing.id}</TableCell>
                        <TableCell>{formatDate(mailing.date)}</TableCell>
                        <TableCell>{mailing.isDone ? t("Done") : t("In progress")}</TableCell>
                        <TableCell>{mailing.sender}</TableCell>
                        <TableCell>{mailing.isBulk ? t("All") : mailing.recipient}</TableCell>
                        <TableCell>{mailing.title}</TableCell>
                        <TableCell>
                            {mailing.body.length > 200 ? <IconButton color="primary" onClick={() => onClickViewMore(mailing)}>
                                <ViewMoreIcon />
                            </IconButton> : mailing.body}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
