const en = {
  translation: {
    Login: "Login",
    Password: "Password",
    "Password mismatch": "Password mismatch",
    "Password reset link sent": "Password reset link sent",
    "Password changed": "Password was successfully changed",
    "Log in": "Log in",
    Register: "Register",
    "New password": "New password",
    "Repeat new password": "Repeat new password",
    "Change password": "Change password",
    Back: "Back",
    "Password reset": "Forgot password?",
    "Welcome!": "Welcome!",
    "Sign up": "Sign up",
    "Full name": "Full Name",
    Email: "Email",
    "Repeat password": "Repeat password",
    "Create account": "Create account",
    "Site Copyrights": "© 2021 Pandora LLC. All rights reserved.",
    "Sign up error": "Sign up error",
    NOT_CONFIRMED: "Not confirmed user",
    ADMINISTRATOR: "Administrator",
    TECH_SUPPORT: "Tech support",
    MANAGEMENT: "Config manager",
    API_USER: "Installer",
    TECH_SPEC: "Tech specialist",
    AUTH_ERROR: "Authorization error",
    REQUEST_ERROR: "An error has occured while processing the request",
    WRONG_LOGIN: "Wrong login and/or password",
    NOT_CONFIRMED_LOGIN: "This account is not activated",
    SIGN_IN_SUCCESS:
      "Account created. Account activation link was sent to specified email",
    SIGN_IN_ERROR: "An error has occured whle creating a new account",
    EMPTY_CREDENTIALS: "One or more of required parameters is empty",
    "Main page": "Main page",
    Users: "Users",
    Configurations: "Configurations",
    "Fast mounts": "Fast mounts",
    Clones: "Clones",
    Pushes: "PUSH notifications",
    Account: "Account",
    Logout: "Logout",
    "Users count": "Users count",
    "Registered users": "Authorizations (last 60 days)",
    "Users offline": "Offline",
    Coefficient: "Coefficient",
    Statistics: "Statistics",
    "Delete user?": "Delete user {{user}}?",
    "Change user role?": "Change role of user {{user}}?",
    "Manage users": "Manage users",
    "Users table": "Users table",
    "Confirm action": "Confirm action",
    Yes: "Yes",
    No: "No",
    Role: "Role",
    Cancel: "Cancel",
    Create: "Create",
    "Enter user credentials": "Enter user credentials",
    "Create user warning":
      "ATTENTION: For security reasons it is preferrable for new users to create their accounts on their own from sign in page",
    "Delete configuration?": "Delete configuration {{configuration}}?",
    "Configurations table": "Configurations table",
    "Car brand": "Brand",
    "Any (fem)": "Any",
    "Car model": "Model",
    Alarm: "Alarm",
    "Reset parameters": "Reset parameters",
    "Auto update": "Auto update",
    "Page size": "Page size",
    Search: "Search",
    "Name/Title": "Title",
    Car: "Car",
    Author: "Author",
    Configuration: "Configuration",
    "Creation date": "Creation date",
    "Change date": "Change date",
    "Loading questions": "Loading questions...",
    Loading: "Loading...",
    "Brand not selected": "Brand not selected",
    "DXS file not loaded": "DXS file not loaded",
    "File is too large": "File is too large",
    "File already specified": "File {{file}} was areadу specified",
    "Unknown DXS file format":
      "DXS file is in unknown format: couldn't find settings node",
    "Unknown DXS file format/encoding":
      "File is in unknown format or wrong encoding (expected UCS-2/UTF-16)",
    "No available models": "No available models",
    "Model not selected": "Model not selected",
    "No available equipments": "No available equipments",
    "Short description": "Short description",
    "Available to everyone": "Available to everyone",
    Equipment: "Equipment",
    Language: "Language",
    Checklist: "Checklist",
    "Configuration file": "Configuration file",
    Upload: "Upload",
    Data: "Data",
    "Mounting map": "Mounting map",
    "Map not loaded": "Map not loaded",
    Open: "Open",
    Images: "Images",
    "No image": "No image",
    "Full description": "Full description",
    Download: "Download",
    Delete: "Delete",
    Save: "Save",
    Instructions: "Instructions",
    "Check question": "Question",
    Answers: "Answers",
    "Answer already exists": "Answer with that value already exists",
    "No available subparts": "No available subparts",
    "Checklist question": "Checklist question",
    "Parent subpart": "Parent subpart",
    "Expected result": "Expected result",
    Answer: "Answer",
    Value: "Value",
    Add: "Add",
    "Checklist part": "Checklist part",
    "No available parts": "No available parts",
    "Checklist subpart": "Checklist subpart",
    "Parent part": "Parent part",
    "Delete part?": "Delete part {{part}}?",
    "Delete subpart?": "Delete subpart {{subpart}}?",
    "Delete question?": "Delete question {{question}}?",
    "Mount in progress": "Mount in progress",
    "Mount finished": "Mount finished",
    "Mount interrupted": "Mount interrupted",
    "Mounts table": "Fast mounts table",
    User: "User",
    Status: "Status",
    Comment: "Comment",
    "Comment on mount": "Comment on mount",
    "Any (mas)": "Any",
    "Mount started": "Mount started",
    Installer: "Installer",
    Phone: "Phone",
    "Mount time": "Mount time",
    Check: "Check",
    "Source configuration": "Source configuration",
    "Mount configuration": "Mount configuration",
    "Installer feedback": "Installer feedback",
    Change: "Change",
    "Mount log": "Mount log",
    "Manager feedback": "Manager feedback",
    "Mount event": "Mount event",
    "Mount events": "Mount events",
    Events: "Events",
    Device: "Device",
    Title: "Title",
    Event: "Event",
    "Process type": "Process type",
    "Event time": "Event time",
    View: "View",
    "View event data": "View event data",
    "Start time": "Start time",
    Time: "Time",
    Algorithm: "Algorithm",
    "Application version": "Application version",
    "Decrypted clone 4096": "Decrypted data (4096) from AS",
    "Result wout zeroes": "result without trailing zeroes",
    "Decrypted clone 4128": "Decrypted clone 4128",
    "Clone logs": "Clone logs",
    "Clone table": "Clone table",
    "Device ID": "Device ID",
    "Send PUSH notification": "Send PUSH notification",
    "PUSH notification table": "PUSH notification table",
    "Send time (UTC)": "Send time (UTC)",
    Sender: "Sender",
    "Newsletter type": "Newsletter type",
    Recipients: "Recipients",
    "Message type": "Message type",
    Notification: "Notification",
    "Click link": "Click link",
    "Image link": "Image link",
    "No answer": "No answer",
    "Mount checklist": "Checklist of mount ID {{mountId}}",
    "No questions in checklist": "No questions in checklist",
    "Message sent": "Message sent",
    "Send PUSH": "Send PUSH",
    "Notification type": "Notification type",
    "Tech support": "Tech support",
    News: "News",
    Video: "Video",
    Warning: "Warning",
    Group: "Group",
    "Recipient login": "Recipient login",
    "Recipient group": "Recipient group",
    Result: "Result",
    Send: "Send",
    "Manage account": "Account settings",
    "Active password": "Active password",
    "Mount logs table": "Mount logs table",
    Schemes: "Schemes",
    "Schemes table": "Schemes table",
    "Add scheme": "Add scheme",
    "Address not detected": "Address is not detected yet",
    Address: "Address",
    Map: "Map",
    "Duplicate configuration?": "Duplicate configuration {{configuration}}?",
    "Reset password token already issued":
      "Reset password token already issued. At least 5 minutes must pass before a new one can be requested",
    "Login already exists": "Login already exists",
    "Email is already in use": "Email is already in use",
    "Max login length - 16": "Max login length - 16",
    "Min login length - 3": "Min login length - 3",
    "Spaces are not allowed in login": "Spaces are not allowed in login",
    "Max password length - 16": "Max password length - 16",
    "Min password length - 6": "Min password length - 6",
    "Spaces are not allowed in password": "Spaces are not allowed in password",
    "Max name length - 16": "Max name length - 16",
    "Min name length - 4": "Min name length - 4",
    "Invalid email format": "Invalid email format",
    "User not found": "User not found",
    "Settings editor": "Settings editor",
    Retries: "Retries",
    "Settings editor unavailable":
      "Settings editor unavailable. You can download the file and view it manually instead.",
    Close: "Close",
    "Edit usergroup": "Edit usergroup",
    "Use usergroup filtering": "Use usergroup filtering",
    "Uses application": "Uses application",
    "User already exists": "User {{user}} already exists",
    Usergroup: "Usergroup",
    "Login or email": "Login or email",
    "Techsupport requests": "Techsupport requests",
    Description: "Description",
    "New (fem)": "New",
    "In work": "In work",
    Waiting: "Waiting",
    Closed: "Closed",
    Control: "Control",
    "Start work": "Start work",
    "Creation time": "Creation time",
    "Close request": "Close request",
    Company: "Company",
    "Request info": "Request info",
    Request: "Request",
    "Techsupport requests table": "Techsupport requests table",
    "Closed by user": "Closed by user",
    "Cancelled by user": "Cancelled by user",
    Issue: "Issue",
    Delayed: "Delayed",
    "Field must be populated": "Field must be populated",
    Delay: "Delay",
    "Work start": "Work start",
    "Work end": "Work end",
    Opened: "Opened",
    Continue: "Continue",
    "Request history": "Request history",
    "Operator comment": "Operator comment",
    "Manage users page comment": "Manage and view users of the admin panel",
    "Usergroup page comment": "List of installers assigned to you",
    "Techsupport requests page comment":
      "Work with technical support requests from installers",
    "Configurations page comment":
      "Manage and view your configurations for fast installation",
    "Fast mounts page comment":
      "Monitoring of fast installation of security systems",
    "Mount event page comment":
      "Monitoring of installers actions during installation",
    "Clone page comment": "Monitoring of Clone procedure",
    "Pushes page comment": "Send notifications to installers",
    "Settings editor page comment":
      "View and edit settings of security systems",
    "Configuration warning tooltip":
      "At least one of configuration fields is empty: name, model, owner ID, configuration file, mounting map or accessibility flag is not set.",
    "Techsupport request switch confirmation":
      "Another user worked on this request before. Are you sure you want to take this request?",
    "Chat page": "Chat page",
    "Chat rooms page": "Chat rooms",
    "Last update": "Last update",
    Message: "Message",
    "Chat room users writing": "{{users}} are writing...",
    "Chat room user writing": "{{users}} is writing...",
    "(last 20 minutes)": "(last 20 minutes)",
    "Package search": "Product search",
    "Serial number": "Serial number",
    "Firmware version": "Firmware version",
    Package: "Package",
    "Chat rooms page comment":
      "Provide techsupport to installers via chat rooms",
    "Registration date": "Registration date",
    "Last online": "Last online",
    "User info tooltip": "User info",
    "User logs tooltip": "User logs",
    Unknown: "Unknown",
    "Clone differences": "Clone differences",
    Present: "Present",
    "Not present": "Not present",
    "Unread messages only": "Unread messages only",
    "Please fix invalid configurations": "Please fix invalid configurations",
    "Please fix invalid configurations (full message)":
      "You have {{count}} invalid configurations. Please fix them as soon as possible.",
    "Search by login/phone": "Search by login/phone",
    "Add usergroup user": "Add usergroup user",
    "Usergroup list dialog header": "List of responsible personel",
    "No users": "No users",
    Completeness: "Completeness",
    Completed: "Completed",
    "Not completed": "Not completed",
    MOUNT_FEEDBACK: "Feedback",
    SIGNAL_SERVICE_LOGS: "Service logs",
    SETTINGS: "Settings",
    CRAWLING: "Autostart",
    CLONE: "Clone",
    FIRMWARE: "Firmware",
    "User activated": "User activated",
    "Unknown error": "Unknown error",
    "Token validation failed": "Token validation failed",
    API_USER_LOGIN: "This account has no access to administration panel",
    "General information": "General information",
    Security: "Security",
    "Account information": "Account information",
    Verification: "Verification",
    Approved: "Approved",
    "Change user membership status?": "Change {{user}} membership status?",
    "Delete user from group?": "Delete user {{user}} from group?",
    "Password reset comment":
      "A link for password reset will be sent to your email",
    ADMIN_PANEL_MANAGER: "Admin panel manager",
    TECHSUPPORT_MANAGER: "Techsupport manager",
    TECH_SPEC_SENIOR: "Senior tech specialist",
    "Statistics comment": "Advanced pandora specialist statistics and charts",
    From: "From",
    Till: "Till",
    "Top devices": "Top devices",
    "Statistics type": "Statistics type",
    Load: "Load",
    Location: "Location",
    Select: "Select",
    Country: "Country",
    State: "State",
    Locality: "Locality",
    Clear: "Clear",
    "No options": "No options",
    "No data": "No data",
    "Render type": "Render type",
    Table: "Table",
    "Top devices dynamics": "Top devices dynamics",
    "Line plot": "Line plot",
    "Please select at least one device type":
      "Please select at least one device type",
    Count: "Count",
    "Top cars": "Top cars",
    "Top cars dynamics": "Top cars dynamics",
    "User devices": "User devices",
    Date: "Date",
    Coordinates: "Coordinates",
    ANALYST: "Analyst",
    "Search text": "Search text",
    Found: "Found",
    "Too much points on map": "Too much points on map",
    CAN: "CAN",
    Other: "Other",
    Tags: "Tags",
    "Specify no more than 3 tags": "Specify no more than 3 tags",
    "Only mine": "Only mine",
    "Factory fault": "Factory fault",
    "Construction fault": "Construction fault",
    Crawling: "Crawling",
    Autostart: "Autostart",
    Algorithms: "Algorithms",
    "Application / Network service": "Application / Network service",
    "Pandora Specialist": "Pandora Specialist",
    "Alarm Studio": "Alarm Studio",
    "Mounting maps": "Mounting maps",
    Manual: "Manual",
    "Human factor": "Human factor",
    Feedback: "Feedback",
    Clone: "Clone",
    "Client type": "Client type",
    SYSTEM_CHECK: "System check",
    Sent: "Sent",
    "Not sent": "Not sent",
    Error: "Error",
    "Push types": "Push types",
    "PUSH preferences": "PUSH preferences",
    Chat: "Chat",
    "Preferred brands": "Preferred brands",
    "Preferred brands (detected)": "Preferred brands (detected)",
    "Preferred models": "Preferred models",
    Preferences: "Preferences",
    "Edit preferences": "Edit preferences",
    "Manage versions": "Manage versions",
    Version: "Version",
    "Update channel": "Update channel",
    "Is published": "Is published",
    Filename: "Filename",
    "Add version": "Add version",
    "Is actual version": "Is actual",
    "Manage update channels": "Manage update channels",
    "Access level": "Access level",
    "Channel type": "Channel type",
    "Manage update channel users": "Manage update channel users",
    "This channel is public and available to everyone":
      "This channel is public and available to everyone",
    "Copy version to...": "Copy version to...",
    "No available channels": "No available channels",
    Automobiles: "Vehicles",
    MainText1Header: "Simple and easy installation of Pandora alarms",
    MainText1:
      "With the help of Pandora Spec you can install Pandora alarm in a few steps - we will update software, choose settings configuration you need and check the system for you",
    MainText2Header: "Experience of professionals",
    MainText2:
      "The largest database of supported car models and thousands of configurations made by our professionals will help you configure alarm for your needs and requirements",
    MainText3Header: "Always in touch",
    MainText3:
      "We are always ready to help you - in our application you can leave a request for a call back or write us directly in chat. Our professionals will surely help you",
    "Log in to account": "Log in to account",
    "Need registration?": "Need registration?",
    "Already registered?": "Already registered?",
    "Try logging in": "Try logging in",
    "Specialist for Android": "Specialist for Android",
    "Specialist for PC": "Specialist for PC",
    "Download for Android": "Download for Android",
    "Download for Huawei": "Download for Huawei",
    "Download for Windows": "Download for Windows",
    "Download for Mac": "Download for Mac",
    "Our applications": "Our applications",
    "Model code": "Model code",
    "CAN block": "CAN block",
    "Immobilizer bypass": "Immobilizer bypass",
    Parameter: "Parameter",
    "Executable commands": "Executable commands",
    "Readable statuses": "Readable statuses",
    Supported: "Supported",
    "Not supported": "Not supported",
    "Pc downloads description":
      "Pandora Spec - an indispensable assistant for professional installation of Pandora alarm systems. Here you can: update firmware, train autostart, change settings and check system for errors",
    "Android downloads description":
      "This project began as an Android application and for now Android version has more features and a wide range of instruments for alarm installation as well as means of getting technical support and of communation with our professionals",
    "Android Version": "Android Version",
    "iOS Version": "iOS Version",
    "Desktop Version": "Desktop Version",
    "Feedbacks table": "Feedbacks table",
    Feedbacks: "Feedbacks",
    Platform: "Platform",
    MAIN_CONFIGURATION_HEADER:
      "Configurations - premade settings and mounting maps",
    "Elements not found": "No elements fitting selected parameters",
    MAIN_AUTOMOBILES_HEADER: "Vehicles - statuses and supported functions",
    "Not available": "Not available",
    "Manage account comment":
      "Manage your account credentials, password, preferences etc.",
    "Auth counts": "Auth counts",
    "Auth counts dynamics": "Auth counts dynamics",
    "Auth counts unique users dynamics": "Auth counts unique users dynamics",
    Mailing: "Mailing",
    "Send mailing": "Send mailing",
    All: "All",
    "In progress": "In progress",
    Done: "Done",
    "Brands table": "Brands table",
    "Car brands": "Car brands",
    "Models table": "Models table",
    "Equipments table": "Equipments table",
    "Add brand": "Add brand",
    "Remove brand?": "Remove brand {{title}}?",
    "This brand has models. Remove them first":
      "This brand has models.Remove them first",
    "Overwrite brand image?": "Overwrite brand image?",
    "Add model": "Add model",
    "Remove model?": "Remove model {{title}}?",
    "This model has equipments. Remove them first":
      "This model has equipments. Remove them first",
    "Overwrite model image?": "Overwrite model image?",
    "Add equipment": "Add equipment",
    "Remove equipment?": "Remove equipment {{title}}?",
    Years: "Years",
    "CAN block devices": "CAN block devices",
    "CAN block prompt": "CAN block prompt",
    "Keyless autostart": "Keyless autostart",
    "Keyless autostart devices": "Keyless autostart devices",
    "Keyless autostart prompt": "Keyless autostart prompt",
    "There is no models for this brand": "There is no models for this brand",
    "There is no equipments for this model":
      "There is no equipments for this model",
    "Add device": "Add device",
    "Remove device?": "Remove device {{title}}?",
    "Devices table": "Devices table",
    Devices: "Devices",
    "There are no subsystems in this device":
      "There are no subsystems in this device",
    UNKNOWN: "Unknown",
    ALARM: "Alarm",
    TRINKET: "Trinket",
    BEACON: "Beacon",
    NAVIGATION_MODULE: "Navigation module",
    TOKEN: "Token",
    BLOCK_RELAY: "Block relay",
    IMMOBILIZER: "Immobilizer",
    PANDORA_BAND: "Pandora Band",
    RMD_RHM: "RMD/RHM",
    BMW_BYPASS: "BMW bypass",
    GPS_RECEIVER: "GPS receiver",
    SIREN: "Siren",
    DMS_DOOR_SENSOR: "DMS door sensor",
    HM_HOOD_LOCK_CONTROL_MODULE: "HM hood lock control module",
    IMMOBILZER_BYPASS: "Immobilizer bypass",
    VOLUME_SENSOR: "Volume sensor",
    CHARGER: "Charger",
    CLOCK: "Clock",
    Type: "Type",
    "Overwrite image?": "Overwrite image?",
    Subsystems: "Subsystems",
    Subsystem: "Subsystem",
    "Remove subsystem?": "Remove subsystem {{title}}?",
    "Add subsystem": "Add subsystem",
    Name: "Name",
    "CAN support": "CAN support",
    "Autostart support": "Autostart support",
    "No equipments": "No equipments",
    "Subsystems table": "Subsystems table",
    "Copy from...": "Copy from...",
    "Back to subsystems": "Back to subsystems",
    "Publish time": "Publish time",
    Firmwares: "Firmwares",
    "Add firmware": "Add firmware",
    "Delete firmware?": "Delete firmware?",
    "Change publication status?": "Change publication status?",
    SPEC_SYSTEM_REQUIREMENTS_WINDOWS: `System requirements:
OS Windows 7/8/10(64-bit)
CPU 1 GHz or more
RAM 1024 Мб
HDD 200 Мб free space
GPU with OpenGL/Direct3D support`,
    Requirements: "Requirements",
    "Voltage calibration not supported": "Voltage calibration not supported",
    Structures: "Structures",
    Features: "Features",
    FIRMWARE_UPDATE: "Firmware update",
    KEYLESS: "Keyless bypass",
    CAN_BLOCK: "CAN block",
    ALARM_SETTINGS: "Settings",
    DYNAMIC_DEVICE_TABLE: "Dynamic device table",
    AUTOSTART: "Autostart",
    "Add structure": "Add structure",
    "Remove structure?": "Remove structure {{title}}?",
    "Menu editor": "Menu editor",
    Menus: "Menus",
    "Add menu": "Add menu",
    "Remove menu?": "Remove menu {{title}}?",
    SPEC_SYSTEM_REQUIREMENTS_MAC: ``,
    Restore: "Restore",
    Generate: "Generate",
    "Translations table": "Translations table",
    "Add translation": "Add translation",
    Translations: "Translations",
    Text: "Text",
    "Add logic": "Add logic",
    Logics: "Logics",
    "Remove logic?": "Remove logic {{title}}?",
    Channel: "Channel",
    "Add logic channel": "Add logic channel",
    "Remove logic channel?": "Remove logic channel {{number}}?",
    Number: "Number",
    Channels: "Channels",
    "Add channel": "Add channel",
    "Remove channel?": "Remove channel {{title}}?",
    "Add channel IO": "Add channel IO",
    "Remove channel IO?": "Remove channel IO {{title}}?",
    "Input logics": "Input logics",
    "Output logics": "Output logics",
    "Default polarity": "Default polarity",
    Wireless: "Wireless",
    "Polarity structure": "Polarity structure",
    "NC structure": "NC structure",
    Wires: "Wires",
    Input: "Input",
    Output: "Output",
    "Alarm menus": "Alarm menus",
    "Add alarm menu": "Add alarm menu",
    Menu: "Menu",
    Structure: "Structure",
    "Remove alarm menu?": "Remove alarm menu {{id}}?",
    "Add wire": "Add wire",
    Color: "Color",
    "Add wire color": "Add wire color",
    "Add color": "Add color",
    "Wire colors": "Wire colors",
    Colors: "Colors",
    "Remove wire?": "Remove wire {{title}}?",
    "Pin number": "Pin number",
    "Remove wire color?": "Remove wire color {{title}}?",
    "Remove color?": "Remove color {{title}}?",
    "Autohelper logs table": "Autohelper logs table",
    Action: "Action",
    Autohelper: "Autohelper",
    "Clone tips table": "Clone tips table",
    "Add clone tip": "Add clone tip",
    "Clone tips": "Clone tips",
    "CAN brand": "CAN brand",
    "CAN model": "CAN model",
    "Subsystem has no published firmwares":
      "Subsystem has no published firmwares",
    "Remove clone tip?": "Remove clone tip {{id}}?",
    "Download from Google Play/AppGallery":
      "Want to download application from Google Play Store or AppGallery?",
    "Download from Google Play": "Download from Google Play",
    "Download from AppGallery": "Download from AppGallery",
    BLUETOOTH: "Bluetooth module",
    ALARM_BASE: "Alarm base program",
    CHARGER_BASE: "Charger base program",
    WATCH_BASE: "Watch base program",
    WATCH_INTERFACE: "Watch interface data",
    "Car alarm features": "Car subsystem features",
    DELETED: "Deleted",
    "User was deleted": "User was deleted {{date}}",
    BLUETOOTH_WATCHES: "Bluetooth module of watches",
    Filter: "Filter",
    To: "To",
    "Duplicate car subsystem features": "Duplicate car subsystem features",
    Functions: "Functions",
    "Statuses and commands": "Statuses and commands",
    "Order requests": "Business requests",
    "Order requests table": "Business requests table",
    "Order request topics table": "Business request topics table",
    "Add topic": "Add topic",
    Order: "Order",
    "Delete topic?": "Delete topic {{id}}?",
    Topic: "Topic",
    Articles: "Articles",
    Offer: "Offer",
    Published: "Published",
    Draft: "Draft",
    "Add article": "Add article",
    TEXT: "Text",
    LINK: "Link",
    IMAGE: "Image",
    BUTTON: "Button",
    "Delete article?": "Delete article {{title}}?",
    Elements: "Elements",
    Preview: "Preview",
    Properties: "Properties",
    "Delete element?": "Delete element?",
    "Include data": "Include data",
    SALES_MANAGER: "Sales manager",
    SPUTNIK_SPECIALIST: "SPUTNIK specialist",
    Languages: "Languages",
    "Fill required fields": "Fill required fields: {{fields}}",
    Filters: "Filters",
    Archived: "Archived",
    "Change article status?":
      "Change article status from {{oldStatus}} to {{newStatus}}?",
    "Save successfull": "Save successfull",
    "You can't edit this article": "You can't edit this article",
    Editor: "Editor",
    Article: "Article",
    "Show all": "Show all",
    FUEL_SENSOR: "Fuel sensor",
    "Remove alias?": "Remove alias {{id}}?",
    "Alarm aliases table": "Alarm aliases table",
    Alias: "Alias",
    "Add alias": "Add alias",
    CAR: "Car",
    TRUCK: "Truck",
    VIDEO: "Video",
    "Alternative url": "Alternative url",
    VOICE_UPDATE_NORMAL: "Update voice in normal mode",
    VOICE_UPDATE_LOADER: "Update voice in loader mode",
    "Upload firmware with unknown format?":
      "Selected file contains data in an unknown format. Are you sure you want to upload it?",
    "Firmware version mismatch":
      "Selected file contains firmware with version [{{fileVersion}}], but the record contains version [{{recordVersion}}]. Are you sure you want to upload it?",
    "Firmware device name mismatch":
      "Selected file containe firmware for device [{{fileDeviceName}}], but current device name is [{{currentDeviceName}}]. Are you sure you want to upload it?",
    BMW_BYPASS_MAIN: "BMW bypass main program",
    "Filename is too long": "Filename is too long",
    "Delete mounting map?": "Delete mounting map {{name}}?",
    "Only with models": "Only with models",
    "Only with equipments": "Only with equipments",
    "Only with subsystems": "Only with subsystems",
    "Only with firmwares": "Only with firmwares",
    "Equipments by CAN": "Equipments by CAN",
    "Sort by firmware date": "Sort by firmware date",
    "Last firmware date": "Last firmware date",
    "Send activation email": "Send activation email",
    "Remote mount session started": "Remote mount session started",
    "Remote mount session stopped": "Remote mount session stopped",
    "Device information requested": "Device information requested",
    "Device information received": "Device information received",
    "Device settings requested": "Device settings requested",
    "Device settings reading in progress":
      "Device settings reading in progress...",
    "Device settings received": "Device settings received",
    "Click to view": "Click to view",
    "Write settings request sent": "Write settings request sent",
    "Write settings request in progress":
      "Write settings request in progress...",
    "Settings written to device": "Settings written to device",
    "Unknown mount event": "Unknown mount event",
    "Send mount event": "Send mount event",
    "Device information request": "Device information request",
    "Device settings request": "Device settings request",
    "Write settings request": "Write settings request",
    "Remote mount": "Remote mount",
    "Reboot request": "Reboot request",
    "Processing start": "Session processing started",
    "Processing stop": "Session processing finished",
    REMOTE_SYNCHRONIZATION: "Remote synchronization",
    "Mount synchronization request": "Mount synchronization request",
    "Mounting points": "Connection descriptions",
    "Edit types": "Edit types",
    "Guide types": "Guide types",
    "Delete type?": "Delete type?",
    "Delete guide?": "Delete guide?",
    Subtitle: "Subtitle",
    "Add new empty image?": "Add new empty image?",
    "Delete image?": "Delete image?",
    "Mounting schemes": "Schemes",
    "Add new empty scheme?": "Add new empty scheme?",
    "Delete mounting scheme?": "Delete mounting scheme?",
    "Add new empty map?": "Add new empty map?",
    Interfaces: "Interfaces",
    "Open manuals requests": "Open manuals requests table",
    Editing: "Editing",
    Review: "Review",
    "Review failed": "Review failed",
    Revision: "Revision",
    Vehicle: "Vehicle",
    Approve: "Approve",
    "To revision": "To revision",
    Decline: "Decline",
    Files: "Files",
    "Add new file?": "Add new file?",
    "Delete file?": "Delete file?",
    "Request title is empty": "Request title is empty",
    "Request description is empty": "Request description is empty",
    "Request brand is not specified": "Request brand is not specified",
    "Request model is not specified": "Request model is not specified",
    "Request equipment is not specified": "Request equipment is not specified",
    "There are file records without uploaded files":
      "There are file records without uploaded files",
    "There are file records without description":
      "There are file records without description",
    "Request is not finished": "Request is not finished",
    "Approve request?": "Approve request?",
    "Open manuals": "Open manuals",
    "Open manual": "Open manual",
    "Copy to open manuals": "Copy to open manuals",
    "Delete open manual?": "Delete open manual?",
    Hidden: "Hidden",
    "Send file": "Send file",
    "Send image?": "Send image {{filename}}?",
    "Send file?": "Send file {{filename}}?",
    "Last configuration": "Last configuration",
    "Functions may vary":
      "The declared functionality may be matching or vary for this model, depending on production year and equipment",
  },
};

export { en };
