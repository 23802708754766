import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Grid,
  Typography,
  Button,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import CarModelsTable from "../components/Tables/CarModelsTable/CarModelsTable";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import { CarModelTypes } from "../enums/CarModelTypes";
import { LanguageConverter } from "../enums/LanguageConverter";
import CarEquipments from "./CarEquipments";
import {
  SettingName,
  settingRepositoryLoadBool,
  settingRepositorySave,
} from "../settingRepository";

export default function CarModels() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [tableData, setTableData] = useState([]);
  const [searchParameters, setSearchParameters] = useState({
    brandId: 0,
    onlyWithEquipments: false,
  });
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshTable = useCallback(
    (searchParameters) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckCarsListModels(
          searchParameters.brandId,
          false,
          searchParameters.onlyWithEquipments
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }
        });
    },
    [t, backend]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    let onlyWithEquipmentsSetting = settingRepositoryLoadBool(
      SettingName.CAR_MODELS_ONLY_WITH_EQUIPMENTS,
      true
    );
    var params = queryString.parse(location.search);
    if (params.brandId) {
      setSearchParameters({
        brandId: params.brandId,
        onlyWithEquipments: onlyWithEquipmentsSetting,
      });
    } else {
      setError("Brand ID is not set");
    }
  }, [location]);

  useEffect(() => {
    if (searchParameters.brandId != 0) {
      refreshTable(searchParameters);
    }
  }, [searchParameters]);

  const handleShowCarModelImage = (model) => {
    dialogDispatch({
      type: DialogActionTypes.ImageViewerOpen,
      image: {
        src: model.imageUrl,
        alt: "",
      },
    });
  };

  const buildModelEquipments = (model) => {
    return <CarEquipments initialModelId={model.id} />;
  };

  const handleCreateModelOpen = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (title) => {
        ++updatesLockCounter.current;

        let id = GenerateInteger();
        lastRequestId.current = id;
        backend
          .bckCarsCreateOrUpdateModel(
            null,
            searchParameters.brandId,
            title,
            "",
            null
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let modelId = json.content;
              let newElement = {
                id: modelId,
                name: title,
                imageUrl: null,
              };
              setTableData((prev) => {
                let newArr = [newElement, ...prev];
                return newArr;
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: "",
      textLabel: t("Title"),
      title: t("Add model"),
    });
  };

  const handleTitleClick = (model) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (title) => {
        ++updatesLockCounter.current;

        let id = GenerateInteger();
        lastRequestId.current = id;
        backend
          .bckCarsCreateOrUpdateModel(
            model.id,
            searchParameters.brandId,
            title,
            model.years,
            null
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let modelId = json.content;
              setTableData((prev) => {
                var foundIndex = prev.findIndex((x) => x.id === modelId);
                if (foundIndex !== -1) {
                  prev[foundIndex].name = title;
                }
                return [...prev];
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: model.name,
      textLabel: t("Title"),
      title: t("Title"),
    });
  };

  const handleRemoveModel = (model) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove model?", { title: model.name }),
      handleConfirm: () => {
        ++updatesLockCounter.current;
        backend.bckCarsRemoveModel(model.id).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
            setTableData((prev) => {
              let foundIndex = prev.findIndex((x) => x.id === model.id);
              if (foundIndex !== -1) {
                prev.splice(foundIndex, 1);
              }
              return [...prev];
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const handleUploadImage = (model, file) => {
    if (model.imageUrl) {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Overwrite model image?"),
        handleConfirm: () => {
          handleUploadImageAccepted(model, file);
        },
      });
    } else {
      handleUploadImageAccepted(model, file);
    }
  };

  const handleUploadImageAccepted = (model, file) => {
    if (file.size > 16 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    backend.bckFilesUploadCarModelImage(model.id, file).then((json) => {
      if (json.code === 0) {
        setTableData((prev) => {
          let next = [...prev];
          let target = next.find((r) => r.id === model.id);
          if (target) target.imageUrl = json.content;
          return next;
        });
      } else {
        setError(json.message);
      }
    });
  };

  const handleYearsClick = (model) => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: model.years
        ? model.years.split(",").map((y) => parseInt(y))
        : [],
      title: t("Years"),
      label: t("Years"),
      options: [...Array(60).keys()].map((i) => i + 1970),
      scrollElementToWhenEmpty: new Date().getFullYear(),
      multiple: true,
      handleConfirm: (newYears) => {
        let newYearsString = newYears.sort().join(",");
        backend
          .bckCarsCreateOrUpdateModel(
            model.id,
            searchParameters.brandId,
            model.name,
            newYearsString,
            null
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              setTableData((prev) => {
                let next = [...prev];
                let target = next.find((r) => r.id === model.id);
                if (target) target.years = newYearsString;
                return next;
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleTypeClick = (model) => {
    let options = Object.keys(CarModelTypes).map((id) => ({
      id: id,
      name: t(CarModelTypes[id]),
    }));
    let selectedOption = options.filter((o) => o.id == model.type)[0];
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: selectedOption,
      title: t("Type"),
      label: t("Type"),
      options: options,
      getOptionLabel: (option) => option?.name,
      handleConfirm: (newType) => {
        if (!newType) return;
        backend
          .bckCarsCreateOrUpdateModel(
            model.id,
            searchParameters.brandId,
            model.name,
            model.years,
            newType.id
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              setTableData((prev) => {
                let next = [...prev];
                let target = next.find((r) => r.id === model.id);
                if (target) target.type = newType.id;
                return next;
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleUploadMountingMap = (model, file) => {
    if (file.size > 40 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }
    if (file.name.length > 100) {
      alert(t("Filename is too long"));
      return;
    }

    backend
      .bckFilesUploadCarModelMountingMap(
        model.id,
        LanguageConverter[i18n.language],
        file
      )
      .then((json) => {
        if (json.code === 0) {
          setTableData((prev) => {
            let next = [...prev];
            let target = next.find((r) => r.id === model.id);
            if (target) {
              let newMaps = [...target.mountingMaps, json.content];
              target.mountingMaps = newMaps;
            }
            return next;
          });
        } else {
          setError(json.message);
        }
      });
  };

  const handleDeleteMountingMap = (model, mountingMap) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete mounting map?", { name: mountingMap.name }),
      handleConfirm: () => {
        handleDeleteMountingMapAccepted(model, mountingMap);
      },
    });
  };

  const handleDeleteMountingMapAccepted = (model, mountingMap) => {
    backend.bckFilesRemoveCarModelMountingMap(mountingMap.id).then((json) => {
      if (json.code === 0) {
        setTableData((prev) => {
          let next = [...prev];
          let target = next.find((r) => r.id === model.id);
          if (target) {
            let newMaps = [...target.mountingMaps];
            let toRemoveIndex = newMaps.indexOf(mountingMap);
            if (toRemoveIndex !== -1) {
              newMaps.splice(toRemoveIndex, 1);
            }
            target.mountingMaps = newMaps;
          }
          return next;
        });
      } else {
        setError(json.message);
      }
    });
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("Models table")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          <Grid item>
            <Button
              onClick={handleCreateModelOpen}
              variant="contained"
              color="primary"
            >
              {t("Add model")}
            </Button>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={searchParameters.onlyWithEquipments}
                  onChange={() => {
                    let newValue = !searchParameters.onlyWithEquipments;
                    setSearchParameters((prev) => ({
                      ...prev,
                      onlyWithEquipments: newValue,
                    }));
                    settingRepositorySave(
                      SettingName.CAR_MODELS_ONLY_WITH_EQUIPMENTS,
                      newValue
                    );
                  }}
                  color="primary"
                />
              }
              label={t("Only with equipments")}
            />
          </Grid>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            <CarModelsTable
              data={tableData}
              handleShowCarModelImage={handleShowCarModelImage}
              buildModelEquipments={buildModelEquipments}
              handleRemoveModel={handleRemoveModel}
              handleUploadImage={handleUploadImage}
              handleTitleClick={handleTitleClick}
              handleYearsClick={handleYearsClick}
              handleTypeClick={handleTypeClick}
              handleUploadMountingMap={handleUploadMountingMap}
              handleDeleteMountingMap={handleDeleteMountingMap}
            />
          </div>
        </Grid>
      </Paper>
    </>
  );
}
