import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { Error as AdviceIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import {
  useDialogContext,
  DialogActionTypes,
} from "../../context/DialogContext";
import EquipmentStatusesTable from "../../components/Tables/EquipmentStatusesTable/EquipmentStatusesTable";
import { CarModelTypes } from "../../enums/CarModelTypes";
import CarMountingSchemesTable from "../../components/Tables/CarMountingSchemesTable";
import CarMountingPointGuide from "./CarMountingPointGuide";
import { Link } from "react-router-dom";
import OpenManuals from "../openManuals/OpenManuals";

export default function EquipmentView({ data, modelData }) {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(1);
  const dialogDispatch = useDialogContext();

  const boolToText = (value) => {
    return value ? (
      <div style={{ color: "green" }}>{t("Supported")}</div>
    ) : (
      <div style={{ color: "red" }}>{t("Not supported")}</div>
    );
  };

  const showOutputDialog = (text) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleOutputDialogOpen,
      text: text,
      textLabel: "",
      title: "",
      noTextField: true,
    });
  };

  const onShowPromptClick = (s) => {
    showOutputDialog(<div dangerouslySetInnerHTML={{ __html: s.prompt }} />);
  };

  const handleDownloadMountingScheme = (scheme) => {
    if (scheme.url) {
      window.open(scheme.url, `_blank`);
    } else {
      window.open(
        "https://specialist.alarmtrade.ru/uploads/cars/mounting_schemes/" +
          scheme.name,
        `_blank`
      );
    }
  };

  const handleDownloadMountingMap = (map) => {
    if (map.url) {
      window.open(map.url, `_blank`);
    } else {
      window.open(
        "https://specialist.alarmtrade.ru/uploads/cars/mounting_maps/" +
          map.name,
        `_blank`
      );
    }
  };

  const showMountingPointsGuides =
    data && data.mountingPointGuides && data.mountingPointGuides.length > 0;
  const showMountingSchemes =
    data && data.mountingSchemes && data.mountingSchemes.length > 0;
  const showMountingMaps =
    data && data.mountingMaps && data.mountingMaps.length > 0;
  const showOpenManuals = data && data.anyOpenManuals;

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.downloadsContainer}>
        <div className={styles.imageRowColumn}>
          <div className={styles.imageRowReversed}>
            <div className={styles.imageRowText}>
              <h2>{`${data.carBrandName} ${data.carModelName}`}</h2>
              <h3>{data.carName}</h3>
              <h3>{t(CarModelTypes[data.modelType])}</h3>
            </div>
            <div className={styles.imageRowImageDiv}>
              {data.carImageUrl ? (
                <img
                  className={styles.imageRowImage}
                  src={data.carImageUrl}
                  alt=""
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>{t("Model code")}</TableCell>
            <TableCell>
              <h2>{data.carCode}</h2>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t("CAN block")}
              {data.canBlockPrompt ? (
                <IconButton
                  color="primary"
                  onClick={() =>
                    showOutputDialog(
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.canBlockPrompt,
                        }}
                      />
                    )
                  }
                >
                  <AdviceIcon />
                </IconButton>
              ) : (
                <></>
              )}
            </TableCell>
            <TableCell>{boolToText(data.canBlock)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {t("Immobilizer bypass")}
              {data.immobKeylessPrompt ? (
                <IconButton
                  color="primary"
                  onClick={() =>
                    showOutputDialog(
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.immobKeylessPrompt,
                        }}
                      />
                    )
                  }
                >
                  <AdviceIcon />
                </IconButton>
              ) : (
                <></>
              )}
            </TableCell>
            <TableCell>{boolToText(data.immobKeyless)}</TableCell>
          </TableRow>
          {modelData?.mountingMaps?.length === 0 ? (
            <></>
          ) : (
            <TableRow>
              <TableCell>{t("Mounting map")}</TableCell>
              <TableCell>
                {modelData?.mountingMaps?.map((mm) => (
                  <div id={mm.id}>
                    <Link
                      component="button"
                      onClick={() => window.open(`${mm.url}`, `_blank`)}
                    >
                      <Typography variant="body1">{mm.name}</Typography>
                    </Link>
                  </div>
                ))}
              </TableCell>
            </TableRow>
          )}
          {data?.description1 ? (
            <TableRow>
              <TableCell>
                <Typography>{t("Description")} 1:</Typography>
              </TableCell>
              <TableCell>
                <div dangerouslySetInnerHTML={{ __html: data.description1 }} />
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
          {data?.description2 ? (
            <TableRow>
              <TableCell>
                <Typography>{t("Description")} 2:</Typography>
              </TableCell>
              <TableCell>
                <div dangerouslySetInnerHTML={{ __html: data.description2 }} />
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
      <div style={{ marginBottom: "16px" }} />
      {showMountingPointsGuides ||
      showMountingSchemes ||
      showMountingMaps ||
      showOpenManuals ? (
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
          variant="scrollable"
          scrollButtons={false}
        >
          <Tab label={t("Statuses and commands")} key={1} value={1} />
          <Tab
            label={t("Mounting points")}
            key={2}
            value={2}
            style={showMountingPointsGuides ? null : { display: "none" }}
          />
          <Tab
            label={t("Mounting schemes")}
            key={3}
            value={3}
            style={showMountingSchemes ? null : { display: "none" }}
          />
          <Tab
            label={t("Mounting maps")}
            key={4}
            value={4}
            style={showMountingMaps ? null : { display: "none" }}
          />
          <Tab
            label={t("Open manuals")}
            key={5}
            value={5}
            style={showOpenManuals ? null : { display: "none" }}
          />
        </Tabs>
      ) : (
        <></>
      )}
      {((tabValue) => {
        switch (tabValue) {
          case 1:
            return (
              <div style={{ width: "100%" }}>
                <EquipmentStatusesTable
                  data={data.statuses}
                  digitalBusesGroups={data.digitalBusesGroups}
                  onShowPromptClick={onShowPromptClick}
                  isEditable={false}
                  onCellClick={() => {}}
                />
                <div
                  style={{
                    margin: "8px",
                    borderRadius: "16px",
                    padding: "8px",
                    backgroundColor: "#FFE854",
                    textAlign: "center",
                  }}
                >
                  <Typography style={{ padding: "8px" }}>
                    {t("Functions may vary")}
                  </Typography>
                </div>
              </div>
            );
          case 2:
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "100%",
                  marginBottom: "16px",
                }}
              >
                {data.mountingPointGuides.map((guide) => (
                  <div key={guide.id} style={{ marginBottom: "16px" }}>
                    <CarMountingPointGuide key={guide.id} guide={guide} />
                  </div>
                ))}
              </div>
            );
          case 3:
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "100%",
                  marginBottom: "16px",
                }}
              >
                <CarMountingSchemesTable
                  data={data.mountingSchemes}
                  readOnly
                  handleDownload={handleDownloadMountingScheme}
                />
              </div>
            );
          case 4:
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "100%",
                  marginBottom: "16px",
                }}
              >
                <CarMountingSchemesTable
                  data={data.mountingMaps}
                  readOnly
                  handleDownload={handleDownloadMountingMap}
                />
              </div>
            );
          case 5:
            return (
              <div style={{ width: "100%" }}>
                <OpenManuals equipmentId={data.id} shortMode={true} />
              </div>
            );
          default:
            return <></>;
        }
      })(tabValue)}
    </div>
  );
}
