import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Tooltip
} from "@mui/material";
import {
    FileCopy as DuplicateIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    ReportProblem as WarningIcon
} from "@mui/icons-material";

import { UserPermissions } from "../../enums/UserRoles";
import { formatDate } from "../../helpers/StringUtils"
import { useTranslation } from "react-i18next"

export default function ConfigurationsTableComponent({ data, onEditClick, onDeleteClick, onDuplicateClick }) {
    const { t } = useTranslation();
    const keys = ["", "#", t("Name/Title"), t("Car"), t("Alarm"), t("Author"), t("Configuration"), t("Creation date"), t("Change date")];

    var userId = parseInt(localStorage.getItem("userId") || "0");
    var permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
    var canDuplicate = permissions.includes(UserPermissions.CONFIGURATIONS_CREATE);
    var canEditAll = permissions.includes(UserPermissions.CONFIGURATIONS_EDIT_ALL);
    var canEditOwn = permissions.includes(UserPermissions.CONFIGURATIONS_EDIT_OWN);

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    data.map(config => {
                        var editAllowed = canEditAll || (canEditOwn && config.authorId === userId);
                        return (
                            <TableRow key={config.id}>
                                <TableCell style={{ minWidth: "190px" }}>
                                    <IconButton
                                        style={{ color: 'black' }}
                                        onClick={() => { onEditClick(config) }} >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={!canDuplicate}
                                        style={!canDuplicate ? { color: 'gray' } : { color: 'black' }}
                                        onClick={() => { onDuplicateClick(config) }} >
                                        <DuplicateIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={!editAllowed}
                                        style={!editAllowed ? { color: 'gray' } : { color: 'red' }}
                                        onClick={() => { onDeleteClick(config) }} >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>{config.id}</TableCell>
                                <TableCell>
                                    <div style={{ flexFlow: "row wrap" }}>
                                        {
                                            config.isComplete ?
                                                <></> :
                                                <Tooltip title={t("Configuration warning tooltip")}>
                                                    <WarningIcon style={{ color: '#EBB734' }} />
                                                </Tooltip>
                                        }
                                        {config.name}
                                    </div>
                                </TableCell>
                                <TableCell>{config.carBrandName} {config.carModelName} | {config.carEquipmentName}</TableCell>
                                <TableCell>{config.alarmName}<br />({config.alarmTitle})</TableCell>
                                <TableCell>{config.authorEmail}</TableCell>
                                <TableCell>{config.configFileName}</TableCell>
                                <TableCell>{formatDate(config.dateAdded)}</TableCell>
                                <TableCell>{formatDate(config.dateChanged)}</TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    );
}
