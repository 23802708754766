import React, { } from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    Delete as DeleteIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next"

import styles from "./hoverableStyles.module.css"

export default function OrderRequestTopicsTable({ data, handleClickOrder, handleClickTitle, handleClickDescription, handleClickDelete }) {
    const { t } = useTranslation();

    var keys = ["#", t("Order"), t("Title"), t("Description")];

    return (
        <Table size="small">
            <TableHead>
                <TableRow key="header">
                    {keys.map((key, i) => (
                        <TableCell key={i} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(topic => (
                    <TableRow key={topic.id}>
                        <TableCell className={styles.cell}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <IconButton color="secondary" onClick={() => handleClickDelete(topic)}>
                                    <DeleteIcon />
                                </IconButton>
                                <div>{topic.id}</div>
                            </div>
                        </TableCell>
                        <TableCell className={styles.hoverableCell} onClick={() => handleClickOrder(topic)}>
                            {topic.order}
                        </TableCell>
                        <TableCell className={styles.hoverableCell} onClick={() => handleClickTitle(topic)}>
                            {topic.title}
                        </TableCell>
                        <TableCell className={styles.hoverableCell} onClick={() => handleClickDescription(topic)}>
                            {topic.description}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
