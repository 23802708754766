import React, { useState } from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    Save as SaveIcon,
    Delete as DeleteIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useDialogContext, DialogActionTypes } from "../../context/DialogContext";
import { useTranslation } from "react-i18next";

export default function CloneTipsTable({ data, handleSave, handleRemove }) {
    const { t, i18n } = useTranslation();
    const dialogDispatch = useDialogContext();
    const [editedCloneTips, setEditedCloneTips] = useState({});

    const handleSaveClick = (cloneTip) => {
        handleSave(cloneTip, editedCloneTips[cloneTip.id], () => {
            setEditedCloneTips((prev) => {
                let newEdited = { ...prev };
                delete newEdited[cloneTip.id];
                return newEdited;
            })
        });
    }

    const updateCloneTips = (cloneTip, update) => {
        setEditedCloneTips((prev) => {
            let newEdited = { ...prev };
            let editedCloneTip = newEdited[cloneTip.id];
            delete newEdited[cloneTip.id];
            if (!editedCloneTip) {
                editedCloneTip = {
                    ...cloneTip,
                    text: {
                        ...cloneTip.text
                    }
                };
                newEdited[cloneTip.id] = editedCloneTip;
            }
            editedCloneTip = {
                ...editedCloneTip,
                ...update
            };
            newEdited[cloneTip.id] = editedCloneTip;
            return newEdited;
        });
    }

    const handleEditText = (cloneTip) => {
        let editedCloneTip = editedCloneTips[cloneTip.id];
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            initialValue: editedCloneTip ? editedCloneTip.text[i18n.language.toUpperCase()] : cloneTip.text[i18n.language.toUpperCase()],
            textLabel: t("Text"),
            title: t("Text"),
            lines: 5,
            handleConfirm: (text) => {
                let newText = {
                    ...(editedCloneTip ?? cloneTip).text
                };
                newText[i18n.language.toUpperCase()] = text;
                updateCloneTips(cloneTip, { text: newText });
            }
        });
    }

    const handleEditStatus = (cloneTip) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            initialValue: editedCloneTips[cloneTip.id] ? editedCloneTips[cloneTip.id].status : cloneTip.status,
            textLabel: t("Status"),
            title: t("Status"),
            handleConfirm: (status) => {
                updateCloneTips(cloneTip, { status });
            }
        });
    }

    const handleEditCanMarka = (cloneTip) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            initialValue: formatCAN(editedCloneTips[cloneTip.id] ? editedCloneTips[cloneTip.id].canMarka : cloneTip.canMarka),
            textLabel: t("CAN brand"),
            title: t("CAN brand"),
            handleConfirm: (canMarkaHex) => {
                let canMarka = parseInt(canMarkaHex, 16);
                updateCloneTips(cloneTip, { canMarka });
            },
            onChange: (e) => {
                let filteredValue = e.target.value.replace(/[^0-9]/g, '');
                return filteredValue.substr(0, 2);
            }
        });
    }

    const handleEditCanModel = (cloneTip) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            initialValue: formatCAN(editedCloneTips[cloneTip.id] ? editedCloneTips[cloneTip.id].canModel : cloneTip.canModel),
            textLabel: t("CAN model"),
            title: t("CAN model"),
            handleConfirm: (canModelHex) => {
                let canModel = parseInt(canModelHex, 16);
                updateCloneTips(cloneTip, { canModel });
            },
            onChange: (e) => {
                let filteredValue = e.target.value.replace(/[^0-9]/g, '');
                return filteredValue.substr(0, 2);
            }
        });
    }

    const handleEditAlgoName = (cloneTip) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            initialValue: editedCloneTips[cloneTip.id] ? editedCloneTips[cloneTip.id].algoName : cloneTip.algoName,
            textLabel: t("Algorithm"),
            title: t("Algorithm"),
            handleConfirm: (algoName) => {
                updateCloneTips(cloneTip, { algoName });
            }
        });
    }

    const formatCAN = (number) => {
        return number?.toString(16).padStart(2, '0');
    }

    var keys = ["#", t("Status"), t("CAN brand"), t("CAN model"), t("Algorithm"), t("Text")];
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(cloneTip => (
                    <TableRow key={cloneTip.id}>
                        <TableCell className={styles.cell}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div>
                                    {cloneTip.id}
                                </div>
                                <IconButton
                                    disabled={!(cloneTip.id in editedCloneTips)}
                                    style={cloneTip.id in editedCloneTips ? { color: 'blue' } : { color: 'gray' }}
                                    onClick={() => handleSaveClick(cloneTip)}
                                >
                                    <SaveIcon />
                                </IconButton>
                                <IconButton
                                    style={{ color: 'red' }}
                                    onClick={() => handleRemove(cloneTip)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditStatus(cloneTip)}
                        >
                            {
                                editedCloneTips[cloneTip.id] ? editedCloneTips[cloneTip.id].status : cloneTip.status
                            }
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditCanMarka(cloneTip)}
                        >
                            {
                                formatCAN(editedCloneTips[cloneTip.id] ? editedCloneTips[cloneTip.id].canMarka : cloneTip.canMarka)
                            }
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditCanModel(cloneTip)}
                        >
                            {
                                formatCAN(editedCloneTips[cloneTip.id] ? editedCloneTips[cloneTip.id].canModel : cloneTip.canModel)
                            }
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditAlgoName(cloneTip)}
                        >
                            {
                                editedCloneTips[cloneTip.id] ? editedCloneTips[cloneTip.id].algoName : cloneTip.algoName
                            }
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditText(cloneTip)}
                        >
                            <pre style={{ maxHeight: "3ch", width: "64ch", overflowX: "hidden", overflowY: "hidden" }}>
                                {
                                    editedCloneTips[cloneTip.id] ? editedCloneTips[cloneTip.id].text[i18n.language.toUpperCase()] : cloneTip.text[i18n.language.toUpperCase()]
                                }
                            </pre>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
