const ArticleElementTextAlign = {
    1: "TEXT_START",
    2: "CENTER",
    3: "TEXT_END"
};
Object.freeze(ArticleElementTextAlign);

const ArticleElementTextAlignByName = {
    "TEXT_START": 1,
    "CENTER": 2,
    "TEXT_END": 3
};
Object.freeze(ArticleElementTextAlignByName);

export {
    ArticleElementTextAlign,
    ArticleElementTextAlignByName
}