import React, { useState, useEffect, useRef, useCallback } from "react";

import { Grid, Typography, Button, Paper } from "@mui/material";

import PushesTable from "../components/Tables/PushesTable";
import { GenerateInteger } from "../helpers/RandomGenerator";

import { useInterval } from "../useInterval";
import { useTranslation } from "react-i18next";
import SendPushDialog from "../components/Dialogs/SendPushDialog";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoadInt,
  settingRepositorySave,
} from "../settingRepository";

export default function Pushes() {
  const backend = useBackend();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [isInitializing, setIsInitializing] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPushes, setTotalPushes] = useState(100);
  const [isDialogOpened, setDialogOpened] = useState(false);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  const handleDialogOpen = () => {
    setDialogOpened(true);
  };

  const refreshTable = useCallback(
    (pageNumber, pageSize, isInitializing) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend.bckPushesList(pageNumber + 1, pageSize).then((json) => {
        --updatesLockCounter.current;
        if (lastRequestId.current === id) {
          if (json.code === 0) {
            setTableData((prev) => {
              if (updatesLockCounter.current === 0) {
                json.content.rows.forEach((r) => {
                  try {
                    r.data = JSON.parse(r.data);
                  } catch (e) {
                    console.log("Push parse error");
                    console.log(r);
                    r.data = {
                      sender: "Parse error",
                      recipient: "Parse error",
                      data: "Parse error",
                      lang: "Parse error",
                      time: 0,
                      recipientType: 0,
                      action_url: "",
                      image_url: "",
                    };
                  }
                });
                return json.content.rows;
              } else {
                return prev;
              }
            });
            setTotalPushes((prev) => {
              if (updatesLockCounter.current === 0) {
                return json.content.total;
              } else {
                return prev;
              }
            });
          } else if (json.code === ResponseCode.ACCESS_DENIED) {
            setError(t("AUTH_ERROR"));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        }

        if (isInitializing) {
          setIsInitializing(false);
        }
      });
    },
    [t, backend]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    if (isInitializing) {
      let pageSize = settingRepositoryLoadInt(SettingName.PUSHES_PAGE_SIZE, 10);
      setPageSize(pageSize);

      refreshTable(0, pageSize, true);
    }
    // eslint-disable-next-line
  }, [isInitializing]);

  useInterval(() => {
    if (updatesLockCounter.current === 0) {
      refreshTable(pageNumber, pageSize, isInitializing);
    }
  }, 2000);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    refreshTable(newPage, pageSize, isInitializing);
  };

  const handleChangeRowsPerPage = (event) => {
    var newRowsPerPage = parseInt(event.target.value, 10);
    settingRepositorySave(SettingName.PUSHES_PAGE_SIZE, newRowsPerPage);
    setPageSize(newRowsPerPage);
    setPageNumber(0);
    refreshTable(0, newRowsPerPage, isInitializing);
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("PUSH notification table")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          <Grid item>
            <Button
              onClick={handleDialogOpen}
              variant="contained"
              color="primary"
            >
              {t("Send PUSH")}
            </Button>
          </Grid>
          <AdvancedTablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRows={totalPushes}
            pageSize={pageSize}
            pageNumber={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          >
            <PushesTable data={tableData} />
          </AdvancedTablePagination>
        </Grid>
      </Paper>
      <SendPushDialog
        isOpened={isDialogOpened}
        handleClose={handleDialogClose}
      />
    </>
  );
}
