import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import CarEquipmentsTableRow from "./CarEquipmentsTableRow";

export default function CarEquipmentsTable({ data, handleRemoveEquipment, handleShowEquipmentFull, handleNameClick, handleCanCodeClick }) {
    const { t } = useTranslation();
   
    var keys = ["#", t("Equipment"), "CAN", ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(equipment => (
                    <CarEquipmentsTableRow
                        key={equipment.id}
                        equipment={equipment}
                        handleCanCodeClick={handleCanCodeClick}
                        handleNameClick={handleNameClick}
                        handleRemoveEquipment={handleRemoveEquipment}
                        handleShowEquipmentFull={handleShowEquipmentFull}
                    />
                ))}
            </TableBody>
        </Table>
    );
}
