import React from "react";
import { Button } from "@mui/material";
import { ClientTypesByName } from "../../enums/ClientTypes";
import { useTranslation } from "react-i18next";
import siteAndroid from "./images/SiteAndroid.png";
import styles from "./styles.module.css"

export default function MainDownloadsMobile() {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div style={{ textAlign: "center", maxWidth: "400px", minHeight: "95vh" }}>
                <img src={siteAndroid} style={{ width: "100%" }} alt="" />
                <h2>
                    {t("Specialist for Android")}
                </h2>
                <p>
                    {t("Android downloads description")}
                </p>
                <Button variant="contained"
                    onClick={() => window.open(`/frontend/UpdateChannel/GetActualFile?clientType=${ClientTypesByName.Android}`, '_blank')}
                    color="primary"
                    style={{ margin: "16px" }}>
                    {t("Download for Android")}
                </Button>
                <p>
                    {t("Download from Google Play/AppGallery")}
                </p>
                <Button variant="contained"
                    onClick={() => window.open(`https://play.google.com/store/apps/details?id=ru.alarmtrade.pandoraspecialist`, '_blank')}
                    color="primary"
                    style={{ margin: "16px" }}>
                    {t("Download from Google Play")}
                </Button>
                <Button variant="contained"
                    onClick={() => window.open(`https://appgallery.huawei.com/#/app/C103462811`, '_blank')}
                    color="primary">
                    {t("Download from AppGallery")}
                </Button>
            </div>
        </div>
    );
}
