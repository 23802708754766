import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Typography,
    Button,
    Link
} from "@mui/material";
import {
    Map as MapIcon,
    NotInterested as ConnectionTypeNone,
    Bluetooth as ConnectionTypeBluetooth,
    Usb as ConnectionTypeUsb,
    Info as InfoIcon,
    HeadsetMic as UsergroupIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next"

import { formatDate } from "../../helpers/StringUtils"
import AdvancedAvatarBadge from "../AdvancedAvatarBadge";

export default function LogsTableComponent({ data, handleShowDevicePackage, handleShowLogAddress, handleUserEmailClick, handleDeviceIdClick, handleShowUserInfo, handleViewClick, handleUsergroupClick }) {
    const { t } = useTranslation();

    let compareFirmwareVersions = (log) => {
        return log.firmware.trim().endsWith(log.latestFirmware.trim());
    }

    var keys = ["#", t("Event time"), t("User"), " ", "  ", t("Device"), "   ", t("Process type"), t("Event"), t("Data"), t("Phone"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(log => (
                    <TableRow key={log.id}>
                        <TableCell>{log.id}</TableCell>
                        <TableCell>{formatDate(log.date)}</TableCell>
                        <TableCell style={{ "width": "30ch" }}>
                            <div style={{ width: "32ch", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <AdvancedAvatarBadge
                                    avatarKey={0}
                                    isUserOnline={log.isUserOnline}
                                    userId={log.userId}
                                    userMail={log.user.email}
                                />
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "12px" }}>
                                    <Link
                                        style={{ fontSize: "inherit", wordWrap: "break-word", rightMargin: "auto", textAlign: "left" }}
                                        onClick={() => handleUserEmailClick(log)}
                                    >
                                        {log.user.email}
                                    </Link>
                                    <Typography variant="body1" style={{ width: "30ch", overflowWrap: "break-word" }}>
                                        {log.user.fullname}
                                    </Typography>
                                    <Typography variant="body1" style={{ width: "30ch", overflowWrap: "break-word" }}>
                                        {log.user.phone}
                                    </Typography>
                                </div>
                            </div>
                        </TableCell>
                        <TableCell>
                            <IconButton
                                color="primary"
                                onClick={() => { handleShowUserInfo(log); }} >
                                <InfoIcon />
                            </IconButton>
                            <IconButton
                                color="primary"
                                disabled={!log.hasUsergroup}
                                onClick={() => { handleUsergroupClick(log); }} >
                                <UsergroupIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell>
                            <IconButton disabled={true} style={{ color: "#000000" }}>
                                {
                                    log.connectionType === 1 ? <ConnectionTypeBluetooth /> :
                                        log.connectionType === 2 ? <ConnectionTypeUsb /> :
                                            <ConnectionTypeNone />
                                }
                            </IconButton>
                        </TableCell>
                        <TableCell>
                            <Link
                                style={{ fontSize: "inherit" }}
                                onClick={() => handleDeviceIdClick(log)}
                            >
                                {log.deviceId}
                            </Link>
                            <br />{log.deviceName}<br />
                            {
                                compareFirmwareVersions(log) ?
                                    <Typography style={{ color: "#00AA00" }}>
                                        {log.firmware}
                                    </Typography>
                                    :
                                    <Typography style={{ color: "#AA0000" }}>
                                        {log.firmware}({log.latestFirmware})
                                    </Typography>
                            }
                            {
                                log.vin ? <>VIN:{log.vin}</> : <></>
                            }
                        </TableCell>
                        <TableCell>
                            <IconButton
                                color="primary"
                                onClick={() => { handleShowDevicePackage(log.deviceId); }} >
                                <InfoIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell>{log.type}</TableCell>
                        <TableCell style={{ "width": "40ch" }}>{log.title}</TableCell>
                        <TableCell style={{ "width": "30ch" }}>
                            <Button onClick={() => handleViewClick(log)} style={{ marginLeft: "12px" }} disabled={log.info === null || log.info === ""} variant="contained" color="primary">{t("View")}</Button>
                        </TableCell>
                        <TableCell style={{ "width": "50ch" }}>{log.phoneName}</TableCell>
                        <TableCell>
                            <IconButton
                                disabled={log.coordinates === null || log.coordinates.trim() === "" || log.coordinates === "0.0 , 0.0" || log.addressId === null}
                                color="primary"
                                onClick={() => { handleShowLogAddress(log); }} >
                                <MapIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
