import React from "react";
import {
    TableRow,
    TableCell,
    IconButton
} from "@mui/material";

import {
    Delete as DeleteIcon,
    ArrowForward as ShowEquipmentFullIcon
} from "@mui/icons-material";

import styles from "../hoverableStyles.module.css"

export default function CarEquipmentsTableRow({ equipment, handleRemoveEquipment, handleShowEquipmentFull, handleNameClick, handleCanCodeClick }) {
    return (
        <TableRow key={equipment.id}>
            <TableCell className={styles.cell}>
                {equipment.id}
            </TableCell>
            <TableCell className={styles.hoverableCell}
                onClick={() => handleNameClick(equipment)}
            >
                { equipment.brandTitle ? `${equipment.brandTitle} | ${equipment.modelTitle} | ${equipment.name}` : equipment.name}
            </TableCell>
            <TableCell className={styles.hoverableCell}
                onClick={() => handleCanCodeClick(equipment)}
            >
                {equipment.canCode}
            </TableCell>
            <TableCell className={styles.cell}>
                <IconButton
                    style={{ color: 'red' }}
                    onClick={() => handleRemoveEquipment(equipment)} >
                    <DeleteIcon />
                </IconButton>
                <IconButton
                    color="primary"
                    onClick={() => handleShowEquipmentFull(equipment)} >
                    <ShowEquipmentFullIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}
