import React, { useState, useEffect, useCallback } from "react";

import { Grid, Typography, Button, Paper } from "@mui/material";

import { useTranslation } from "react-i18next";
import DevicesAliasesTable from "../components/Tables/DevicesAliasesTable";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";

export default function DevicesAliases() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [alarms, setAlarms] = useState([]);
  const [tableData, setTableData] = useState([]);

  const refreshTable = useCallback(
    (backend) => {
      backend.bckDevicesListAliases().then((json) => {
        if (json.code === 0) {
          setTableData((prev) => {
            return json.content.list;
          });
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
    },
    [t]
  );

  const loadAlarms = () => {
    backend.bckDevicesList("", false, false).then((json) => {
      var listElements = json.content.map((alarm) => ({
        id: alarm.id,
        name: alarm.name,
      }));
      setAlarms(listElements);
    });
  };

  useEffect(() => {
    loadAlarms();
    refreshTable(backend);
  }, []);

  const handleCreateAlias = () => {
    backend.bckDevicesCreateOrUpdateAlias({}).then((json) => {
      if (json.code === 0) {
        let aliasId = json.content;
        let newElement = {
          id: aliasId,
        };
        setTableData((prev) => {
          let newArr = [newElement, ...prev];
          return newArr;
        });
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleRemoveAlias = (alias) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove alias?", { id: alias.id }),
      handleConfirm: () => {
        backend.bckDevicesRemoveAlias(alias.id).then((json) => {
          if (json.code === 0) {
            setTableData((prev) => {
              let foundIndex = prev.findIndex((x) => x.id === alias.id);
              if (foundIndex !== -1) {
                prev.splice(foundIndex, 1);
              }
              return [...prev];
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const saveAlias = (newAlias) => {
    backend.bckDevicesCreateOrUpdateAlias(newAlias).then((json) => {
      if (json.code === 0) {
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
    setTableData((prev) => {
      let newArr = prev.map((e) => (e.id === newAlias.id ? newAlias : e));
      return newArr;
    });
  };

  const handleEditAlarmBase = (alias) => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: alarms.filter((a) => a.id === alias.alarmIdBase)[0],
      title: t("Alarm"),
      label: t("Alarm"),
      options: alarms,
      getOptionLabel: (option) => option?.name,
      handleConfirm: (alarm) => {
        let newAlias = { ...alias, alarmIdBase: alarm.id };
        saveAlias(newAlias);
      },
    });
  };

  const handleEditAlarmAlias = (alias) => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: alarms.filter((a) => a.id === alias.alarmIdAlias)[0],
      title: t("Alias"),
      label: t("Alias"),
      options: alarms,
      getOptionLabel: (option) => option?.name,
      handleConfirm: (alarm) => {
        let newAlias = { ...alias, alarmIdAlias: alarm.id };
        saveAlias(newAlias);
      },
    });
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("Alarm aliases table")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          <Grid item>
            <Button
              onClick={handleCreateAlias}
              variant="contained"
              color="primary"
            >
              {t("Add alias")}
            </Button>
          </Grid>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            <DevicesAliasesTable
              data={tableData}
              alarms={alarms}
              handleEditAlarmBase={handleEditAlarmBase}
              handleEditAlarmAlias={handleEditAlarmAlias}
              handleRemoveAlias={handleRemoveAlias}
            />
          </div>
        </Grid>
      </Paper>
    </>
  );
}
