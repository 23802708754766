import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Typography
} from "@mui/material";

import {
    TouchApp as ActionIcon,
    Image as ImageIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next"

import { formatDate } from "../../helpers/StringUtils"
import { PushStatuses } from "../../enums/PushStatuses";

export default function PushesTable({ data }) {
    const { t } = useTranslation();

    var keys = [t("Status"), t("Send time (UTC)"), t("Sender"), t("Newsletter type"), t("Recipients"), t("Message type"), t("Title"), t("Notification"), t("Click link"), t("Image link")];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(push => (
                    <TableRow key={push.time}>
                        <TableCell>{t(PushStatuses[push.status])}{push.error ? <br /> : <></>}<Typography variant="caption">{push.error}</Typography></TableCell>
                        <TableCell>{formatDate(push.time)}</TableCell>
                        <TableCell>{push.sender}</TableCell>
                        <TableCell>{push.recipientType === 0 ? t("User") : t("Group")}</TableCell>
                        <TableCell>{push.recipientType === 0 ? push.recipient : push.lang}</TableCell>
                        <TableCell>{push.data.type}</TableCell>
                        <TableCell>{push.data.title}</TableCell>
                        <TableCell>{push.data.body}</TableCell>
                        <TableCell>
                            <IconButton
                                disabled={push.data.action_url === null || push.data.action_url.trim() === ""}
                                color="primary"
                                onClick={() => { window.open(push.data.action_url) }} >
                                <ActionIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell>
                            <IconButton
                                disabled={push.data.image_url === null || push.data.image_url.trim() === ""}
                                color="primary"
                                onClick={() => { window.open(push.data.image_url) }} >
                                <ImageIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
