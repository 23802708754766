import React, { useState, useEffect, useCallback, useRef } from "react";

import {
  Typography,
  Paper,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import { useTranslation } from "react-i18next";
import ChatRoomsTable from "../components/Tables/ChatRoomsTable/ChatRoomsTable";
import { GenerateInteger } from "../helpers/RandomGenerator";
import { useInterval } from "../useInterval";

import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoadBool,
  settingRepositoryLoadInt,
  settingRepositorySave,
} from "../settingRepository";

let searchTimeout = null;

export default function ChatRoomsPage() {
  const backend = useBackend();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isInitializing, setIsInitializing] = useState(true);
  const [paginationComment, setPaginationComment] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRooms, setTotalRooms] = useState(100);
  const [searchUserInput, setSearchUserInput] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchAnyUnread, setSearchAnyUnread] = useState(false);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = 450;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen) {
      setPaginationComment(null);
    } else if (!isSmallScreen) {
      setPaginationComment(t("Page size"));
    }
  }

  const refreshTable = useCallback(
    (
      backend,
      searchUser,
      searchAnyUnread,
      pageNumber,
      pageSize,
      isInitializing
    ) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckChatGetRooms(searchUser, searchAnyUnread, pageNumber + 1, pageSize)
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.list;
                } else {
                  return prev;
                }
              });
              setTotalRooms((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.count;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }

          if (isInitializing) {
            setIsInitializing(false);
          }
        });
    },
    [t]
  );

  useEffect(() => {
    if (isInitializing) {
      setIsInitializing(false);

      var params = queryString.parse(location.search);
      if (params.login) {
        setSearchUserInput(params.login);
        setSearchUser(params.login);
      }
      if (params.pageNumber) {
        setPageNumber(parseInt(params.pageNumber));
      }
      let pageSize = settingRepositoryLoadInt(
        SettingName.CHAT_ROOMS_PAGE_SIZE,
        10
      );
      if (params.pageSize) {
        pageSize = params.pageSize;
      }
      setPageSize(pageSize);

      let anyUnread = settingRepositoryLoadBool(
        SettingName.CHAT_ROOMS_ANY_UNREAD,
        false
      );
      setSearchAnyUnread(anyUnread);

      refreshTable(
        backend,
        params.login ? params.login : "",
        anyUnread,
        params.pageNumber ? params.pageNumber : 0,
        pageSize,
        isInitializing
      );
    }
  }, [location, isInitializing, refreshTable, backend]);

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
    // eslint-disable-next-line
  }, []);

  useInterval(() => {
    if (updatesLockCounter.current === 0) {
      refreshTable(
        backend,
        searchUser,
        searchAnyUnread,
        pageNumber,
        pageSize,
        isInitializing
      );
    }
  }, 2000);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    refreshTable(
      backend,
      searchUser,
      searchAnyUnread,
      newPage,
      pageSize,
      isInitializing
    );
  };

  const handleChangeRowsPerPage = (event) => {
    var newRowsPerPage = parseInt(event.target.value, 10);
    settingRepositorySave(SettingName.CHAT_ROOMS_PAGE_SIZE, newRowsPerPage);
    setPageSize(newRowsPerPage);
    setPageNumber(0);
    refreshTable(
      backend,
      searchUser,
      searchAnyUnread,
      0,
      newRowsPerPage,
      isInitializing
    );
  };

  const startSearch = () => {
    var newSearchUser = "";
    if (searchUserInput.trim() === "") {
      setSearchUser("");
    } else {
      newSearchUser = searchUserInput;
      setSearchUser(searchUserInput);
    }
    setPageNumber(0);
    refreshTable(
      backend,
      newSearchUser,
      searchAnyUnread,
      0,
      pageSize,
      isInitializing
    );
  };

  const handleSearchKeyUp = (e) => {
    if (e.keyCode === 13) {
      window.clearTimeout(searchTimeout);
      startSearch();
    } else {
      window.clearTimeout(searchTimeout);
      searchTimeout = window.setTimeout(() => {
        startSearch();
      }, 1000);
    }
  };

  const handleJoinChat = useCallback(
    (login) => {
      navigate(
        `/app/chatRoomsPage?login=${searchUser}&pageSize=${pageSize}&pageNumber=${pageNumber}`
      );
      navigate(`/app/chatPage?login=${login}`);
    },
    [navigate, searchUser, pageNumber, pageSize]
  );

  const handleClickFavourite = useCallback((room) => {
    backend.bckChatMarkFavouriteRoom(room.id, !room.isFavourite).then(() => {
      refreshTable(
        backend,
        searchUser,
        searchAnyUnread,
        pageNumber,
        pageSize,
        isInitializing
      );
    });
  });

  return (
    <>
      <Paper
        style={{
          overflow: "auto",
          padding: "24px",
          maxHeight: "85vh",
          maxWidth: "120ch",
          margin: "auto",
        }}
      >
        <Typography variant="h5" color="textSecondary">
          {t("Chat rooms page")}
        </Typography>
        <Typography color="secondary">{error}</Typography>
        <div style={{ margin: "auto" }}>
          <TextField
            label={t("User")}
            value={searchUserInput}
            onChange={(e) => setSearchUserInput(e.target.value)}
            style={{ marginRight: "12px" }}
            onKeyUp={handleSearchKeyUp}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={searchAnyUnread}
                  onChange={() => {
                    setSearchAnyUnread(!searchAnyUnread);
                    settingRepositorySave(
                      SettingName.CHAT_ROOMS_ANY_UNREAD,
                      !searchAnyUnread
                    );
                    startSearch();
                  }}
                  color="primary"
                />
              }
              label={t("Unread messages only")}
            />
          </Grid>
          <AdvancedTablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRows={totalRooms}
            pageSize={pageSize}
            pageNumber={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={paginationComment}
          >
            <ChatRoomsTable
              data={tableData}
              handleJoinChat={handleJoinChat}
              handleClickFavourite={handleClickFavourite}
            />
          </AdvancedTablePagination>
        </div>
      </Paper>
    </>
  );
}
