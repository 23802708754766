import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Switch
} from "@mui/material";

import styles from "./hoverableStyles.module.css"
import { useTranslation } from "react-i18next";

import { formatDate } from "../../helpers/StringUtils";
import ReactFileReader from "react-file-reader";
import { SubsystemFirmwareTypes } from "../../enums/SubsystemFirmwareTypes";

import {
    Publish as UploadIcon,
    Delete as DeleteIcon,
    GetApp as DownloadIcon
} from "@mui/icons-material";

export default function SubsystemFirmwaresTable({ data, handlePublishChanged, handleTypeClick, handleRemoveFirmware,
    handleVersionClick, handleDownloadClick, handleUploadSelect,
    handleDescriptionClick }) {
    const { t } = useTranslation();

    var keys = ["ID", t("Type"), t("Version"), t("Is published"), t("Creation time"), t("Filename"), t("Description")];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(firmware => (
                    <TableRow key={firmware.id}>
                        <TableCell className={styles.cell}>
                            <IconButton
                                style={{ color: 'red' }}
                                onClick={() => handleRemoveFirmware(firmware)} >
                                <DeleteIcon />
                            </IconButton>
                            {firmware.id}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleTypeClick(firmware)}
                        >
                            {SubsystemFirmwareTypes[firmware.type]}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleVersionClick(firmware)}
                        >
                            {firmware.version}
                        </TableCell>
                        <TableCell className={styles.cell}>
                            {formatDate(firmware.publishTime)}
                            <Switch color="primary"
                                disabled={!firmware.filename}
                                checked={firmware.isPublished}
                                onChange={e => handlePublishChanged(firmware, e.target.checked)} />
                        </TableCell>
                        <TableCell className={styles.cell}>{formatDate(firmware.creationTime)}</TableCell>
                        <TableCell className={styles.cell}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {firmware.filename}
                                <ReactFileReader handleFiles={(files) => handleUploadSelect(firmware, files[0])} fileTypes={[".pld", ".mld", ".enc"]}>
                                    <IconButton color="primary">
                                        <UploadIcon />
                                    </IconButton>
                                </ReactFileReader>
                                <IconButton color="primary" disabled={!firmware.filename} onClick={() => handleDownloadClick(firmware)}>
                                    <DownloadIcon />
                                </IconButton>
                            </div>
                        </TableCell>
                        <TableCell className={styles.hoverableCell} onClick={() => handleDescriptionClick(firmware)}>
                            <pre style={{ maxHeight: "7ch", maxWidth: "64ch", overflowX: "hidden", overflowY: "hidden" }}>
                                {firmware.description}
                            </pre>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
