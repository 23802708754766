import React, { useState } from "react";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import styles from "./styles.module.css";

export default function SidebarLink({
  link,
  icon,
  label,
  children,
  isSidebarOpened,
  nested,
  type,
  onClick,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isLinkActive =
    link &&
    location &&
    (location.pathname === link || location.pathname.indexOf(link) !== -1);

  if (type === "title")
    return (
      <Typography
        className={classnames(styles.linkText, styles.sectionTitle, {
          [styles.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );

  if (type === "divider") return <Divider className={styles.divider} />;

  return (
    <>
      <ListItem
        component={link && Link}
        to={link}
        className={styles.link}
        classes={{
          root: classnames(styles.linkRoot, {
            [styles.linkActive]: isLinkActive,
            [styles.linkNested]: nested,
          }),
        }}
        onClick={() => {
          if (children) setIsOpen(!isOpen);
          if (onClick) onClick();
        }}
      >
        <ListItemIcon
          className={classnames(styles.linkIcon, {
            [styles.linkIconActive]: isLinkActive,
          })}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(styles.linkText, {
              [styles.linkTextActive]: isLinkActive,
              [styles.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
      </ListItem>
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={styles.nestedList}
        >
          <List component="div" disablePadding>
            {children.map((childrenLink) => (
              <SidebarLink
                key={childrenLink.id}
                location={location}
                isSidebarOpened={isSidebarOpened}
                onClick={childrenLink.onClick}
                icon={childrenLink.icon}
                label={childrenLink.label}
                link={childrenLink.link}
                children={childrenLink.children}
                styles={styles}
                nested
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
