import UnicodeToWin1251 from "./unicodeToWin1251";

const unsignedToSignedByte = (v) => {
  var sign = v & (1 << 7);
  var i = v & 0x7f;
  if (sign) {
    i = i - 128;
  }
  return i;
};

const signedToUnsignedByte = (v) => {
  var sign = v & (1 << 7);
  var i = v & 0x7f;
  if (sign) {
    i = 128 - i;
  }
  return i;
};

function binToString(array) {
  let decoder = new TextDecoder("windows-1251");
  let result = decoder.decode(new Uint8Array(array));
  return result;
}

function stringToBin(str) {
  return UnicodeToWin1251(str);
}

export { unsignedToSignedByte, signedToUnsignedByte, binToString, stringToBin };
