import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";

export default function ChangePasswordDialog({ isOpened, handleClose }) {
  const backend = useBackend();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [passwordChangeInProgress, setPasswordChangeInProgress] =
    useState(false);

  const handleOkClick = () => {
    if (newPassword !== newPasswordRepeat) {
      setError(t("Password mismatch"));
      return;
    }

    setError("");
    setOldPassword("");
    setNewPassword("");
    setNewPasswordRepeat("");
    setPasswordChangeInProgress(true);

    backend.bckUsersChangePassword(oldPassword, newPassword).then((json) => {
      setPasswordChangeInProgress(false);
      if (json.code === 0) {
        handleClose();
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{t("Change password")}</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography color="secondary">{error ? t(error) : ""}</Typography>
          <TextField
            margin="dense"
            label={t("Active password")}
            value={oldPassword}
            onChange={(event) =>
              setOldPassword(event.target.value.substr(0, 32))
            }
            variant="outlined"
            type="password"
            style={{ width: "32ch", marginTop: "12px" }}
          />
          <TextField
            margin="dense"
            label={t("New password")}
            value={newPassword}
            onChange={(event) =>
              setNewPassword(event.target.value.substr(0, 32))
            }
            variant="outlined"
            type="password"
            style={{ width: "32ch", marginTop: "12px" }}
          />
          <TextField
            margin="dense"
            label={t("Repeat new password")}
            value={newPasswordRepeat}
            onChange={(event) =>
              setNewPasswordRepeat(event.target.value.substr(0, 32))
            }
            variant="outlined"
            type="password"
            style={{ width: "32ch", marginTop: "12px" }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Cancel")}
        </Button>
        <Button
          disabled={
            oldPassword === undefined ||
            oldPassword === null ||
            oldPassword.trim() === "" ||
            newPassword === undefined ||
            newPassword === null ||
            oldPassword.trim() === "" ||
            newPasswordRepeat === undefined ||
            newPasswordRepeat === null ||
            newPasswordRepeat.trim() === "" ||
            passwordChangeInProgress
          }
          onClick={handleOkClick}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
