import React from "react";

import {
    TablePagination
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function AdvancedTablePagination({ rowsPerPageOptions, totalRows, pageSize,
    pageNumber, handleChangePage, handleChangeRowsPerPage, labelRowsPerPage, children }) {
    const { t } = useTranslation();

    return (<>
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalRows}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={labelRowsPerPage ? labelRowsPerPage : t("Page size")}
        />
        {children}
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalRows}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={labelRowsPerPage ? labelRowsPerPage : t("Page size")}
        />
    </>);
}
