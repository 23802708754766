const ArticleElementGravity = {
    1: "RIGHT",
    2: "CENTER",
    3: "LEFT"
};
Object.freeze(ArticleElementGravity);

const ArticleElementGravityByName = {
    "RIGHT": 1,
    "CENTER": 2,
    "LEFT": 3
};
Object.freeze(ArticleElementGravityByName);

export {
    ArticleElementGravity,
    ArticleElementGravityByName
}