import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
} from "@mui/material";
import { formatDate } from "../../helpers/StringUtils"

import { useTranslation } from "react-i18next"

import { TechsupportRequestStatuses } from "../../enums/TechsupportRequestStatuses";

export default function TechsupportRequestSessionsTable({ data }) {
    const { t } = useTranslation();
    var keys = ["#", t("Work start"), t("User"), t("Comment"), t("Work end")];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(session => (
                    <TableRow key={session.id}>
                        <TableCell>{session.id}</TableCell>
                        <TableCell>
                            {formatDate(session.creationTime)}<br />
                            {t(TechsupportRequestStatuses[session.startStatus])}
                        </TableCell>
                        <TableCell>
                            {session.handlerName} <br />
                            {session.handlerMail}
                        </TableCell>
                        <TableCell>
                            {session.comment}
                        </TableCell>
                        <TableCell>
                            {session.endTime ? formatDate(session.endTime) : ""}<br />
                            {session.endStatus ? t(TechsupportRequestStatuses[session.endStatus]) : ""}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
