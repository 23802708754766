import React, { useState } from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    Save as SaveIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useDialogContext, DialogActionTypes } from "../../context/DialogContext";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../LanguageSelect";

export default function TranslationsTable({ data, handleSave }) {
    const { t } = useTranslation();
    const dialogDispatch = useDialogContext();
    const [editedTranslations, setEditedTranslations] = useState({});
    const [leftLanguage, setLeftLanguage] = useState("RU");
    const [rightLanguage, setRightLanguage] = useState("EN");

    const handleEdit = (translation, language) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            initialValue: editedTranslations[translation.id] && (language in editedTranslations[translation.id].text) ? editedTranslations[translation.id].text[language] : translation.text[language],
            textLabel: t("Text"),
            title: t("Text"),
            lines: 5,
            handleConfirm: (text) => {
                setEditedTranslations((prev) => {
                    let newEdited = { ...prev };
                    let editedTranslation = newEdited[translation.id];
                    if (!editedTranslation) {
                        editedTranslation = {
                            ...translation,
                            text: {
                                ...translation.Text
                            }
                        };
                        newEdited[translation.id] = editedTranslation;
                    }
                    editedTranslation.text[language] = text;
                    return newEdited;
                });
            }
        });
    };

    const handleRowClick = (translation) => {
        handleSave(translation, editedTranslations[translation.id], () => {
            setEditedTranslations((prev) => {
                let newEdited = { ...prev };
                delete newEdited[translation.id];
                return newEdited;
            })
        });
    }

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>#</TableCell>
                    <TableCell>
                        <LanguageSelect value={leftLanguage}
                            onChange={(e) => { setLeftLanguage(e.target.value.toUpperCase()); }} />
                    </TableCell>
                    <TableCell>
                        <LanguageSelect value={rightLanguage}
                            onChange={(e) => { setRightLanguage(e.target.value.toUpperCase()); }} />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(translation => (
                    <TableRow key={translation.id}>
                        <TableCell className={styles.cell}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div>
                                    {translation.id}
                                </div>
                                <IconButton
                                    disabled={!(translation.id in editedTranslations)}
                                    style={translation.id in editedTranslations ? { color: 'blue' } : { color: 'gray' }}
                                    onClick={() => handleRowClick(translation)}
                                >
                                    <SaveIcon />
                                </IconButton>
                            </div>
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEdit(translation, leftLanguage)}
                        >
                            <pre style={{ maxHeight: "3ch", width: "64ch", overflowX: "hidden", overflowY: "hidden" }}>
                                {
                                    editedTranslations[translation.id] && (leftLanguage in editedTranslations[translation.id].text) ? editedTranslations[translation.id].text[leftLanguage] : translation.text[leftLanguage]
                                }
                            </pre>
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEdit(translation, rightLanguage)}
                        >
                            <pre style={{ maxHeight: "3ch", width: "64ch", overflowX: "hidden", overflowY: "hidden" }}>
                                {
                                    editedTranslations[translation.id] && (rightLanguage in editedTranslations[translation.id].text) ? editedTranslations[translation.id].text[rightLanguage] : translation.text[rightLanguage]
                                }
                            </pre>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
