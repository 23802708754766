import React, { useState, useEffect } from "react";
import { Grid, Typography, Link, Paper, CircularProgress } from "@mui/material";

import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useBackend } from "../context/BackendContext";

export default function ActivateUser() {
  const { t, i18n } = useTranslation();
  const backend = useBackend();
  const location = useLocation();
  const [error, setError] = useState("Unknown error");
  const [inProgress, setInProgress] = useState(true);

  useEffect(() => {
    setInProgress(true);

    var params = queryString.parse(location.search);
    if (!params.token) {
      setError("Token is not set");
      setInProgress(false);
      return;
    }

    backend
      .bckUsersActivate(params.token)
      .then((requestData) => {
        setInProgress(false);
        return requestData.json();
      })
      .then((json) => {
        if (json.code === 0) {
          setError("");
        } else {
          if (json.message) {
            setError(json.message);
          }
        }
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={6} style={{ maxWidth: "64ch", minWidth: "32ch" }}>
          <Paper style={{ padding: "16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {inProgress ? (
                <CircularProgress />
              ) : (
                <Typography color={error ? "secondary" : "textPrimary"}>
                  {error ? t(error) : t("User activated")}
                </Typography>
              )}
            </div>
            <Grid
              container
              direction="row"
              style={{
                marginTop: "12px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item>
                <div style={{ display: "flex" }}>
                  <Link
                    component="button"
                    onClick={() => {
                      i18n.changeLanguage("ru");
                    }}
                    style={{
                      marginRight: "12px",
                    }}
                  >
                    Русский
                  </Link>
                </div>
              </Grid>
              <Grid item>
                <div style={{ display: "flex" }}>
                  <Link
                    component="button"
                    onClick={() => {
                      i18n.changeLanguage("en");
                    }}
                    style={{
                      marginRight: "12px",
                    }}
                  >
                    English
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
