import React, { useState } from "react";

import { Grid, Button, MenuItem } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import {
  DialogActionTypes,
  useDialogContext,
} from "../../context/DialogContext";
import SettingsChannelsTable from "../../components/Tables/SettingsChannelsTable";
import SettingsChannelsIosTable from "../../components/Tables/SettingsChannelsIosTable";
import {
  SettingsChannelsIoPolarities,
  SettingsChannelsIoTypesByName,
} from "../../enums/SettingsMenuTypes";
import { getFlatStructures } from "./Misc";

export default function Channels({ data, setData, setError }) {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t } = useTranslation();
  const [chosenChannel, setChosenChannel] = useState(null);

  const handleAddChannelClick = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        let newChannel = {
          Id: null,
          Name: name,
          InputLogics: null,
          OutputLogics: null,
          Ios: null,
        };
        saveChannel(newChannel);
      },
      initialValue: "",
      textLabel: t("Name"),
      title: t("Add channel"),
    });
  };

  const handleRemoveChannel = (channel) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove channel?", { title: channel.Name }),
      handleConfirm: () => {
        backend.bckSettingsRemoveChannel(channel.Id).then((json) => {
          if (json.code === 0) {
            setData((prev) => {
              let nextChannels = { ...prev.Channels };
              delete nextChannels[channel.Id];
              let next = { ...prev, Channels: nextChannels };
              return next;
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const handleShowChannelIos = (channel) => {
    setChosenChannel(channel);
  };

  const saveChannel = (newChannel) => {
    backend.bckSettingsCreateOrUpdateChannel(newChannel).then((json) => {
      if (json.code === 0) {
        newChannel.Id = json.content;
        setData((prev) => {
          let newChannels = { ...prev.Channels };
          newChannels[newChannel.Id] = newChannel;
          let next = { ...prev, Channels: newChannels };
          return next;
        });
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const saveChannelIo = (newIo) => {
    backend.bckSettingsCreateOrUpdateChannelIo(newIo).then((json) => {
      if (json.code === 0) {
        newIo.Id = json.content;
        setData((prev) => {
          let newIos = [...chosenChannel.Ios];
          let index = newIos.indexOf(newIo);
          if (index === -1) {
            newIos.push(newIo);
          }
          let newChannel = { ...chosenChannel, Ios: newIos };
          setChosenChannel(newChannel);

          let newChannels = { ...prev.Channels };
          newChannels[newChannel.Id] = newChannel;
          let next = { ...prev, Channels: newChannels };
          return next;
        });
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleAddChannelIoClick = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        let newIo = {
          ChannelId: chosenChannel.Id,
          Name: name,
          Type: 1,
          DefaultPolarity: 1,
          Number: "0",
          Wires: [],
        };
        saveChannelIo(newIo);
      },
      initialValue: "",
      textLabel: t("Name"),
      title: t("Add channel IO"),
    });
  };

  const handleRemoveChannelIoClick = (io) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove channel IO?", { title: io.Name }),
      handleConfirm: () => {
        let newIos = [...chosenChannel.Ios];
        let index = newIos.indexOf(io);
        newIos.splice(index, 1);
        let newChannel = { ...chosenChannel, Ios: newIos };
        saveChannel(newChannel);
        setChosenChannel(newChannel);
      },
    });
  };

  const handleBackClick = () => {
    setChosenChannel(null);
  };

  const handleEditChannelName = (channel) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        let newChannel = { ...channel, Name: name };
        saveChannel(newChannel);
      },
      initialValue: channel.Name,
      textLabel: t("Name"),
      title: t("Name"),
    });
  };

  const showEditLogics = (initialValue, callback) => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: initialValue,
      title: "Logics",
      label: "Logics",
      options: Object.values(data.Logics),
      getOptionLabel: (option) =>
        option ? `${option?.Name} (${option?.Id})` : "",
      multiple: false,
      handleConfirm: callback,
    });
  };

  const handleEditInputLogics = (channel) => {
    showEditLogics(
      channel.InputLogics
        ? Object.values(data.Logics).find((l) => l.Id === channel.InputLogics)
        : Object.values(data.Logics)[0],
      (newLogic) => {
        let newChannel = { ...channel, InputLogics: newLogic?.Id };
        saveChannel(newChannel);
      }
    );
  };

  const handleEditOutputLogics = (channel) => {
    showEditLogics(
      channel.OutputLogics
        ? Object.values(data.Logics).find((l) => l.Id === channel.OutputLogics)
        : Object.values(data.Logics)[0],
      (newLogic) => {
        let newChannel = { ...channel, OutputLogics: newLogic?.Id };
        saveChannel(newChannel);
      }
    );
  };

  const updateIo = (io, ioCallback) => {
    let newIos = [...chosenChannel.Ios];
    let newIo = { ...io };
    ioCallback(newIo);
    newIos[newIos.indexOf(io)] = newIo;
    let newChannel = { ...chosenChannel, Ios: newIos };
    saveChannel(newChannel);
    setChosenChannel(newChannel);
  };

  const handleEditIoNumber = (io) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      initialValue: io.Number?.toString() ?? "",
      textLabel: t("Number"),
      title: t("Number"),
      handleConfirm: (number) => {
        updateIo(io, (newIo) => {
          newIo.Number = parseInt(number);
        });
      },
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 4);
      },
    });
  };

  const handleEditIoName = (io) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        updateIo(io, (newIo) => {
          newIo.Name = name;
        });
      },
      initialValue: io.Name,
      textLabel: t("Name"),
      title: t("Name"),
    });
  };

  const handleEditIoPolarity = (io) => {
    const polarityMenuItems = Object.keys(SettingsChannelsIoPolarities).map(
      (i) => (
        <MenuItem key={i} value={i}>
          {SettingsChannelsIoPolarities[i]}
        </MenuItem>
      )
    );

    dialogDispatch({
      type: DialogActionTypes.SimpleSelectDialogOpen,
      initialValue: io.DefaultPolarity,
      label: t("Default polarity"),
      title: t("Default polarity"),
      menuItems: polarityMenuItems,
      handleConfirm: (value) => {
        updateIo(io, (newIo) => {
          newIo.DefaultPolarity = value;
        });
      },
    });
  };

  const handleEditIoIsWireless = (io) => {
    updateIo(io, (newIo) => {
      newIo.IsWireless = !io.IsWireless;
    });
  };

  const handleEditIoType = (io) => {
    updateIo(io, (newIo) => {
      newIo.Type =
        io.Type === SettingsChannelsIoTypesByName.Input
          ? SettingsChannelsIoTypesByName.Output
          : SettingsChannelsIoTypesByName.Input;
    });
  };

  const handleEditIoPolarityStructure = (io) => {
    let flatStructures = getFlatStructures(Object.values(data.Structures));
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: flatStructures[io.PolarityStructureId] ?? flatStructures[1],
      label: t("Polarity structure"),
      title: t("Polarity structure"),
      options: Object.values(flatStructures),
      getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
      multiple: false,
      handleConfirm: (value) => {
        updateIo(io, (newIo) => {
          newIo.PolarityStructureId = value ? value.Id : null;
        });
      },
    });
  };

  const handleEditIoNcStructure = (io) => {
    let flatStructures = getFlatStructures(Object.values(data.Structures));
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: flatStructures[io.NcStructureId] ?? flatStructures[1],
      label: t("NC structure"),
      title: t("NC structure"),
      options: Object.values(flatStructures),
      getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
      multiple: false,
      handleConfirm: (value) => {
        updateIo(io, (newIo) => {
          newIo.NcStructureId = value ? value.Id : null;
        });
      },
    });
  };

  const handleEditIoWires = (io) => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: io.Wires.map((id) => data.Wires[id]),
      title: t("Wires"),
      label: t("Wires"),
      options: Object.values(data.Wires),
      getOptionLabel: (option) =>
        option ? `${option?.Name} (${option?.Id})` : "",
      multiple: true,
      handleConfirm: (newValues) => {
        updateIo(io, (newIo) => {
          newIo.Wires = newValues.map((w) => w.Id);
        });
      },
    });
  };

  return (
    <>
      {chosenChannel === null ? (
        <>
          <Grid item>
            <Button
              onClick={handleAddChannelClick}
              variant="contained"
              color="primary"
            >
              {t("Add channel")}
            </Button>
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "70vh",
              marginTop: "16px",
            }}
          >
            <SettingsChannelsTable
              settingInfo={data}
              data={Object.values(data.Channels)}
              handleEditChannelName={handleEditChannelName}
              handleShowLogicChannels={handleShowChannelIos}
              handleRemoveChannel={handleRemoveChannel}
              handleEditInputLogics={handleEditInputLogics}
              handleEditOutputLogics={handleEditOutputLogics}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {chosenChannel !== null ? (
        <>
          <Grid item container direction="row" spacing={2}>
            <Grid item>
              <Button
                onClick={handleBackClick}
                variant="contained"
                color="primary"
              >
                {t("Back")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleAddChannelIoClick}
                variant="contained"
                color="primary"
              >
                {t("Add channel IO")}
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "70vh",
              marginTop: "16px",
            }}
          >
            <SettingsChannelsIosTable
              settingInfo={data}
              data={chosenChannel.Ios}
              handleRemoveLogicChannel={handleRemoveChannelIoClick}
              handleEditNumber={handleEditIoNumber}
              handleEditName={handleEditIoName}
              handleEditPolarity={handleEditIoPolarity}
              handleEditIsWireless={handleEditIoIsWireless}
              handleEditType={handleEditIoType}
              handleEditPolarityStructure={handleEditIoPolarityStructure}
              handleEditNcStructure={handleEditIoNcStructure}
              handleEditWires={handleEditIoWires}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
