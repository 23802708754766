import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
  Settings as SettingsIcon,
  Person as AccountIcon,
  ExitToApp as LogoutIcon,
  Language as LanguageIcon,
  ArrowDropDown as LanguageDropIcon,
} from "@mui/icons-material";
import classNames from "classnames";
import styles from "./styles.module.css";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import { useNavigate } from "react-router";

export default function Header({ headerText }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const userDispatch = useUserDispatch();
  const backend = useBackend();
  const [profileMenu, setProfileMenu] = useState(null);
  const [languageMenu, setLanguageMenu] = useState(null);

  return (
    <AppBar position="fixed" className={styles.appBar}>
      <Toolbar className={styles.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            styles.headerMenuButton,
            styles.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              styles={{
                root: classNames(styles.headerIcon),
              }}
            />
          ) : (
            <MenuIcon
              styles={{
                root: classNames(styles.headerIcon),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={styles.logotype}>
          {headerText}
        </Typography>
        <div className={styles.grow} />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={styles.headerMenuButton}
          aria-controls="language-menu"
          onClick={(e) => setLanguageMenu(e.currentTarget)}
        >
          <LanguageIcon />
          {i18n.language.toUpperCase()}
          <LanguageDropIcon />
        </IconButton>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={styles.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <SettingsIcon styles={{ root: styles.headerIcon }} />
        </IconButton>
        <Menu
          id="language-menu"
          open={Boolean(languageMenu)}
          anchorEl={languageMenu}
          onClose={() => setLanguageMenu(null)}
          className={styles.headerMenu}
          styles={{ paper: styles.profileMenu }}
          disableAutoFocusItem
        >
          <MenuItem
            onClick={() => {
              i18n.changeLanguage("ru");
              setLanguageMenu(null);
            }}
            style={
              i18n.language.toUpperCase() === "RU"
                ? { textDecoration: "underline" }
                : {}
            }
          >
            Русский
          </MenuItem>
          <MenuItem
            onClick={() => {
              i18n.changeLanguage("en");
              setLanguageMenu(null);
            }}
            style={
              i18n.language.toUpperCase() === "EN"
                ? { textDecoration: "underline" }
                : {}
            }
          >
            English
          </MenuItem>
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={styles.headerMenu}
          styles={{ paper: styles.profileMenu }}
          disableAutoFocusItem
        >
          <MenuItem
            onClick={() => {
              navigate("/app/manageAccount");
              setProfileMenu(null);
            }}
          >
            <AccountIcon />
            {t("Account")}
          </MenuItem>
          <MenuItem onClick={() => signOut(userDispatch, backend)}>
            <LogoutIcon />
            {t("Logout")}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
