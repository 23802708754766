import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    RemoveCircle as RemoveIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next"
import { generateTranslationStringTag } from "../../pages/menuEditor/Misc";

export default function SettingsLogicsChannelsTable({ settingInfo, data, handleRemoveLogicChannel, handleEditChannelId, handleEditName, handleEditDescription }) {
    const { t, i18n } = useTranslation();

    var keys = [t("Channel"), t("Name"), t("Description"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(channel => (
                    <TableRow key={channel.ChannelId}>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditChannelId(channel)}
                        >
                            {channel.ChannelId}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditName(channel)}
                        >
                            {generateTranslationStringTag(channel.NameTranslationText, channel.NameTranslationId, i18n.language, settingInfo.Translations)}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditDescription(channel)}
                        >
                            {generateTranslationStringTag(channel.DescriptionTranslationText, channel.DescriptionTranslationId, i18n.language, settingInfo.Translations)}
                        </TableCell>
                        <TableCell className={styles.cell}>
                            <IconButton
                                color="secondary"
                                onClick={(e) => { handleRemoveLogicChannel(channel); }}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
