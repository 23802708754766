import React from "react";

import StatisticsPanel from "../components/StatisticsPanel/StatisticsPanel";

export default function StatisticsPage() {
    return (
        <>
            <StatisticsPanel />
        </>);
}
