import React from "react";
import styles from "./styles.module.css";

import PackageSearchPage from "../PackageSearchPage";

export default function MainPackageSearch() {
    return (
        <div className={styles.container} style={{ minHeight: "100vh" }}>
            <div>
                <PackageSearchPage />
            </div>            
        </div>
    );
}
