import React from "react";
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    IconButton,
} from "@mui/material";

import {
    Delete as DeleteIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next";

export default function CarMountingPointGuideTypesTable({ data, handleEditName, handleEditOrder, handleDelete }) {
    const { t } = useTranslation();

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
                    <TableCell>
                        {t("Order")}
                    </TableCell>
                    <TableCell>
                        {t("Name")}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(type => (
                    <TableRow key={type.id}>
                        <TableCell className={styles.cell} style={{ width: "16ch" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <IconButton
                                    style={{ color: 'red' }}
                                    onClick={() => handleDelete(type)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <div>
                                    {type.id}
                                </div>
                            </div>
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            style={{ width: "16ch" }}
                            onClick={() => handleEditOrder(type)}
                        >
                            <div>
                                {type.order}
                            </div>
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditName(type)}
                        >
                            <div>
                                {type.name}
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
