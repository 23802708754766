import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    ArrowForward as ShowLogicChannelsIcon,
    RemoveCircle as RemoveIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next"

export default function SettingsChannelsTable({ data, settingInfo, handleEditChannelName, handleRemoveChannel,
    handleShowLogicChannels, handleEditInputLogics, handleEditOutputLogics }) {
    const { t } = useTranslation();

    var keys = ["#", t("Name"), t("Input logics"), t("Output logics"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(channel => (
                    <TableRow key={channel.Id}>
                        <TableCell className={styles.cell}>{channel.Id}</TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditChannelName(channel)}
                        >
                            {channel.Name}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditInputLogics(channel)}
                        >
                            {channel.InputLogics && settingInfo ? `${Object.values(settingInfo.Logics).find(l => l.Id === channel.InputLogics).Name} (${channel.InputLogics})` : ""}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditOutputLogics(channel)}
                        >
                            {channel.OutputLogics && settingInfo ? `${Object.values(settingInfo.Logics).find(l => l.Id === channel.OutputLogics).Name} (${channel.OutputLogics})` : ""}
                        </TableCell>
                        <TableCell className={styles.cell} style={{ display: "flex", flexDirection: "row" }}>
                            <IconButton
                                color="secondary"
                                onClick={() => handleRemoveChannel(channel)}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <IconButton
                                color="primary"
                                onClick={() => handleShowLogicChannels(channel)} >
                                <ShowLogicChannelsIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
