import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";

import { Settings as TopicsIcon } from "@mui/icons-material";

import { GenerateInteger } from "../helpers/RandomGenerator";
import { useInterval } from "../useInterval";
import { OrderRequestStatuses } from "../enums/OrderRequestStatuses";

import OrderRequestsTable from "../components/Tables/OrderRequestsTable";
import AdvancedTablePagination from "../components/AdvancedTablePagination";

import { useTranslation } from "react-i18next";
import { generateUserInfoString } from "../helpers/UserInfoStringGenerator";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { useBackend } from "../context/BackendContext";
import { UserPermissions } from "../enums/UserRoles";
import { LanguageConverter } from "../enums/LanguageConverter";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoad,
  settingRepositoryLoadBool,
  settingRepositoryLoadInt,
  settingRepositorySave,
} from "../settingRepository";
import { useNavigate } from "react-router";

export default function OrderRequests() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchUserInput, setSearchUserInput] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchStatusInput, setSearchStatusInput] = useState(-1);
  const [searchStatus, setSearchStatus] = useState("");
  const [totalRows, setTotalRows] = useState(100);
  const [autoUpdateChecked, setAutoUpdateChecked] = useState(true);
  const [tableUserName, setTableUserName] = useState("");
  const [allowEditTopics, setAllowEditTopics] = useState(false);
  const [topics, setTopics] = useState({});
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const handleShowUserInfo = (request) => {
    backend.bckUsersGetInfo(request.senderId).then((json) => {
      if (json.code === 0) {
        let info = json.content;
        dialogDispatch({
          type: DialogActionTypes.SimpleOutputDialogOpen,
          text: generateUserInfoString(t, info),
          textLabel: "",
          title: t("Description"),
          noTextField: true,
        });
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleUsergroupClick = (request) => {
    backend.bckUsersGetUsergroupOwners(request.senderId).then((json) => {
      if (json.code === 0) {
        dialogDispatch({
          type: DialogActionTypes.UsergroupListDialogOpen,
          list: json.content,
        });
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const openOutputDialog = (request) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleOutputDialogOpen,
      text: request.description,
      textLabel: "",
      title: t("Description"),
      noTextField: true,
    });
  };

  const handleShowRequestAddress = (request) => {
    let action = {
      type: DialogActionTypes.AddressDialogOpen,
      mapDisabled: false,
      handleOpenMap: () =>
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${request.coordinates.replace(
            ";",
            ","
          )}`
        ),
      text: t("Loading"),
    };
    if (request.addressId === null || request.addressId === undefined) {
      action.text = t("Address not detected");
      dialogDispatch(action);
    } else {
      backend.bckAddressGetLocationString(request.addressId).then((json) => {
        if (json.content !== null) {
          action.text = json.content;
        } else {
          action.text = t("Address not detected");
        }
        dialogDispatch(action);
      });
    }
  };

  const refreshTable = useCallback(
    (backend, userQuery, status, pageNumber, pageSize) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckOrderRequestsList(userQuery, status, pageNumber + 1, pageSize)
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.list;
                } else {
                  return prev;
                }
              });
              setTotalRows((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.count;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }

          if (isLoading) setIsLoading(false);
        });
    },
    [t, isLoading]
  );

  useEffect(() => {
    var permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
    setAllowEditTopics(
      permissions.includes(UserPermissions.MANAGE_ORDER_REQUEST_TOPICS)
    );
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    backend
      .bckOrderRequestsListTopics(LanguageConverter[i18n.language])
      .then((json) => {
        if (json.code === 0) {
          let newTopics = {};
          json.content.list.forEach((t) => (newTopics[t.id] = t));
          setTopics(newTopics);
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
  }, [i18n.language]);

  useInterval(() => {
    if (updatesLockCounter.current === 0 && autoUpdateChecked) {
      refreshTable(backend, searchUser, searchStatus, pageNumber, pageSize);
    }
  }, 2000);

  useEffect(() => {
    if (isInitializing) {
      setAutoUpdateChecked(
        settingRepositoryLoadBool(SettingName.AUTO_UPDATE_ORDER_REQUESTS, true)
      );

      let pageSize = settingRepositoryLoadInt(
        SettingName.ORDER_REQUESTS_PAGE_SIZE,
        10
      );
      setPageSize(pageSize);

      setTableUserName(settingRepositoryLoad(SettingName.USER_NAME, ""));
      resetSearchParams();
      refreshTable(backend, "", "", 0, pageSize);
      setIsInitializing(false);
    }
  }, [backend, isInitializing, refreshTable]);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    refreshTable(backend, searchUser, searchStatus, newPage, pageSize);
  };

  const handleChangeRowsPerPage = (event) => {
    var newPageSize = parseInt(event.target.value, 10);
    settingRepositorySave(SettingName.ORDER_REQUESTS_PAGE_SIZE, newPageSize);
    setPageSize(newPageSize);
    setPageNumber(0);
    refreshTable(backend, searchUser, searchStatus, pageNumber, newPageSize);
  };

  const resetSearchParams = () => {
    setSearchUserInput("");
    setSearchUser("");
    setSearchStatusInput(-1);
    setSearchStatus("");
    setPageNumber(0);
  };

  const handleSearchPress = () => {
    var newSearchUser = "";
    if (searchUserInput.trim() === "") {
      setSearchUser("");
    } else {
      newSearchUser = searchUserInput;
      setSearchUser(searchUserInput);
    }

    var newSearchStatus = "";
    if (searchStatusInput === -1) {
      setSearchStatus("");
    } else {
      newSearchStatus = searchStatusInput;
      setSearchStatus(searchStatusInput);
    }

    setPageNumber(0);
    refreshTable(backend, newSearchUser, newSearchStatus, 0, pageSize);
  };

  const handleSearchResetPress = () => {
    resetSearchParams();
    refreshTable(backend, "", "", 0, pageSize);
  };

  const gotoFullOrderRequestPage = (request, viewOnly) => {
    navigate(
      `/app/orderRequests/fullRequest?requestId=${request.id}&viewOnly=${viewOnly}`
    );
  };

  const assignRequest = (techRequest) => {
    ++updatesLockCounter.current;
    var id = GenerateInteger();
    lastRequestId.current = id;
    techRequest.status = 2;
    backend.bckOrderRequestsAssign(techRequest.id).then((json) => {
      --updatesLockCounter.current;
      refreshTable(backend, searchUser, searchStatus, pageNumber, pageSize);
      if (json.code === 0) {
        gotoFullOrderRequestPage(techRequest, false);
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleAssignRequest = (request) => {
    var requestConfirm =
      !tableUserName ||
      (request.handlerName && request.handlerName !== tableUserName);
    if (requestConfirm) {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Techsupport request switch confirmation"),
        handleConfirm: () => assignRequest(request),
      });
    } else {
      assignRequest(request);
    }
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Typography color="secondary">{error}</Typography>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h5" color="textSecondary">
            {t("Order requests table")}
          </Typography>
          {allowEditTopics ? (
            <IconButton
              onClick={(e) => {
                navigate("/app/orderRequests/topics");
              }}
              color="primary"
            >
              <TopicsIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ paddingTop: "12px" }}
        >
          <Grid container item spacing={2} alignContent="center">
            <Grid item>
              <TextField
                label={t("User")}
                value={searchUserInput}
                onChange={(e) => setSearchUserInput(e.target.value)}
                style={{ marginRight: "12px" }}
              />
            </Grid>
            <Grid item>
              <FormControl style={{ marginRight: "12px", minWidth: "25ch" }}>
                <InputLabel>{t("Status")}</InputLabel>
                <Select
                  value={searchStatusInput}
                  label={t("Status")}
                  onChange={(e) => setSearchStatusInput(e.target.value)}
                >
                  <MenuItem key={-1} value={-1}>
                    <em>{t("Any (mas)")}</em>
                  </MenuItem>
                  {Object.keys(OrderRequestStatuses).map((id) => (
                    <MenuItem key={id} value={id}>
                      {t(OrderRequestStatuses[id])}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item>
              <Button
                onClick={handleSearchPress}
                variant="contained"
                color="primary"
              >
                {t("Search")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSearchResetPress}
                disabled={
                  searchStatusInput === -1 && searchUserInput.trim() === ""
                }
                variant="contained"
                color="primary"
              >
                {t("Reset parameters")}
              </Button>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={autoUpdateChecked}
                    onChange={() => {
                      setAutoUpdateChecked(!autoUpdateChecked);
                      settingRepositorySave(
                        SettingName.AUTO_UPDATE_ORDER_REQUESTS,
                        !autoUpdateChecked
                      );
                    }}
                    color="primary"
                  />
                }
                label={t("Auto update")}
              />
            </Grid>
          </Grid>
        </Grid>
        <AdvancedTablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          totalRows={totalRows}
          pageSize={pageSize}
          pageNumber={pageNumber}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <OrderRequestsTable
            data={tableData}
            topics={topics}
            userName={tableUserName}
            handleShowRequestAddress={handleShowRequestAddress}
            handleShowDescription={openOutputDialog}
            handleAssignRequest={handleAssignRequest}
            handleViewRequest={(request) =>
              gotoFullOrderRequestPage(request, true)
            }
            handleContinueRequest={(request) =>
              gotoFullOrderRequestPage(request, false)
            }
            handleShowUserInfo={handleShowUserInfo}
            handleUsergroupClick={handleUsergroupClick}
          />
        </AdvancedTablePagination>
      </Paper>
    </>
  );
}
