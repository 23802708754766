const ClientTypes = {
    0: "Android",
    1: "Huawei",
    2: "iPhone",
    3: "Web",
    4: "Windows",
    5: "Linux",
    6: "Mac"
};
Object.freeze(ClientTypes);

const ClientTypesByName = {
    "Android": 0,
    "Huawei": 1,
    "iPhone": 2,
    "Web": 3,
    "Windows": 4,
    "Linux": 5,
    "Mac": 6
};
Object.freeze(ClientTypesByName);

export { ClientTypes, ClientTypesByName }
