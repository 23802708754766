import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Link,
  Grid,
} from "@mui/material";

//import useStyles from "./styles";

import {
  loginUser,
  signIn,
  useUserDispatch,
} from "../../../context/UserContext";
import { useTranslation } from "react-i18next";
import { useBackend } from "../../../context/BackendContext";

export default function LoginDialog({ isOpened, handleClose, ...props }) {
  const { t } = useTranslation();
  //const classes = useStyles();
  const userDispatch = useUserDispatch();
  const backend = useBackend();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [activeTabId, setActiveTabId] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeated, setPasswordRepeated] = useState("");
  const [passwordResetMode, setPasswordResetMode] = useState(false);

  const clearFields = () => {
    setPasswordResetMode(false);
    setError("");
    setName("");
    setEmail("");
    setLogin("");
    setPassword("");
    setPasswordRepeated("");
  };

  const checkPasswords = () => {
    if (password !== passwordRepeated) {
      setError("Password mismatch");
      return false;
    }
    return true;
  };

  const handleRequestPasswordResetClick = () => {
    backend
      .bckUsersRequestPasswordReset(login)
      .then((requestData) => {
        return requestData.json();
      })
      .then((json) => {
        if (json.code === 0) {
          setError("Password reset link sent");
        } else {
          setError(json.message);
        }
      });
    setLogin("");
  };

  const handleLoginPasswordKeyDown = (e) => {
    if (!passwordResetMode) {
      if (e.keyCode === 13) {
        handleTryLogin();
      }
    }
  };

  const handleTryLogin = () => {
    if (login.length !== 0 && password.length !== 0) {
      loginUser(
        userDispatch,
        backend,
        login,
        password,
        setIsLoading,
        (text) => {
          setError(text);
        }
      );
    }
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      BackdropProps={{
        style: {
          backdropFilter: "blur(3px)",
        },
      }}
    >
      <DialogContent>
        <div>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h3">{t("Log in to account")}</Typography>
              {error !== null && error.trim() !== "" ? (
                <Typography color="secondary">{t(error)}</Typography>
              ) : (
                <></>
              )}
              <div>
                <TextField
                  value={login}
                  onKeyDown={handleLoginPasswordKeyDown}
                  onChange={(e) => setLogin(e.target.value)}
                  margin="normal"
                  label={t("Login or email")}
                  fullWidth
                />
                {passwordResetMode ? (
                  <></>
                ) : (
                  <>
                    <TextField
                      value={password}
                      onKeyDown={handleLoginPasswordKeyDown}
                      onChange={(e) => setPassword(e.target.value)}
                      margin="normal"
                      label={t("Password")}
                      type="password"
                      fullWidth
                    />
                  </>
                )}
              </div>
              <div>
                {isLoading ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress size={26} />
                  </Grid>
                ) : (
                  <Grid container direction="column">
                    {passwordResetMode ? (
                      <>
                        <Typography variant="caption">
                          {t("Password reset comment")}
                        </Typography>
                        <Button
                          disabled={login.length === 0}
                          onClick={handleRequestPasswordResetClick}
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth
                        >
                          {t("Send")}
                        </Button>
                        <Button
                          onClick={() => setPasswordResetMode(false)}
                          variant="contained"
                          color="primary"
                          size="large"
                          style={{ marginTop: "12px" }}
                          fullWidth
                        >
                          {t("Back")}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          disabled={login.length === 0 || password.length === 0}
                          onClick={handleTryLogin}
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth
                        >
                          {t("Log in")}
                        </Button>
                        <div style={{ display: "flex" }}>
                          <Link
                            component="button"
                            onClick={() => setPasswordResetMode(true)}
                            style={{
                              marginTop: "12px",
                            }}
                          >
                            {t("Password reset")}
                          </Link>
                        </div>
                        <div style={{ display: "flex", marginTop: "12px" }}>
                          {t("Need registration?")}
                          <Link
                            component="button"
                            onClick={() => {
                              clearFields();
                              setActiveTabId(1);
                            }}
                            style={{ marginLeft: "6px" }}
                          >
                            {t("Register")}
                          </Link>
                        </div>
                      </>
                    )}
                  </Grid>
                )}
              </div>
            </React.Fragment>
          )}
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h2">{t("Sign up")}</Typography>
              {error !== null && error.trim() !== "" ? (
                <Typography color="secondary">{t(error)}</Typography>
              ) : (
                <></>
              )}
              <TextField
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                margin="normal"
                label={t("Full name")}
                fullWidth
              />
              <TextField
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                label={t("Email")}
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                label={t("Password")}
                type="password"
                fullWidth
              />
              <TextField
                id="passwordRepeated"
                value={passwordRepeated}
                onChange={(e) => setPasswordRepeated(e.target.value)}
                margin="normal"
                label={t("Repeat password")}
                type="password"
                fullWidth
              />
              <div>
                {isLoading ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress size={26} />
                  </Grid>
                ) : (
                  <Button
                    onClick={() => {
                      if (checkPasswords()) {
                        signIn(
                          backend,
                          name,
                          email,
                          password,
                          setIsLoading,
                          setError
                        );
                      }
                    }}
                    disabled={password.length === 0 || name.length === 0}
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    {t("Create account")}
                  </Button>
                )}
              </div>
              <div style={{ display: "flex", marginTop: "12px" }}>
                {t("Already registered?")}
                <Link
                  component="button"
                  onClick={() => {
                    clearFields();
                    setActiveTabId(0);
                  }}
                  style={{ marginLeft: "6px" }}
                >
                  {t("Try logging in")}
                </Link>
              </div>
            </React.Fragment>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
