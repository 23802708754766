import React, { useState } from "react"
import {
    IconButton,
} from "@mui/material";

export default function IconButtonState({ defaultState, tags, onClick }) {
    const [state, setState] = useState(defaultState);

    const handleClick = (e) => {
        let newValue = (state + 1) % tags.length;
        if (onClick(e, newValue)) {
            setState(newValue);
        }
    };

    return (
        <IconButton style={{ width: 32, height: 32, fontSize: "12pt", padding: 0 }} onClick={handleClick}>
            {tags[state]}
        </IconButton>
    );
}
