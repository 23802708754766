import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Grid,
  Typography,
  Button,
  Paper,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import DevicesTable from "../components/Tables/DevicesTable";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { DeviceTypes } from "../enums/DeviceTypes";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoadBool,
  settingRepositorySave,
} from "../settingRepository";

export default function Devices() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [deviceNameInput, setDeviceNameInput] = useState("");
  const [tableData, setTableData] = useState([]);
  const [subsystemsData, setSubsystemsData] = useState([]);
  const [searchParams, setSearchParams] = useState({
    deviceName: "",
    showAll: null,
    onlyWithSubsystems: false,
    pageNumber: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(100);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const deviceTypeMenuItems = Object.keys(DeviceTypes).map((i) => (
    <MenuItem key={i} value={i}>
      {t(DeviceTypes[i])}
    </MenuItem>
  ));

  const refreshTable = useCallback(
    (params) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      let realPageSize = params.showAll ? 1000 : params.pageSize;
      let realPageNumber = params.showAll ? 1 : params.pageNumber + 1;
      backend
        .bckDevicesListDevices(
          params.deviceName,
          params.onlyWithSubsystems,
          realPageNumber,
          realPageSize
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTotalRows(json.content.count);
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.list;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }
        });
    },
    [t, backend]
  );

  useEffect(() => {
    backend.bckDevicesList("", false, false).then((json) => {
      setSubsystemsData(json.content);
    });
    let repositoryShowAll = settingRepositoryLoadBool(
      SettingName.DEVICES_SHOW_ALL,
      false
    );
    let repositoryOnlyWithSubsystems = settingRepositoryLoadBool(
      SettingName.DEVICES_ONLY_WITH_SUBSYSTEMS,
      true
    );
    setSearchParams((prev) => ({
      ...prev,
      showAll: repositoryShowAll,
      onlyWithSubsystems: repositoryOnlyWithSubsystems,
    }));
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  const handleShowDeviceImage = (device) => {
    dialogDispatch({
      type: DialogActionTypes.ImageViewerOpen,
      image: {
        src: device.imageUrl,
        alt: "",
      },
    });
  };

  const handleCreateDeviceOpen = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        ++updatesLockCounter.current;

        let requestId = GenerateInteger();
        lastRequestId.current = requestId;
        backend
          .bckDevicesCreateOrUpdateDevice(null, name, 0, [], null)
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let deviceId = json.content;
              let newElement = {
                id: deviceId,
                name: name,
                type: 0,
                imageUrl: null,
                subsystems: [],
              };
              setTableData((prev) => {
                let newArr = [newElement, ...prev];
                return newArr;
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: "",
      textLabel: t("Title"),
      title: t("Add device"),
    });
  };

  const handleTitleClick = (device) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        ++updatesLockCounter.current;

        let requestId = GenerateInteger();
        lastRequestId.current = requestId;
        backend
          .bckDevicesCreateOrUpdateDevice(
            device.id,
            name,
            device.type,
            device.subsystems,
            device.creationTime
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let deviceId = json.content;
              setTableData((prev) => {
                var foundIndex = prev.findIndex((x) => x.id === deviceId);
                if (foundIndex !== -1) {
                  prev[foundIndex].name = name;
                }
                return [...prev];
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: device.name,
      textLabel: t("Title"),
      title: t("Title"),
    });
  };

  const handleCreationTimeClick = (device) => {
    dialogDispatch({
      type: DialogActionTypes.DateTimeDialogOpen,
      handleConfirm: (dateTime) => {
        ++updatesLockCounter.current;

        let requestId = GenerateInteger();
        lastRequestId.current = requestId;
        backend
          .bckDevicesCreateOrUpdateDevice(
            device.id,
            device.name,
            device.type,
            device.subsystems,
            dateTime
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let deviceId = json.content;
              setTableData((prev) => {
                var foundIndex = prev.findIndex((x) => x.id === deviceId);
                if (foundIndex !== -1) {
                  prev[foundIndex].creationTime = dateTime?.toJSON();
                }
                return [...prev];
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: Date.parse(device.creationTime),
      title: t("Creation time"),
    });
  };

  const handleTypeClick = (device) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleSelectDialogOpen,
      initialValue: device.type,
      label: t("Type"),
      title: t("Type"),
      menuItems: deviceTypeMenuItems,
      handleConfirm: (value) => {
        ++updatesLockCounter.current;

        let requestId = GenerateInteger();
        lastRequestId.current = requestId;
        backend
          .bckDevicesCreateOrUpdateDevice(
            device.id,
            device.name,
            value,
            device.subsystems,
            device.creationTime
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let deviceId = json.content;
              setTableData((prev) => {
                var foundIndex = prev.findIndex((x) => x.id === deviceId);
                if (foundIndex !== -1) {
                  prev[foundIndex].type = value;
                }
                return [...prev];
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleSubsystemsClick = (device) => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: device.subsystems
        ? subsystemsData.filter((sd) =>
            device.subsystems.some((s) => s === sd.id)
          )
        : [],
      title: t("Subsystems"),
      label: t("Subsystems"),
      options: subsystemsData,
      getOptionLabel: (option) =>
        `${option?.name ?? "<No name>"} (${option?.id})`,
      multiple: true,
      handleConfirm: (newSubsystemsObj) => {
        let newSubsystems = newSubsystemsObj.map((nso) => nso.id);
        ++updatesLockCounter.current;

        let requestId = GenerateInteger();
        lastRequestId.current = requestId;
        backend
          .bckDevicesCreateOrUpdateDevice(
            device.id,
            device.name,
            device.type,
            newSubsystems,
            device.creationTime
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let deviceId = json.content;
              setTableData((prev) => {
                var foundIndex = prev.findIndex((x) => x.id === deviceId);
                if (foundIndex !== -1) {
                  prev[foundIndex].subsystems = newSubsystems;
                }
                return [...prev];
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleRemoveDevice = (device) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove brand?", { title: device.name }),
      handleConfirm: () => {
        ++updatesLockCounter.current;
        backend.bckDevicesRemoveDevice(device.id).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
            setTableData((prev) => {
              let foundIndex = prev.findIndex((x) => x.id === device.id);
              if (foundIndex !== -1) {
                prev.splice(foundIndex, 1);
              }
              return [...prev];
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const handleUploadImage = (device, file) => {
    if (device.imageUrl) {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Overwrite image?"),
        handleConfirm: () => {
          handleUploadImageAccepted(device, file);
        },
      });
    } else {
      handleUploadImageAccepted(device, file);
    }
  };

  const handleUploadImageAccepted = (device, file) => {
    if (file.size > 16 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    backend.bckFilesUploadDeviceImage(device.id, file).then((json) => {
      if (json.code === 0) {
        setTableData((prev) => {
          let next = [...prev];
          let target = next.find((r) => r.id === device.id);
          if (target) target.imageUrl = json.content;
          return next;
        });
      } else {
        setError(json.message);
      }
    });
  };

  useEffect(() => {
    if (searchParams.showAll != null) {
      refreshTable(searchParams);
    }
  }, [searchParams]);

  const handleChangePage = (event, newPage) => {
    setSearchParams((prev) => ({ ...prev, pageNumber: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    var newRowsPerPage = parseInt(event.target.value, 10);
    setSearchParams((prev) => ({
      ...prev,
      pageNumber: 0,
      pageSize: newRowsPerPage,
    }));
  };

  const renderTable = () => {
    return (
      <DevicesTable
        data={tableData}
        subsystemsData={subsystemsData}
        handleShowDeviceImage={handleShowDeviceImage}
        handleRemoveDevice={handleRemoveDevice}
        handleUploadImage={handleUploadImage}
        handleTitleClick={handleTitleClick}
        handleTypeClick={handleTypeClick}
        handleSubsystemsClick={handleSubsystemsClick}
        handleCreationTimeClick={handleCreationTimeClick}
      />
    );
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("Devices table")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          <Grid item>
            <TextField
              label={t("Device")}
              value={deviceNameInput}
              onChange={(e) => setDeviceNameInput(e.target.value)}
              style={{ marginRight: "12px" }}
            />
          </Grid>
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <Button
                onClick={(e) => {
                  setSearchParams((prev) => ({
                    ...prev,
                    deviceName: deviceNameInput,
                    pageNumber: 0,
                  }));
                }}
                variant="contained"
                color="primary"
              >
                {t("Search")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleCreateDeviceOpen}
                variant="contained"
                color="primary"
              >
                {t("Add device")}
              </Button>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={searchParams.showAll ?? false}
                    onClick={() => {
                      let newShowAll = !searchParams.showAll;
                      setSearchParams((prev) => ({
                        ...prev,
                        showAll: newShowAll,
                      }));
                      settingRepositorySave(
                        SettingName.DEVICES_SHOW_ALL,
                        newShowAll
                      );
                    }}
                    color="primary"
                  />
                }
                label={t("Show all")}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={searchParams.onlyWithSubsystems ?? false}
                    onClick={() => {
                      let newOnlyWithSubsystems =
                        !searchParams.onlyWithSubsystems;
                      setSearchParams((prev) => ({
                        ...prev,
                        onlyWithSubsystems: newOnlyWithSubsystems,
                      }));
                      settingRepositorySave(
                        SettingName.DEVICES_ONLY_WITH_SUBSYSTEMS,
                        newOnlyWithSubsystems
                      );
                    }}
                    color="primary"
                  />
                }
                label={t("Only with subsystems")}
              />
            </Grid>
          </Grid>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            {searchParams.showAll ? (
              renderTable()
            ) : (
              <AdvancedTablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                totalRows={totalRows}
                pageSize={searchParams.pageSize}
                pageNumber={searchParams.pageNumber}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                {renderTable()}
              </AdvancedTablePagination>
            )}
          </div>
        </Grid>
      </Paper>
    </>
  );
}
