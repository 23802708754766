import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";

export default function DateTimeDialog({
  isOpened,
  title,
  initialValue,
  handleConfirm,
  handleClose,
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (isOpened) {
      setValue(initialValue ? initialValue : {});
    }
  }, [isOpened, initialValue]);

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DatePicker
          style={{ marginRight: "16px", marginBottom: "16px" }}
          label={title}
          format="yyyy-MM-dd HH:mm:ss"
          value={value}
          onChange={(v) => {
            setValue(v);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Cancel")}
        </Button>
        <Button
          disabled={value === undefined || value === null}
          onClick={() => {
            handleClose();
            handleConfirm(value);
          }}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
