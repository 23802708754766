import React, { useState } from "react";

import {
    Grid,
    Tabs,
    Tab
} from "@mui/material";

import { useTranslation } from "react-i18next";
import Wires from "./Wires";
import WireColors from "./WireColors";
import Colors from "./Colors";

export default function WireEditor({ data, setData, setError }) {
    const { t } = useTranslation();
    const [tabValue, setTabValue] = useState(0);

    const handleSetTab = (event, newValue) => {
        setTabValue(newValue);
    };

    return (<Grid container direction="column" spacing={2}>
        <Tabs value={tabValue} onChange={handleSetTab}>
            <Tab label={t("Wires")} />
            <Tab label={t("Wire colors")} />
            <Tab label={t("Colors")} />
        </Tabs>
        {
            tabValue === 0 ? <Wires data={data} setData={setData} setError={setError} /> : <></>
        }
        {
            tabValue === 1 ? <WireColors data={data} setData={setData} setError={setError} /> : <></>
        }
        {
            tabValue === 2 ? <Colors data={data} setData={setData} setError={setError} /> : <></>
        }
    </Grid>);
}
