import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  Grid,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";

export default function CreateUpdateChannelFileDialog({
  isOpened,
  updateChannels,
  handleClose,
}) {
  const backend = useBackend();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [version, setVersion] = useState("");
  const [versionInt, setVersionInt] = useState(0);
  const [updateChannelId, setUpdateChannelId] = useState(0);
  const [isDisabled, setDisabled] = useState(false);

  const handleSendPress = (e) => {
    setError("");
    setDisabled(true);

    backend
      .bckUpdateChannelCreateFile(version, versionInt, updateChannelId)
      .then((json) => {
        setDisabled(false);
        if (json.code === 0) {
          handleClose();
        } else {
          setError(json.message);
        }
      });
  };

  useEffect(() => {
    setError("");
    setVersion("");
    setVersionInt(0);
    setUpdateChannelId(0);
  }, [isOpened]);

  const handleVersionChange = (e) => {
    let sliced = e.target.value.slice(0, 64);
    if (sliced) {
      let parsed = parseInt(sliced, 10);
      if (parsed) {
        setVersionInt(parsed);
      } else {
        setVersionInt(0);
      }
    } else {
      setVersionInt("");
    }
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{t("Add version")}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Typography color="secondary">{error}</Typography>
          <FormControl style={{ maxWidth: "30ch", marginTop: "12px" }}>
            <InputLabel>{t("Update channel")}</InputLabel>
            <Select
              disabled={isDisabled}
              label={t("Update channel")}
              value={updateChannelId}
              onChange={(e) => {
                setUpdateChannelId(e.target.value);
              }}
            >
              {updateChannels.map((ch) => (
                <MenuItem key={ch.id} value={ch.id}>
                  {ch.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label={t("Version")}
            value={version}
            onChange={(e) => setVersion(e.target.value.slice(0, 64))}
            style={{ maxWidth: "90ch", marginTop: "12px" }}
          />
          <TextField
            label={t("Version")}
            value={versionInt}
            onChange={handleVersionChange}
            style={{ maxWidth: "90ch", marginTop: "12px" }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            isDisabled ||
            !version.trim() ||
            !versionInt ||
            versionInt <= 0 ||
            updateChannelId === 0
          }
          onClick={handleSendPress}
          variant="contained"
          color="primary"
        >
          {t("Create")}
        </Button>
        <Button onClick={handleClose} variant="contained" color="primary">
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
