import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Tooltip
} from "@mui/material";

import {
    Image as ImageIcon,
    Delete as DeleteIcon,
    Publish as UploadIcon,
    ReportProblem as WarningIcon
} from "@mui/icons-material";

import ReactFileReader from "react-file-reader";
import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next"
import { DeviceTypes } from "../../enums/DeviceTypes";
import { formatDate } from "../../helpers/StringUtils";

export default function DevicesTable({ data, subsystemsData,
    handleShowDeviceImage, handleRemoveDevice, handleUploadImage, handleTitleClick, handleTypeClick, handleCreationTimeClick, handleSubsystemsClick }) {
    const { t } = useTranslation();

    var keys = ["#", t("Device"), t("Type"), t("Subsystems"), t("Creation time"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(device => (
                    <TableRow key={device.id}>
                        <TableCell className={styles.cell}>{device.id}</TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleTitleClick(device)}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {
                                    device.subsystems && device.subsystems.length > 0 ? <></> : <Tooltip title={t("There are no subsystems in this device")}>
                                        <WarningIcon style={{ color: '#EBB734' }} />
                                    </Tooltip>
                                }
                                <>{device.name}</>
                            </div>
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleTypeClick(device)}>
                            {t(DeviceTypes[device.type])}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleSubsystemsClick(device)}>
                            {subsystemsData && device.subsystems ? device.subsystems.map(s => subsystemsData.find(sd => sd.id === s)?.name).join(", ") : ""}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleCreationTimeClick(device)}>
                            {formatDate(device.creationTime)}
                        </TableCell>
                        <TableCell className={styles.cell} style={{ display: "flex", flexDirection: "row" }}>
                            <IconButton
                                style={{ color: 'red' }}
                                onClick={() => handleRemoveDevice(device)} >
                                <DeleteIcon />
                            </IconButton>
                            <ReactFileReader handleFiles={(files) => handleUploadImage(device, files[0])} fileTypes={["*.*"]}>
                                <IconButton color="primary">
                                    <UploadIcon />
                                </IconButton>
                            </ReactFileReader>
                            <IconButton
                                disabled={!device.imageUrl}
                                color="primary"
                                onClick={() => handleShowDeviceImage(device) } >
                                <ImageIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
