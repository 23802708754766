const PushTypes = {
    1: "Notification",
    2: "News",
    3: "Video",
    4: "Warning",
    5: "Chat",
    6: "Article"
};
Object.freeze(PushTypes);

export { PushTypes }