import React from "react";
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    Typography
} from "@mui/material";

import {
    Star
} from "@mui/icons-material";

import AdvancedAvatarBadge from "../AdvancedAvatarBadge";
import { useTranslation } from "react-i18next"
import { formatDate } from "../../helpers/StringUtils";
import { ClientTypes } from "../../enums/ClientTypes";

export default function FeedbacksTable({ data }) {
    const { t } = useTranslation();

    return (
        <Table size="small">
            <TableBody>
                {data.map(feedback => (
                    <React.Fragment key={feedback.id}>
                        <TableRow>
                            <TableCell>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <AdvancedAvatarBadge
                                        avatarKey={0}
                                        isUserOnline={false}
                                        userId={feedback.userId}
                                        userMail={feedback.username}
                                    />
                                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "12px" }}>
                                        <Typography variant="h6">{feedback.username}</Typography>
                                        <Typography variant="caption">{formatDate(feedback.creationTime)}</Typography>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", marginTop: "12px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Typography style={{ marginRight: "12px", marginLeft: "16px" }}>{t("Platform")}</Typography>
                                        <Typography color="primary">{ClientTypes[feedback.clientType]}</Typography>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Typography style={{ marginRight: "12px", marginLeft: "16px" }}>{t("Version")}</Typography>
                                        <Typography color="primary">{feedback.versionInt}</Typography>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Typography style={{ marginRight: "12px", marginLeft: "16px" }}>{t("Language")}</Typography>
                                        <Typography color="primary">{feedback.lang}</Typography>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Typography style={{ marginRight: "12px", marginLeft: "16px" }}>{t("Device")}</Typography>
                                        <Typography color="primary">{feedback.clientInfo}</Typography>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignContent: "center" }}>
                                    {
                                        [0.5, 1.5, 2.5, 3.5, 4.5].map(v => {
                                            if (feedback.rate > v) return <Star key={v} color="primary" />
                                            return <Star key={v} />
                                        })
                                    }
                                    <Typography>{feedback.rate}</Typography>
                                </div>
                                <Typography style={{ marginTop: "16px" }}>{feedback.text}</Typography>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>
    );
}
