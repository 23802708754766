import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField
} from "@mui/material";

import { useTranslation } from "react-i18next";

export default function SimpleTextDialog({ title, initialValue, textLabel, maxLength, isOpened, lines, allowEmptyOk, onChange, handleConfirm, handleClose }) {
    const { t } = useTranslation();
    const [textValue, setTextValue] = useState("");

    useEffect(() => {
        if (isOpened) {
            setTextValue(initialValue ? initialValue : "");
        }
    }, [isOpened, initialValue]);

    const defaultOnChange = (e) => {
        return maxLength ? e.target.value.substr(0, maxLength) : e.target.value;
    };

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label={textLabel}
                    value={textValue === undefined ? "" : textValue}
                    onChange={e => setTextValue(onChange ? onChange(e) : defaultOnChange(e))}
                    multiline={lines && lines > 1}
                    rows={lines}
                    onKeyDown={(e) => {
                        if (e.keyCode == 9) {
                            e.preventDefault()
                            let point = e.target.selectionStart
                            setTextValue(prev => prev.substr(0, point) + '\t' + prev.substr(point, prev.length - point))
                            setTextValue(prev => maxLength ? prev.substr(0, maxLength) : prev)
                        }
                    }}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={!allowEmptyOk && (textValue === undefined || textValue === null || textValue.trim() === "")} onClick={() => { handleClose(); handleConfirm(textValue); }} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
