import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography
} from "@mui/material";

export default function SimpleOutputDialog({ title, text, textLabel, noTextField, isOpened, handleClose }) {
    const [textValue, setTextValue] = useState("");

    useEffect(() => {
        setTextValue(text);
    }, [text]);

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {
                    noTextField ? <Typography style={{ whiteSpace: "pre-line" }}>{textValue}</Typography> : <TextField
                        autoFocus
                        margin="dense"
                        label={textLabel}
                        value={textValue === undefined ? "" : textValue}
                        variant="outlined"
                        multiline
                        rows="7"
                        fullWidth
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
