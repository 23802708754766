import React, { useState, useEffect, useRef } from "react";
import { Badge, Grid, Paper, Typography } from "@mui/material";
import { useInterval } from "../../useInterval";
import { useTranslation } from "react-i18next";
import { UserPermissions } from "../../enums/UserRoles";
import { generateSidebarPageList } from "../../SidebarPageListGenerator";
import { useBackend } from "../../context/BackendContext";
import styles from "./styles.module.css";
import { useNavigate } from "react-router";

export default function Dashboard() {
  const { t } = useTranslation();
  const backend = useBackend();
  const navigate = useNavigate();
  const [structure, setStructure] = useState([]);
  const [showStats, setShowStats] = useState(false);
  const [showChatStatus, setShowChatStatus] = useState(false);
  const [anyUnreadMessages, setAnyUnreadMessages] = useState(false);
  const [showRequestsStatus, setShowRequestsStatus] = useState(false);
  const [anyOpenedTechsupportRequests, setAnyOpenedTechsupportRequests] =
    useState(false);
  const [
    showAnyWithPendingUsergroupMembers,
    setShowAnyWithPendingUsergroupMembers,
  ] = useState(false);
  const [anyWithPendingUsergroupMembers, setAnyWithPendingUsergroupMembers] =
    useState(false);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [onlineUsersCount, setOnlineUsersCount] = useState(0);
  const isInitialized = useRef(false);

  const updateUsersCount = (backend) => {
    backend.bckStatsTotalUsers().then((json) => {
      if (json.code === 0) {
        setTotalUsersCount(json.content);
      }
    });

    backend.bckStatsOnlineUsers().then((json) => {
      if (json.code === 0) {
        setOnlineUsersCount(json.content);
      }
    });
  };

  const updateChatStatus = (backend) => {
    backend.bckChatAnyUnreadMessages().then((json) => {
      if (json.code === 0) {
        setAnyUnreadMessages(json.content);
      }
    });
  };

  const updateRequestsStatus = (backend) => {
    backend.bckTechsupportRequestsAnyOpened().then((json) => {
      if (json.code === 0) {
        setAnyOpenedTechsupportRequests(json.content);
      }
    });
  };

  const updateAnyWithPendingUsergroupMembers = (backend) => {
    backend.bckUsersAnyWithPendingUsergroupMembers().then((json) => {
      if (json.code === 0) {
        setAnyWithPendingUsergroupMembers(json.content);
      }
    });
  };

  useEffect(() => {
    setStructure(
      generateSidebarPageList(t, { fontSize: 96, marginRight: "12px" })
    );
  }, [t]);

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      var permissions =
        JSON.parse(localStorage.getItem("permissions") || "[]") || [];
      if (permissions.includes(UserPermissions.GET_STATS)) {
        setShowStats(true);
        updateUsersCount(backend);
      }

      if (permissions.includes(UserPermissions.CHAT_ANY)) {
        setShowChatStatus(true);
        updateChatStatus(backend);
      }

      if (permissions.includes(UserPermissions.LIST_TECHSUPPORT_REQUESTS)) {
        setShowRequestsStatus(true);
        updateRequestsStatus(backend);
      }

      if (permissions.includes(UserPermissions.USERS_LIST)) {
        setShowAnyWithPendingUsergroupMembers(true);
        updateAnyWithPendingUsergroupMembers(backend);
      }
    }
  }, [backend]);

  useInterval(() => {
    if (showStats) {
      updateUsersCount(backend);
    }
    if (showChatStatus) {
      updateChatStatus(backend);
    }
    if (showRequestsStatus) {
      updateRequestsStatus(backend);
    }
    if (showAnyWithPendingUsergroupMembers) {
      updateAnyWithPendingUsergroupMembers(backend);
    }
  }, 5000);

  return (
    <div
      style={{ display: "flex", flexFlow: "row wrap", alignItems: "center" }}
    >
      {showStats ? (
        <Paper
          style={{
            overflow: "auto",
            padding: "12px",
            display: "inline-block",
            width: "48ch",
            height: "170px",
            marginRight: "12px",
            marginBottom: "12px",
          }}
        >
          <Typography variant="h5">{t("Users count")}</Typography>
          <Typography variant="caption">{t("(last 20 minutes)")}</Typography>
          <Typography variant="h2" weight="medium">
            {onlineUsersCount}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography>{t("Registered users")}</Typography>
              <Typography variant="h4">{totalUsersCount}</Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <div />
      )}
      {structure
        .filter((link) => link.id !== 0 && !link.children)
        .map((link) => (
          <Paper
            key={link.id}
            onClick={() => {
              navigate(link.link);
            }}
            className={styles.pagePaper}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              {(anyUnreadMessages && link.id === 11) ||
              (anyOpenedTechsupportRequests && link.id === 10) ||
              (anyWithPendingUsergroupMembers && link.id === 1) ? (
                <Badge
                  badgeContent={"!"}
                  color="primary"
                  sx={{
                    "& .MuiBadge-badge": {
                      height: 36,
                      width: 36,
                      borderRadius: "49%",
                      fontSize: "24px",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  overlap="circular"
                >
                  {link.icon}
                </Badge>
              ) : (
                link.icon
              )}
              <div>
                <Typography size="md">
                  <b>{link.label}</b>
                </Typography>
                <Typography>{link.comment}</Typography>
              </div>
            </div>
          </Paper>
        ))}
    </div>
  );
}
