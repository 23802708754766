import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    Select,
    MenuItem,
    Typography,
    TextField
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { ChatMountEventTypes } from "../../enums/ChatMountEventTypes";
import ReactFileReader from "react-file-reader";

export default function SendMountEventDialog({ isOpened, initialDeviceId, handleConfirm, handleClose }) {
    const { t } = useTranslation();
    const [selectedType, setSelectedType] = useState(ChatMountEventTypes.PROCESSING_START);
    const [dxsData, setDxsData] = useState("");
    const [dxsFilename, setDxsFilename] = useState(t("DXS file not loaded"));
    const [deviceIdInput, setDeviceIdInput] = useState("");

    useEffect(() => {
        setDeviceIdInput(initialDeviceId);
    }, [initialDeviceId]);

    const onSelectChange = (e) => {
        setSelectedType(e.target.value);
    };

    const handleDxsFileSelect = (files) => {
        if (files[0].size > 32 * 1024) {
            alert(t("File is too large"));
            return;
        }

        var reader = new FileReader();
        reader.onloadend = function (e) {
            try {
                var dxsJson = JSON.parse(reader.result);
                if (!dxsJson.hasOwnProperty("settings")) {
                    alert(t("Unknown DXS file format"));
                    return;
                }
                setDxsFilename(files[0].name);
                setDxsData(reader.result);
            } catch {
                alert(t("Unknown DXS file format/encoding"));
            }
        }
        reader.readAsText(files[0], "UTF-16");
    };

    const getSizeComment = (data) => {
        if (data === undefined || data === null || data.trim() === "") return "";
        try {
            let parsed = JSON.parse(data);
            let settings = parsed.settings;
            if (settings === undefined || settings === null || settings.trim() === "") return "";
            let length = settings.length / 2;
            return ` (${length} bytes)`;
        } catch (e) {
            return "";
        }
    };

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{t("Send mount event")}</DialogTitle>
            <DialogContent>
                <FormControl style={{ marginRight: "12px", minWidth: "64ch" }}>
                    <Select
                        value={selectedType}
                        onChange={onSelectChange}
                    >
                        <MenuItem key={ChatMountEventTypes.MOUNT_SYNCHRONIZATION_REQUEST} value={ChatMountEventTypes.MOUNT_SYNCHRONIZATION_REQUEST}>
                            {t("Mount synchronization request")}
                        </MenuItem>
                        <MenuItem key={ChatMountEventTypes.PROCESSING_START} value={ChatMountEventTypes.PROCESSING_START}>
                            {t("Processing start")}
                        </MenuItem>
                        <MenuItem key={ChatMountEventTypes.PROCESSING_STOP} value={ChatMountEventTypes.PROCESSING_STOP}>
                            {t("Processing stop")}
                        </MenuItem>
                        <MenuItem key={ChatMountEventTypes.DEVICE_INFORMATION_REQUEST} value={ChatMountEventTypes.DEVICE_INFORMATION_REQUEST}>
                            {t("Device information request")}
                        </MenuItem>
                        <MenuItem key={ChatMountEventTypes.DEVICE_SETTINGS_REQUEST} value={ChatMountEventTypes.DEVICE_SETTINGS_REQUEST}>
                            {t("Device settings request")}
                        </MenuItem>
                        <MenuItem key={ChatMountEventTypes.WRITE_SETTINGS_REQUEST} value={ChatMountEventTypes.WRITE_SETTINGS_REQUEST}>
                            {t("Write settings request")}
                        </MenuItem>
                        <MenuItem key={ChatMountEventTypes.REBOOT_REQUEST} value={ChatMountEventTypes.REBOOT_REQUEST}>
                            {t("Reboot request")}
                        </MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label={t("Device ID")}
                    value={deviceIdInput}
                    onChange={e => {
                        let filteredValue = e.target.value.replace(/[^0-9]/g, '');
                        setDeviceIdInput(filteredValue.substr(0, 12));
                    }}
                    style={{ marginRight: "12px", marginTop: "12px" }}
                />
                {
                    selectedType === ChatMountEventTypes.WRITE_SETTINGS_REQUEST ?
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: "12px"
                        }}>
                            <ReactFileReader handleFiles={handleDxsFileSelect} fileTypes={[".dxs"]}>
                                <Button variant="contained" color="primary">{t("Upload")}</Button>
                            </ReactFileReader>
                            <Typography style={{ paddingLeft: "12px" }}>{dxsFilename}<b>{getSizeComment(dxsData)}</b></Typography>
                        </div> : <></>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button onClick={() => { handleClose(); handleConfirm(selectedType, deviceIdInput, dxsData); }} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
