import React, { } from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Button,
    Typography
} from "@mui/material";
import {
    Edit as EditIcon,
    RemoveCircle as RemoveIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next"

import { formatDate } from "../../helpers/StringUtils"
import { ArticleStatuses } from "../../enums/Articles/ArticleStatuses";
import { ArticleTypes } from "../../enums/Articles/ArticleTypes";

import {
    Add as CreationTimeIcon,
    Create as UpdateTimeIcon,
    Assignment as PublishTimeIcon
} from "@mui/icons-material";

export default function ArticlesTable({ data, handleShowDescription, handleShowFullClick, handleRemoveClick }) {
    const { t } = useTranslation();

    var keys = ["#", t("Title"), t("Type"), t("Description"), t("Date"), t("Editor"), t("Status")];

    return (
        <Table size="small">
            <TableHead>
                <TableRow key="header">
                    {keys.map((key, i) => (
                        <TableCell key={i} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(article => (
                    <React.Fragment key={article.id}>
                        <TableRow>
                            <TableCell>{article.id}</TableCell>
                            <TableCell>{article.title}</TableCell>
                            <TableCell>{t(ArticleTypes[article.type])}</TableCell>
                            <TableCell style={{ minWidth: "32ch", maxWidth: "32ch", wordWrap: "break-word" }}>
                                {
                                    article.description === null || article.description === undefined || article.description.length < 100 ?
                                        article.description :
                                        <Button onClick={() => { handleShowDescription(article); }} variant="contained" color="primary">{t("Description")}</Button>
                                }
                            </TableCell>
                            <TableCell>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <CreationTimeIcon />
                                    {formatDate(article.creationDate)}
                                </div>
                                {
                                    article.lastUpdateDate ?
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <UpdateTimeIcon />
                                            {formatDate(article.lastUpdateDate)}
                                        </div> : <></>
                                }
                                {
                                    article.publishDate ?
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <PublishTimeIcon />
                                            {formatDate(article.publishDate)}
                                        </div> : <></>
                                }
                            </TableCell>
                            <TableCell>
                                <Typography>{article.userFio}<br />{article.userLogin}</Typography>
                            </TableCell>
                            <TableCell>{t(ArticleStatuses[article.status])}</TableCell>
                            <TableCell>
                                <IconButton
                                    color="primary"
                                    onClick={() => { handleShowFullClick(article); }} >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    color="secondary"
                                    onClick={() => { handleRemoveClick(article); }} >
                                    <RemoveIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>
    );
}
