import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    RemoveCircle as RemoveIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next"

export default function SettingsColorsTable({ data, handleEditName, handleEditColor, handleRemoveColor }) {
    const { t } = useTranslation();

    var keys = ["#", t("Name"), t("Color"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(color => (
                    <TableRow key={color.Id}>
                        <TableCell className={styles.cell}>{color.Id}</TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditName(color)}
                        >
                            {color.Name}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            style={{ backgroundColor: `rgba(${color.Red},${color.Green},${color.Blue},${color.Alpha / 255.0})` }}
                            onClick={() => handleEditColor(color)}
                        >
                            {`RGBA:${color.Red} ${color.Green} ${color.Blue} ${color.Alpha}`}
                        </TableCell>
                        <TableCell className={styles.cell} style={{ display: "flex", flexDirection: "row" }}>
                            <IconButton
                                color="secondary"
                                onClick={() => handleRemoveColor(color)}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
