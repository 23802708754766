import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";

import { Autocomplete } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";

let searchTimeout = null;

const filterOptions = (options, { inputValue }) => {
  return options;
};

export default function SelectUserDialog({
  isOpened,
  handleClose,
  handleConfirm,
}) {
  const { t } = useTranslation();
  const backend = useBackend();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleLoadUsergroup = (login) => {
    setOptions([]);
    setLoading(true);
    backend.bckUsergroupGetPossibleUsers(login).then((json) => {
      setLoading(false);
      if (json.code === 0) {
        setOptions(json.content.rows);
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleUserFieldChange = (e) => {
    let newValue = e.target.value.slice(0, 48);
    if (newValue.length >= 3) {
      window.clearTimeout(searchTimeout);
      searchTimeout = window.setTimeout(() => {
        handleLoadUsergroup(newValue);
      }, 1000);
    }
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("Add usergroup user")}
      </DialogTitle>
      <DialogContent>
        {error}
        <Autocomplete
          style={{ width: 300, paddingTop: 16 }}
          isOptionEqualToValue={(option, value) => option.login === value.login}
          getOptionLabel={(option) => option.login}
          onChange={(event, value) => setSelectedValue(value)}
          noOptionsText={t("No users")}
          loadingText={t("Loading")}
          options={options}
          loading={loading}
          filterOptions={filterOptions}
          renderOption={(props, option) => (
            <li {...props}>
              <div>
                <Typography variant="body1">{option.name}</Typography>
                <Typography variant="body2">{option.login}</Typography>
                <Typography variant="caption">{option.phone}</Typography>
              </div>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Search by login/phone")}
              variant="outlined"
              onChange={handleUserFieldChange}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Cancel")}
        </Button>
        <Button
          onClick={() => {
            handleClose();
            handleConfirm(selectedValue);
          }}
          color="primary"
          autoFocus
        >
          {t("Add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
