const OpenManualRequestStatuses = {
  1: "Editing",
  2: "Sent",
  3: "Review",
  4: "Approved",
  5: "Review failed",
  6: "Revision",
};
Object.freeze(OpenManualRequestStatuses);

export { OpenManualRequestStatuses };
