import React, { } from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Button,
    Typography
} from "@mui/material";
import {
    Map as MapIcon,
    Add as CreationTimeIcon,
    Create as UpdateTimeIcon,
    Visibility as ViewIcon,
    Info as InfoIcon,
    HeadsetMic as UsergroupIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next"

import { formatDate } from "../../helpers/StringUtils"

import { UserPermissions } from "../../enums/UserRoles";

import { OrderRequestStatuses, OrderRequestStatusesByName } from "../../enums/OrderRequestStatuses";
import AdvancedAvatarBadge from "../AdvancedAvatarBadge";

export default function OrderRequestsTable({ data, topics, userName, handleShowRequestAddress,
    handleShowDescription, handleAssignRequest, handleViewRequest,
    handleContinueRequest, handleShowUserInfo, handleUsergroupClick }) {
    const { t } = useTranslation();

    var keys = ["#", t("Status"), t("Time"), " ", t("Control"), t("User"), "  ", "", t("Topic"), t("Description")];

    var permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
    var hasWorkViewPrivileges = permissions.includes(UserPermissions.ORDER_REQUESTS_CLOSE_ANY);

    const getRowStyle = (request) => {
        var updateDate = new Date(request.updateTime);
        var currentDate = new Date();
        var timeSinceLastUpdate = (currentDate - updateDate) / 1000 | 0;
        var more2hours = timeSinceLastUpdate > 120 * 60;

        if (request.status === OrderRequestStatusesByName.Opened) {
            if (more2hours) {
                return { backgroundColor: '#ffa9a9' };
            } else {
                return { backgroundColor: '#a9a9ff' };
            }
        } else if (request.status === OrderRequestStatusesByName.Closed || request.status === OrderRequestStatusesByName["Closed by user"] || request.status === OrderRequestStatusesByName["Cancelled by user"]) {
            return { backgroundColor: '#a9ffa9' };
        } else if (request.status === OrderRequestStatusesByName.Delayed) {
            if (more2hours) {
                return { backgroundColor: '#ffa9a9' };
            } else {
                return { backgroundColor: '#ffb778' };
            }
        } else if (request.status === OrderRequestStatusesByName["In work"]) {
            return { backgroundColor: '#ffffa9' };
        }
        return {};
    };

    return (
        <Table size="small">
            <TableHead>
                <TableRow key="header">
                    {keys.map((key, i) => (
                        <TableCell key={i} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(request => (
                    <React.Fragment key={request.id}>
                        <TableRow style={getRowStyle(request)}>
                            <TableCell>{request.id}</TableCell>
                            <TableCell style={{ width: "12ch" }}>{t(OrderRequestStatuses[request.status])}</TableCell>
                            <TableCell>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <CreationTimeIcon />
                                    {formatDate(request.creationTime)}
                                </div>
                                {
                                    request.creationTime !== request.updateTime ?
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <UpdateTimeIcon />
                                            {formatDate(request.updateTime)}
                                        </div> : <></>
                                }
                            </TableCell>
                            <TableCell style={{ width: "64px" }}>
                                <IconButton disabled={request.status === OrderRequestStatusesByName["In work"] && !hasWorkViewPrivileges} onClick={() => handleViewRequest(request)}>
                                    <ViewIcon color={request.status === OrderRequestStatusesByName["In work"] && !hasWorkViewPrivileges ? "gray" : "primary"} />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                {
                                    request.status === OrderRequestStatusesByName["In work"] ?
                                        !(userName === null || request.handlerName === userName) ?
                                            <Typography>{request.handlerName}<br />{request.handlerMail}</Typography>
                                            :
                                            <Button variant="contained" color="primary"
                                                onClick={() => { handleContinueRequest(request) }}
                                            >
                                                {t("Continue")}
                                            </Button>
                                        :
                                        request.status === OrderRequestStatusesByName.Opened || request.status === OrderRequestStatusesByName.Delayed ?
                                            <Button variant="contained" color="primary"
                                                onClick={() => handleAssignRequest(request)}
                                            >
                                                {request.status === OrderRequestStatusesByName.Opened ? t("Start work") : t("Continue")}
                                            </Button>
                                            :
                                            <Typography>{request.handlerName}<br />{request.handlerMail}</Typography>
                                }
                            </TableCell>
                            <TableCell>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <AdvancedAvatarBadge
                                        avatarKey={0}
                                        isUserOnline={request?.isSenderOnline}
                                        userId={request?.senderId}
                                        userMail={request?.senderMail}
                                    />
                                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "12px" }}>
                                        <Typography variant="h6">{request.senderMail}</Typography>
                                        <Typography variant="body2" noWrap style={{ fontStyle: 'italic', textOverflow: 'ellipsis', maxWidth: "32ch" }}>{request.requesterName}</Typography>
                                        <Typography variant="body2" noWrap style={{ fontStyle: 'italic', textOverflow: 'ellipsis', maxWidth: "32ch" }}>{request.requesterPhone}</Typography>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    color="primary"
                                    onClick={() => { handleShowUserInfo(request); }} >
                                    <InfoIcon />
                                </IconButton>
                                <IconButton
                                    color="primary"
                                    disabled={!request.hasUsergroup}
                                    onClick={() => { handleUsergroupClick(request); }} >
                                    <UsergroupIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    disabled={request.coordinates === null || request.coordinates.trim() === "" || request.coordinates === "0.0 ; 0.0" || request.addressId === null}
                                    color="primary"
                                    onClick={() => { handleShowRequestAddress(request); }} >
                                    <MapIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                {topics[request.topicId]?.title}
                            </TableCell>
                            <TableCell style={{ minWidth: "32ch", maxWidth: "32ch", wordWrap: "break-word" }}>
                                {
                                    request.description === null || request.description === undefined || request.description.length < 100 ?
                                        request.description :
                                        <Button onClick={() => { handleShowDescription(request); }} variant="contained" color="primary">{t("Description")}</Button>
                                }
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>
    );
}
