import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  IconButton,
} from "@mui/material";

import Widget from "../components/Widget/Widget";
import { useInterval } from "../useInterval";
import { GenerateInteger } from "../helpers/RandomGenerator";
import { generateUserInfoString } from "../helpers/UserInfoStringGenerator";
import ClonesTable from "../components/Tables/ClonesTable";

import { useTranslation } from "react-i18next";

import { Base64ToHex } from "../helpers/StringUtils";
import UsergroupListDialog from "../components/Dialogs/UsergroupListDialog";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";

import { ContactSupport as CloneTipsIcon } from "@mui/icons-material";
import { UserPermissions } from "../enums/UserRoles";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryCheckPermission,
  settingRepositoryLoadBool,
  settingRepositoryLoadInt,
  settingRepositorySave,
} from "../settingRepository";
import { useNavigate } from "react-router";

export default function Clones() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalClones, setTotalClones] = useState(100);
  const [autoUpdateChecked, setAutoUpdateChecked] = useState(true);
  const [deviceId, setDeviceId] = useState("");
  const [deviceIdInput, setDeviceIdInput] = useState("");
  const [firmware, setFirmware] = useState("");
  const [firmwareInput, setFirmwareInput] = useState("");
  const [status, setStatus] = useState("");
  const [statusInput, setStatusInput] = useState("");
  const [userQuery, setUserQuery] = useState("");
  const [userQueryInput, setUsertQueryInput] = useState("");
  const [carModelQuery, setCarModelQuery] = useState("");
  const [carModelQueryInput, setCarModelQueryInput] = useState("");
  const [canQuery, setCanQuery] = useState("");
  const [canQueryInput, setCanQueryInput] = useState("");
  const [vinQuery, setVinQuery] = useState("");
  const [vinQueryInput, setVinQueryInput] = useState("");
  const [algorithmQuery, setAlgorithmQuery] = useState("");
  const [algorithmQueryInput, setAlgorithmQueryInput] = useState("");
  const [versionQuery, setVersionQuery] = useState("");
  const [versionQueryInput, setVersionQueryInput] = useState("");
  const [includeData, setIncludeData] = useState(false);
  const [isUsergroupListDialogOpened, setIsUsergroupListDialogOpened] =
    useState(false);
  const [usergroupListDialogList, setUsergroupListDialogList] = useState([]);
  const [cloneTipsAvailable, setCloneTipsAvailable] = useState(false);
  const [allowEditAttempts, setAllowEditAttempts] = useState(false);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const handleUsergroupListDialogClose = () => {
    setIsUsergroupListDialogOpened(false);
  };

  const handleShowDevicePackage = (deviceId) => {
    if (deviceId) {
      dialogDispatch({
        type: DialogActionTypes.FactoryPackageDialogOpen,
        deviceId: deviceId,
      });
    }
  };

  const openOutputDialog = (text) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleOutputDialogOpen,
      title: t("View event data"),
      text: text,
      textLabel: "",
      noTextField: true,
    });
  };

  const handleShowData = (clone) => {
    backend.bckClonesGetData(clone.id).then((json) => {
      if (json.code === 0) {
        let info = json.content;
        var text = `${t("Decrypted clone 4096")}: ${Base64ToHex(info.data4096)}
${t("Result wout zeroes")}: ${Base64ToHex(info.resultNoZeroes)}
${t("Decrypted clone 4128")}: ${Base64ToHex(info.data4128)}`;
        openOutputDialog(text);
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleShowUserInfo = (clone) => {
    backend.bckUsersGetInfo(clone.userId).then((json) => {
      if (json.code === 0) {
        let info = json.content;
        openOutputDialog(generateUserInfoString(t, info));
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const refreshTable = useCallback(
    (
      backend,
      deviceId,
      firmware,
      status,
      userQuery,
      carModelQuery,
      canQuery,
      vinQuery,
      algorithmQuery,
      versionQuery,
      includeData,
      pageNumber,
      pageSize,
      isInitializing
    ) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckClonesList(
          deviceId,
          firmware,
          status,
          userQuery,
          carModelQuery,
          canQuery,
          vinQuery,
          algorithmQuery,
          versionQuery,
          includeData,
          pageNumber + 1,
          pageSize
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.clones;
                } else {
                  return prev;
                }
              });
              setTotalClones((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.count;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }

          if (isInitializing) {
            setIsInitializing(false);
            setIsLoading(false);
          }
        });
    },
    [t]
  );

  useEffect(() => {
    var permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
    setAllowEditAttempts(permissions.includes(UserPermissions.CLONE_COUNT_SET));
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    if (isInitializing) {
      setAutoUpdateChecked(
        settingRepositoryLoadBool(SettingName.AUTO_UPDATE_CLONES, true)
      );

      let pageSize = settingRepositoryLoadInt(SettingName.CLONES_PAGE_SIZE, 10);
      setPageSize(pageSize);
      setCloneTipsAvailable(
        settingRepositoryCheckPermission(UserPermissions.MANAGE_CLONE_TIPS)
      );

      refreshTable(
        backend,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        0,
        pageSize,
        true
      );
    }
  }, [isInitializing, backend, refreshTable]);

  useInterval(() => {
    if (updatesLockCounter.current === 0 && autoUpdateChecked) {
      refreshTable(
        backend,
        deviceId,
        firmware,
        status,
        userQuery,
        carModelQuery,
        canQuery,
        vinQuery,
        algorithmQuery,
        versionQuery,
        includeData,
        pageNumber,
        pageSize,
        isInitializing
      );
    }
  }, 2000);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    refreshTable(
      backend,
      deviceId,
      firmware,
      status,
      userQuery,
      carModelQuery,
      canQuery,
      vinQuery,
      algorithmQuery,
      versionQuery,
      includeData,
      newPage,
      pageSize,
      isInitializing
    );
  };

  const handleChangeRowsPerPage = (event) => {
    var newPageSize = parseInt(event.target.value, 10);
    settingRepositorySave(SettingName.CLONES_PAGE_SIZE, newPageSize);
    setPageSize(newPageSize);
    setPageNumber(0);
    refreshTable(
      backend,
      deviceId,
      firmware,
      status,
      userQuery,
      carModelQuery,
      canQuery,
      vinQuery,
      algorithmQuery,
      versionQuery,
      includeData,
      0,
      newPageSize,
      isInitializing
    );
  };

  const resetSearchParams = () => {
    setDeviceId("");
    setDeviceIdInput("");
    setStatus("");
    setStatusInput("");
    setFirmware("");
    setFirmwareInput("");
    setUserQuery("");
    setUsertQueryInput("");
    setCarModelQuery("");
    setCarModelQueryInput("");
    setCanQuery("");
    setCanQueryInput("");
    setVinQuery("");
    setVinQueryInput("");
    setAlgorithmQuery("");
    setAlgorithmQueryInput("");
    setVersionQuery("");
    setVersionQueryInput("");
    setPageNumber(0);
  };

  const handleSearchPress = () => {
    var newDeviceId = "";
    if (deviceIdInput.trim() === "") {
      setDeviceId("");
    } else {
      newDeviceId = deviceIdInput;
      setDeviceId(deviceIdInput);
    }

    var newFirmware = "";
    if (firmwareInput.trim() === "") {
      setFirmware("");
    } else {
      newFirmware = firmwareInput;
      setFirmware(firmwareInput);
    }

    var newStatus = "";
    if (statusInput.trim() === "") {
      setStatus("");
    } else {
      newStatus = statusInput;
      setStatus(statusInput);
    }

    var newUserQuery = "";
    if (userQueryInput.trim() === "") {
      setUserQuery("");
    } else {
      newUserQuery = userQueryInput;
      setUserQuery(userQueryInput);
    }

    var newCarModelQuery = "";
    if (carModelQueryInput.trim() === "") {
      setCarModelQuery("");
    } else {
      newCarModelQuery = carModelQueryInput;
      setCarModelQuery(carModelQueryInput);
    }

    var newCanQuery = "";
    if (canQueryInput.trim() === "") {
      setCanQuery("");
    } else {
      newCanQuery = canQueryInput;
      setCanQuery(canQueryInput);
    }

    var newVinQuery = "";
    if (vinQueryInput.trim() === "") {
      setVinQuery("");
    } else {
      newVinQuery = vinQueryInput;
      setVinQuery(vinQueryInput);
    }

    var newAlgorithmQuery = "";
    if (algorithmQueryInput.trim() === "") {
      setAlgorithmQuery("");
    } else {
      newAlgorithmQuery = algorithmQueryInput;
      setAlgorithmQuery(algorithmQueryInput);
    }

    var newVersionQuery = "";
    if (versionQueryInput.trim() === "") {
      setVersionQuery("");
    } else {
      newVersionQuery = versionQueryInput;
      setVersionQuery(versionQueryInput);
    }

    setPageNumber(0);
    refreshTable(
      backend,
      newDeviceId,
      newFirmware,
      newStatus,
      newUserQuery,
      newCarModelQuery,
      newCanQuery,
      newVinQuery,
      newAlgorithmQuery,
      newVersionQuery,
      includeData,
      0,
      pageSize,
      isInitializing
    );
  };

  const handleSearchResetPress = () => {
    resetSearchParams();
    refreshTable(
      backend,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      includeData,
      0,
      pageSize,
      isInitializing
    );
  };

  const handleUsergroupClick = (clone) => {
    backend.bckUsersGetUsergroupOwners(clone.userId).then((json) => {
      if (json.code === 0) {
        setUsergroupListDialogList(json.content);
        setIsUsergroupListDialogOpened(true);
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleEditAttemptsClick = (clone) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      initialValue: clone.remainingAttempts?.toString(),
      textLabel: t("Value"),
      title: t("Value"),
      handleConfirm: (value) => {
        backend
          .bckClonesSetAttemptsCount(clone.deviceId, parseInt(value))
          .then((json) => {
            if (json.code === 0) {
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 2);
      },
    });
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Typography color="secondary">{error}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            noBodyPadding
            header={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "inherit",
                  width: "100%",
                }}
              >
                <Typography variant="h5" color="textSecondary">
                  {t("Clone table")}
                </Typography>
                {cloneTipsAvailable ? (
                  <IconButton
                    onClick={(e) => {
                      navigate("/app/cloneTips");
                    }}
                    color="primary"
                  >
                    <CloneTipsIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </div>
            }
          >
            <Grid container direction="column" style={{ paddingLeft: "24px" }}>
              <Grid item>
                <TextField
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                  label={t("Device ID")}
                  value={deviceIdInput}
                  onChange={(e) => setDeviceIdInput(e.target.value)}
                />
                <TextField
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                  label={t("Device")}
                  value={firmwareInput}
                  onChange={(e) => setFirmwareInput(e.target.value)}
                />
                <TextField
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                  label={t("User")}
                  value={userQueryInput}
                  onChange={(e) => setUsertQueryInput(e.target.value)}
                />
                <TextField
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                  label={t("Status")}
                  value={statusInput}
                  onChange={(e) => setStatusInput(e.target.value)}
                />
                <TextField
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                  label={t("Car model")}
                  value={carModelQueryInput}
                  onChange={(e) => setCarModelQueryInput(e.target.value)}
                />
                <TextField
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                  label="CAN"
                  value={canQueryInput}
                  onChange={(e) => setCanQueryInput(e.target.value)}
                />
                <TextField
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                  label="VIN"
                  value={vinQueryInput}
                  onChange={(e) => setVinQueryInput(e.target.value)}
                />
                <TextField
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                  label={t("Algorithm")}
                  value={algorithmQueryInput}
                  onChange={(e) => setAlgorithmQueryInput(e.target.value)}
                />
                <TextField
                  style={{ marginRight: "12px", marginBottom: "12px" }}
                  label={t("Application version")}
                  value={versionQueryInput}
                  onChange={(e) => setVersionQueryInput(e.target.value)}
                />
              </Grid>
              <Grid container item spacing={2}>
                <Grid item>
                  <Button
                    onClick={handleSearchPress}
                    variant="contained"
                    color="primary"
                  >
                    {t("Search")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleSearchResetPress}
                    disabled={
                      deviceIdInput.trim() === "" &&
                      firmwareInput.trim() === "" &&
                      statusInput.trim() === "" &&
                      userQueryInput.trim() === "" &&
                      carModelQueryInput.trim() === "" &&
                      canQueryInput.trim() === "" &&
                      vinQueryInput.trim() === "" &&
                      algorithmQueryInput.trim() === "" &&
                      versionQueryInput.trim() === ""
                    }
                    variant="contained"
                    color="primary"
                  >
                    {t("Reset parameters")}
                  </Button>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={autoUpdateChecked}
                        onChange={() => {
                          setAutoUpdateChecked(!autoUpdateChecked);
                          settingRepositorySave(
                            SettingName.AUTO_UPDATE_CLONES,
                            !autoUpdateChecked
                          );
                        }}
                        color="primary"
                      />
                    }
                    label={t("Auto update")}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeData}
                        onChange={() => {
                          setIncludeData(!includeData);
                        }}
                        color="primary"
                      />
                    }
                    label={t("Include data")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <AdvancedTablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              totalRows={totalClones}
              pageSize={pageSize}
              pageNumber={pageNumber}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              <ClonesTable
                data={tableData}
                includeData={includeData}
                allowEditAttempts={allowEditAttempts}
                handleEditAttemptsClick={handleEditAttemptsClick}
                handleShowData={handleShowData}
                handleShowDevicePackage={handleShowDevicePackage}
                handleShowUserInfo={handleShowUserInfo}
                handleUsergroupClick={handleUsergroupClick}
              />
            </AdvancedTablePagination>
          </Widget>
        </Grid>
      </Grid>

      <UsergroupListDialog
        handleClose={handleUsergroupListDialogClose}
        isOpened={isUsergroupListDialogOpened}
        list={usergroupListDialogList}
      />
    </>
  );
}
