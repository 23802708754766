const SettingName = {
    AUTHENTICATED: "authenticated",
    USER_ID: "userId",
    USER_NAME: "userName",
    USER_MAIL: "userMail",
    USER_PHONE: "userPhone",
    USER_COMPANY: "userCompany",
    USER_ADDRESS: "userAddress",
    ROLE: "role",
    PERMISSIONS: "permissions",
    USER_USERGROUP_VISIBILITY: "useUsergroupVisibility",
    AUTO_UPDATE_LOGS: "autoUpdateLogs",
    LOGS_PAGE_SIZE: "logsPageSize",
    USERGROUP_PAGE_SIZE: "usergroupPageSize",
    USERGROUP_PAGE: "usergroupPage",
    CHAT_ROOMS_PAGE_SIZE: "chatRoomsPageSize",
    CHAT_ROOMS_ANY_UNREAD: "chatRoomsAnyUnread",
    AUTO_UPDATE_CLONES: "autoUpdateClones",
    CLONES_PAGE_SIZE: "clonesPageSize",
    AUTO_UPDATE_CONFIGURATIONS: "autoUpdateConfigurations",
    CONFIGURATIONS_PAGE_SIZE: "configurationsPageSize",
    DEVICES_SHOW_ALL: "devicesShowAll",
    DEVICES_ONLY_WITH_SUBSYSTEMS: "devicesOnlyWithSubsystems",
    AUTO_UPDATE_MOUNTS: "autoUpdateMounts",
    MOUNTS_PAGE_SIZE: "mountsPageSize",
    AUTO_UPDATE_FEEDBACKS: "autoUpdateFeedbacks", 
    FEEDBACKS_PAGE_SIZE: "feedbacksPageSize",
    MAILINGS_PAGE_SIZE: "mailingsPageSize",
    VERSIONS_PAGE_SIZE: "versionsPageSize",
    USERS_PAGE_SIZE: "usersPageSize",
    USERS_PAGE: "usersPage",
    AUTO_UPDATE_ORDER_REQUESTS: "autoUpdateOrderRequests", 
    ORDER_REQUESTS_PAGE_SIZE: "orderRequestsPageSize",
    PUSHES_PAGE_SIZE: "pushesPageSize",
    SUBSYSTEMS_SHOW_ALL: "subsystemsShowAll",
    SUBSYSTEMS_ONLY_WITH_FIRMWARES: "subsystemsOnlyWithFirmwares",
    SUBSYSTEMS_SORT_BY_FIRMWARE_DATE: "subsystemsSortByFirmwareDate",
    AUTO_UPDATE_TECHSUPPORT_REQUESTS: "autoUpdateTechsupportRequests",
    TECHSUPPORT_REQUESTS_PAGE_SIZE: "techsupportRequestsPageSize",
    CAR_BRANDS_ONLY_WITH_MODELS: "carBrandsOnlyWithModels",
    CAR_MODELS_ONLY_WITH_EQUIPMENTS: "carModelsOnlyWithEquipments"
};
Object.freeze(SettingName);

function settingRepositorySave(name, value) {
    if (value === null) {
        localStorage.removeItem(name);
    } else {
        localStorage.setItem(name, value);
    }
}

function settingRepositoryLoad(name, defaultValue) {
    let loaded = localStorage.getItem(name);
    if (loaded === null) {
        settingRepositorySave(name, defaultValue);
        return defaultValue;
    }
    return loaded;
}   

function settingRepositoryLoadInt(name, defaultValue) {
    let loaded = settingRepositoryLoad(name, defaultValue);
    return parseInt(loaded);
}

function settingRepositoryLoadBool(name, defaultValue) {
    let loaded = settingRepositoryLoad(name, defaultValue);
    return loaded === 'true' || loaded === true;
}   

function settingRepositoryLoadPermissions() {
    let permissions = JSON.parse(localStorage.getItem("permissions") || "[]") || [];
    return permissions;
}

function settingRepositoryCheckPermission(permission) {
    let permissions = settingRepositoryLoadPermissions();
    return permissions.includes(permission);
}

export { settingRepositorySave, settingRepositoryLoad, settingRepositoryLoadInt, settingRepositoryLoadBool, settingRepositoryLoadPermissions, settingRepositoryCheckPermission, SettingName };