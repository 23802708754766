import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import {
    ListItem,
    Typography,
    IconButton
} from "@mui/material";

import {
    RemoveCircle as RemoveIcon,
    Height as MoveIcon
} from "@mui/icons-material";

import { ArticleElementTypes, ArticleElementTypesByName } from "../../enums/Articles/ArticleElementTypes";

import { useTranslation } from "react-i18next";

const style = {
    cursor: 'move',
}

export const ItemTypes = {
    CARD: 'card',
}

export default function ElementCard({ id, index, element, selectedElement, onClick, handleRemoveElement, moveCard }) {
    const { t } = useTranslation();
    const ref = useRef(null)

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    const getElementLabel = (element) => {
        let label = "";
        switch (element.type) {
            case ArticleElementTypesByName.BUTTON:
            case ArticleElementTypesByName.TEXT:
                label = element.text;
                break;
            case ArticleElementTypesByName.LINK:
            case ArticleElementTypesByName.IMAGE:
                label = element.url;
                break;
            case ArticleElementTypesByName.VIDEO:
                label = element.alternativeUrl;
                break;
            default:
                label = "";
                break;
        }
        return label;
    }

    return (
        <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
            <ListItem
                button
                style={element === selectedElement ? { background: "#6cb5f9" } : { }}
                onClick={onClick}
            >
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <MoveIcon />
                    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                        <Typography style={element === selectedElement ? { fontWeight: "bold" } : {}}>{`${t(ArticleElementTypes[element.type])}`}</Typography>
                        <Typography variant="caption" inline
                            style={{ fontStyle: "italic", maxWidth: "16ch", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                        >
                            {getElementLabel(element)}
                        </Typography>
                    </div>
                    <IconButton
                        color="secondary"
                        onClick={(e) => { handleRemoveElement(element); e.stopPropagation(); }}
                    >
                        <RemoveIcon />
                    </IconButton>
                </div>
            </ListItem>
        </div>
    )
}
