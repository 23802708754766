const LanguageConverter = {
    ru: 1,
    en: 2,
    de: 3
};

const LanguageConverterInverse = {
    1: "ru",
    2: "en",
    3: "de"
};

export { LanguageConverter, LanguageConverterInverse };