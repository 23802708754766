import React, { useState, useEffect } from "react";

import { Grid, Typography, Paper, CircularProgress } from "@mui/material";

import UpdateChannelsTable from "../components/Tables/UpdateChannelsTable";

import { useTranslation } from "react-i18next";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { LanguageConverter } from "../enums/LanguageConverter";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import { useNavigate } from "react-router";

export default function ManageUpdateChannels() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [updateChannels, setUpdateChannels] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    backend
      .bckUpdateChannelList(true, LanguageConverter[i18n.language])
      .then((json) => {
        setIsLoading(false);
        if (json.code === 0) {
          setUpdateChannels(json.content);
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
    return () => {};
  }, [t, i18n.language]);

  const handleShowUsersClick = (row) => {
    if (row.accessLevel === 2) {
      dialogDispatch({
        type: DialogActionTypes.SimpleOutputDialogOpen,
        title: "",
        text: t("This channel is public and available to everyone"),
        textLabel: "",
        noTextField: true,
      });
    } else {
      navigate(`/app/manageUpdateChannelUsers?channelId=${row.id}`);
    }
  };

  const handleRequirementsClick = (row) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      initialValue: row.requirements,
      textLabel: t("Requirements"),
      title: t("Requirements"),
      lines: 5,
      handleConfirm: (requirements) => {
        backend
          .bckUpdateChannelEditRequirements(
            row.id,
            requirements,
            LanguageConverter[i18n.language]
          )
          .then((json) => {
            if (json.code === 0) {
              setUpdateChannels((prev) => {
                let next = [...prev];
                let target = next.find((r) => r.id === row.id);
                target.requirements = requirements;
                return next;
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("Manage update channels")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          <UpdateChannelsTable
            data={updateChannels}
            handleShowUsersClick={handleShowUsersClick}
            handleRequirementsClick={handleRequirementsClick}
          />
        </Grid>
      </Paper>
    </>
  );
}
