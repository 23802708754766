import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Grid,
  Typography,
  Button,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import SubsystemsTable from "../components/Tables/SubsystemsTable";

import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoadBool,
  settingRepositorySave,
} from "../settingRepository";
import { useNavigate } from "react-router";

export default function Subsystems({
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  subsystemName,
  setSubsystemName,
  onShowSubsystemFull,
}) {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [subsystemNameInput, setSubsystemNameInput] = useState("");
  const [showAll, setShowAll] = useState(null);
  const [onlyWithFirmwares, setOnlyWithFirmwares] = useState(null);
  const [sortByFirmwareDate, setSortByFirmwareDate] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(100);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshTable = useCallback(
    (
      subsystemName,
      onlyWithFirmwares,
      sortByFirmwareDate,
      pageNumber,
      pageSize
    ) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckDevicesListSubsystems(
          subsystemName,
          onlyWithFirmwares,
          sortByFirmwareDate,
          pageNumber + 1,
          pageSize
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTotalRows(json.content.count);
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.list;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }
        });
    },
    [t, backend]
  );

  useEffect(() => {
    setSubsystemNameInput(subsystemName);
    setShowAll(
      settingRepositoryLoadBool(SettingName.SUBSYSTEMS_SHOW_ALL, false)
    );
    setOnlyWithFirmwares(
      settingRepositoryLoadBool(
        SettingName.SUBSYSTEMS_ONLY_WITH_FIRMWARES,
        false
      )
    );
    setSortByFirmwareDate(
      settingRepositoryLoadBool(
        SettingName.SUBSYSTEMS_SORT_BY_FIRMWARE_DATE,
        false
      )
    );
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  const handleShowFullInfo = (subsystem) => {
    if (onShowSubsystemFull) {
      onShowSubsystemFull(subsystem);
    } else {
      navigate(`/app/subsystemFull?id=${subsystem.id}`);
    }
  };

  const handleCreateSubsystemOpen = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (title) => {
        ++updatesLockCounter.current;

        let requestId = GenerateInteger();
        lastRequestId.current = requestId;
        backend
          .bckDevicesCreateOrUpdateSubsystem(
            null,
            null,
            2,
            title,
            null,
            null,
            null,
            null,
            null
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let subsystemId = json.content;
              let newElement = {
                id: subsystemId,
                title: title,
              };
              setTableData((prev) => {
                let newArr = [newElement, ...prev];
                return newArr;
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: "",
      textLabel: t("Title"),
      title: t("Add subsystem"),
    });
  };

  const handleRemoveSubsystem = (subsystem) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove subsystem?", { title: subsystem.title }),
      handleConfirm: () => {
        ++updatesLockCounter.current;
        backend.bckDevicesRemoveSubsystem(subsystem.id).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
            setTableData((prev) => {
              let foundIndex = prev.findIndex((x) => x.id === subsystem.id);
              if (foundIndex !== -1) {
                prev.splice(foundIndex, 1);
              }
              return [...prev];
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const handleCreationTimeClick = (subsystem) => {
    dialogDispatch({
      type: DialogActionTypes.DateTimeDialogOpen,
      handleConfirm: (dateTime) => {
        ++updatesLockCounter.current;
        backend
          .bckDevicesCreateOrUpdateSubsystem(
            subsystem.id,
            subsystem.name,
            subsystem.type,
            subsystem.title,
            null,
            null,
            null,
            null,
            dateTime
          )
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
              let subsystemId = subsystem.id;
              setTableData((prev) => {
                var foundIndex = prev.findIndex((x) => x.id === subsystemId);
                if (foundIndex !== -1) {
                  prev[foundIndex].creationTime = dateTime?.toJSON();
                }
                return [...prev];
              });
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: Date.parse(subsystem.creationTime),
      title: t("Creation time"),
    });
  };

  useEffect(() => {
    if (
      showAll != null &&
      onlyWithFirmwares != null &&
      sortByFirmwareDate != null
    ) {
      refreshTable(
        subsystemName,
        onlyWithFirmwares,
        sortByFirmwareDate,
        showAll ? 0 : pageNumber,
        showAll ? 9999 : pageSize
      );
    }
  }, [
    pageNumber,
    subsystemName,
    showAll,
    onlyWithFirmwares,
    sortByFirmwareDate,
  ]);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    var newRowsPerPage = parseInt(event.target.value, 10);
    setPageSize(newRowsPerPage);
    setPageNumber(0);
  };

  const renderTable = () => {
    return (
      <SubsystemsTable
        data={tableData}
        handleRemoveSubsystem={handleRemoveSubsystem}
        handleShowFullInfo={handleShowFullInfo}
        handleCreationTimeClick={handleCreationTimeClick}
      />
    );
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="h5" color="textSecondary">
              {t("Subsystems table")}
            </Typography>
            <div>
              <Button
                onClick={(e) => {
                  navigate("/app/devicesAliases");
                }}
                color="primary"
                variant="contained"
              >
                {t("Alarm aliases table")}
              </Button>
            </div>
          </div>
          <Typography color="secondary">{error}</Typography>
          <Grid item>
            <TextField
              label={t("Subsystem")}
              value={subsystemNameInput}
              onChange={(e) => setSubsystemNameInput(e.target.value)}
              style={{ marginRight: "12px" }}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Button
                onClick={(e) => {
                  setSubsystemName(subsystemNameInput);
                  setPageNumber(0);
                }}
                variant="contained"
                color="primary"
              >
                {t("Search")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleCreateSubsystemOpen}
                variant="contained"
                color="primary"
              >
                {t("Add device")}
              </Button>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAll ?? false}
                    onClick={() => {
                      setShowAll(!showAll);
                      settingRepositorySave(
                        SettingName.SUBSYSTEMS_SHOW_ALL,
                        !showAll
                      );
                    }}
                    color="primary"
                  />
                }
                label={t("Show all")}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onlyWithFirmwares ?? false}
                    onClick={() => {
                      let newValue = !onlyWithFirmwares;
                      setOnlyWithFirmwares(newValue);
                      settingRepositorySave(
                        SettingName.SUBSYSTEMS_ONLY_WITH_FIRMWARES,
                        newValue
                      );
                    }}
                    color="primary"
                  />
                }
                label={t("Only with firmwares")}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortByFirmwareDate ?? false}
                    onClick={() => {
                      let newValue = !sortByFirmwareDate;
                      setSortByFirmwareDate(newValue);
                      settingRepositorySave(
                        SettingName.SUBSYSTEMS_SORT_BY_FIRMWARE_DATE,
                        newValue
                      );
                    }}
                    color="primary"
                  />
                }
                label={t("Sort by firmware date")}
              />
            </Grid>
          </Grid>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            {showAll ? (
              renderTable()
            ) : (
              <AdvancedTablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                totalRows={totalRows}
                pageSize={pageSize}
                pageNumber={pageNumber}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              >
                {renderTable()}
              </AdvancedTablePagination>
            )}
          </div>
        </Grid>
      </Paper>
    </>
  );
}
