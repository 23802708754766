import React from "react";

import { Grid, Button } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import {
  DialogActionTypes,
  useDialogContext,
} from "../../context/DialogContext";
import SettingsAlarmMenusTable from "../../components/Tables/SettingsAlarmMenusTable";

export default function AlarmMenus({ data, setData, setError }) {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t } = useTranslation();

  const saveAlarmMenu = (newAlarmMenu) => {
    backend.bckSettingsCreateOrUpdateAlarmMenu(newAlarmMenu).then((json) => {
      if (json.code === 0) {
        newAlarmMenu.Id = json.content;
        setData((prev) => {
          let newAlarmMenus = { ...prev.AlarmMenus };
          newAlarmMenus[newAlarmMenu.Id] = newAlarmMenu;
          let next = { ...prev, AlarmMenus: newAlarmMenus };
          return next;
        });
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleAddAlarmMenu = () => {
    saveAlarmMenu({
      AlarmId: Object.values(data.Alarms)[0].Id,
      RootMenuId: Object.values(data.Menus)[0].Id,
      RootStructureId: Object.values(data.Structures)[0].Id,
      ChannelsId: null,
    });
  };

  const handleRemoveAlarmMenu = (alarmMenu) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove alarm menu?", { id: alarmMenu.Id }),
      handleConfirm: () => {
        backend.bckSettingsRemoveAlarmMenu(alarmMenu.Id).then((json) => {
          if (json.code === 0) {
            setData((prev) => {
              let newAlarmMenus = { ...prev.AlarmMenus };
              delete newAlarmMenus[alarmMenu.Id];
              let next = { ...prev, AlarmMenus: newAlarmMenus };
              return next;
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const updateAlarmMenu = (alarmMenu, callback) => {
    let newAlarmMenu = { ...alarmMenu };
    callback(newAlarmMenu);
    saveAlarmMenu(newAlarmMenu);
  };

  const handleEditAlarm = (alarmMenu) => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: data.Alarms[alarmMenu.AlarmId],
      label: t("Alarm"),
      title: t("Alarm"),
      options: Object.values(data.Alarms),
      getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
      multiple: false,
      handleConfirm: (value) => {
        updateAlarmMenu(alarmMenu, (newAlarmMenu) => {
          newAlarmMenu.AlarmId = value ? value.Id : null;
        });
      },
    });
  };

  const handleEditChannels = (alarmMenu) => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: alarmMenu.ChannelsId
        ? data.Channels[alarmMenu.ChannelsId]
        : Object.values(data.Channels)[0],
      label: t("Channels"),
      title: t("Channels"),
      options: Object.values(data.Channels),
      getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
      multiple: false,
      handleConfirm: (value) => {
        updateAlarmMenu(alarmMenu, (newAlarmMenu) => {
          newAlarmMenu.ChannelsId = value ? value.Id : null;
        });
      },
    });
  };

  const handleEditRootMenu = (alarmMenu) => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: data.Menus[alarmMenu.RootMenuId],
      label: t("Menu"),
      title: t("Menu"),
      options: Object.values(data.Menus),
      getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
      multiple: false,
      handleConfirm: (value) => {
        updateAlarmMenu(alarmMenu, (newAlarmMenu) => {
          newAlarmMenu.RootMenuId = value ? value.Id : null;
        });
      },
    });
  };

  const handleEditRootStructure = (alarmMenu) => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: data.Structures[alarmMenu.RootStructureId],
      label: t("Structure"),
      title: t("Structure"),
      options: Object.values(data.Structures),
      getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
      multiple: false,
      handleConfirm: (value) => {
        updateAlarmMenu(alarmMenu, (newAlarmMenu) => {
          newAlarmMenu.RootStructureId = value ? value.Id : null;
        });
      },
    });
  };

  return (
    <>
      <Grid item>
        <Button
          onClick={handleAddAlarmMenu}
          variant="contained"
          color="primary"
        >
          {t("Add alarm menu")}
        </Button>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "70vh",
          marginTop: "16px",
        }}
      >
        <SettingsAlarmMenusTable
          settingInfo={data}
          data={Object.values(data.AlarmMenus)}
          handleEditAlarm={handleEditAlarm}
          handleEditChannels={handleEditChannels}
          handleEditRootMenu={handleEditRootMenu}
          handleEditRootStructure={handleEditRootStructure}
          handleRemoveAlarmMenu={handleRemoveAlarmMenu}
        />
      </div>
    </>
  );
}
