const DigitalBuses = {
    1: "CAN1",
    2: "CAN2",
    3: "CAN3",
    4: "LIN",
    5: "CAN4",
    6: "CAN1-FD",
    7: "CAN2-FD",
    8: "CAN3-FD",
    9: "CAN4-FD",
    10: "LIN (IN-OUT)",
    11: "BEAN",
    12: "IMO-KEY1",
    13: "IMO-KEY2"
};
Object.freeze(DigitalBuses);

const DigitalBusesByName = {
    "CAN1": 1,
    "CAN2": 2,
    "CAN3": 3,
    "LIN": 4,
    "CAN4": 5,
    "CAN1-FD": 6,
    "CAN2-FD": 7,
    "CAN3-FD": 8,
    "CAN4-FD": 9,
    "LIN (IN-OUT)": 10,
    "BEAN": 11,
    "IMO-KEY1": 12,
    "IMO-KEY2": 13
};
Object.freeze(DigitalBusesByName);

export { DigitalBuses, DigitalBusesByName }