import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  Button,
  MenuItem,
  TextField,
  Autocomplete,
} from "@mui/material";

import ArticlesTable from "../components/Tables/ArticlesTable";
import AdvancedTablePagination from "../components/AdvancedTablePagination";

import { useTranslation } from "react-i18next";
import { useBackend } from "../context/BackendContext";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import {
  ArticleTypes,
  ArticleTypesByName,
} from "../enums/Articles/ArticleTypes";
import { ArticleStatuses } from "../enums/Articles/ArticleStatuses";
import { ResponseCode } from "../enums/ResponseCode";
import { useNavigate } from "react-router";

export default function Articles() {
  const defaultSearchParams = {
    title: null,
    type: null,
    status: null,
    pageNumber: 0,
    pageSize: 10,
  };
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [titleInput, setTitleInput] = useState("");
  const [typeInput, setTypeInput] = useState([]);
  const [statusInput, setStatusInput] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(100);

  const refreshTable = useCallback(
    (searchParams) => {
      backend
        .bckArticlesList(
          searchParams.title,
          searchParams.type,
          searchParams.status,
          null,
          searchParams.pageNumber + 1,
          searchParams.pageSize
        )
        .then((json) => {
          if (json.code === 0 && json.content) {
            setTableData(json.content.list);
            setTotalRows(json.content.count);
          } else if (json.code === ResponseCode.ACCESS_DENIED) {
            setError(t("AUTH_ERROR"));
          } else {
            setError(t("REQUEST_ERROR"));
          }

          setIsLoading(false);
        });
    },
    [t, backend]
  );

  useEffect(() => {
    refreshTable(searchParams);
  }, [searchParams]);

  const handleChangePage = (event, newPage) => {
    setSearchParams((prev) => ({ ...prev, pageNumber: newPage }));
  };

  const handleChangeRowsPerPage = (event, newPageSize) => {
    setSearchParams((prev) => ({
      ...prev,
      pageNumber: 0,
      pageSize: newPageSize,
    }));
  };

  const handleShowFullClick = (article) => {
    navigate(`/app/articles/full?id=${article.id}`);
  };

  const handleShowDescription = (article) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleOutputDialogOpen,
      text: article.description,
      textLabel: "",
      title: t("Description"),
      noTextField: true,
    });
  };

  const handleAddArticle = () => {
    const articleTypeMenuItems = Object.keys(ArticleTypes).map((i) => (
      <MenuItem key={i} value={i}>
        {t(ArticleTypes[i])}
      </MenuItem>
    ));

    dialogDispatch({
      type: DialogActionTypes.SimpleSelectDialogOpen,
      initialValue: ArticleTypesByName.News,
      label: t("Type"),
      title: t("Type"),
      menuItems: articleTypeMenuItems,
      handleConfirm: (value) => {
        backend
          .bckArticlesCreateOrUpdate({ id: null, type: value })
          .then((json) => {
            if (json.content) {
              setTableData((prev) => {
                let next = [json.content, ...prev];
                return next;
              });
            }
          });
      },
    });
  };

  const handleRemoveArticle = (article) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete article?"),
      handleConfirm: () => {
        backend.bckArticlesRemove(article.id).then((json) => {
          if (json.content) {
            setTableData((prev) => {
              let next = [...prev];
              let index = next.indexOf(article);
              if (index !== -1) {
                next.splice(index, 1);
              }
              return next;
            });
          }
        });
      },
    });
  };

  const handleSearchPress = () => {
    setSearchParams({
      ...searchParams,
      pageNumber: 0,
      title: titleInput,
      type: typeInput,
      status: statusInput,
    });
  };

  const handleSearchResetPress = () => {
    setTitleInput("");
    setTypeInput([]);
    setStatusInput([]);
    setSearchParams(defaultSearchParams);
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Typography color="secondary">{error}</Typography>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h5" color="textSecondary">
            {t("Articles")}
          </Typography>
          <Button
            onClick={handleAddArticle}
            variant="contained"
            color="primary"
          >
            {t("Add article")}
          </Button>
        </div>
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ paddingTop: "12px" }}
        >
          <Grid container item spacing={2} alignContent="center">
            <Grid item>
              <TextField
                label={t("Title")}
                value={titleInput}
                onChange={(e) => setTitleInput(e.target.value)}
                style={{ marginRight: "12px" }}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                multiple
                size="small"
                value={typeInput}
                options={Object.keys(ArticleTypes)}
                onChange={(...params) => {
                  setTypeInput(params[1]);
                }}
                getOptionLabel={(option) => t(ArticleTypes[option])}
                renderInput={(params) => (
                  <TextField {...params} label={t("Type")} />
                )}
                style={{ minWidth: "32ch" }}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                multiple
                size="small"
                value={statusInput}
                options={Object.keys(ArticleStatuses)}
                onChange={(...params) => {
                  setStatusInput(params[1]);
                }}
                getOptionLabel={(option) => t(ArticleStatuses[option])}
                renderInput={(params) => (
                  <TextField {...params} label={t("Status")} />
                )}
                style={{ minWidth: "32ch" }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item>
              <Button
                onClick={handleSearchPress}
                variant="contained"
                color="primary"
              >
                {t("Search")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSearchResetPress}
                variant="contained"
                color="primary"
              >
                {t("Reset parameters")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <AdvancedTablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          totalRows={totalRows}
          pageSize={searchParams.pageSize}
          pageNumber={searchParams.pageNumber}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <ArticlesTable
            data={tableData}
            handleShowDescription={handleShowDescription}
            handleShowFullClick={handleShowFullClick}
            handleRemoveClick={handleRemoveArticle}
          />
        </AdvancedTablePagination>
      </Paper>
    </>
  );
}
