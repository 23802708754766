import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    RemoveCircle as RemoveIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next"

export default function SettingsWireColorsTable({ data, settingInfo, handleEditName, handleEditColor1, handleEditColor2, handleEditColor3, handleRemoveWireColor }) {
    const { t } = useTranslation();

    var keys = ["#", t("Name"), t("Color") + " 1", t("Color") + " 2", t("Color") + " 3", ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(wireColor => (
                    <TableRow key={wireColor.Id}>
                        <TableCell className={styles.cell}>{wireColor.Id}</TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditName(wireColor)}
                        >
                            {wireColor.Name}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditColor1(wireColor)}
                        >
                            {wireColor.ColorId1 && settingInfo ? `${settingInfo.Colors[wireColor.ColorId1].Name} (${wireColor.ColorId1})` : ""}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditColor2(wireColor)}
                        >
                            {wireColor.ColorId2 && settingInfo ? `${settingInfo.Colors[wireColor.ColorId2].Name} (${wireColor.ColorId2})` : ""}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditColor3(wireColor)}
                        >
                            {wireColor.ColorId3 && settingInfo ? `${settingInfo.Colors[wireColor.ColorId3].Name} (${wireColor.ColorId3})` : ""}
                        </TableCell>
                        <TableCell className={styles.cell} style={{ display: "flex", flexDirection: "row" }}>
                            <IconButton
                                color="secondary"
                                onClick={() => handleRemoveWireColor(wireColor)}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
