let cachedSettingsInfo = null;

const getSettingsInfo = (backend, noCache) => {
    if (cachedSettingsInfo && !noCache) {
        return Promise.resolve(cachedSettingsInfo);
    } else {
        let result = Promise.resolve(backend.bckSettingsGenerateSettingsEditorInfo(noCache).then(json => {
            if (json.code === 0) {
                let parsed = JSON.parse(json.content);
                parsed.AlarmMenus = parsed.AlarmMenus.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
                parsed.Alarms = parsed.Alarms.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
                parsed.Menus = parsed.Menus.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
                parsed.Structures = parsed.Structures.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
                parsed.Logics = parsed.Logics.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
                parsed.Channels = parsed.Channels.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
                parsed.Wires = parsed.Wires.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
                parsed.WireColors = parsed.WireColors.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
                parsed.Colors = parsed.Colors.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
                parsed.Translations.en = parsed.Translations.en.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
                parsed.Translations.ru = parsed.Translations.ru.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
                if (!noCache) {
                    cachedSettingsInfo = parsed;
                }
                return parsed;
            } else {
                return null;
            }
        }));
        return result;
    }
}

export { getSettingsInfo };