import React from "react";

import { IconButton, Paper } from "@mui/material";

import {
  Delete as DeleteIcon,
  Publish as UploadIcon,
  NavigateBefore as MoveLeftIcon,
  NavigateNext as MoveRightIcon,
} from "@mui/icons-material";

import ReactFileReader from "react-file-reader";

import ImageUpload from "../../images/ImageUpload.jpg";

export default function CarMountingPointGuideEditorImage({
  image,
  handleMoveLeft,
  handleMoveRight,
  handleDelete,
  handleUpload,
}) {
  return (
    <Paper style={{ padding: "16px", width: "192px" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ width: "100%", maxHeight: "100%" }}>
          {image.name ? (
            <img
              src={
                image.url
                  ? image.url
                  : `https://specialist.alarmtrade.ru/uploads/cars/mounting_point_guides/${image.name}`
              }
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              alt=""
            />
          ) : (
            <img
              src={ImageUpload}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              alt=""
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            style={{ color: "red" }}
            onClick={() => handleDelete(image)}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
          <ReactFileReader
            handleFiles={(files) => handleUpload(image, files[0])}
            fileTypes={["image/png", "image/jpeg"]}
          >
            <IconButton color="primary" size="small">
              <UploadIcon />
            </IconButton>
          </ReactFileReader>
          <IconButton
            color="primary"
            disabled={!handleMoveLeft}
            onClick={() => handleMoveLeft(image)}
            size="small"
          >
            <MoveLeftIcon />
          </IconButton>
          {"#" + image.order}
          <IconButton
            color="primary"
            disabled={!handleMoveRight}
            onClick={() => handleMoveRight(image)}
            size="small"
          >
            <MoveRightIcon />
          </IconButton>
        </div>
      </div>
    </Paper>
  );
}
