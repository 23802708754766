const ArticleElementTextStyle = {
    1: "NORMAL",
    2: "BOLD",
    3: "ITALIC"
};
Object.freeze(ArticleElementTextStyle);

const ArticleElementTextStyleByName = {
    "NORMAL": 1,
    "BOLD": 2,
    "ITALIC": 3
};
Object.freeze(ArticleElementTextStyleByName);

export {
    ArticleElementTextStyle,
    ArticleElementTextStyleByName
}