import React from "react";

import {
    Typography
} from "@mui/material";

import { formatDate } from "../helpers/StringUtils"

export default function FirmwareList({ list }) {
    return (
        <>
            {
                list ? list.map(firmware => <Typography key={`${firmware.type}-${firmware.name}`} variant="body2">
                    {firmware.type}: {firmware.name} ({formatDate(firmware.factoryDate)})
                </Typography>) : <></>
            }
        </>
    );
}
