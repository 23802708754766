import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
} from "@mui/material";

import SettingsEditor from "../components/SettingsEditor/SettingsEditor";

import ReactFileReader from "react-file-reader";

import { useTranslation } from "react-i18next";
import { hexToBytes } from "../helpers/StringUtils";
import { getSettingsInfo } from "../components/SettingsEditor/SettingsInfoCache";
import { useBackend } from "../context/BackendContext";

export default function SettingsEditorPage() {
  const { t } = useTranslation();
  const backend = useBackend();
  const [chosenAlarm, setChosenAlarm] = useState(-1);
  const [dxsData, setDxsData] = useState("");
  const [dxsFile, setDxsFile] = useState("");
  const [settingsInfo, setSettingsInfo] = useState(null);
  const [dxsFilename, setDxsFilename] = useState(t("DXS file not loaded"));

  const handleDxsFileSelect = (files) => {
    if (files[0].size > 32 * 1024) {
      alert(t("File is too large"));
      return;
    }

    var reader = new FileReader();
    reader.onloadend = function (e) {
      try {
        var dxsJson = JSON.parse(reader.result);
        if (!dxsJson.hasOwnProperty("settings")) {
          alert(t("Unknown DXS file format"));
          return;
        }
        setDxsFilename(files[0].name);
        setDxsFile(dxsJson);
        try {
          setDxsData(hexToBytes(dxsJson.settings));
        } catch {
          alert("Hex to byte parsing error");
          setDxsFilename(t("DXS file not loaded"));
        }
      } catch {
        alert(t("Unknown DXS file format/encoding"));
      }
    };
    reader.readAsText(files[0], "UTF-16");
  };

  useEffect(() => {
    getSettingsInfo(backend, false).then((result) => setSettingsInfo(result));
  }, [backend]);

  const onChooseType = (e) => {
    setChosenAlarm(e.target.value);
  };

  return (
    <>
      <Grid
        container
        item
        spacing={2}
        direction="column"
        style={{ marginBottom: "12px" }}
      >
        <Grid item>
          <Grid container direction="row" alignItems="baseline">
            <FormControl style={{ marginRight: "12px", minWidth: "25ch" }}>
              <InputLabel>{t("Alarm")}</InputLabel>
              <Select
                label={t("Alarm")}
                value={chosenAlarm}
                onChange={onChooseType}
              >
                <MenuItem key={-1} value={-1}>
                  <em>{t("No")}</em>
                </MenuItem>
                {settingsInfo?.AlarmMenus ? (
                  Object.values(settingsInfo?.AlarmMenus)
                    .sort((am1, am2) =>
                      settingsInfo.Alarms[am1.AlarmId]?.Name >
                      settingsInfo.Alarms[am2.AlarmId]?.Name
                        ? 1
                        : -1
                    )
                    .map((am, i) => (
                      <MenuItem key={am.AlarmId} value={am.AlarmId}>{`${
                        settingsInfo.Alarms[am.AlarmId]?.Name
                      } (${settingsInfo.Alarms[am.AlarmId]?.Title})`}</MenuItem>
                    ))
                ) : (
                  <MenuItem />
                )}
              </Select>
            </FormControl>
            <ReactFileReader
              handleFiles={handleDxsFileSelect}
              fileTypes={[".dxs"]}
            >
              <Button variant="contained" color="primary">
                {t("Upload")}
              </Button>
            </ReactFileReader>
            <Typography style={{ paddingLeft: "12px" }}>
              {dxsFilename}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {chosenAlarm === -1 ||
      dxsData === null ||
      dxsData === undefined ||
      dxsData.length === 0 ? (
        <></>
      ) : (
        <Paper
          style={{
            overflow: "hidden",
            height: "80vh",
            width: "100%",
          }}
        >
          <SettingsEditor
            chosenAlarm={chosenAlarm}
            settingsData={dxsFile}
            data={dxsData}
          />
        </Paper>
      )}
    </>
  );
}
