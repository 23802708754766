import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Typography,
    Avatar,
    IconButton
} from "@mui/material";

import ChatRoomHeader from "../../ChatRoomHeader";
import styles from "./styles.module.css";

import {
    Bookmark as FavouriteIcon
} from "@mui/icons-material";

const isToday = (date) => {
    const today = new Date()
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
};

function formatDate(date) {
    if (!date) return "";
    var parsedDate = Date.parse(date);
    var offsetDate = new Date(parsedDate);
    if (isToday(offsetDate)) {
        return offsetDate.toLocaleTimeString();
    } else {
        return offsetDate.toLocaleDateString();
    }
}

export default function ChatRoomsTable({ data, handleJoinChat, handleClickFavourite }) {
    return (
        <Table size="small">
            <TableHead>
            </TableHead>
            <TableBody>
                {data.map(room => (
                    <TableRow className={styles.tableRow} key={room.id} style={room.unreadHostMessages > 0 ? {
                        backgroundColor: '#a9a9ff'
                    } : null} onClick={() => handleJoinChat(room.login)}>
                        <TableCell>
                            <ChatRoomHeader room={room} showRemoteMountButton={false} />
                        </TableCell>
                        <TableCell>
                            <Typography style={{ textAlign: "right" }}>{formatDate(room.lastActivity)}</Typography>
                            {
                                room.unreadHostMessages > 0 ?
                                    <Avatar style={{ height: "24px", width: "24px", backgroundColor: "#9cceb7", fontSize: "12pt", float: "right" }}>
                                        {room.unreadHostMessages}
                                    </Avatar> : <div />
                            }
                        </TableCell>
                        <TableCell>
                            <IconButton color={room.isFavourite ? "primary" : "default"} onClick={(e) => {
                                e.stopPropagation();
                                handleClickFavourite(room);
                            }
                            }>
                                <FavouriteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
