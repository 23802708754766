import { Typography, Button } from "@mui/material";

import { ArticleElementTypesByName } from "../../enums/Articles/ArticleElementTypes";
import { ArticleElementSizeByName } from "../../enums/Articles/ArticleElementSize";
import { ArticleElementColorByName } from "../../enums/Articles/ArticleElementColor";
import { ArticleElementTextStyleByName } from "../../enums/Articles/ArticleElementTextStyle";
import { ArticleElementTextAlignByName } from "../../enums/Articles/ArticleElementTextAlign";
import { ArticleElementFillingByName } from "../../enums/Articles/ArticleElementFilling";
import { ArticleElementGravityByName } from "../../enums/Articles/ArticleElementGravity";

const textSizeToCss = {
  [ArticleElementSizeByName.TINY]: "x-small",
  [ArticleElementSizeByName.SMALL]: "small",
  [ArticleElementSizeByName.MEDIUM]: "medium",
  [ArticleElementSizeByName.LARGE]: "large",
};

const colorStyleToCss = {
  [ArticleElementColorByName.ACCENT]: "purple",
  [ArticleElementColorByName.ATTENTION]: "yellow",
  [ArticleElementColorByName.PRIMARY]: "black",
  [ArticleElementColorByName.SECONDARY]: "red",
  [ArticleElementColorByName.SURFACE]: "green",
  [ArticleElementColorByName.TEXT_PRIMARY]: "black",
};

const textAlignToCss = {
  [ArticleElementTextAlignByName.CENTER]: "center",
  [ArticleElementTextAlignByName.TEXT_END]: "right",
  [ArticleElementTextAlignByName.TEXT_START]: "left",
};

const marginPaddingToCss = {
  [ArticleElementSizeByName.TINY]: "4px",
  [ArticleElementSizeByName.SMALL]: "8px",
  [ArticleElementSizeByName.MEDIUM]: "16px",
  [ArticleElementSizeByName.LARGE]: "32px",
};

const fillingToCss = {
  [ArticleElementFillingByName.FILL]: "100%",
  [ArticleElementFillingByName.WRAP]: "fit-content",
};

const gravityToCss = {
  [ArticleElementGravityByName.CENTER]: "center",
  [ArticleElementGravityByName.LEFT]: "start",
  [ArticleElementGravityByName.RIGHT]: "end",
};

const generateTextStyle = (element) => {
  let style = {};
  if (element.sizeStyle) {
    style.fontSize = textSizeToCss[element.sizeStyle];
  }
  if (element.colorStyle) {
    style.color = colorStyleToCss[element.colorStyle];
  }
  if (element.textStyle) {
    if (element.textStyle == ArticleElementTextStyleByName.BOLD) {
      style.fontWeight = "bold";
    }
    if (element.textStyle == ArticleElementTextStyleByName.ITALIC) {
      style.fontStyle = "italic";
    }
  }
  if (element.textAlign) {
    style.textAlign = textAlignToCss[element.textAlign];
  }
  return style;
};

const generateMarginPaddingsStyle = (element) => {
  let style = {};
  if (element.marginLeft) {
    style.marginLeft = marginPaddingToCss[element.marginLeft];
  }
  if (element.marginTop) {
    style.marginTop = marginPaddingToCss[element.marginTop];
  }
  if (element.marginRight) {
    style.marginRight = marginPaddingToCss[element.marginRight];
  }
  if (element.marginBottom) {
    style.marginBottom = marginPaddingToCss[element.marginBottom];
  }
  if (element.paddingLeft) {
    style.paddingLeft = marginPaddingToCss[element.paddingLeft];
  }
  if (element.paddingTop) {
    style.paddingTop = marginPaddingToCss[element.paddingTop];
  }
  if (element.paddingRight) {
    style.paddingRight = marginPaddingToCss[element.paddingRight];
  }
  if (element.paddingBottom) {
    style.paddingBottom = marginPaddingToCss[element.paddingBottom];
  }
  return style;
};

const generateFillingsStyle = (element) => {
  let style = {};
  if (element.widthFilling) {
    style.width = fillingToCss[element.widthFilling];
  }
  if (element.heightFilling) {
    style.height = fillingToCss[element.heightFilling];
  }
  return style;
};

const generateGravityStyle = (element) => {
  let style = { display: "flex", flexDirection: "row" };
  if (element.gravity) {
    style.justifyContent = gravityToCss[element.gravity];
  }
  return style;
};

export default function ElementsRender({ elements, setSelectedElement }) {
  const renderElement = (element) => {
    let elementRender = null;
    switch (element.type) {
      case ArticleElementTypesByName.BUTTON:
        elementRender = (
          <Button
            variant="contained"
            color="primary"
            style={{
              ...generateTextStyle(element),
              ...generateFillingsStyle(element),
            }}
          >
            {element.text}
          </Button>
        );
        break;
      case ArticleElementTypesByName.TEXT:
        elementRender = (
          <Typography
            style={{
              ...generateTextStyle(element),
              ...generateFillingsStyle(element),
            }}
          >
            {element.text}
          </Typography>
        );
        break;
      case ArticleElementTypesByName.LINK:
        elementRender = (
          <Typography
            style={{
              ...generateTextStyle(element),
              ...generateFillingsStyle(element),
              textDecorationLine: "underline",
            }}
          >
            {element.text ?? element.link}
          </Typography>
        );
        break;
      case ArticleElementTypesByName.IMAGE:
      case ArticleElementTypesByName.VIDEO:
        elementRender = (
          <img
            style={{
              ...generateFillingsStyle(element),
              width: element.width,
              height: "auto",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            src={
              element.urlRel
                ? `https://specialist.alarmtrade.ru/uploads/articles/elements/${element.url}`
                : ""
            }
            alt=""
          />
        );
        break;
      default:
        elementRender = <div>{JSON.stringify(element)}</div>;
    }
    return (
      <div
        key={element.id}
        onClick={() => {
          if (setSelectedElement) setSelectedElement(element);
        }}
        style={{
          ...generateMarginPaddingsStyle(element),
          ...generateGravityStyle(element),
        }}
      >
        {elementRender}
      </div>
    );
  };

  return <div>{elements.map((e) => renderElement(e))}</div>;
}
