import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Autocomplete,
    createFilterOptions
} from "@mui/material";

import { useTranslation } from "react-i18next";

export default function AutocompleteDialog({ title, initialValue, options, label, isOpened, scrollElementToWhenEmpty, getOptionLabel, multiple, handleConfirm, handleClose }) {
    const { t } = useTranslation();
    const [value, setValue] = useState([]);

    useEffect(() => {
        if (isOpened) {
            setValue(initialValue ? initialValue : []);
        }
    }, [isOpened, initialValue]);

    const onChange = (...params) => {
        setValue(params[1]);
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => getOptionLabel ? getOptionLabel(option) : option.toString(),
    });

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Autocomplete
                    style={{ paddingTop: 16 }}
                    multiple={multiple}
                    size="small"
                    value={value}
                    options={options}
                    onChange={onChange}
                    getOptionLabel={(option) => getOptionLabel ? getOptionLabel(option) : option.toString()}
                    filterOptions={filterOptions}
                    onOpen={() => {
                        if (scrollElementToWhenEmpty) {
                            let scrollTo = scrollElementToWhenEmpty;
                            if (value && (!multiple || value.length !== 0)) {
                                let scrollToValue = value;
                                if (multiple) {
                                    scrollToValue = value[0];
                                }
                                scrollTo = getOptionLabel ? getOptionLabel(scrollToValue) : scrollToValue.toString();
                            }
                            setTimeout(() => {
                                const optionEl = document.querySelector(
                                    `[data-name="${scrollTo}"]`
                                );
                                optionEl?.scrollIntoView();
                            }, 1);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={label} placeholder={label} />
                    )}
                    renderOption={(props, option) => {
                        let label = getOptionLabel ? getOptionLabel(option) : option.toString();
                        return (
                            <li {...props}>
                                {label}
                            </li>
                        );
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button onClick={() => { handleClose(); handleConfirm(value); }} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
