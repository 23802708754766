import React from "react";
import { formatDate } from "./StringUtils"

function generateUserInfoString(t, info) {
    let str = <div>
        <b>{t("Login")}</b>: {info.email === null ? "" : info.email}<br />
        <b>{t("Full name")}</b>: {info.fullname === null ? "" : info.fullname}<br />
        <b>{t("Registration date")}</b>: {info.registrationDate === null ? "" : formatDate(info.registrationDate)}<br />
        <b>{t("Company")}</b>: {info.company === null ? "" : info.company}<br />
        <b>{t("Address")}</b>: {info.address === null ? "" : info.address}
        {
            info.preferredBrands !== null && info.preferredBrands.length > 0 ?
                <><br /><b>{t("Preferred brands")}</b>: {info.preferredBrands.join(', ')}</>
                : <></>
        }
        {
            info.preferredModels !== null && info.preferredModels.length > 0 ?
                <><br /><b>{t("Preferred models")}</b>: {info.preferredModels.join(', ')}</>
                : <></>
        }
        {
            info.preferredBrandsDetected !== null && info.preferredBrandsDetected.length > 0 ?
                <><br /><b>{t("Preferred brands (detected)")}</b>: {info.preferredBrandsDetected.join(', ')}</>
                : <></>
        }
    </div>;
    return str;
}

export { generateUserInfoString };