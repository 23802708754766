import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";

export default function CreateUserDialog({
  isUserCreateOpened,
  handleUserCreate,
  handleUserCreateClose,
}) {
  const { t } = useTranslation();
  const backend = useBackend();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [sendActivationEmail, setSendActivationEmail] = useState(false);

  const onCreateClick = () => {
    if (password === repeatedPassword) {
      setIsLoading(true);
      backend
        .bckUsersSignIn(name, email, password, sendActivationEmail)
        .then((data) => {
          return data.json();
        })
        .then((json) => {
          if (json.code === 0) {
            handleUserCreate();
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("Sign in error"));
          }
          setIsLoading(false);
        });
    } else {
      setError(t("Password mismatch"));
    }
  };

  return (
    <Dialog
      open={isUserCreateOpened}
      onClose={handleUserCreateClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t("Create account")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("Enter user credentials")}
          <br />
          {t("Create user warning")}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="fullname"
          label={t("Full name")}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        <TextField
          margin="dense"
          id="email"
          label={t("Email")}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <TextField
          margin="dense"
          id="password"
          label={t("Password")}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          fullWidth
        />
        <TextField
          margin="dense"
          id="repeatedPassword"
          label={t("Repeat password")}
          onChange={(e) => setRepeatedPassword(e.target.value)}
          type="password"
          fullWidth
        />
        <FormControlLabel
          key="sendActivationEmail"
          control={
            <Checkbox
              color="primary"
              defaultChecked={sendActivationEmail}
              onChange={(e) => setSendActivationEmail(!sendActivationEmail)}
            />
          }
          label={t("Send activation email")}
        />
        <Typography style={{ color: "red" }}>{error}</Typography>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <CircularProgress size={32} />
        ) : (
          <>
            <Button onClick={handleUserCreateClose} color="primary">
              {t("Cancel")}
            </Button>
            <Button onClick={onCreateClick} color="primary">
              {t("Create")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
