import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Button,
    IconButton,
    Typography
} from "@mui/material";

import {
    Info as InfoIcon,
    HeadsetMic as UsergroupIcon,
    Edit as EditAttemptsIcon
} from "@mui/icons-material";

import { formatDate, Base64ToHex } from "../../helpers/StringUtils"
import { useTranslation } from "react-i18next"

function GetRowColor(status) {
    if (status !== 'success') {
        if (status === 'work') {
            return { backgroundColor: '#a9ffa9' };
        }
        if (status === 'DEBUG_DATA') {
            return { backgroundColor: '#ffb778' };
        }
        if (status === 'ready') {
            return { backgroundColor: '#a9a9ff' };
        }
        return { backgroundColor: "#ffa9a9" };
    }
    return {};
}

export default function ClonesTableComponent({ data, includeData, allowEditAttempts, handleShowData, handleShowDevicePackage, handleShowUserInfo, handleUsergroupClick, handleEditAttemptsClick }) {
    const { t } = useTranslation();
    var keys = ["#", t("Device"), "", t("Retries"), t("Status"), t("Data"), t("User"), " ", t("Car model"), t("Start time"), t("Time"), t("Algorithm"), "IP", t("Application version")];

    if (includeData) {
        keys.push(t("Data") + " ");
    }

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(clone => (
                    <TableRow key={clone.id} style={GetRowColor(clone.status)}>
                        <TableCell>{clone.id}</TableCell>
                        <TableCell>{clone.deviceId}<br />{clone.deviceName}</TableCell>
                        <TableCell>
                            <IconButton
                                color="primary"
                                onClick={() => { handleShowDevicePackage(clone.deviceId); }} >
                                <InfoIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div>{clone.remainingAttempts === null ? "?" : clone.remainingAttempts}</div>
                                {
                                    allowEditAttempts ?
                                        <IconButton
                                            color="primary"
                                            onClick={() => { handleEditAttemptsClick(clone); }} >
                                            <EditAttemptsIcon />
                                        </IconButton>
                                        : <></>
                                }
                            </div>
                        </TableCell>
                        <TableCell>{clone.status}</TableCell>
                        <TableCell>
                            <Button
                                onClick={() => { handleShowData(clone); }}
                                size="small"
                                variant="contained"
                                color="primary"
                            >
                                {t("View")}
                            </Button>
                        </TableCell>
                        <TableCell>{clone.userEmail}<br />{clone.userName}<br />{clone.userPhone}</TableCell>
                        <TableCell>
                            <IconButton
                                color="primary"
                                onClick={() => { handleShowUserInfo(clone); }} >
                                <InfoIcon />
                            </IconButton>
                            <IconButton
                                color="primary"
                                disabled={!clone.hasUsergroup}
                                onClick={() => { handleUsergroupClick(clone); }} >
                                <UsergroupIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell>
                            {clone.carModel}<br />
                            <b>CAN:</b> {`${clone.canMarka.toString(16)}_${clone.canModel.toString(16)}`}<br />
                            <b>VIN:</b> {clone.vin}
                        </TableCell>
                        <TableCell>{formatDate(clone.startTime)}</TableCell>
                        <TableCell>{clone.workTime}</TableCell>
                        <TableCell>{clone.algorithmName}<br/>{clone.algorithm}</TableCell>
                        <TableCell>{clone.ip}</TableCell>
                        <TableCell>{clone.studioVersion}</TableCell>
                        {
                            includeData ? <TableCell style={{ font: "\"Courier New\"" }}>
                                <div style={{ display: "flex", flexDirection: "row", whiteSpace: "nowrap" }}>
                                    <div style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                                        <Typography style={{ fontWeight: "bold" }}>{`${t("Decrypted clone 4096")}:`}</Typography>
                                        <Typography style={{ fontWeight: "bold" }}>{`${t("Result wout zeroes")}:`}</Typography>
                                        <Typography style={{ fontWeight: "bold" }}>{`${t("Decrypted clone 4128")}:`}</Typography>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Typography style={{ fontFamily: "monospace" }}>{`${Base64ToHex(clone.data4096)}`}</Typography>
                                        <Typography style={{ fontFamily: "monospace" }}>{`${Base64ToHex(clone.resultNoZeroes)}`}</Typography>
                                        <Typography style={{ fontFamily: "monospace" }}>{`${Base64ToHex(clone.data4128)}`}</Typography>
                                    </div>
                                </div>
                            </TableCell> : <></>
                        }
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
