import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Link,
  Paper,
  CircularProgress,
} from "@mui/material";

import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useBackend } from "../context/BackendContext";

export default function PasswordRecovery() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const backend = useBackend();
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordRepeated, setPasswordRepeated] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const checkPasswords = () => {
    if (password !== passwordRepeated) {
      setError(t("Password mismatch"));
      return false;
    }
    return true;
  };

  const handleChangePasswordClick = () => {
    if (!checkPasswords()) return;

    var params = queryString.parse(location.search);
    if (!params.token) {
      setError("Token is not set");
      return;
    }

    setInProgress(true);
    backend
      .bckUsersResetPassword(params.token, password)
      .then((requestData) => {
        setInProgress(false);
        return requestData.json();
      })
      .then((json) => {
        if (json.code === 0) {
          setError("");
          setIsDone(true);
        } else {
          if (json.message) {
            setError(json.message);
          }
        }
      });
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={6} style={{ maxWidth: "64ch", minWidth: "32ch" }}>
          <Paper style={{ padding: "16px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {isDone ? (
                <></>
              ) : (
                <Typography align="center" variant="h4">
                  {t("Password reset")}
                </Typography>
              )}
              <Typography color="secondary">{error ? t(error) : ""}</Typography>
              {inProgress ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : isDone ? (
                <Typography align="center" variant="h4">
                  {t("Password changed")}
                </Typography>
              ) : (
                <>
                  <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    label={t("New password")}
                    type="password"
                  />
                  <TextField
                    value={passwordRepeated}
                    onChange={(e) => setPasswordRepeated(e.target.value)}
                    margin="normal"
                    label={t("Repeat new password")}
                    type="password"
                  />
                  <Button
                    onClick={handleChangePasswordClick}
                    disabled={!(password && passwordRepeated)}
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    {t("Change password")}
                  </Button>
                </>
              )}
            </div>
            <Grid
              container
              direction="row"
              style={{
                marginTop: "12px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item>
                <div style={{ display: "flex" }}>
                  <Link
                    component="button"
                    onClick={() => {
                      i18n.changeLanguage("ru");
                    }}
                    style={{
                      marginRight: "12px",
                    }}
                  >
                    Русский
                  </Link>
                </div>
              </Grid>
              <Grid item>
                <div style={{ display: "flex" }}>
                  <Link
                    component="button"
                    onClick={() => {
                      i18n.changeLanguage("en");
                    }}
                    style={{
                      marginRight: "12px",
                    }}
                  >
                    English
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
