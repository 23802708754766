import React from "react";

import { Grid, Button } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import {
  DialogActionTypes,
  useDialogContext,
} from "../../context/DialogContext";
import SettingsWiresTable from "../../components/Tables/SettingsWiresTable";

export default function Wires({ data, setData, setError }) {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t, i18n } = useTranslation();

  const saveWire = (newWire) => {
    backend.bckSettingsCreateOrUpdateWire(newWire).then((json) => {
      if (json.code === 0) {
        newWire.Id = json.content;
        setData((prev) => {
          let newWires = { ...prev.Wires };
          newWires[newWire.Id] = newWire;
          let next = { ...prev, Wires: newWires };
          return next;
        });
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleAddWire = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        let newWire = {
          Id: null,
          Name: name,
        };
        saveWire(newWire);
      },
      initialValue: "",
      textLabel: t("Title"),
      title: t("Add wire"),
    });
  };

  const handleRemoveWire = (wire) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove wire?", { title: wire.Name }),
      handleConfirm: () => {
        backend.bckSettingsRemoveWire(wire.Id).then((json) => {
          if (json.code === 0) {
            setData((prev) => {
              let newWires = { ...prev.Wires };
              delete newWires[wire.Id];
              let next = { ...prev, Wires: newWires };
              return next;
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const updateWire = (wire, callback) => {
    let newWire = { ...wire };
    callback(newWire);
    saveWire(newWire);
  };

  const handleEditName = (wire) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        updateWire(wire, (newWire) => {
          newWire.Name = name;
        });
      },
      initialValue: wire.Name,
      textLabel: t("Name"),
      title: t("Name"),
    });
  };

  const handleEditTitle = (wire) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (value) => {
        updateWire(wire, (newWire) => {
          if (!newWire.TitleTranslationText) newWire.TitleTranslationText = {};
          newWire.TitleTranslationText[i18n.language] = value;
        });
      },
      initialValue:
        data.Translations[i18n.language][wire.TitleTranslationId]?.Text,
      textLabel: t("Title"),
      title: t("Title"),
    });
  };

  const handleEditColor = (wire) => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: wire.ColorId
        ? data.WireColors[wire.ColorId]
        : Object.values(data.WireColors)[0],
      label: t("Color"),
      title: t("Color"),
      options: Object.values(data.WireColors),
      getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
      multiple: false,
      handleConfirm: (value) => {
        updateWire(wire, (newWire) => {
          newWire.ColorId = value.Id;
        });
      },
    });
  };

  const handleEditPin = (wire) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (option) => {
        let parsed = parseInt(option);
        updateWire(wire, (newWire) => {
          newWire.PinNumber = parsed;
        });
      },
      initialValue: wire.PinNumber ? wire.PinNumber.toString() : "0",
      textLabel: t("Pin number"),
      title: t("Pin number"),
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 4);
      },
    });
  };

  return (
    <>
      <Grid item>
        <Button onClick={handleAddWire} variant="contained" color="primary">
          {t("Add wire")}
        </Button>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "70vh",
          marginTop: "16px",
        }}
      >
        <SettingsWiresTable
          settingInfo={data}
          data={Object.values(data.Wires)}
          handleEditColor={handleEditColor}
          handleEditName={handleEditName}
          handleEditPin={handleEditPin}
          handleEditTitle={handleEditTitle}
          handleRemoveWire={handleRemoveWire}
        />
      </div>
    </>
  );
}
