const ArticleElementSize = {
    1: "TINY",
    2: "SMALL",
    3: "MEDIUM",
    4: "LARGE"
};
Object.freeze(ArticleElementSize);

const ArticleElementSizeByName = {
    "TINY": 1,
    "SMALL": 2,
    "MEDIUM": 3,
    "LARGE": 4
};
Object.freeze(ArticleElementSizeByName);

export {
    ArticleElementSize,
    ArticleElementSizeByName
}