// Convert a hex string to a byte array
function hexToBytes(hex) {
    for (var bytes = [], c = 0; c < hex.length; c += 2)
        bytes.push(parseInt(hex.substr(c, 2), 16));
    return bytes;
}

// Convert a byte array to a hex string
function bytesToHex(bytes) {
    for (var hex = [], i = 0; i < bytes.length; i++) {
        var current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
        hex.push((current >>> 4).toString(16));
        hex.push((current & 0xF).toString(16));
    }
    return hex.join("");
}

function formatDate(date) {
    if (!date) return "";
    var parsedDate = Date.parse(date);
    var offsetDate = new Date(parsedDate);
    return offsetDate.toLocaleString()
}

function formatElapsedTime(seconds) {
    var hours = seconds / 3600 | 0;
    var mins = (seconds - hours * 3600) / 60 | 0;
    var secs = seconds % 60 | 0;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
}

function EncodeUTF16LE(str) {
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = str.length;
    i = 0;
    while (i < len) {
        c = str.charCodeAt(i++);
        switch (c >> 4) {
            case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                // 0xxxxxxx
                out += str.charAt(i - 1);
                break;
            case 12: case 13:
                // 110x xxxx   10xx xxxx
                char2 = str.charCodeAt(i++);
                out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                out += str.charAt(i - 1);
                break;
            case 14:
                // 1110 xxxx  10xx xxxx  10xx xxxx
                char2 = str.charCodeAt(i++);
                char3 = str.charCodeAt(i++);
                out += String.fromCharCode(((c & 0x0F) << 12) | ((char2 & 0x3F) << 6) | ((char3 & 0x3F) << 0));
                break;
            default:
                break;
        }
    }

    var byteArray = new Uint8Array(out.length * 2);
    for (i = 0; i < out.length; i++) {
        byteArray[i * 2] = out.charCodeAt(i); // & 0xff;
        byteArray[i * 2 + 1] = out.charCodeAt(i) >> 8; // & 0xff;
    }

    return String.fromCharCode.apply(String, byteArray);
}

function Base64ToHex(str) {
    if (str === null || str === undefined || str.trim() === '') return '';
    var byteChars = atob(str);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
        byteNumbers[i] = byteChars.charCodeAt(i);
    }
    return Array.from(byteNumbers, function (byte) {
        return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('').toUpperCase();
}

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

function yearsStringToSpanString(years) {
    if (!years) return "";
    let yearsTokens = years.split(",").map(yt => parseInt(yt)).sort();
    yearsTokens.push(20000);

    let previous = yearsTokens[0];
    let leftMark = previous;
    let result = "";
    for (let i = 0; i < yearsTokens.length; ++i) {
        let nextValue = yearsTokens[i];
        if (nextValue - previous > 1) {
            if (result) result += ",";
            if (leftMark === previous) {
                result += `${leftMark}`;
            } else {
                result += `${leftMark}-${previous}`;
            }
            leftMark = nextValue;
        }
        previous = nextValue;
    }
    return result;
}

function stringIndexTrim(str, ch) {
    var start = 0,
        end = str.length;

    while (start < end && str[start] === ch)
        ++start;

    while (end > start && str[end - 1] === ch)
        --end;

    return (start > 0 || end < str.length) ? str.substring(start, end) : str;
}

export {
    formatDate,
    formatElapsedTime,
    hexToBytes,
    bytesToHex,
    EncodeUTF16LE,
    Base64ToHex,
    validateEmail,
    yearsStringToSpanString,
    stringIndexTrim
}