import React, { useEffect, useState } from "react";
import {
    Select,
    MenuItem
} from "@mui/material";
import SettingSlider from "./SettingSlider"
import { SettingsMenuSirenRingtoneType } from "../../enums/SettingsMenuTypes";

export default function SettingSirenMenu({ defaultValue, handleChange }) {
    const [type, setType] = useState(0);
    const [sliderValue, setSliderValue] = useState(0);
    const [valueLabelFormat, setValueLabelFormat] = useState("");
    const [maxValue, setMaxValue] = useState(10);
    const [marks, setMarks] = useState([]);

    const updateSliderType = (newType, value) => {
        let minValue = 0, maxValue = 0, unitLabel = "";

        setSliderValue(value);
        if (newType === 0) {
            maxValue = 100;
            unitLabel = "*100Hz";
        } else {
            unitLabel = "";
            maxValue = 10;
        }
        setValueLabelFormat(unitLabel);
        setMaxValue(maxValue);

        let minLabel = `${minValue}`;
        let maxLabel = `${maxValue}${unitLabel}`;
        const marks = [
            {
                value: minValue,
                label: minLabel
            },
            {
                value: maxValue,
                label: maxLabel
            }
        ];
        setMarks(marks);
    }

    useEffect(() => {
        if (defaultValue < 128) {
            setType(0);
            updateSliderType(0, defaultValue);
        } else {
            setType(1);
            updateSliderType(1, defaultValue - 128);
        }
    }, [defaultValue]);

    const handleSliderChange = (e, newValue) => {
        setSliderValue(newValue);
        if (type === 0) {
            handleChange(e, newValue);
        } else {
            handleChange(e, newValue + 128);
        }
    };

    const handleTypeChange = (e, object) => {
        let newType = e.target.value;
        setType(newType);
        updateSliderType(newType, 0);
        handleChange(e, 0);
    };

    return <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
        <Select value={type} onChange={handleTypeChange}>
            {
                Object.keys(SettingsMenuSirenRingtoneType).map(k => <MenuItem key={k} value={k}>
                    {SettingsMenuSirenRingtoneType[k]}
                </MenuItem>)
            }
        </Select>
        <SettingSlider
            defaultValue={sliderValue}
            step={1}
            marks={marks}
            min={0}
            max={maxValue}
            onChangeCommitted={handleSliderChange}
            valueLabelFormat={(value, index) => `${value}${valueLabelFormat}`}
        />
    </div>
}
