import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import UsergroupUserTable from "./Tables/UsergroupUsersTable";

import { GenerateInteger } from "../helpers/RandomGenerator";

import { UserPermissions } from "../enums/UserRoles";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import AdvancedTablePagination from "./AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoad,
  settingRepositoryLoadInt,
  settingRepositoryLoadPermissions,
  settingRepositorySave,
} from "../settingRepository";
import { useNavigate } from "react-router";

let searchTimeout = null;

export default function EditUsergroup({
  searchMode,
  userLogin,
  defaultUserVisibility,
  setDefaultUserVisibility,
  usePageNumberSave,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const [isFilteringOn, setIsFilteringOn] = useState(false);
  const [searchLogin, setSearchLogin] = useState("");
  const [searchCompany, setSearchCompany] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchLoginInput, setSearchLoginInput] = useState("");
  const [searchCompanyInput, setSearchCompanyInput] = useState("");
  const [searchAddressInput, setSearchAddressInput] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(100);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menuTarget, setMenuTarget] = useState("");
  const [userRole, setUserRole] = useState(0);

  const onMenuSelected = (status) => () => {
    setMenuAnchor(null);
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Change user membership status?", { user: menuTarget }),
      handleConfirm: () => {
        ++updatesLockCounter.current;
        setTableData((prev) => {
          var foundIndex = prev.findIndex((x) => x.login === menuTarget);
          prev[foundIndex].status = status;
          return [...prev];
        });
        backend
          .bckUsergroupSetMemberStatus(userLogin, menuTarget, status)
          .then((json) => {
            --updatesLockCounter.current;
            if (json.code === 0) {
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleEditStatus = (event, login) => {
    var permissions = settingRepositoryLoadPermissions();
    if (
      permissions.includes(UserPermissions.USERGROUP_MEMBER_CHANGE_STATUS) &&
      !searchMode
    ) {
      setMenuAnchor(event.currentTarget);
      setMenuTarget(login);
    }
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleAdd = () => {
    dialogDispatch({
      type: DialogActionTypes.SelectUserDialogOpen,
      handleConfirm: (selectedUser) => {
        if (tableData.some((user) => user.login === selectedUser.login)) {
          setError(t("User already exists", { user: selectedUser.login }));
        } else {
          setError("");
          ++updatesLockCounter.current;

          var id = GenerateInteger();
          lastRequestId.current = id;
          backend
            .bckUsergroupAddMember(userLogin, selectedUser.login)
            .then((json) => {
              --updatesLockCounter.current;
              if (json.code === 0) {
                forceUpdate();
              } else if (json.code === 3) {
                setError(t(json.message));
              } else {
                setError(t("REQUEST_ERROR"));
              }
            });
        }
      },
    });
  };

  const handleDelete = (user) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete user from group?", { user: user.login }),
      handleConfirm: () => {
        ++updatesLockCounter.current;
        setTableData((prev) => {
          var foundIndex = prev.findIndex((x) => x.login === user.login);
          prev.splice(foundIndex, 1);
          return [...prev];
        });

        var id = GenerateInteger();
        lastRequestId.current = id;
        backend.bckUsergroupDeleteMember(userLogin, user.login).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const handleEditAddress = (user) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newAddress) => {
        ++updatesLockCounter.current;
        setTableData((prev) => {
          var foundIndex = prev.findIndex((x) => x.login === user.login);
          prev[foundIndex].address = newAddress;
          return [...prev];
        });

        var id = GenerateInteger();
        lastRequestId.current = id;
        backend.bckUsersEditAddress(user.id, newAddress).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
      initialValue: user.address,
      textLabel: t("Address"),
      title: t("Address"),
    });
  };

  const handleEditCompany = (user) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newCompany) => {
        ++updatesLockCounter.current;
        setTableData((prev) => {
          var foundIndex = prev.findIndex((x) => x.id === user.id);
          prev[foundIndex].company = newCompany;
          return [...prev];
        });

        var id = GenerateInteger();
        lastRequestId.current = id;
        backend.bckUsersEditCompany(user.id, newCompany).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
      initialValue: user.company,
      textLabel: t("Company"),
      title: t("Company"),
    });
  };

  const refreshTable = useCallback(
    (
      userId,
      searchLogin,
      searchCompany,
      searchAddress,
      searchComment,
      asVisible,
      pageNumber,
      pageSize
    ) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckUsergroupGet(
          userId,
          searchLogin,
          searchCompany,
          searchAddress,
          searchComment,
          false,
          pageNumber + 1,
          pageSize
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.rows;
                } else {
                  return prev;
                }
              });
              setTotalUsers((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.total;
                } else {
                  return prev;
                }
              });
              setIsFilteringOn(json.content.useUsergroupVisibility);
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          } else {
            console.log("dropped");
          }
        });
    },
    [t, backend]
  );

  useEffect(() => {
    if (userLogin) {
      let pageSize = settingRepositoryLoadInt(
        SettingName.USERGROUP_PAGE_SIZE,
        10
      );
      setPageSize(pageSize);

      let pageNumber = 0;
      if (usePageNumberSave) {
        pageNumber = settingRepositoryLoadInt(SettingName.USERGROUP_PAGE, 0);
        setPageNumber(pageNumber);
      }

      refreshTable(userLogin, "", "", "", "", searchMode, pageNumber, pageSize);
      setUserRole(settingRepositoryLoad(SettingName.ROLE, null));
    }
    return () => {
      lastRequestId.current = null;
    };
    // eslint-disable-next-line
  }, [userLogin]);

  useEffect(() => {
    if (userLogin && userLogin.trim() !== "") {
      setIsFilteringOn(defaultUserVisibility);
    }
  }, [userLogin, defaultUserVisibility]);

  const forceUpdate = () => {
    refreshTable(
      userLogin,
      searchLogin,
      searchCompany,
      searchAddress,
      "",
      searchMode,
      pageNumber,
      pageSize
    );
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    if (usePageNumberSave) {
      settingRepositorySave(SettingName.USERGROUP_PAGE, newPage);
    }
    refreshTable(
      userLogin,
      searchLogin,
      searchCompany,
      searchAddress,
      "",
      searchMode,
      newPage,
      pageSize
    );
  };

  const handleChangeRowsPerPage = (event) => {
    var newRowsPerPage = parseInt(event.target.value, 10);
    settingRepositorySave(SettingName.USERGROUP_PAGE_SIZE, newRowsPerPage);
    setPageSize(newRowsPerPage);
    setPageNumber(0);
    if (usePageNumberSave) {
      settingRepositorySave(SettingName.USERGROUP_PAGE, 0);
    }
    refreshTable(
      userLogin,
      searchLogin,
      searchCompany,
      searchAddress,
      "",
      searchMode,
      0,
      newRowsPerPage
    );
  };

  const handleFilteringSwitchClick = (value) => {
    setIsFilteringOn(value);
    if (setDefaultUserVisibility) {
      setDefaultUserVisibility(value);
    }
    backend.bckUsersSetUserGroupVisibility(userLogin, value).then((json) => {
      if (json.code === 0) {
        forceUpdate();
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const startSearch = (searchLogin, searchCompany, searchAddress) => {
    if (searchLogin || searchCompany || searchAddress) {
      setPageNumber(0);
      if (usePageNumberSave) {
        settingRepositorySave(SettingName.USERGROUP_PAGE, 0);
      }
      setSearchLogin(searchLogin);
      setSearchCompany(searchCompany);
      setSearchAddress(searchAddress);
      refreshTable(
        userLogin,
        searchLogin,
        searchCompany,
        searchAddress,
        "",
        searchMode,
        0,
        pageSize
      );
    }
  };

  const handleSearch = () => {
    startSearch(searchLogin, searchCompany, searchAddress);
  };

  const handleClear = () => {
    setSearchLogin("");
    setSearchCompany("");
    setSearchAddress("");
    setSearchLoginInput("");
    setSearchCompanyInput("");
    setSearchAddressInput("");
    refreshTable(userLogin, "", "", "", "", searchMode, pageNumber, pageSize);
  };

  const handleSearchTimeoutStart = (
    searchLogin,
    searchCompany,
    searchAddress
  ) => {
    window.clearTimeout(searchTimeout);
    searchTimeout = window.setTimeout(() => {
      startSearch(searchLogin, searchCompany, searchAddress);
    }, 1000);
  };

  const handleSearchLoginChange = (e) => {
    let newValue = e.target.value.slice(0, 48);
    setSearchLoginInput(newValue);
    handleSearchTimeoutStart(newValue, searchCompany, searchAddress);
  };

  const handleSearchCompanyChange = (e) => {
    let newValue = e.target.value.slice(0, 48);
    setSearchCompanyInput(newValue);
    handleSearchTimeoutStart(searchLogin, newValue, searchAddress);
  };

  const handleSearchAddressChange = (e) => {
    let newValue = e.target.value.slice(0, 48);
    setSearchAddressInput(newValue);
    handleSearchTimeoutStart(searchLogin, searchCompany, newValue);
  };

  const handleShowLogs = (user) => {
    navigate(`/app/events?login=${user.login}`);
  };

  const handleShowMounts = (user) => {
    navigate(`/app/fastMounts?login=${user.login}`);
  };

  return (
    <div>
      <div>
        {searchMode ? (
          <></>
        ) : (
          <FormControlLabel
            control={
              <Switch
                checked={isFilteringOn}
                onChange={(e) => handleFilteringSwitchClick(e.target.checked)}
                color="primary"
              />
            }
            label={t("Use usergroup filtering")}
          />
        )}
      </div>
      <div
        style={{ display: "flex", alignItems: "flex-end", flexWrap: "wrap" }}
      >
        <TextField
          label={t("Search by login/phone")}
          value={searchLoginInput}
          onChange={handleSearchLoginChange}
          style={{
            minWidth: "30ch",
            maxWidth: "30ch",
            marginTop: "12px",
            marginRight: "12px",
            marginBottom: "16px",
          }}
        />
        <TextField
          label={t("Company")}
          value={searchCompanyInput}
          onChange={handleSearchCompanyChange}
          style={{ marginRight: "12px", marginBottom: "16px" }}
        />
        <TextField
          label={t("Address")}
          value={searchAddressInput}
          onChange={handleSearchAddressChange}
          style={{ marginRight: "12px", marginBottom: "16px" }}
        />
        <div style={{ marginBottom: "16px" }}>
          <Button
            onClick={handleSearch}
            style={{ marginRight: "16px" }}
            variant="contained"
            color="primary"
          >
            {t("Search")}
          </Button>
          <Button
            onClick={handleClear}
            style={{ marginRight: "16px" }}
            variant="contained"
            color="primary"
          >
            {t("Reset parameters")}
          </Button>
        </div>
      </div>
      <Button
        onClick={handleAdd}
        disabled={(searchMode && userRole === "2") || !isFilteringOn}
        variant="contained"
        color="primary"
      >
        {t("Add")}
      </Button>
      <Typography color="secondary">{error}</Typography>
      <AdvancedTablePagination
        rowsPerPageOptions={[10, 20, 50, 100]}
        totalRows={totalUsers}
        pageSize={pageSize}
        pageNumber={pageNumber}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      >
        <UsergroupUserTable
          allowEdit={isFilteringOn}
          data={tableData}
          handleDeletePress={handleDelete}
          handleEditAddress={handleEditAddress}
          handleEditCompany={handleEditCompany}
          handleEditStatus={handleEditStatus}
          handleShowLogs={handleShowLogs}
          handleShowMounts={handleShowMounts}
        />
      </AdvancedTablePagination>

      <Menu
        keepMounted
        open={menuAnchor !== null}
        onClose={handleMenuClose}
        anchorEl={menuAnchor}
      >
        <MenuItem style={{ display: "none" }} />
        <MenuItem onClick={onMenuSelected(0)}>{t("Verification")}</MenuItem>
        <MenuItem onClick={onMenuSelected(1)}>{t("Approved")}</MenuItem>
      </Menu>
    </div>
  );
}
