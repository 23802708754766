import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "./styles.module.css";
import siteDesktop from "./images/SiteDesktop.png";
import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import { ClientTypesByName } from "../../enums/ClientTypes";

export default function MainDownloadsPc() {
  const [pcVersion, setPcVersion] = useState("");
  const [macVersion, setMacVersion] = useState("");
  const { t } = useTranslation();
  const backend = useBackend();

  useEffect(() => {
    backend
      .bckUpdateChannelGetActualVersion(ClientTypesByName.Windows)
      .then((json) => {
        if (json.code === 0) {
          setPcVersion(json.content);
        }
      });
    backend
      .bckUpdateChannelGetActualVersion(ClientTypesByName.Mac)
      .then((json) => {
        if (json.code === 0) {
          setMacVersion(json.content);
        }
      });
  }, []);

  return (
    <div className={styles.container}>
      <div
        style={{ textAlign: "center", maxWidth: "400px", minHeight: "95vh" }}
      >
        <img src={siteDesktop} style={{ width: "100%" }} alt="" />
        <h2>{t("Specialist for PC")}</h2>
        <p>{t("Pc downloads description")}</p>
        {pcVersion ? (
          <>
            <pre style={{ fontSize: "0.8em" }}>
              <b>{t("SPEC_SYSTEM_REQUIREMENTS_WINDOWS")}</b>
            </pre>
            <Button
              variant="contained"
              onClick={() =>
                window.open(
                  `/frontend/UpdateChannel/GetActualFile?clientType=${ClientTypesByName.Windows}`,
                  "_blank"
                )
              }
              color="primary"
              style={{ margin: "16px" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>{t("Download for Windows")}</div>
                <pre style={{ fontSize: "0.8em", margin: "0px" }}>
                  <b>{pcVersion}</b>
                </pre>
              </div>
            </Button>
          </>
        ) : (
          <></>
        )}
        {macVersion ? (
          <>
            <pre style={{ fontSize: "0.8em" }}>
              <b>{t("SPEC_SYSTEM_REQUIREMENTS_MAC")}</b>
            </pre>
            <Button
              variant="contained"
              onClick={() =>
                window.open(
                  `/frontend/UpdateChannel/GetActualFile?clientType=${ClientTypesByName.Mac}`,
                  "_blank"
                )
              }
              color="primary"
              style={{ margin: "16px" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>{t("Download for Mac")}</div>
                <pre style={{ fontSize: "0.8em", margin: "0px" }}>
                  <b>{macVersion}</b>
                </pre>
              </div>
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
