import React, { useState } from "react";

import {
    Tabs,
    Tab,
    IconButton,
    Typography
} from "@mui/material";

import Devices from "./Devices";
import Subsystems from "./Subsystems";
import SubsystemsFull from "./SubsystemFull";

import { useTranslation } from "react-i18next";

import {
    ArrowBack as BackIcon
} from "@mui/icons-material";

export default function DevicesSubsystems() {
    const { t } = useTranslation();
    const [tabValue, setTabValue] = useState(0);
    const [subsystemFullId, setSubsystemFullId] = useState(null);
    const [subsystemName, setSubsystemName] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const handleSetTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleShowSubsystemFull = (subsystem) => {
        setSubsystemFullId(subsystem.id);
    };

    const handleBackFromSubsystemFull = () => {
        setSubsystemFullId(null);
    }

    return (
        <>
            <Tabs value={tabValue} onChange={handleSetTab}>
                <Tab label={t("Devices")} />
                <Tab label={t("Subsystems")} />
            </Tabs>
            <div style={tabValue === 0 ? null : { display: "none" }}>
                <Devices />
            </div>
            {
                tabValue === 1 && subsystemFullId ? <div>
                    <IconButton color="primary" onClick={handleBackFromSubsystemFull}>
                        <BackIcon />
                        <Typography variant="body2">{t("Back to subsystems")}</Typography>
                    </IconButton>
                    <SubsystemsFull subsystemId={subsystemFullId} />
                </div> : tabValue === 1 ? <div>
                        <Subsystems
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            subsystemName={subsystemName}
                            setSubsystemName={setSubsystemName}
                            onShowSubsystemFull={handleShowSubsystemFull}
                        />
                </div> : <></>
            }
        </>
    );
}
