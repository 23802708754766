import { Typography } from "@mui/material";
import React from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";

export default function FileDndContainer({ style, onDrop, children }) {
  const { t } = useTranslation();
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item) {
        return true;
      },
      hover(item) {},
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    []
  );

  const isActive = canDrop && isOver;
  return (
    <div ref={drop} style={{ position: "relative", ...style }}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "black",
          opacity: 0.5,
          zIndex: 1,
          display: isActive ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h3" style={{ color: "white" }}>
          {t("Send file")}
        </Typography>
      </div>
      {children}
    </div>
  );
}
