import React from "react";

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Paper,
    IconButton
} from "@mui/material";

import {
    Delete as DeleteIcon,
    ExpandMore as ExpandMoreIcon,
    AddCircle as AddCircleIcon,
    FileCopy as CopyIcon
} from "@mui/icons-material";

export default function SubsystemEquipmentsAccordion({ title, noEquipmentText, data, onClickCopy, onClickDelete, onClickAdd }) {
    return (<Accordion style={{ background: "#E2E2E2" }} defaultExpanded>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
        >
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography>{title}</Typography>
                <IconButton
                    style={{ color: 'black' }}
                    onClick={(e) => { onClickCopy(); e.stopPropagation(); }}
                >
                    <CopyIcon />
                </IconButton>
                <IconButton
                    style={{ color: 'black' }}
                    onClick={(e) => { onClickAdd(); e.stopPropagation(); }}
                >
                    <AddCircleIcon />
                </IconButton>
            </div>
        </AccordionSummary>
        <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
            {
                data && data.length > 0 ? [...new Set(data.map(e => e.brandName))].sort().map(brandName => <Accordion key={brandName} style={{ background: "#D2D2D2" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Typography>{brandName}</Typography>
                            <IconButton
                                style={{ color: 'black' }}
                                onClick={(e) => { onClickAdd(); e.stopPropagation(); }}
                            >
                                <AddCircleIcon />
                            </IconButton>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
                        {
                            data.filter(e => e.brandName === brandName).sort().map(e => <Paper
                                key={e.id}
                                style={{ padding: "16px", background: "#C2C2C2", display: "flex", flexDirection: "row", alignItems: "center" }}
                            >
                                <Typography>{`${e.brandName} | ${e.modelName} | ${e.name}`}</Typography>
                                <IconButton
                                    style={{ color: 'red' }}
                                    onClick={() => onClickDelete(e)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Paper>)
                        }
                    </AccordionDetails>
                </Accordion>) : <Typography>{noEquipmentText}</Typography>
            }
        </AccordionDetails>
    </Accordion>);
}
