const CarModelTypes = {
    1: "CAR",
    2: "TRUCK"
};
Object.freeze(CarModelTypes);

const CarModelTypesByName = {
    "CAR": 1,
    "TRUCK": 2
};
Object.freeze(CarModelTypesByName);

export { CarModelTypes, CarModelTypesByName };