import React, { useState, useEffect } from "react";

import {
    Typography,
    IconButton,
    Button,
    MenuItem,
    Table,
    TableRow,
    TableBody,
    TableCell
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SettingsMenuSirenRingtoneEventId, SettingsMenuTextItemType, SettingsMenuTypes, SettingsMenuTypesByName } from "../../enums/SettingsMenuTypes"
import { useDialogContext, DialogActionTypes } from "../../context/DialogContext";
import MenuEditDuplicate from "./MenuEditDuplicate";

import {
    Edit as EditIcon,
    RemoveCircle as RemoveIcon,
    AddCircle as AddCircleIcon,
    LinkOff as DuplicateIcon,
    Link as LinkIcon
} from "@mui/icons-material";
import { getFlatStructures, generateTranslationStringTag, generateTranslationString } from "./Misc";

export default function Menu({ settingInfo, menu, handleSave }) {
    const dialogDispatch = useDialogContext();
    const [localMenu, setLocalMenu] = useState({});
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setLocalMenu((prev) => {
            let duplicateMenu = {
                ...menu
            };
            if (menu.FloatAsIntItem) duplicateMenu.FloatAsIntItem = { ...menu.FloatAsIntItem };
            if (menu.IntegerItem) duplicateMenu.IntegerItem = { ...menu.IntegerItem };
            if (menu.OptionsItem) duplicateMenu.OptionsItem = { ...menu.OptionsItem };
            if (menu.SwitchItem) duplicateMenu.SwitchItem = { ...menu.SwitchItem };
            if (menu.SirenRingtoneItem) duplicateMenu.SirenRingtoneItem = { ...menu.SirenRingtoneItem };
            if (menu.TextItem) duplicateMenu.TextItem = { ...menu.TextItem };
            return duplicateMenu;
        });
    }, [menu]);

    const areMenusDifferent = (menu1, menu2) => {
        return JSON.stringify(menu1) !== JSON.stringify(menu2);
    };

    const getFlatMenus = () => {
        let flatMenus = Object.values(settingInfo.Menus).reduce((prev, cur, i) => {
            let flatChildren = cur.Children?.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
            if (flatChildren) {
                prev = { ...prev, [cur.Id]: cur, ...flatChildren };
            } else {
                prev = { ...prev, [cur.Id]: cur };
            }
            return prev;
        }, {});
        return flatMenus;
    };

    const handleEditName = () => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: (name) => {
                setLocalMenu((prev) => ({ ...prev, Name: name }));
            },
            initialValue: localMenu.Name,
            textLabel: t("Name"),
            title: t("Name")
        });
    };

    const handleEditParentId = () => {
        dialogDispatch({
            type: DialogActionTypes.SimpleSelectDialogOpen,
            initialValue: localMenu.ParentId,
            label: "Parent ID",
            title: "Parent ID",
            menuItems: Object.keys(settingInfo.Menus).map(k => <MenuItem key={parseInt(k)} value={parseInt(k)}>
                {`${settingInfo.Menus[k].Name} (${k})`}
            </MenuItem>),
            handleConfirm: (value) => {
                setLocalMenu((prev) => ({ ...prev, ParentId: value }));
            }
        });
    };

    const handleEditPrototypeId = () => {
        let flatMenus = getFlatMenus();
        dialogDispatch({
            type: DialogActionTypes.AutocompleteDialogOpen,
            initialValue: flatMenus[localMenu.PrototypeId] ?? flatMenus[1],
            label: "Prototype ID",
            title: "Prototype ID",
            options: Object.values(flatMenus),
            getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
            multiple: false,
            handleConfirm: (value) => {
                if (value) {
                    setLocalMenu((prev) => ({ ...prev, PrototypeId: value.Id }));
                }
            }
        });
    };

    const handleRemoveOptionsItemValue = (o) => {
        setLocalMenu((prev) => {
            let next = {
                ...prev,
                OptionsItem: {
                    ...prev.OptionsItem,
                    Value2TranslationId: {
                        ...prev.OptionsItem.Value2TranslationId
                    },
                    Value2TranslationText: {
                        ...prev.OptionsItem.Value2TranslationText
                    }
                }
            };
            if (next.OptionsItem.Value2TranslationId[o]) {
                delete next.OptionsItem.Value2TranslationId[o];
            }
            if (next.OptionsItem.Value2TranslationText[o]) {
                delete next.OptionsItem.Value2TranslationText[o];
            }
            return next;
        });
    };

    const handleOptionsItemEditText = (v, defaultText) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: (text) => {
                setLocalMenu((prev) => {
                    let next = {
                        ...prev,
                        OptionsItem: {
                            ...prev.OptionsItem,
                            Value2TranslationText: {
                                ...prev.OptionsItem?.Value2TranslationText
                            }
                        }
                    };
                    if (!next.OptionsItem.Value2TranslationText[v]) {
                        next.OptionsItem.Value2TranslationText[v] = {};
                    }
                    next.OptionsItem.Value2TranslationText[v][i18n.language] = text;
                    return next;
                });
            },
            initialValue: defaultText,
            textLabel: "Text",
            title: "Text"
        });
    };

    const handleAddOptionsItemValue = () => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: (option) => {
                let parsed = parseInt(option);
                setLocalMenu((prev) => {
                    let next = {
                        ...prev,
                        OptionsItem: {
                            ...prev.OptionsItem,
                            Value2TranslationId: {
                                ...prev.OptionsItem?.Value2TranslationId
                            },
                            Value2TranslationText: {
                                ...prev.OptionsItem?.Value2TranslationText
                            }
                        }
                    };
                    next.OptionsItem.Value2TranslationId[parsed] = null;
                    next.OptionsItem.Value2TranslationText[parsed] = { [i18n.language]: "" };
                    return next;
                });
            },
            initialValue: "0",
            textLabel: "Option",
            title: "Option",
            onChange: (e) => {
                let filteredValue = e.target.value.replace(/[^0-9]/g, '');
                return filteredValue.substr(0, 4);
            }
        });
    };

    const typeMenuItems = Object.keys(SettingsMenuTypes).map(i =>
        <MenuItem key={parseInt(i)} value={parseInt(i)}>
            {`${SettingsMenuTypes[i]} (${i})`}
        </MenuItem>
    );

    const handleEditType = () => {
        dialogDispatch({
            type: DialogActionTypes.SimpleSelectDialogOpen,
            initialValue: localMenu.Type,
            label: "Type",
            title: "Type",
            menuItems: typeMenuItems,
            handleConfirm: (value) => {
                setLocalMenu((prev) => ({ ...prev, Type: value }));
            }
        });
    };

    const handleEditNumberWithSetter = (defaultValue, setter) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: setter,
            initialValue: defaultValue ? defaultValue : "0",
            textLabel: t("Value"),
            title: t("Value"),
            onChange: (e) => {
                let filteredValue = e.target.value.replace(/[^0-9-]/g, '');
                return filteredValue.substr(0, 6);
            }
        });
    };

    const handleEditNumber = (valueName, defaultValue) => {
        handleEditNumberWithSetter(defaultValue, (value) => {
            let parsed = parseInt(value);
            setLocalMenu((prev) => {
                let next = { ...prev };
                next[valueName] = parsed
                return next;
            });
        });
    };

    const handleEditSubtypeNumber = (subtypeName, valueName, defaultValue) => {
        handleEditNumberWithSetter(defaultValue, (value) => {
            let parsed = parseInt(value);
            if (!parsed) parsed = 0;
            setLocalMenu((prev) => {
                let next = { ...prev };
                if (!next[subtypeName]) next[subtypeName] = {};
                next[subtypeName][valueName] = parsed
                return next;
            });
        });
    }

    const handleEditSubtypeFloat = (subtypeName, valueName) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: (value) => {
                let parsed = parseFloat(value);
                if (!parsed) parsed = 0;
                setLocalMenu((prev) => {
                    let next = { ...prev };
                    if (!next[subtypeName]) next[subtypeName] = {};
                    next[subtypeName][valueName] = parsed
                    return next;
                });
            },
            initialValue: "0.0",
            textLabel: t("Value"),
            title: t("Value"),
            onChange: (e) => {
                let filteredValue = e.target.value.replace(/[^0-9.-]/g, '');
                return filteredValue.substr(0, 6);
            }
        });
    };

    const handleEditSubtypeTranslation = (initialValue, subtypeName, valueName) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: (value) => {
                setLocalMenu((prev) => {
                    let next = { ...prev };
                    if (!next[subtypeName]) next[subtypeName] = {};
                    let nextSubtype = next[subtypeName];
                    if (!nextSubtype[valueName]) nextSubtype[valueName] = {};
                    nextSubtype[valueName][i18n.language] = value;
                    return next;
                });
            },
            initialValue: initialValue,
            textLabel: "Text",
            title: "Text",
            lines: 5
        });
    };

    const handleEditSubtypeUnitsTranslationId = (subtypeName, valueName) => {
        const addTranslations = (m, possibleValues) => {
            let unitsValue = null;
            unitsValue = m.FloatAsIntItem?.UnitsTranslationId;
            if (unitsValue) possibleValues.push(unitsValue);
            unitsValue = m.IntegerItem?.UnitsTranslationId;
            if (unitsValue) possibleValues.push(unitsValue);
            unitsValue = m.SirenRingtoneItem?.UnitsTranslationId;
            if (unitsValue) possibleValues.push(unitsValue);
            m.Children?.forEach(c => {
                addTranslations(c, possibleValues);
            });
        }
        let possibleIds = [];
        Object.values(settingInfo.Menus).forEach(m => {
            addTranslations(m, possibleIds);
        });
        possibleIds = [...new Set(possibleIds)];
        if (possibleIds.length === 0) alert("No available UnitsTranslationIds");

        let initialId = localMenu[subtypeName] ? localMenu[subtypeName][valueName] : null;
        let possibleTranslations = possibleIds.map(id => settingInfo.Translations[i18n.language][id]);
        dialogDispatch({
            type: DialogActionTypes.AutocompleteDialogOpen,
            initialValue: settingInfo.Translations[i18n.language][initialId] ?? possibleTranslations[0],
            label: "Units",
            title: "Units",
            options: possibleTranslations,
            getOptionLabel: (option) => `${option?.Text} (${option?.Id})`,
            multiple: false,
            handleConfirm: (value) => {
                if (value) {
                    setLocalMenu((prev) => {
                        let next = { ...prev };
                        if (!next[subtypeName]) next[subtypeName] = {};
                        next[subtypeName][valueName] = value.Id;
                        return next;
                    });
                }
            }
        });
    };

    const handleEditTranslation = (initialValue, valueName) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: (value) => {
                setLocalMenu((prev) => {
                    let next = { ...prev };
                    if (!next[valueName]) next[valueName] = {};
                    next[valueName][i18n.language] = value;
                    return next;
                });
            },
            initialValue: initialValue,
            textLabel: "Text",
            title: "Text",
            lines: 5
        });
    };

    const handleEditStructureId = () => {
        let flatStructures = getFlatStructures(Object.values(settingInfo.Structures));
        dialogDispatch({
            type: DialogActionTypes.AutocompleteDialogOpen,
            initialValue: flatStructures[localMenu.StructureId] ?? flatStructures[1],
            label: "Structure ID",
            title: "Structure ID",
            options: Object.values(flatStructures),
            getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
            multiple: false,
            handleConfirm: (value) => {
                if (value) {
                    setLocalMenu((prev) => ({ ...prev, StructureId: value.Id }));
                }
            }
        });
    };

    const handleEditSirenRingtoneEventId = () => {
        let items = Object.keys(SettingsMenuSirenRingtoneEventId).map(k => <MenuItem key={k} value={k}>
            {SettingsMenuSirenRingtoneEventId[k]}
        </MenuItem>)

        dialogDispatch({
            type: DialogActionTypes.SimpleSelectDialogOpen,
            initialValue: localMenu.SirenRingtoneItem?.EventId ?? 0,
            label: "Event ID",
            title: "Event ID",
            menuItems: items,
            handleConfirm: (value) => {
                setLocalMenu((prev) => ({ ...prev, SirenRingtoneItem: { ...prev.SirenRingtoneItem, EventId: value } }));
            }
        });
    };

    const handleEditTextType = () => {
        let items = Object.keys(SettingsMenuTextItemType).map(k => <MenuItem key={k} value={k}>
            {SettingsMenuTextItemType[k]}
        </MenuItem>)

        dialogDispatch({
            type: DialogActionTypes.SimpleSelectDialogOpen,
            initialValue: localMenu.TextItem?.InputTypeId ?? 0,
            label: "Text type",
            title: "Text type",
            menuItems: items,
            handleConfirm: (value) => {
                setLocalMenu((prev) => ({ ...prev, TextItem: { ...prev.TextItem, InputTypeId: value } }));
            }
        });
    };

    const handleEditTextValidationPattern = () => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: (pattern) => {
                setLocalMenu((prev) => ({ ...prev, TextItem: { ...prev.TextItem, ValidationPattern: pattern } }));
            },
            initialValue: localMenu.TextItem ? localMenu.TextItem.ValidationPattern : "",
            textLabel: "Validation pattern",
            title: "Validation pattern"
        });
    };

    const showTranslationsAutocomplete = (initialId, handleConfirm) => {
        dialogDispatch({
            type: DialogActionTypes.AutocompleteDialogOpen,
            initialValue: initialId ? settingInfo.Translations[i18n.language][initialId] : Object.values(settingInfo.Translations[i18n.language])[0],
            label: "Translation ID",
            title: "Translation ID",
            options: Object.values(settingInfo.Translations[i18n.language]),
            getOptionLabel: (option) => `${option?.Text?.substr(0, 25)} (${option?.Id})`,
            multiple: false,
            handleConfirm: handleConfirm
        });
    }

    const handleLinkTranslation = (idFieldName, textFieldName) => {
        showTranslationsAutocomplete(localMenu[idFieldName], (value) => {
            setLocalMenu((prev) => {
                let next = { ...prev };
                next[textFieldName] = {};
                next[textFieldName]["en"] = settingInfo.Translations["en"][value.Id]?.Text;
                next[textFieldName]["ru"] = settingInfo.Translations["ru"][value.Id]?.Text;
                next[idFieldName] = value.Id;
                return next;
            });
        })
    };

    const handleLinkSubtypeTranslation = (subtypeName, idFieldName, textFieldName) => {
        showTranslationsAutocomplete(localMenu[idFieldName], (value) => {
            setLocalMenu((prev) => {
                let next = { ...prev };
                let nextSubtype = { ...next[subtypeName] };
                nextSubtype[textFieldName] = {};
                nextSubtype[textFieldName]["en"] = settingInfo.Translations["en"][value.Id]?.Text;
                nextSubtype[textFieldName]["ru"] = settingInfo.Translations["ru"][value.Id]?.Text;
                nextSubtype[idFieldName] = value.Id;
                next[subtypeName] = nextSubtype;
                return next;
            });
        })
    };

    const handleDuplicateTranslation = (idFieldName, textFieldName) => {
        setLocalMenu((prev) => {
            let next = { ...prev };
            next[textFieldName] = {};
            next[textFieldName]["en"] = settingInfo.Translations["en"][next[idFieldName]]?.Text;
            next[textFieldName]["ru"] = settingInfo.Translations["ru"][next[idFieldName]]?.Text;
            delete next[idFieldName];
            return next;
        });
    };

    const handleDuplicateSubtypeTranslation = (subtypeName, idFieldName, textFieldName) => {
        setLocalMenu((prev) => {
            let next = { ...prev };
            let nextSubtype = { ...next[subtypeName] };
            nextSubtype[textFieldName] = {};
            nextSubtype[textFieldName]["en"] = settingInfo.Translations["en"][nextSubtype[idFieldName]]?.Text;
            nextSubtype[textFieldName]["ru"] = settingInfo.Translations["ru"][nextSubtype[idFieldName]]?.Text;
            delete nextSubtype[idFieldName];
            next[subtypeName] = nextSubtype;
            return next;
        });
    };

    const handleDuplicateOptionTranslation = (optionValue) => {
        setLocalMenu((prev) => {
            let subtypeName = "OptionsItem";
            let next = { ...prev };
            let nextSubtype = { ...next[subtypeName] };
            let srcTranslationId = nextSubtype.Value2TranslationId[optionValue];
            nextSubtype.Value2TranslationId[optionValue] = null;
            if (!nextSubtype.Value2TranslationText) nextSubtype.Value2TranslationText = {};
            nextSubtype.Value2TranslationText[optionValue] = {};
            nextSubtype.Value2TranslationText[optionValue]["en"] = settingInfo.Translations["en"][srcTranslationId]?.Text;
            nextSubtype.Value2TranslationText[optionValue]["ru"] = settingInfo.Translations["ru"][srcTranslationId]?.Text;
            next[subtypeName] = nextSubtype;
            return next;
        });
    };

    const handleLinkOptionTranslation = (optionValue) => {
        let srcTranslationId = localMenu.OptionsItem?.Value2TranslationId[optionValue];
        showTranslationsAutocomplete(srcTranslationId, (value) => {
            setLocalMenu((prev) => {
                let newTranslationId = value.Id;
                let subtypeName = "OptionsItem";
                let next = { ...prev };
                let nextSubtype = { ...next[subtypeName] };
                if (!nextSubtype.Value2TranslationText) nextSubtype.Value2TranslationText = {};
                if (!nextSubtype.Value2TranslationText[optionValue]) nextSubtype.Value2TranslationText[optionValue] = {};
                nextSubtype.Value2TranslationText[optionValue]["en"] = settingInfo.Translations["en"][newTranslationId]?.Text;
                nextSubtype.Value2TranslationText[optionValue]["ru"] = settingInfo.Translations["ru"][newTranslationId]?.Text;
                nextSubtype.Value2TranslationId[optionValue] = newTranslationId;
                next[subtypeName] = nextSubtype;
                return next;
            });
        })
    };

    return (
        <>
            <Button
                onClick={() => handleSave(localMenu)}
                disabled={!areMenusDifferent(localMenu, menu)}
                variant="contained"
                color="primary"
                style={{ marginBottom: "16px" }}
            >
                {t("Save")}
            </Button>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>ID: </b>{localMenu.Id}</Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>Parent ID: </b>{localMenu?.ParentId ? `${settingInfo.Menus[localMenu.ParentId]?.Name} (${localMenu.ParentId})` : ""}</Typography>
                <IconButton
                    color="primary"
                    disabled={localMenu.Children && localMenu.Children.length > 0}
                    onClick={handleEditParentId}>
                    <EditIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>Name: </b>{localMenu.Name}</Typography>
                <IconButton
                    color="primary"
                    onClick={handleEditName}>
                    <EditIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>Type: </b>{localMenu?.Type ? `${SettingsMenuTypes[localMenu.Type]} (${localMenu.Type})` : ""}</Typography>
                <IconButton
                    color="primary"
                    disabled={localMenu.Children && localMenu.Children.length > 0}
                    onClick={handleEditType}>
                    <EditIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>Order: </b>{localMenu.Order}</Typography>
                <IconButton
                    color="primary"
                    onClick={() => handleEditNumber("Order", localMenu.Order?.toString())}
                >
                    <EditIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>Privacy: </b>{localMenu.Privacy}</Typography>
                <IconButton
                    color="primary"
                    onClick={() => handleEditNumber("Privacy", localMenu.Privacy?.toString())}
                >
                    <EditIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography>
                    <b>Title: </b>
                    {generateTranslationStringTag(localMenu.TitleTranslationText, localMenu.TitleTranslationId, i18n.language, settingInfo.Translations)}
                </Typography>
                <MenuEditDuplicate
                    duplicateDisabled={!localMenu.TitleTranslationId}
                    handleDuplicateClick={() => handleDuplicateTranslation("TitleTranslationId", "TitleTranslationText")}
                    handleEditClick={() => handleEditTranslation(settingInfo.Translations[i18n.language][localMenu.TitleTranslationId]?.Text, "TitleTranslationText")}
                    handleLinkClick={() => handleLinkTranslation("TitleTranslationId", "TitleTranslationText")}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography>
                    <b>Description: </b>
                    {generateTranslationStringTag(localMenu.DescriptionTranslationText, localMenu.DescriptionTranslationId, i18n.language, settingInfo.Translations)}
                </Typography>
                <MenuEditDuplicate
                    duplicateDisabled={!localMenu.DescriptionTranslationId}
                    handleDuplicateClick={() => handleDuplicateTranslation("DescriptionTranslationId", "DescriptionTranslationText")}
                    handleEditClick={() => handleEditTranslation(settingInfo.Translations[i18n.language][localMenu.DescriptionTranslationId]?.Text, "DescriptionTranslationText")}
                    handleLinkClick={() => handleLinkTranslation("DescriptionTranslationId", "DescriptionTranslationText")}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>PrototypeId: </b>{localMenu.PrototypeId}</Typography>
                <IconButton
                    color="primary"
                    onClick={handleEditPrototypeId}
                >
                    <EditIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>Count: </b>{localMenu.Count}</Typography>
                <IconButton
                    color="primary"
                    onClick={() => handleEditNumber("Count", localMenu.Count?.toString())}
                >
                    <EditIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>Index: </b>{localMenu.Index}</Typography>
                <IconButton
                    color="primary"
                    onClick={() => handleEditNumber("Index", localMenu.Index?.toString())}
                >
                    <EditIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>StructureId: </b>{localMenu.StructureId}</Typography>
                <IconButton
                    color="primary"
                    onClick={handleEditStructureId}
                >
                    <EditIcon />
                </IconButton>
            </div>

            {
                localMenu.Type === SettingsMenuTypesByName.FloatAsIntMenuItem ? <>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography><b>MinValue: </b>{localMenu.FloatAsIntItem?.MinValue}</Typography>
                        <IconButton
                            color="primary"
                            onClick={() => handleEditSubtypeFloat("FloatAsIntItem", "MinValue")}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography><b>MaxValue: </b>{localMenu.FloatAsIntItem?.MaxValue}</Typography>
                        <IconButton
                            color="primary"
                            onClick={() => handleEditSubtypeFloat("FloatAsIntItem", "MaxValue")}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography>
                            <b>UnitsTranslationId: </b>
                            {settingInfo.Translations[i18n.language][localMenu.FloatAsIntItem?.UnitsTranslationId]?.Text}
                        </Typography>
                        <IconButton
                            color="primary"
                            onClick={() => handleEditSubtypeUnitsTranslationId("FloatAsIntItem", "UnitsTranslationId")}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography>
                            <b>MinValueText: </b>
                            {generateTranslationStringTag(localMenu.FloatAsIntItem?.MinValueText, localMenu.FloatAsIntItem?.MinValueTextId, i18n.language, settingInfo.Translations)}
                        </Typography>
                        <MenuEditDuplicate
                            duplicateDisabled={!localMenu.FloatAsIntItem?.MinValueTextId}
                            handleDuplicateClick={() => handleDuplicateSubtypeTranslation("FloatAsIntItem", "MinValueTextId", "MinValueText")}
                            handleEditClick={() => handleEditSubtypeTranslation(settingInfo.Translations[i18n.language][localMenu.FloatAsIntItem?.MinValueTextId]?.Text, "FloatAsIntItem", "MinValueText")}
                            handleLinkClick={() => handleLinkSubtypeTranslation("FloatAsIntItem", "MinValueTextId", "MinValueText")}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography>
                            <b>MaxValueText: </b>
                            {generateTranslationStringTag(localMenu.FloatAsIntItem?.MaxValueText, localMenu.FloatAsIntItem?.MaxValueTextId, i18n.language, settingInfo.Translations)}
                        </Typography>
                        <MenuEditDuplicate
                            duplicateDisabled={!localMenu.FloatAsIntItem?.MaxValueTextId}
                            handleDuplicateClick={() => handleDuplicateSubtypeTranslation("FloatAsIntItem", "MaxValueTextId", "MaxValueText")}
                            handleEditClick={() => handleEditSubtypeTranslation(settingInfo.Translations[i18n.language][localMenu.FloatAsIntItem?.MaxValueTextId]?.Text, "FloatAsIntItem", "MaxValueText")}
                            handleLinkClick={() => handleLinkSubtypeTranslation("FloatAsIntItem", "MaxValueTextId", "MaxValueText")}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography><b>Step: </b>{localMenu.FloatAsIntItem?.Step}</Typography>
                        <IconButton
                            color="primary"
                            onClick={() => handleEditSubtypeFloat("FloatAsIntItem", "Step")}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                </> : <></>
            }

            {
                localMenu.Type === SettingsMenuTypesByName.IntegerMenuItem ? <>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography><b>MinValue: </b>{localMenu.IntegerItem?.MinValue}</Typography>
                        <IconButton
                            color="primary"
                            onClick={() => handleEditSubtypeNumber("IntegerItem", "MinValue", localMenu.IntegerItem?.MinValue?.toString())}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography><b>MaxValue: </b>{localMenu.IntegerItem?.MaxValue}</Typography>
                        <IconButton
                            color="primary"
                            onClick={() => handleEditSubtypeNumber("IntegerItem", "MaxValue", localMenu.IntegerItem?.MaxValue?.toString())}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography>
                            <b>UnitsTranslation: </b>
                            {settingInfo.Translations[i18n.language][localMenu.IntegerItem?.UnitsTranslationId]?.Text}
                        </Typography>
                        <IconButton
                            color="primary"
                            onClick={() => handleEditSubtypeUnitsTranslationId("IntegerItem", "UnitsTranslationId")}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography>
                            <b>MinValueText: </b>
                            {generateTranslationStringTag(localMenu.IntegerItem?.MinValueText, localMenu.IntegerItem?.MinValueTextId, i18n.language, settingInfo.Translations)}
                        </Typography>
                        <MenuEditDuplicate
                            duplicateDisabled={!localMenu.IntegerItem?.MinValueTextId}
                            handleDuplicateClick={() => handleDuplicateSubtypeTranslation("IntegerItem", "MinValueTextId", "MinValueText")}
                            handleEditClick={() => handleEditSubtypeTranslation(settingInfo.Translations[i18n.language][localMenu.IntegerItem?.MaxValueTextId]?.Text, "IntegerItem", "MinValueText")}
                            handleLinkClick={() => handleLinkSubtypeTranslation("IntegerItem", "MinValueTextId", "MinValueText")}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography>
                            <b>MaxValueText: </b>
                            {generateTranslationStringTag(localMenu.IntegerItem?.MaxValueText, localMenu.IntegerItem?.MaxValueTextId, i18n.language, settingInfo.Translations)}
                        </Typography>
                        <MenuEditDuplicate
                            duplicateDisabled={!localMenu.IntegerItem?.MaxValueTextId}
                            handleDuplicateClick={() => handleDuplicateSubtypeTranslation("IntegerItem", "MaxValueTextId", "MaxValueText")}
                            handleEditClick={() => handleEditSubtypeTranslation(settingInfo.Translations[i18n.language][localMenu.IntegerItem?.MaxValueTextId]?.Text, "IntegerItem", "MaxValueText")}
                            handleLinkClick={() => handleLinkSubtypeTranslation("IntegerItem", "MaxValueTextId", "MaxValueText")}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography><b>Factor: </b>{localMenu.IntegerItem?.Factor}</Typography>
                        <IconButton
                            color="primary"
                            onClick={() => handleEditSubtypeNumber("IntegerItem", "Factor", localMenu.IntegerItem?.Factor?.toString())}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                </> : <></>
            }

            {
                localMenu.Type === SettingsMenuTypesByName.OptionMenuItem ? <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography><b>Value2TranslationId: </b>{JSON.stringify(localMenu.Value2TranslationId)}</Typography>
                        <Table size="small">
                            <TableBody>
                                {
                                    localMenu?.OptionsItem ? Object.keys(localMenu.OptionsItem.Value2TranslationId).map(v =>
                                        <TableRow key={v}>
                                            <TableCell>
                                                <IconButton
                                                    color="secondary"
                                                    onClick={(e) => { handleRemoveOptionsItemValue(v) }}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                                <b>{v}</b>
                                            </TableCell>
                                            <TableCell>
                                                { generateTranslationStringTag(localMenu.OptionsItem.Value2TranslationText?.[v], localMenu.OptionsItem.Value2TranslationId?.[v], i18n.language, settingInfo.Translations) }
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    color="primary"
                                                    onClick={(e) => {
                                                        handleOptionsItemEditText(v, generateTranslationString(localMenu.OptionsItem.Value2TranslationText?.[v], localMenu.OptionsItem.Value2TranslationId?.[v], i18n.language, settingInfo.Translations, false))
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    color="primary"
                                                    onClick={(e) => {
                                                        handleLinkOptionTranslation(v)
                                                    }}
                                                >
                                                    <LinkIcon />
                                                </IconButton>
                                                <IconButton
                                                    disabled={localMenu.OptionsItem.Value2TranslationId?.[v] == null}
                                                    color="primary"
                                                    onClick={(e) => {
                                                        handleDuplicateOptionTranslation(v)
                                                    }}
                                                >
                                                    <DuplicateIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ) : <></>
                                }
                                <TableRow>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            onClick={(e) => { handleAddOptionsItemValue(); }}
                                        >
                                            <AddCircleIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </> : <></>
            }

            {
                localMenu.Type === SettingsMenuTypesByName.SwitchMenuItem ? <>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography>
                            <b>OnTitleTranslationText: </b>
                            {generateTranslationStringTag(localMenu.SwitchItem?.OnTitleTranslationText, localMenu.SwitchItem?.OnTitleTranslationId, i18n.language, settingInfo.Translations)}
                        </Typography>
                        <MenuEditDuplicate
                            duplicateDisabled={!localMenu.SwitchItem?.OnTitleTranslationId}
                            handleDuplicateClick={() => handleDuplicateSubtypeTranslation("SwitchItem", "OnTitleTranslationId", "OnTitleTranslationText")}
                            handleEditClick={() => handleEditSubtypeTranslation(settingInfo.Translations[i18n.language][localMenu.SwitchItem?.OnTitleTranslationId]?.Text, "SwitchItem", "OnTitleTranslationText")}
                            handleLinkClick={() => handleLinkSubtypeTranslation("SwitchItem", "OnTitleTranslationId", "OnTitleTranslationText")}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography>
                            <b>OffTitleTranslationText: </b>
                            {generateTranslationStringTag(localMenu.SwitchItem?.OffTitleTranslationText, localMenu.SwitchItem?.OffTitleTranslationId, i18n.language, settingInfo.Translations)}
                        </Typography>
                        <MenuEditDuplicate
                            duplicateDisabled={!localMenu.SwitchItem?.OffTitleTranslationId}
                            handleDuplicateClick={() => handleDuplicateSubtypeTranslation("SwitchItem", "OffTitleTranslationId", "OffTitleTranslationText")}
                            handleEditClick={() => handleEditSubtypeTranslation(settingInfo.Translations[i18n.language][localMenu.SwitchItem?.OffTitleTranslationId]?.Text, "SwitchItem", "OffTitleTranslationText")}
                            handleLinkClick={() => handleLinkSubtypeTranslation("SwitchItem", "OffTitleTranslationId", "OffTitleTranslationText")}
                        />
                    </div>
                </> : <></>
            }

            {
                localMenu.Type === SettingsMenuTypesByName.SirenRingtoneMenuItem ? <>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography><b>EventId: </b>{localMenu.SirenRingtoneItem ? SettingsMenuSirenRingtoneEventId[localMenu.SirenRingtoneItem.EventId] : ""}</Typography>
                        <IconButton
                            color="primary"
                            onClick={handleEditSirenRingtoneEventId}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                </> : <></>
            }

            {
                localMenu.Type === SettingsMenuTypesByName.TextMenuItem ? <>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography><b>MaxLength: </b>{localMenu.TextItem?.MaxLength}</Typography>
                        <IconButton
                            color="primary"
                            onClick={() => handleEditSubtypeNumber("TextItem", "MaxLength", localMenu.TextItem?.MaxLength?.toString())}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography><b>InputTypeId: </b>{localMenu.TextItem ? SettingsMenuTextItemType[localMenu.TextItem.InputTypeId] : ""}</Typography>
                        <IconButton
                            color="primary"
                            onClick={handleEditTextType}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography><b>ValidationPattern: </b>{localMenu.TextItem?.ValidationPattern}</Typography>
                        <IconButton
                            color="primary"
                            onClick={handleEditTextValidationPattern}
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography>
                            <b>ValidationErrorText: </b>
                            {generateTranslationStringTag(localMenu.TextItem?.ValidationErrorText, localMenu.TextItem?.ValidationErrorTextId, i18n.language, settingInfo.Translations)}
                        </Typography>
                        <MenuEditDuplicate
                            duplicateDisabled={!localMenu.TextItem?.ValidationErrorTextId}
                            handleDuplicateClick={() => handleDuplicateSubtypeTranslation("TextItem", "ValidationErrorTextId", "ValidationErrorText")}
                            handleEditClick={() => handleEditSubtypeTranslation(settingInfo.Translations[i18n.language][localMenu.TextItem?.ValidationErrorTextId]?.Text, "TextItem", "ValidationErrorText")}
                            handleLinkClick={() => handleLinkSubtypeTranslation("TextItem", "ValidationErrorTextId", "ValidationErrorText")}
                        />
                    </div>
                </> : <></>
            }
        </>);
}
