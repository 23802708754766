import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
  Grid,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PdfIcon from "@mui/icons-material/PictureAsPdf";

import ReactFileReader from "react-file-reader";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import { useTranslation } from "react-i18next";

import { LanguageConverter } from "../../enums/LanguageConverter";
import {
  useDialogContext,
  DialogActionTypes,
} from "../../context/DialogContext";
import { useBackend } from "../../context/BackendContext";

export default function ConfigurationDialog({
  isOpened,
  config,
  saveAllowed,
  handleCreate,
  handleClose,
}) {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t, i18n } = useTranslation();

  const [isInitializing, setIsInitializing] = useState(true);
  const [name, setName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [fullDescription, setFullDescription] = useState("");
  const [accessibleEveryone, setAccessibleEveryone] = useState(false);
  const [carBrandList, setCarBrandList] = useState([
    <option key={-2} value={-2}>
      {t("Loading")}
    </option>,
  ]);
  const [carBrand, setCarBrand] = useState(-1);
  const [carModelList, setCarModelList] = useState([
    <option key={-2} value={-2}>
      {t("Brand not selected")}
    </option>,
  ]);
  const [carModel, setCarModel] = useState(-1);
  const [carEquipmentList, setCarEquipmentList] = useState([
    <option key={-2} value={-2}>
      {t("Model not selected")}
    </option>,
  ]);
  const [carEquipment, setCarEquipment] = useState(-1);
  const [alarmTypeList, setAlarmTypeList] = useState([
    { id: -2, name: t("Loading") },
  ]);
  const [alarmType, setAlarmType] = useState(-1);
  const [language, setLanguage] = useState("ru");
  const [checkListPanels, setChecklistPanel] = useState(
    <Typography>{t("Loading questions")}</Typography>
  );
  const [questionsList, setQuestionsList] = useState({});
  const [dxsData, setDxsData] = useState("");
  const [dxsFilename, setDxsFilename] = useState(t("DXS file not loaded"));
  const [imageFiles, setImageFiles] = useState([]);
  const [mapFilename, setMapFilename] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const openSettingsEditor = (alarmType, data) => {
    var entry = alarmTypeList.find((opt) => opt.id === alarmType);
    if (entry === undefined || entry === null) {
      console.log("Alarm not found in a list");
      return;
    }

    dialogDispatch({
      type: DialogActionTypes.SettingsEditorDialogOpen,
      data: JSON.parse(data),
      alarmName: entry.name,
    });
  };

  const deleteImageFile = (index) => {
    setImageFiles(() => {
      const updatedImageFiles = [...imageFiles];
      updatedImageFiles.splice(index, 1);
      return updatedImageFiles;
    });
  };

  const handleImageFileSelect = (files) => {
    if (files[0].size > 16 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    if (imageFiles.find((f) => f.name === files[0].name)) {
      alert(t("File already specified"), { file: files[0].name });
      return;
    }

    backend
      .bckFilesUploadConfigurationImage(config.id, files[0].name, files[0])
      .then((json) => {
        setImageFiles([
          ...imageFiles,
          {
            name: files[0].name,
            description: "",
            fullDescription: "",
            previewBase64: json.content,
          },
        ]);
      });
  };

  const handleMapFileSelect = (files) => {
    if (files[0].size > 40 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    backend.bckFilesUploadMountingMap(files[0].name, files[0]);
    setMapFilename(files[0].name);
  };

  const handleDxsFileSelect = (files) => {
    if (files[0].size > 32 * 1024) {
      alert(t("File is too large"));
      return;
    }

    var reader = new FileReader();
    reader.onloadend = function (e) {
      try {
        var dxsJson = JSON.parse(reader.result);
        if (!dxsJson.hasOwnProperty("settings")) {
          alert(t("Unknown DXS file format"));
          return;
        }
        setDxsFilename(files[0].name);
        setDxsData(reader.result);
      } catch {
        alert(t("Unknown DXS file format/encoding"));
      }
    };
    reader.readAsText(files[0], "UTF-16");
  };

  const invertIsQuestionSet = useCallback((questionId) => {
    setQuestionsList((q) => {
      q[questionId] = !q[questionId];
      return q;
    });
  }, []);

  const loadAlarms = () => {
    backend.bckDevicesList("", true, false).then((json) => {
      var listElements = json.content.map((alarm) => ({
        id: alarm.id,
        name: alarm.name,
      }));
      setAlarmTypeList(listElements);
    });
  };

  const loadCarBrands = () => {
    backend.bckCarsListBrands(false, true, false).then((json) => {
      var sortedElements = json.content.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      var listElements = sortedElements.map((car) => (
        <option key={car.id} value={car.id}>
          {car.name}
        </option>
      ));
      listElements.unshift([<option key={-1} value={-1}></option>]);
      setCarBrandList(listElements);
    });
  };

  const loadCarModels = useCallback(
    (brandId) => {
      setCarModelList([
        <option key={-3} value={-3}>
          {t("Loading")}
        </option>,
      ]);
      setCarModel(-3);
      backend.bckCarsListModels(brandId, false, true).then((json) => {
        var sortedElements = json.content.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        var listElements = sortedElements.map((model) => (
          <option key={model.id} value={model.id}>
            {model.name}
          </option>
        ));
        if (listElements.length === 0) {
          setCarModel(-4);
          setCarModelList([
            <option key={-4} value={-4}>
              {t("No available models")}
            </option>,
          ]);
        } else {
          listElements.unshift(<option key={-1} value={-1}></option>);
          setCarModelList(listElements);
        }
      });
    },
    [t]
  );

  const loadCarEquipments = useCallback(
    (modelId) => {
      setCarEquipmentList([
        <option key={-3} value={-3}>
          {t("Loading")}
        </option>,
      ]);
      setCarEquipment(-3);
      backend
        .bckCarsListEquipments(modelId, null, LanguageConverter[i18n.language])
        .then((json) => {
          var listElements = json.content.map((equipment) => (
            <option key={equipment.id} value={equipment.id}>
              {equipment.name}
            </option>
          ));
          if (listElements.length === 0) {
            setCarEquipment(-4);
            setCarEquipmentList([
              <option key={-4} value={-4}>
                {t("No available equipments")}
              </option>,
            ]);
          } else {
            listElements.unshift(<option key={-1} value={-1}></option>);
            setCarEquipmentList(listElements);
          }
        });
    },
    [t, i18n.language]
  );

  const handleCheckboxChange = useCallback(
    (event, questionId) => {
      invertIsQuestionSet(questionId);
    },
    [invertIsQuestionSet]
  );

  const renderChecklistQuestions = useCallback(
    (questions) => {
      setQuestionsList((q) => {
        questions.forEach((question) => (q[question.id] = question.isRequired));
        return q;
      });
      return questions.map((question) => (
        <FormControlLabel
          key={question.id}
          control={
            <Checkbox
              color="primary"
              defaultChecked={question.isRequired}
              onChange={(e) => handleCheckboxChange(e, question.id)}
            />
          }
          label={question.text}
        />
      ));
    },
    [handleCheckboxChange]
  );

  const renderChecklistSubparts = useCallback(
    (subparts) => {
      return subparts.map((subpart) => (
        <Accordion
          key={subpart.name}
          style={{ background: "#C2C2C2" }}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{subpart.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div id={subpart.name}>
              {renderChecklistQuestions(subpart.questions)}
            </div>
          </AccordionDetails>
        </Accordion>
      ));
    },
    [renderChecklistQuestions]
  );

  const renderChecklistParts = useCallback(
    (parts) => {
      var partsPanels = parts.map((part) => (
        <Accordion key={part.name} style={{ background: "#D2D2D2" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{part.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>{renderChecklistSubparts(part.subparts)}</div>
          </AccordionDetails>
        </Accordion>
      ));
      return <div>{partsPanels}</div>;
    },
    [renderChecklistSubparts]
  );

  const loadChecklist = useCallback(() => {
    if (config !== undefined) {
      setChecklistPanel(
        <div key="checklistLoading">{t("Loading questions")}</div>
      );
      backend
        .bckConfigurationsGetChecklist(config ? config.id : null)
        .then((json) => {
          setChecklistPanel(renderChecklistParts(json.content));
        });
    }
  }, [t, config, renderChecklistParts]);

  useEffect(() => {
    setCarEquipmentList([
      <option key={-4} value={-4}>
        {t("No available equipments")}
      </option>,
    ]);
    setCarModelList([
      <option key={-2} value={-2}>
        {t("Model not selected")}
      </option>,
    ]);
  }, [t, i18n.language]);

  useEffect(() => {
    var effConfig = config;
    if (effConfig === null || effConfig === undefined) {
      effConfig = {
        id: -1,
        name: "",
        shortDescription: "",
        fullDescription: "",
        carBrandName: "",
        carBrandId: -1,
        carModelName: "",
        carModelId: -1,
        carEquipmentName: "",
        carEquipmentId: -1,
        alarmName: "",
        alarmId: -1,
        authorEmail: "",
        authorId: -1,
        configFileName: t("DXS file not loaded"),
        dateAdded: "",
        dateChanged: "",
        language: "ru",
        access: false,
      };
      setDxsData("");
      setMapFilename(null);
      setImageFiles([]);
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
      setDxsData(t("Loading"));
      backend.bckConfigurationsGetAdditionalInfo(effConfig.id).then((json) => {
        setSaveDisabled(false);
        setDxsData(json.content.dxsData);
        setMapFilename(json.content.mountingMapUrl);
        setImageFiles(json.content.images);
      });
    }
    setName(effConfig.name);
    setShortDescription(effConfig.shortDescription);
    setFullDescription(effConfig.fullDescription);
    setCarBrand(effConfig.carBrandId);
    loadCarModels(effConfig.carBrandId);
    setCarModel(effConfig.carModelId);
    loadCarEquipments(effConfig.carModelId);
    setCarEquipment(effConfig.carEquipmentId);
    setAlarmType(effConfig.alarmId);
    setFullDescription(effConfig.fullDescription);
    setDxsFilename(effConfig.configFileName);
    setAccessibleEveryone(effConfig.access);
    setLanguage(effConfig.language);
    loadChecklist();
  }, [config, t]);

  useEffect(() => {
    if (isInitializing) {
      setIsInitializing(false);
      loadAlarms();
      loadCarBrands();
      loadChecklist();
    }
  }, [isInitializing, config]);

  const onCreateClick = () => {
    var questionIds = [];
    for (var questionId of Object.keys(questionsList)) {
      if (questionsList[questionId]) {
        questionIds.push(parseInt(questionId));
      }
    }

    backend.bckConfigurationCreateOrUpdate(
      config === null || config.id === null || config.id < 0 ? null : config.id,
      name,
      shortDescription,
      fullDescription,
      carEquipment,
      alarmType,
      questionIds,
      dxsFilename,
      dxsData,
      mapFilename,
      imageFiles,
      accessibleEveryone && mapFilename !== null && mapFilename.trim() !== "",
      language
    );

    handleCreate();
  };

  const onSelectCarBrand = (event, child) => {
    var newBrand = event.target.value;
    setCarBrand(newBrand);
    if (newBrand < 0) {
      setCarModel(-2);
      setCarModelList([
        <option key={-2} value={-2}>
          {t("Brand not selected")}
        </option>,
      ]);
    } else {
      loadCarModels(newBrand);
    }
  };

  const onSelectCarModel = (event, child) => {
    var newModel = event.target.value;
    setCarModel(newModel);
    if (newModel < 0) {
      setCarEquipment(-2);
      setCarEquipmentList([
        <option key={-2} value={-2}>
          {t("Brand not selected")}
        </option>,
      ]);
    } else {
      loadCarEquipments(newModel);
    }
  };

  const getSizeComment = (data) => {
    if (dxsData === undefined || dxsData === null || dxsData.trim() === "")
      return "";
    try {
      let parsed = JSON.parse(dxsData);
      let settings = parsed.settings;
      if (settings === undefined || settings === null || settings.trim() === "")
        return "";
      let length = settings.length / 2;
      return ` (${length} bytes)`;
    } catch (e) {
      return "";
    }
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle id="form-dialog-title">{t("Configuration")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="name"
          label={t("Name/Title")}
          style={{ marginTop: 16, marginBottom: 16 }}
          value={name}
          onChange={(e) => {
            if (!e.target.value.match(/[`"'\\/~^:,;?!%$@*]/)) {
              setName(e.target.value);
            }
          }}
          fullWidth
        />
        <TextField
          id="shortDescription"
          label={t("Short description")}
          style={{ marginBottom: 16 }}
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          fullWidth
        />
        <CKEditor
          editor={Editor}
          data={fullDescription}
          style={{ marginBottom: 16 }}
          onChange={(event, editor) => {
            setFullDescription(editor.getData());
          }}
        />
        <FormControlLabel
          style={{ marginBottom: 16 }}
          control={
            <Checkbox
              disabled={mapFilename === null || mapFilename.trim() === ""}
              checked={accessibleEveryone}
              onChange={() => setAccessibleEveryone(!accessibleEveryone)}
              color="primary"
            />
          }
          label={t("Available to everyone")}
        />
        <Grid
          container
          direction="row"
          spacing={2}
          style={{ paddingTop: 12, marginBottom: 12, marginLeft: 2 }}
        >
          <FormControl style={{ marginRight: 16, marginBottom: 16 }}>
            <InputLabel htmlFor="car-brand-select-label">
              {t("Car brand")}
            </InputLabel>
            <Select
              native
              label={t("Car brand")}
              value={carBrand}
              onChange={onSelectCarBrand}
              inputProps={{
                id: "car-brand-select-label",
              }}
            >
              {carBrandList}
            </Select>
          </FormControl>
          <FormControl style={{ marginRight: 16, marginBottom: 16 }}>
            <InputLabel htmlFor="car-model-select-label">
              {t("Car model")}
            </InputLabel>
            <Select
              native
              label={t("Car model")}
              value={carModel}
              onChange={onSelectCarModel}
              inputProps={{
                id: "car-model-select-label",
              }}
            >
              {carModelList}
            </Select>
          </FormControl>
          <FormControl style={{ marginRight: 16, marginBottom: 16 }}>
            <InputLabel htmlFor="car-equipment-select-label">
              {t("Equipment")}
            </InputLabel>
            <Select
              native
              label={t("Equipment")}
              value={carEquipment}
              onChange={(e) => setCarEquipment(e.target.value)}
              inputProps={{
                id: "car-equipment-select-label",
              }}
            >
              {carEquipmentList}
            </Select>
          </FormControl>
          <FormControl style={{ marginRight: 16, marginBottom: 16 }}>
            <InputLabel htmlFor="alarm-select-label">{t("Alarm")}</InputLabel>
            <Select
              native
              label={t("Alarm")}
              value={alarmType}
              onChange={(e) => setAlarmType(e.target.value)}
              inputProps={{
                id: "alarm-select-label",
              }}
            >
              <option key={-1} value={-1}></option>
              {alarmTypeList.map((alarm) => (
                <option key={alarm.id} value={alarm.id}>
                  {alarm.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginRight: 16, marginBottom: 16 }}>
            <InputLabel htmlFor="alarm-select-label">
              {t("Language")}
            </InputLabel>
            <Select
              native
              label={t("Language")}
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option key={1} value="ru">
                RU
              </option>
              <option key={2} value="en">
                EN
              </option>
              <option key={3} value="de">
                DE
              </option>
            </Select>
          </FormControl>
        </Grid>
        <div>
          <Accordion style={{ background: "#E2E2E2" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{t("Checklist")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>{checkListPanels}</div>
            </AccordionDetails>
          </Accordion>
        </div>
        <Typography style={{ fontWeight: "bold" }}>
          {t("Configuration file")}(*.dxs)
        </Typography>
        <Grid container direction="row" alignItems="center">
          <ReactFileReader
            disabled={!saveAllowed}
            handleFiles={handleDxsFileSelect}
            fileTypes={[".dxs"]}
          >
            <Button disabled={!saveAllowed} variant="contained" color="primary">
              {t("Upload")}
            </Button>
          </ReactFileReader>
          <Typography style={{ paddingLeft: "12px" }}>
            {dxsFilename}
            <b>{getSizeComment(dxsData)}</b>
          </Typography>
          <Button
            onClick={() => openSettingsEditor(alarmType, dxsData)}
            style={{ marginLeft: "12px" }}
            disabled={
              dxsData === undefined || dxsData === null || dxsData.trim() === ""
            }
            variant="contained"
            color="primary"
          >
            {t("View")}
          </Button>
        </Grid>
        <Typography style={{ fontWeight: "bold" }}>
          {t("Mounting map")}
        </Typography>
        <Grid container direction="row" alignItems="center">
          <ReactFileReader
            disabled={!saveAllowed}
            handleFiles={handleMapFileSelect}
            fileTypes={[".pdf"]}
          >
            <Button disabled={!saveAllowed} variant="contained" color="primary">
              {t("Upload")}
            </Button>
          </ReactFileReader>
          <Typography style={{ paddingLeft: "12px" }}>
            {mapFilename === null || mapFilename === ""
              ? t("Map not loaded")
              : mapFilename}
          </Typography>
          <Button
            onClick={() =>
              window.open(
                `/frontend/files/downloadMountingMap?name=${encodeURIComponent(
                  mapFilename
                )}`,
                `_blank`
              )
            }
            style={{ marginLeft: "12px" }}
            disabled={mapFilename === null || mapFilename === ""}
            variant="contained"
            color="primary"
          >
            {t("Open")}
          </Button>
        </Grid>
        <Typography style={{ fontWeight: "bold" }}>{t("Images")}</Typography>
        <Grid container direction="row" alignItems="center">
          <ReactFileReader
            disabled={!saveAllowed}
            handleFiles={handleImageFileSelect}
            fileTypes={[".pdf", ".jpg", ".jpeg", ".png"]}
          >
            <Button disabled={!saveAllowed} variant="contained" color="primary">
              {t("Upload")}
            </Button>
          </ReactFileReader>
        </Grid>
        <>
          {imageFiles == null
            ? ""
            : imageFiles.map((image, index) => (
                <Paper
                  key={image.name}
                  style={{
                    marginTop: "12px",
                    padding: "12px",
                    background: "#F9F9F9",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item xs={2}>
                      {image.name.slice(
                        ((image.name.lastIndexOf(".") - 1) >>> 0) + 2
                      ) === "pdf" ? (
                        <PdfIcon style={{ fontSize: "120px" }} />
                      ) : (
                        <img
                          src={
                            image.previewBase64 === undefined
                              ? ``
                              : image.previewBase64
                          }
                          alt={t("No image")}
                          style={{
                            width: "120px",
                            maxWidth: "120px",
                            height: "120px",
                            display: "inline-block",
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item style={{ marginLeft: "12px" }}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item container direction="row" spacing={2}>
                          <Grid item>
                            <TextField
                              label={t("Name/Title")}
                              value={image.name}
                              fullWidth
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              label={t("Short description")}
                              value={image.description}
                              onChange={(e) => {
                                const updatedImageFiles = [...imageFiles];
                                updatedImageFiles[index].description =
                                  e.target.value;
                                setImageFiles(updatedImageFiles);
                              }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid item>
                          <TextField
                            label={t("Full description")}
                            value={image.fullDescription}
                            onChange={(e) => {
                              const updatedImageFiles = [...imageFiles];
                              updatedImageFiles[index].fullDescription =
                                e.target.value;
                              setImageFiles(updatedImageFiles);
                            }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Button
                    href={`/frontend/files/downloadConfigurationImage?configId=${
                      config === null ? "" : config.id
                    }&name=${encodeURIComponent(image.name)}`}
                    variant="contained"
                    color="primary"
                  >
                    {t("Download")}
                  </Button>
                  <Button
                    disabled={!saveAllowed}
                    variant="contained"
                    color="primary"
                    onClick={() => deleteImageFile(index)}
                    style={{ marginLeft: "12px" }}
                  >
                    {t("Delete")}
                  </Button>
                </Paper>
              ))}
        </>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={handleClose} color="primary">
            {t("Cancel")}
          </Button>
          <Button
            disabled={
              !saveAllowed ||
              saveDisabled ||
              carBrand < 0 ||
              carModel < 0 ||
              carEquipment < 0 ||
              alarmType < 0 ||
              dxsData === null ||
              dxsData.trim() === ""
            }
            onClick={onCreateClick}
            color="primary"
          >
            {t("Save")}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}
