import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  Grid,
} from "@mui/material";
import { validateEmail } from "../../helpers/StringUtils";
import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";

export default function SendMailingDialog({ isOpened, handleClose }) {
  const backend = useBackend();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [recipientType, setRecipientType] = useState(0);
  const [recipientUser, setRecipientUser] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [isDisabled, setDisabled] = useState(false);

  const handleSendPress = (e) => {
    setError("");

    if (recipientType == 0) {
      if (!validateEmail(recipientUser)) {
        setError("Invalid email format");
        return;
      }
    }

    backend
      .bckMailingSend(recipientType == 1, recipientUser, title, body)
      .then((json) => {
        setDisabled(false);
        if (json.code === 0) {
          setError(t("Message sent"));
        } else {
          setError(json.message);
        }
      });
  };

  const handleClearPress = (e) => {
    setError("");
    setRecipientType(0);
    setRecipientUser("");
    setTitle("");
    setBody("");
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{t("Send mailing")}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Typography color="secondary">{error}</Typography>
          <FormControl style={{ maxWidth: "20ch", marginTop: "24px" }}>
            <InputLabel>{t("Newsletter type")}</InputLabel>
            <Select
              label={t("Newsletter type")}
              disabled={isDisabled}
              value={recipientType}
              onChange={(e) => {
                setRecipientType(e.target.value);
              }}
            >
              <MenuItem key={0} value={0}>
                {t("User")}
              </MenuItem>
              <MenuItem key={1} value={1}>
                {t("All")}
              </MenuItem>
            </Select>
          </FormControl>
          {recipientType === 0 ? (
            <TextField
              disabled={isDisabled}
              label={t("Recipient login")}
              value={recipientUser}
              onChange={(e) => setRecipientUser(e.target.value.slice(0, 48))}
              style={{ maxWidth: "30ch", marginTop: "12px" }}
            />
          ) : (
            <></>
          )}
          <TextField
            disabled={isDisabled}
            label={t("Title")}
            value={title}
            onChange={(e) => setTitle(e.target.value.slice(0, 36))}
            style={{ maxWidth: "40ch", marginTop: "12px" }}
          />
          <TextField
            multiline
            disabled={isDisabled}
            label={t("Notification")}
            value={body}
            onChange={(e) => setBody(e.target.value.slice(0, 500))}
            style={{ maxWidth: "90ch", marginTop: "12px" }}
            rows={7}
            variant="outlined"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            isDisabled ||
            (recipientType === 0 && recipientUser.trim() === "") ||
            title.trim() === "" ||
            body.trim() === ""
          }
          onClick={handleSendPress}
          variant="contained"
          color="primary"
        >
          {t("Send")}
        </Button>
        <Button
          disabled={isDisabled}
          onClick={handleClearPress}
          variant="contained"
          color="primary"
        >
          {t("Reset parameters")}
        </Button>
        <Button onClick={handleClose} variant="contained" color="primary">
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
