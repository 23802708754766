import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Typography,
    Button
} from "@mui/material";

import {
    Clear as DeleteIcon,
    Edit as EditIcon,
    FormatListBulleted as LogsIcon,
    Dvr as MountsIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";

import styles from "./hoverableStyles.module.css"
import AdvancedAvatarBadge from "../AdvancedAvatarBadge";

export default function UsergroupUsersTable({ data, allowEdit, handleDeletePress, handleEditAddress, handleEditCompany, handleEditStatus, handleShowLogs, handleShowMounts }) {
    const { t } = useTranslation();

    var keys = ["", t("User"), t("Status"), " ", t("Company"), t("Address")];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map((key, i) => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>
                            {key}
                            { i === 4 || i === 5 ? <EditIcon /> : <></> }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(user => (
                    <TableRow key={user.id}>
                        <TableCell className={styles.cell}>
                            <IconButton
                                disabled={!allowEdit}
                                style={allowEdit ? { color: 'red' } : { color: 'gray' }}
                                onClick={() => { handleDeletePress(user); }} >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell className={styles.cell}>
                            <div style={{ width: "32ch", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <AdvancedAvatarBadge
                                    isUserOnline={user.isOnline}
                                    userId={user.id}
                                    userMail={user?.login}
                                    avatarKey={0}
                                />
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "12px" }}>
                                    <Typography variant="body1" noWrap>
                                        {user.login}
                                    </Typography>
                                    <Typography variant="body1" noWrap>
                                        {user.name}
                                    </Typography>
                                    <Typography variant="body1" noWrap>
                                        {user.phone}
                                    </Typography>
                                </div>
                            </div>
                        </TableCell>
                        <TableCell className={styles.cell}>
                            <Button
                                style={{ backgroundColor: user.status === 0 ? "#ffc83d" : "#3CD4A0", color: "#FFFFFF" }}
                                onClick={(e) => handleEditStatus(e, user.login)}
                                size="small"
                                className="px-2"
                                variant="contained"
                            >
                                { user.status === 0 ? t("Verification") : t("Approved") }
                            </Button>
                        </TableCell>
                        <TableCell className={styles.cell} style={{ width: "8ch" }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flexWrap: "nowrap" }}>
                                <IconButton
                                    disabled={user.status === 0}
                                    style={user.status !== 0 ? { color: '#536DFE' } : { color: 'gray' }}
                                    onClick={() => { handleShowLogs(user); }} >
                                    <LogsIcon />
                                </IconButton>
                                <IconButton
                                    disabled={user.status === 0}
                                    style={user.status !== 0 ? { color: '#536DFE' } : { color: 'gray' }}
                                    onClick={() => { handleShowMounts(user); }} >
                                    <MountsIcon />
                                </IconButton>
                            </div>
                        </TableCell>
                        <TableCell onClick={() => { handleEditCompany(user); }} className={styles.hoverableCell} style={{ minWidth: "16ch", maxWidth: "64ch" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "nowrap" }}>
                                <Typography variant="body1" style={{ width: "32ch", wordWrap: "break-word" }}>
                                    {user.company}
                                </Typography>
                            </div>
                        </TableCell>
                        <TableCell onClick={() => { handleEditAddress(user); }} className={styles.hoverableCell} style={{ minWidth: "16ch", maxWidth: "64ch" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "nowrap" }}>
                                <Typography variant="body1" style={{ width: "32ch", wordWrap: "break-word" }}>
                                    {user.address}
                                </Typography>
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
