import React from "react";
import {
    Typography
} from "@mui/material";
import styles from "./styles.module.css"
import MainImage1 from "./images/MainImage1.png";
import MainImage2 from "./images/MainImage2.svg";
import MainImage3 from "./images/MainImage3.svg";
import siteAndroid from "./images/SiteAndroid.png";
import siteDesktop from "./images/SiteDesktop.png";
import siteiOS from "./images/SiteiOS.png";
import { useTranslation } from "react-i18next";
import {
    Android as DownloadAndroidIcon,
    Apple as DownloadiOSIcon,
    Computer as DownloadPCIcon
} from "@mui/icons-material";
import { useNavigate } from "react-router";

export default function MainLanding() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleIosVersionClick = () => {
        if (i18n.language.toLocaleLowerCase() === "ru") {
            window.open(`https://apps.apple.com/ru/app/pandora-спец/id1563735964`, '_blank')
        } else {
            window.open(`https://apps.apple.com/us/app/pandora-спец/id1563735964`, '_blank')
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.downloadsContainer}>
                <div className={styles.imageRowColumn}>
                    <div className={styles.imageRow}>
                        <div className={styles.imageRowText}>
                            <h1>{t("MainText1Header")}</h1>
                            <p>
                                {t("MainText1")}
                            </p>
                        </div>
                        <div className={styles.imageRowImageDiv}>
                            <img className={styles.imageRowImage} src={MainImage1} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.container} style={{ backgroundColor: "#f0f0f0", width: "100%" }}>
                <div className={styles.downloadsContainer}>
                    <h2 style={{ textAlign: "center", marginTop: "16px" }}>
                        {t("Our applications")}
                    </h2>
                    <div className={styles.imageTable} style={{ backgroundColor: "transparent" }}>
                        <div className={styles.downloadCell}
                            onClick={(e) => navigate("/main/downloads/android")}>
                            <div className={styles.downloadCellImageDiv}>
                                <img src={siteAndroid} className={styles.downloadCellImage} alt="" />
                            </div>
                            <div className={styles.downloadCellTextDiv}>
                                <DownloadAndroidIcon />
                                <Typography style={{ textAlign: "center", fontSize: "1.2rem" }}>
                                    {t("Android Version")}
                                </Typography>
                            </div>
                        </div>
                        <div className={styles.downloadCell}
                            onClick={handleIosVersionClick}>
                            <div className={styles.downloadCellImageDiv}>
                                <img src={siteiOS} className={styles.downloadCellImage} alt="" />
                            </div>
                            <div className={styles.downloadCellTextDiv}>
                                <DownloadiOSIcon />
                                <Typography style={{ textAlign: "center", fontSize: "1.2rem" }}>
                                    {t("iOS Version")}
                                </Typography>
                            </div>
                        </div>
                        <div className={styles.downloadCell}
                            onClick={(e) => navigate("/main/downloads/pc")}>
                            <div className={styles.downloadCellImageDiv}>
                                <img src={siteDesktop} className={styles.downloadCellImage} alt="" />
                            </div>
                            <div className={styles.downloadCellTextDiv}>
                                <DownloadPCIcon />
                                <Typography style={{ textAlign: "center", fontSize: "1.2rem" }}>
                                    {t("Desktop Version")}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.downloadsContainer}>
                    <div className={styles.imageRowColumn}>
                        <div className={styles.imageRowReversed}>
                            <div className={styles.imageRowText}>
                                <h2>{t("MainText2Header")}</h2>
                                <p>
                                    {t("MainText2")}
                                </p>
                            </div>
                            <div className={styles.imageRowImageDiv}>
                                <img className={styles.imageRowImage} src={MainImage2} alt="" />
                            </div>
                        </div>
                        <div className={styles.imageRow}>
                            <div className={styles.imageRowText}>
                                <h2>{t("MainText3Header")}</h2>
                                <p>
                                    {t("MainText3")}
                                </p>
                            </div>
                            <div className={styles.imageRowImageDiv}>
                                <img className={styles.imageRowImage} src={MainImage3} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
