import React, { useState, useEffect } from "react";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton
} from "@mui/material";

import { ArticleElementFilling } from "../../enums/Articles/ArticleElementFilling";
import { ArticleElementSize } from "../../enums/Articles/ArticleElementSize";
import { ArticleElementColor } from "../../enums/Articles/ArticleElementColor";
import { ArticleElementGravity } from "../../enums/Articles/ArticleElementGravity";
import { ArticleElementTextAlign } from "../../enums/Articles/ArticleElementTextAlign";
import { ArticleElementTextStyle } from "../../enums/Articles/ArticleElementTextStyle";

import {
    Publish as UploadIcon
} from "@mui/icons-material";

import ReactFileReader from "react-file-reader";
import { ElementFields, ElementTypesAndFields } from "./ElementFields";
import { useTranslation } from "react-i18next";

export default function ElementProperties({ element, setElement, handleUrlUpload }) {
    const { t } = useTranslation();
    const [text, setText] = useState("");
    const [widthFilling, setWidthFilling] = useState("");
    const [heightFilling, setHeightFilling] = useState("");
    const [sizeStyle, setSizeStyle] = useState("");
    const [colorStyle, setColorStyle] = useState("");
    const [marginLeft, setMarginLeft] = useState("");
    const [marginTop, setMarginTop] = useState("");
    const [marginRight, setMarginRight] = useState("");
    const [marginBottom, setMarginBottom] = useState("");
    const [paddingLeft, setPaddingLeft] = useState("");
    const [paddingTop, setPaddingTop] = useState("");
    const [paddingRight, setPaddingRight] = useState("");
    const [paddingBottom, setPaddingBottom] = useState("");
    const [gravity, setGravity] = useState("");
    const [textAlign, setTextAlign] = useState("");
    const [url, setUrl] = useState("");
    const [alternativeUrl, setAlternativeUrl] = useState("");
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const [textStyle, setTextStyle] = useState("");

    const TextStyleMenuItems = Object.keys(ArticleElementTextStyle).map(i =>
        <MenuItem key={i} value={i}>
            {ArticleElementTextStyle[i]}
        </MenuItem>
    );

    const TextAlignMenuItems = Object.keys(ArticleElementTextAlign).map(i =>
        <MenuItem key={i} value={i}>
            {ArticleElementTextAlign[i]}
        </MenuItem>
    );

    const GravityMenuItems = Object.keys(ArticleElementGravity).map(i =>
        <MenuItem key={i} value={i}>
            {ArticleElementGravity[i]}
        </MenuItem>
    );

    const FillingsMenuItems = Object.keys(ArticleElementFilling).map(i =>
        <MenuItem key={i} value={i}>
            {ArticleElementFilling[i]}
        </MenuItem>
    );

    const SizeStylesMenuItems = Object.keys(ArticleElementSize).map(i =>
        <MenuItem key={i} value={i}>
            {ArticleElementSize[i]}
        </MenuItem>
    );

    const ColorStylesMenuItems = Object.keys(ArticleElementColor).map(i =>
        <MenuItem key={i} value={i}>
            {ArticleElementColor[i]}
        </MenuItem>
    );

    useEffect(() => {
        setText(element.text ?? "");
        setWidthFilling(element.widthFilling ?? "");
        setHeightFilling(element.heightFilling ?? "");
        setSizeStyle(element.sizeStyle ?? "");
        setColorStyle(element.colorStyle ?? "");
        setMarginLeft(element.marginLeft ?? "");
        setMarginTop(element.marginTop ?? "");
        setMarginRight(element.marginRight ?? "");
        setMarginBottom(element.marginBottom ?? "");
        setPaddingLeft(element.paddingLeft ?? "");
        setPaddingTop(element.paddingTop ?? "");
        setPaddingRight(element.paddingRight ?? "");
        setPaddingBottom(element.paddingBottom ?? "");
        setGravity(element.gravity ?? "");
        setTextAlign(element.textAlign ?? "");
        setUrl(element.url ?? "");
        setAlternativeUrl(element.alternativeUrl ?? "");
        setWidth(element.width ?? "");
        setHeight(element.height ?? "");
        setTextStyle(element.textStyle ?? "");
    }, [element]);

    const renderField = (element, fieldType, index) => {
        let result = null;
        switch (fieldType) {
            case ElementFields.Text:
                result = <>
                    <TextField
                        label={"Text"}
                        value={text}
                        onChange={e => {
                            e.persist();
                            setElement((prev) => {
                                prev.text = e.target.value;
                                return prev;
                            });
                            setText(e.target.value);
                        }}
                        style={{ width: "100%", marginBottom: "12px" }}
                        multiline
                        minRows="3"
                        maxRows="3"
                    />
                </>;
                break;
            case ElementFields.Fillings:
                result = <>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Width filling"}</InputLabel>
                        <Select
                            label={"Width filling"}
                            value={widthFilling}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.widthFilling = e.target.value;
                                    return prev;
                                });
                                setWidthFilling(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {FillingsMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Height filling"}</InputLabel>
                        <Select
                            label={"Height filling"}
                            value={heightFilling}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.heightFilling = e.target.value;
                                    return prev;
                                });
                                setHeightFilling(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {FillingsMenuItems}
                        </Select>
                    </FormControl>
                </>;
                break;
            case ElementFields.SizeStyle:
                result = <>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Size style"}</InputLabel>
                        <Select
                            label={"Size style"}
                            value={sizeStyle}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.sizeStyle = e.target.value;
                                    return prev;
                                });
                                setSizeStyle(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {SizeStylesMenuItems}
                        </Select>
                    </FormControl>
                </>;
                break;
            case ElementFields.ColorStyle:
                result = <>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Color style"}</InputLabel>
                        <Select
                            label={"Color style"}
                            value={colorStyle}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.colorStyle = e.target.value;
                                    return prev;
                                });
                                setColorStyle(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {ColorStylesMenuItems}
                        </Select>
                    </FormControl>
                </>;
                break;
            case ElementFields.Margins:
                result = <>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Margin left"}</InputLabel>
                        <Select
                            label={"Margin left"}
                            value={marginLeft}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.marginLeft = e.target.value;
                                    return prev;
                                });
                                setMarginLeft(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {SizeStylesMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Margin top"}</InputLabel>
                        <Select
                            label={"Margin top"}
                            value={marginTop}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.marginTop = e.target.value;
                                    return prev;
                                });
                                setMarginTop(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {SizeStylesMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Margin right"}</InputLabel>
                        <Select
                            label={"Margin right"}
                            value={marginRight}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.marginRight = e.target.value;
                                    return prev;
                                });
                                setMarginRight(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {SizeStylesMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Margin bottom"}</InputLabel>
                        <Select
                            label={"Margin bottom"}
                            value={marginBottom}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.marginBottom = e.target.value;
                                    return prev;
                                });
                                setMarginBottom(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {SizeStylesMenuItems}
                        </Select>
                    </FormControl>
                </>;
                break
            case ElementFields.Paddings:
                result = <>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Padding left"}</InputLabel>
                        <Select
                            label={"Padding left"}
                            value={paddingLeft}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.paddingLeft = e.target.value;
                                    return prev;
                                });
                                setPaddingLeft(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {SizeStylesMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Padding top"}</InputLabel>
                        <Select
                            label={"Padding top"}
                            value={paddingTop}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.paddingTop = e.target.value;
                                    return prev;
                                });
                                setPaddingTop(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {SizeStylesMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Padding right"}</InputLabel>
                        <Select
                            label={"Padding right"}
                            value={paddingRight}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.paddingRight = e.target.value;
                                    return prev;
                                });
                                setPaddingRight(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {SizeStylesMenuItems}
                        </Select>
                    </FormControl>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Padding bottom"}</InputLabel>
                        <Select
                            label={"Padding bottom"}
                            value={paddingBottom}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.paddingBottom = e.target.value;
                                    return prev;
                                });
                                setPaddingBottom(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {SizeStylesMenuItems}
                        </Select>
                    </FormControl>
                </>;
                break
            case ElementFields.Gravity:
                result = <>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Gravity"}</InputLabel>
                        <Select
                            label={"Gravity"}
                            value={gravity}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.gravity = e.target.value;
                                    return prev;
                                });
                                setGravity(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {GravityMenuItems}
                        </Select>
                    </FormControl>
                </>;
                break;
            case ElementFields.TextAlign:
                result = <>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Text align"}</InputLabel>
                        <Select
                            label={"Text align"}
                            value={textAlign}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.textAlign = e.target.value;
                                    return prev;
                                });
                                setTextAlign(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {TextAlignMenuItems}
                        </Select>
                    </FormControl>
                </>;
                break;
            case ElementFields.UrlImage:
                result = <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <TextField
                        label={"Url"}
                        disabled={true}
                        value={url}
                        style={{ width: "100%", marginBottom: "12px" }}
                        inputProps={{ maxLength: 255 }}
                    />
                    <ReactFileReader handleFiles={(files) => handleUrlUpload(element, files[0], (response) => {
                        setElement((prev) => {
                            prev.url = response.name;
                            prev.width = response.width;
                            prev.height = response.height;
                            return prev;
                        });
                        setUrl(response.name);
                        setWidth(response.width);
                        setHeight(response.height);
                    })} fileTypes={["*.jpg", "*.png"]}>
                        <IconButton color="primary">
                            <UploadIcon />
                        </IconButton>
                    </ReactFileReader>
                </div>;
                break;
            case ElementFields.Sizes:
                result = <>
                    <TextField
                        label={"Width"}
                        value={width}
                        onChange={e => {
                            let filteredValue = e.target.value.replace(/[^0-9]/g, '');
                            filteredValue = filteredValue.substr(0, 6);
                            let parsedValue = parseInt(filteredValue);
                            setElement((prev) => {
                                prev.width = parsedValue;
                                return prev;
                            });
                            setWidth(parsedValue);
                        }}
                        style={{ marginBottom: "12px" }}
                    />
                    <TextField
                        label={"Height"}
                        value={height}
                        onChange={e => {
                            let filteredValue = e.target.value.replace(/[^0-9]/g, '');
                            filteredValue = filteredValue.substr(0, 6);
                            let parsedValue = parseInt(filteredValue);
                            setElement((prev) => {
                                prev.height = parsedValue;
                                return prev;
                            });
                            setHeight(parsedValue);
                        }}
                        style={{ marginBottom: "12px" }}
                    />
                </>;
                break;
            case ElementFields.TextStyle:
                result = <>
                    <FormControl style={{ marginBottom: "12px" }}>
                        <InputLabel>{"Text style"}</InputLabel>
                        <Select
                            label={"Text style"}
                            value={textStyle}
                            onChange={(e) => {
                                e.persist();
                                setElement((prev) => {
                                    prev.textStyle = e.target.value;
                                    return prev;
                                });
                                setTextStyle(e.target.value);
                            }}
                        >
                            <MenuItem value="">
                                {t("Clear")}
                            </MenuItem>
                            {TextStyleMenuItems}
                        </Select>
                    </FormControl>
                </>;
                break;
            case ElementFields.UrlText:
                result = <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <TextField
                        label={"Url"}
                        value={url}
                        style={{ width: "100%", marginBottom: "12px" }}
                        inputProps={{ maxLength: 1024 }}
                        onChange={e => {
                            e.persist();
                            setElement((prev) => {
                                prev.url = e.target.value;
                                return prev;
                            });
                            setUrl(e.target.value);
                        }}
                    />
                </div>;
                break;
            case ElementFields.AlternativeUrl:
                result = <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <TextField
                        label={"Alternative url"}
                        value={alternativeUrl}
                        style={{ width: "100%", marginBottom: "12px" }}
                        inputProps={{ maxLength: 1024 }}
                        onChange={e => {
                            e.persist();
                            setElement((prev) => {
                                prev.alternativeUrl = e.target.value;
                                return prev;
                            });
                            setAlternativeUrl(e.target.value);
                        }}
                    />
                </div>;
                break;
            default:
                result = <></>;
                break;
        }

        return <React.Fragment key={index}>{result}</React.Fragment>;
    }

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            {
                ElementTypesAndFields[element.type].map((t, index) => renderField(element, t, index))
            }
        </div>
    );
}
