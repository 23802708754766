import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Grid,
  Typography,
  Button,
  Paper,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import UpdateChannelFilesTable from "../components/Tables/UpdateChannelFilesTable";
import { GenerateInteger } from "../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import CreateUpdateChannelFileDialog from "../components/Dialogs/CreateUpdateChannelFileDialog";
import EditVersionDialog from "../components/Dialogs/EditVersionDialog";
import { useInterval } from "../useInterval";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { LanguageConverter } from "../enums/LanguageConverter";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoadInt,
  settingRepositorySave,
} from "../settingRepository";
import { useNavigate } from "react-router";

export default function ManageUpdateChannelFiles() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [searchParams, setSearchParams] = useState({
    pageNumber: 0,
    pageSize: 10,
    searchChannelId: null,
  });
  const [totalRows, setTotalRows] = useState(100);
  const [isDialogOpened, setDialogOpened] = useState(false);
  const [updateChannels, setUpdateChannels] = useState([]);
  const [searchChannelIdInput, setSearchChannelIdInput] = useState("");
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [isEditVersionOpened, setIsEditVersionOpened] = useState(false);
  const [editVersionInitialValue, setEditVersionInitialValue] = useState("");
  const [editVersionInitialValueInt, setEditVersionInitialValueInt] =
    useState(0);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const handleCopyClick = (file) => {
    let menuItems = [];
    let srcChannel = updateChannels.find(
      (uc) => uc.id === file.updateChannelId
    );
    let allowedChannels = updateChannels.filter(
      (uc) => uc.clientType === srcChannel.clientType && uc.id !== srcChannel.id
    );
    if (allowedChannels && allowedChannels.length > 0) {
      menuItems = allowedChannels.map((c) => (
        <MenuItem key={c.id} value={c.id}>
          {c.name}
        </MenuItem>
      ));
    } else {
      menuItems = [
        <MenuItem key={0} value={0}>
          {t("No available channels")}
        </MenuItem>,
      ];
    }

    dialogDispatch({
      type: DialogActionTypes.SimpleSelectDialogOpen,
      initialValue: "",
      label: t("Copy version to..."),
      title: t("Update channel"),
      menuItems: menuItems,
      handleConfirm: (value) => {
        setIsLoading(true);
        backend.bckUpdateChannelDuplicateFile(file.id, value).then((json) => {
          setIsLoading(false);
          if (json.code === 0) {
          } else {
            setError(json.message);
          }
        });
      },
    });
  };

  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  const handleDialogOpen = () => {
    setDialogOpened(true);
  };

  const refreshTable = useCallback(
    (channelId, pageNumber, pageSize, isInitializing) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckUpdateChannelListFiles(
          channelId,
          true,
          LanguageConverter[i18n.language],
          pageNumber + 1,
          pageSize
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.list;
                } else {
                  return prev;
                }
              });
              setTotalRows((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.count;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }

          if (isInitializing) {
            setIsInitializing(false);
          }
        });
    },
    [t, i18n, backend]
  );

  useInterval(() => {
    if (updatesLockCounter.current === 0) {
      refreshTable(
        searchParams.searchChannelId,
        searchParams.pageNumber,
        searchParams.pageSize,
        isInitializing
      );
    }
  }, 2000);

  useEffect(() => {
    backend.bckUpdateChannelList(true, 1).then((json) => {
      if (json.code === 0) {
        setUpdateChannels(json.content);
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
    return () => {
      lastRequestId.current = null;
    };
  }, [t]);

  useEffect(() => {
    if (isInitializing) {
      setIsInitializing(false);
      let pageSize = settingRepositoryLoadInt(
        SettingName.VERSIONS_PAGE_SIZE,
        10
      );
      setSearchParams((prev) => ({
        ...prev,
        pageSize: pageSize,
      }));
    }
    // eslint-disable-next-line
  }, [isInitializing]);

  useEffect(() => {
    if (!isInitializing) {
      refreshTable(
        searchParams.searchChannelId,
        searchParams.pageNumber,
        searchParams.pageSize,
        isInitializing
      );
    }
  }, [searchParams, i18n.language]);

  const handleChangePage = (event, newPage) => {
    setSearchParams((prev) => ({
      ...prev,
      pageNumber: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    var newRowsPerPage = parseInt(event.target.value, 10);
    settingRepositorySave(SettingName.VERSIONS_PAGE_SIZE, newRowsPerPage);
    setSearchParams((prev) => ({
      ...prev,
      pageSize: newRowsPerPage,
      pageNumber: 0,
    }));
  };

  const handleSearchPress = () => {
    setSearchParams((prev) => ({
      ...prev,
      searchChannelId: searchChannelIdInput,
      pageNumber: 0,
    }));
  };

  const handleUploadSelect = (id, file) => {
    if (file.size > 512 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    setIsLoading(true);
    backend.bckFilesUploadUpdateChannelFile(id, file).then((json) => {
      setIsLoading(false);
      if (json.code === 0) {
        setTableData((prev) => {
          let next = [...prev];
          let target = next.find((r) => r.id === id);
          if (target) target.filename = json.content;
          return next;
        });
      } else {
        setError(json.message);
      }
    });
  };

  const handleDeleteClick = (id) => {
    backend.bckFilesRemoveUpdateChannelFile(id).then((json) => {
      if (json.code === 0) {
        setTableData((prev) => {
          let next = [...prev];
          let target = next.find((r) => r.id === id);
          if (target) target.filename = null;
          return next;
        });
      } else {
        setError(json.message);
      }
    });
  };

  const handleDownloadClick = (id) => {
    window.open(
      `/frontend/files/downloadUpdateChannelFile?id=${encodeURIComponent(id)}`,
      `_blank`
    );
  };

  const handlePublishChanged = (id, value) => {
    backend.bckUpdateChannelSetIsPublished(id, value).then((json) => {
      if (json.code === 0) {
        setTableData((prev) => {
          let next = [...prev];
          let target = next.find((r) => r.id === id);
          if (target) target.isPublished = value;
          return next;
        });
      } else {
        setError(json.message);
      }
    });
  };

  const gotoManageUpdateChannels = () => {
    navigate(`/app/manageUpdateChannels`);
  };

  const onSelectSearchUpdateChannel = (e) => {
    setSearchChannelIdInput(e.target.value);
  };

  const handleDescriptionClick = (row) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newDescription) => {
        setTableData((prev) => {
          var foundIndex = prev.findIndex((x) => x.id === row.id);
          prev[foundIndex].description = newDescription;
          return prev;
        });

        backend
          .bckUpdateChannelEditFileDescription(
            row.id,
            newDescription,
            LanguageConverter[i18n.language]
          )
          .then((json) => {
            if (json.code === 0) {
            } else if (json.code === 3) {
              setError(json.message);
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: row.description,
      textLabel: t("Description"),
      title: t("Description"),
      lines: 5,
    });
  };

  const handleVersionClick = (row) => {
    setEditVersionInitialValue(row.version);
    setEditVersionInitialValueInt(row.versionInt);
    setSelectedFileId(row.id);
    setIsEditVersionOpened(true);
  };

  const handleEditVersionClose = () => {
    setIsEditVersionOpened(false);
  };

  const handleEditVersionConfirm = (newVersion, newVersionInt) => {
    setTableData((prev) => {
      var foundIndex = prev.findIndex((x) => x.id === selectedFileId);
      prev[foundIndex].version = newVersion;
      prev[foundIndex].versionInt = newVersionInt;
      return prev;
    });

    backend
      .bckUpdateChannelEditFileVersion(
        selectedFileId,
        newVersion,
        newVersionInt
      )
      .then((json) => {
        setIsEditVersionOpened(false);
        if (json.code === 0) {
        } else if (json.code === 3) {
          setError(json.message);
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <div
            style={{
              display: "flex",
              direction: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" color="textSecondary">
              {t("Manage versions")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={gotoManageUpdateChannels}
            >
              {t("Manage update channels")}
            </Button>
          </div>
          <Typography color="secondary">{error}</Typography>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 12 }}
          >
            <FormControl
              size="small"
              style={{ marginRight: "12px", minWidth: "25ch" }}
            >
              <InputLabel>{t("Update channel")}</InputLabel>
              <Select
                label={t("Update channel")}
                value={searchChannelIdInput}
                onChange={onSelectSearchUpdateChannel}
              >
                <MenuItem key={-1} value={""}>
                  <em>{t("Any (mas)")}</em>
                </MenuItem>
                {updateChannels.map((uc) => (
                  <MenuItem key={uc.id} value={uc.id}>
                    {uc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              onClick={handleSearchPress}
              variant="contained"
              color="primary"
            >
              {t("Search")}
            </Button>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={handleDialogOpen}
              variant="contained"
              color="primary"
            >
              {t("Add version")}
            </Button>
          </div>
          <AdvancedTablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRows={totalRows}
            pageSize={searchParams.pageSize}
            pageNumber={searchParams.pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          >
            <UpdateChannelFilesTable
              data={tableData}
              updateChannels={updateChannels}
              handleUploadSelect={handleUploadSelect}
              handleDeleteClick={handleDeleteClick}
              handleDownloadClick={handleDownloadClick}
              handlePublishChanged={handlePublishChanged}
              handleDescriptionClick={handleDescriptionClick}
              handleVersionClick={handleVersionClick}
              handleCopyClick={handleCopyClick}
            />
          </AdvancedTablePagination>
        </Grid>
      </Paper>

      <CreateUpdateChannelFileDialog
        isOpened={isDialogOpened}
        updateChannels={updateChannels}
        handleClose={handleDialogClose}
      />

      <EditVersionDialog
        handleConfirm={handleEditVersionConfirm}
        handleClose={handleEditVersionClose}
        initialValue={editVersionInitialValue}
        initialValueInt={editVersionInitialValueInt}
        isOpened={isEditVersionOpened}
      />
    </>
  );
}
