import React from "react";
import { ResponseCode } from "../enums/ResponseCode";
import { SettingName, settingRepositorySave } from "../settingRepository";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            settingRepositorySave(SettingName.AUTHENTICATED, true);
            settingRepositorySave(SettingName.USER_ID, action.id);
            settingRepositorySave(SettingName.USER_NAME, action.username);
            settingRepositorySave(SettingName.USER_MAIL, action.email);
            settingRepositorySave(SettingName.USER_PHONE, action.phone);
            settingRepositorySave(SettingName.USER_COMPANY, action.company);
            settingRepositorySave(SettingName.USER_ADDRESS, action.address);
            settingRepositorySave(SettingName.ROLE, action.role);
            settingRepositorySave(SettingName.PERMISSIONS, action.permissions);
            settingRepositorySave(SettingName.USER_USERGROUP_VISIBILITY, action.useUsergroupVisibility);
            window.location.href = "/#/app/dashboard";
            return {
                ...state,
                isAuthenticated: true
            };
        case "SIGN_OUT_SUCCESS":
            settingRepositorySave(SettingName.AUTHENTICATED, false);
            settingRepositorySave(SettingName.USER_ID, null);
            settingRepositorySave(SettingName.USER_NAME, null);
            settingRepositorySave(SettingName.USER_MAIL, null);
            settingRepositorySave(SettingName.USER_PHONE, null);
            settingRepositorySave(SettingName.USER_COMPANY, null);
            settingRepositorySave(SettingName.USER_ADDRESS, null);
            settingRepositorySave(SettingName.ROLE, null);
            settingRepositorySave(SettingName.PERMISSIONS, null);
            settingRepositorySave(SettingName.USER_USERGROUP_VISIBILITY, null);
            window.location.href = "/#/main";
            return {
                ...state,
                isAuthenticated: false
            };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function UserProvider({ children }) {
    var [state, dispatch] = React.useReducer(userReducer, {
        isAuthenticated: (localStorage.getItem("authenticated") === 'true'),
    });

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
}

function useUserState() {
    var context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
    }
    return context;
}

function useUserDispatch() {
    var context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error("useUserDispatch must be used within a UserProvider");
    }
    return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, signIn, checkToken };

// ###########################################################

function checkToken(dispatch, backend) {
    backend.bckUsersCheckToken().then(json => {
        if ((json.code === 0 && !json.content) || json.code === 5) {
            dispatch({ type: "SIGN_OUT_SUCCESS" });
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
            //setError(Localization.RU.AUTH_ERROR);
        } else {
            //setError(Localization.RU.REQUEST_ERROR);
        }
    });
}

function loginUser(dispatch, backend, login, password, setIsLoading, setError) {
    setError(null);
    setIsLoading(true);

    if (!!login && !!password) {
        backend.bckUsersLogin(login, password).then(data => {
            return data.json()
        })
            .then(json => {
                if (json.code === 0) {
                    dispatch({
                        type: "LOGIN_SUCCESS",
                        username: json.content.username,
                        email: json.content.email,
                        id: json.content.id,
                        company: json.content.company,
                        address: json.content.address,
                        role: json.content.role,
                        useUsergroupVisibility: json.content.useUsergroupVisibility,
                        phone: json.content.phone,
                        permissions: JSON.stringify(json.content.permissions)
                    });
                } else if (json.code === ResponseCode.ACCESS_DENIED) {
                    setError("WRONG_LOGIN");
                } else if (json.code === 4) {
                    setError("NOT_CONFIRMED_LOGIN");
                } else if (json.code === 11) {
                    setError("API_USER_LOGIN");
                } else {
                    setError("AUTH_ERROR");
                }

                setIsLoading(false);
            });
    } else {
        setError("WRONG_LOGIN");
        setIsLoading(false);
    }
}

function signOut(dispatch, backend) {
    backend.bckUsersLogout();
    dispatch({ type: "SIGN_OUT_SUCCESS" });
}

function signIn(backend, fullname, email, password, setIsLoading, setError) {
    setError(null);
    setIsLoading(true);

    if (!!fullname && !!password) {
        backend.bckUsersSignIn(fullname, email, password).then(data => {
            return data.json();
        }).then(json => {
            if (json.code === 0) {
                setError("SIGN_IN_SUCCESS");
            } else if (json.code === 3) {
                setError(json.message);
            } else {
                setError("SIGN_IN_ERROR");
            }

            setIsLoading(false);
        });
    } else {
        setError("EMPTY_CREDENTIALS");
        setIsLoading(false);
    }
}
