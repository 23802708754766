import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  Button,
  TextField,
  Tabs,
  Tab,
  Avatar,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import OrderRequestSessionsTable from "../components/Tables/OrderRequestSessionsTable";

import { GenerateInteger } from "../helpers/RandomGenerator";
import { useInterval } from "../useInterval";

import { formatDate } from "../helpers/StringUtils";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import { generateUserInfoString } from "../helpers/UserInfoStringGenerator";
import ElementsRender from "./articleFull/ElementsRender";
import { StyledBadge } from "../components/StyledBadge/StyledBadge";

export default function FullOrderRequest({ headerSetter }) {
  const { t, i18n } = useTranslation();
  const backend = useBackend();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [requestId, setRequestId] = useState(0);
  const [requestStatus, setRequestStatus] = useState(0);
  const [userFio, setUserFio] = useState("");
  const [userId, setUserId] = useState("");
  const [userMail, setUserMail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [viewOnlyMode, setViewOnlyMode] = useState(false);
  const [issueText, setIssueText] = useState("");
  const [sessions, setSessions] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [commentError, setCommentError] = useState(false);
  const [commentHelperText, setCommentHelperText] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [isApiUserOnline, setIsApiUserOnline] = useState(false);
  const [lastOnline, setLastOnline] = useState(false);
  const [userInfoString, setUserInfoString] = useState("");
  const [article, setArticle] = useState(null);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshIsApiUserOnline = useCallback(
    (backend) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend.bckUsersIsApiUserOnline(userId).then((json) => {
        --updatesLockCounter.current;
        if (lastRequestId.current === id) {
          if (json.code === 0) {
            setIsApiUserOnline(json.content);
          } else if (json.code === ResponseCode.ACCESS_DENIED) {
            setError(t("AUTH_ERROR"));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        }
      });
    },
    [t, userId]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useInterval(() => {
    if (updatesLockCounter.current === 0) {
      refreshIsApiUserOnline(backend);
    }
  }, 4000);

  useEffect(() => {
    setCommentError(false);
    setCommentHelperText("");
  }, [i18n.language, commentText]);

  useEffect(() => {
    var params = queryString.parse(location.search);
    if (params.requestId) {
      setRequestId(params.requestId);
    } else {
      setError("Request ID is not set");
    }

    if (params.viewOnly === "true") {
      setViewOnlyMode(true);
    } else {
      setViewOnlyMode(false);
    }
  }, [location]);

  useEffect(() => {
    if (requestId) {
      setIsLoading(true);
      headerSetter(t("Request") + ` #${requestId}`);
      backend.bckOrderRequestsGetFullInfo(requestId).then((json) => {
        setIsLoading(false);
        if (json.code === 0) {
          var request = json.content;
          setUserId(request.senderId);
          backend.bckUsersGetInfo(request.senderId).then((json) => {
            if (json.code === 0) {
              let info = json.content;
              setUserInfoString(generateUserInfoString(t, info));
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
          setUserFio(request.requesterName);
          setUserMail(request.senderMail);
          setUserPhone(request.requesterPhone);
          setIssueText(request.description);
          setCommentText("");
          setRequestStatus(request.status);
          setSessions(request.sessions);
          setIsApiUserOnline(request.isSenderOnline);
          setLastOnline(request.senderLastOnline);
          if (request.articleId) {
            backend.bckArticlesFullInfo(request.articleId).then((json) => {
              if (json.code === 0) {
                let article = json.content;
                setArticle(article);
              } else if (json.code === ResponseCode.ACCESS_DENIED) {
                setError(t("AUTH_ERROR"));
              } else {
                setError(t("REQUEST_ERROR"));
              }
            });
          }
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
    }
  }, [requestId, headerSetter, t]);

  const handleSetTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseRequest = (isFinal) => {
    if (!commentText) {
      setCommentError(true);
      setCommentHelperText(t("Field must be populated"));
      return;
    }
    setCommentError(false);

    setRequestStatus(isFinal ? 4 : 3);
    backend
      .bckOrderRequestsClose(requestId, commentText, isFinal)
      .then((json) => {
        if (json.code === 0) {
          navigate(-1);
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Typography color="secondary">{error}</Typography>
      <Paper style={{ overflow: "auto", padding: "12px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
            invisible={!isApiUserOnline}
          >
            <Avatar
              style={{ height: "48px", width: "48px" }}
              onClick={() =>
                window.open(
                  `/frontend/Files/DownloadAvatar?userId=${userId}`,
                  "_blank"
                )
              }
              src={`/frontend/Files/DownloadAvatar?userId=${userId}`}
            >
              {userMail ? userMail[0].toUpperCase() : "X"}
            </Avatar>
          </StyledBadge>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
            }}
          >
            <Typography variant="h3">
              {userFio} ({userMail})
            </Typography>
            <Typography variant="h3">{userPhone}</Typography>
            {isApiUserOnline ? (
              <></>
            ) : (
              <Typography variant="caption">
                {`${t("Last online")} ${formatDate(lastOnline)}`}
              </Typography>
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: "12",
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginRight: "24px", minWidth: "52ch", flex: 1 }}>
            <TextField
              label={t("Description")}
              margin="dense"
              value={issueText === undefined ? "" : issueText}
              variant="outlined"
              multiline
              rows="4"
              fullWidth
            />
          </div>
          <div
            style={{
              display: "flex",
              marginRight: "24px",
              flexFlow: "column",
              flexGrow: 1,
            }}
          >
            <TextField
              style={{ width: "100%", marginBottom: "16px", flexGrow: 1 }}
              disabled={requestStatus !== 2}
              label={t("Operator comment")}
              margin="dense"
              value={commentText === undefined ? "" : commentText}
              onChange={(e) => setCommentText(e.target.value)}
              variant="outlined"
              error={commentError}
              helperText={commentHelperText}
              multiline
              rows="2"
              fullWidth
            />
          </div>
          <div style={{ display: "flex", flexFlow: "column" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "24px", width: "24ch" }}
              disabled={viewOnlyMode || requestStatus !== 2}
              onClick={() => {
                handleCloseRequest(true);
              }}
            >
              {t("Close request")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "12px", width: "24ch" }}
              disabled={viewOnlyMode || requestStatus !== 2}
              onClick={() => {
                handleCloseRequest(false);
              }}
            >
              {t("Delay")}
            </Button>
          </div>
        </div>
      </Paper>
      <Tabs value={tabValue} onChange={handleSetTab}>
        <Tab label={t("User")} value={0} />
        <Tab
          label={t("Article")}
          value={1}
          style={{ display: article ? null : "none" }}
        />
        <Tab label={t("Request history")} value={2} />
      </Tabs>
      {tabValue === 0 ? (
        <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "62vh" }}>
          {userInfoString}
        </Paper>
      ) : tabValue === 1 ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Paper
            style={{ padding: "24px", maxHeight: "80vh", maxWidth: "120ch" }}
          >
            <Typography>
              <b>{article.title}</b>
            </Typography>
            <Typography>{article.description}</Typography>
            <br />
            <ElementsRender elements={article.elements} />
          </Paper>
        </div>
      ) : (
        <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "62vh" }}>
          <OrderRequestSessionsTable data={sessions} />
        </Paper>
      )}
    </>
  );
}
