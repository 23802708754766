const DeviceFeatureTypes = {
    1: "FIRMWARE_UPDATE",
    2: "KEYLESS",
    3: "CLONE",
    4: "CAN_BLOCK",
    5: "ALARM_SETTINGS",
    6: "DYNAMIC_DEVICE_TABLE",
    7: "AUTOSTART",
    8: "VOICE_UPDATE_NORMAL",
    9: "VOICE_UPDATE_LOADER",
    10: "REMOTE_SYNCHRONIZATION"
};
Object.freeze(DeviceFeatureTypes);

const DeviceFeatureTypesByName = {
    "FIRMWARE_UPDATE": 1,
    "KEYLESS": 2,
    "CLONE": 3,
    "CAN_BLOCK": 4,
    "ALARM_SETTINGS": 5,
    "DYNAMIC_DEVICE_TABLE": 6,
    "AUTOSTART": 7,
    "VOICE_UPDATE_NORMAL": 8,
    "VOICE_UPDATE_LOADER": 9,
    "REMOTE_SYNCHRONIZATION": 10
};
Object.freeze(DeviceFeatureTypes);

export { DeviceFeatureTypes, DeviceFeatureTypesByName }