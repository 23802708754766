import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { PushTypes } from "../../enums/PushTypes";
import { useBackend } from "../../context/BackendContext";

function isURL(str) {
  return /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(str);
}

/**
 * JavaScript function to match (and return) the video Id
 * of any valid Youtube Url, given as input string.
 * @author: Stephan Schmitz <eyecatchup@gmail.com>
 * @url: https://stackoverflow.com/a/10315969/624466
 */
function ytVidId(url) {
  var p =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return url.match(p) ? RegExp.$1 : false;
}

export default function SendPushDialog({ isOpened, handleClose }) {
  const backend = useBackend();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [pushType, setPushType] = useState(0);
  const [clientType, setClientType] = useState(null);
  const [recipientType, setRecipientType] = useState(0);
  const [recipientUser, setRecipientUser] = useState("");
  const [recipientGroup, setRecipientGroup] = useState("");
  const [header, setHeader] = useState("");
  const [notification, setNotification] = useState("");
  const [notificationUrl, setNotificationUrl] = useState("");
  const [notificationUrlError, setNotificationUrlError] = useState(false);
  const [notificationImageUrl, setNotificationImageUrl] = useState("");
  const [notificationImageUrlError, setNotificationImageUrlError] =
    useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const handleSendPress = (e) => {
    setError("");
    setNotificationUrlError(false);
    setNotificationImageUrlError(false);

    if (pushType === 0) {
      if (notificationUrl.trim() !== "" && !isURL(notificationUrl)) {
        setNotificationUrlError(true);
        return;
      }
      if (notificationImageUrl.trim() !== "" && !isURL(notificationImageUrl)) {
        setNotificationImageUrlError(true);
        return;
      }
    }

    var currentNotificationUrl = notificationUrl;
    var currentNotificationImageUrl = notificationImageUrl;
    if (pushType === 1 || pushType === 4) {
      currentNotificationUrl = "";
      currentNotificationImageUrl = "";
    }

    if (pushType === 2) {
      if (notificationUrl.trim() === "" || !isURL(notificationUrl)) {
        setNotificationUrlError(true);
        return;
      }
      if (notificationImageUrl.trim() !== "" && !isURL(notificationImageUrl)) {
        setNotificationImageUrlError(true);
        return;
      }
    }

    if (pushType === 3) {
      if (notificationUrl.trim() === "" || !ytVidId(notificationUrl)) {
        setNotificationUrlError(true);
        return;
      }
      if (notificationImageUrl.trim() !== "" && !isURL(notificationImageUrl)) {
        setNotificationImageUrlError(true);
        return;
      }
    }

    backend
      .bckPushesSend(
        pushType,
        clientType,
        recipientType,
        recipientType === 0 ? recipientUser : recipientGroup,
        header,
        notification,
        currentNotificationUrl,
        currentNotificationImageUrl,
        "ru"
      )
      .then((json) => {
        setDisabled(false);
        if (json.code === 0) {
          setError(t("Message sent"));
        } else {
          setError(json.message);
        }
      });
  };

  const handleClearPress = (e) => {
    setError("");
    setClientType(null);
    setPushType(0);
    setRecipientType(0);
    setRecipientUser("");
    setRecipientGroup("");
    setHeader("");
    setNotification("");
    setNotificationUrl("");
    setNotificationImageUrl("");
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{t("Sending PUSH")}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Typography color="secondary">{error}</Typography>
          <FormControl style={{ maxWidth: "20ch", marginTop: "24px" }}>
            <InputLabel>{t("Notification type")}</InputLabel>
            <Select
              label={t("Notification type")}
              disabled={isDisabled}
              value={pushType}
              onChange={(e) => {
                setPushType(e.target.value);
              }}
            >
              {Object.keys(PushTypes).map((key) => (
                <MenuItem key={key} value={key}>
                  {t(PushTypes[key])}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            size="small"
            style={{ maxWidth: "20ch", marginTop: "12px" }}
          >
            <InputLabel>{t("Client type")}</InputLabel>
            <Select
              label={t("Client type")}
              disabled={isDisabled}
              value={clientType}
              onChange={(e) => {
                setClientType(e.target.value);
              }}
            >
              <MenuItem key={-1} value={null}>
                {t("Any (mas)")}
              </MenuItem>
              <MenuItem key={0} value={0}>
                Android
              </MenuItem>
              <MenuItem key={1} value={1}>
                Huawei
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ maxWidth: "20ch", marginTop: "12px" }}>
            <InputLabel>{t("Newsletter type")}</InputLabel>
            <Select
              label={t("Newsletter type")}
              disabled={isDisabled}
              value={recipientType}
              onChange={(e) => {
                setRecipientType(e.target.value);
              }}
            >
              <MenuItem key={0} value={0}>
                {t("User")}
              </MenuItem>
              <MenuItem key={1} value={1}>
                {t("Group")}
              </MenuItem>
            </Select>
          </FormControl>
          {recipientType === 0 ? (
            <TextField
              disabled={isDisabled}
              label={t("Recipient login")}
              value={recipientUser}
              onChange={(e) => setRecipientUser(e.target.value.slice(0, 48))}
              style={{ maxWidth: "30ch", marginTop: "12px" }}
            />
          ) : (
            <FormControl style={{ maxWidth: "30ch", marginTop: "12px" }}>
              <InputLabel>{t("Recipient group")}</InputLabel>
              <Select
                label={t("Recipient group")}
                disabled={isDisabled}
                value={recipientGroup}
                onChange={(e) => {
                  setRecipientGroup(e.target.value);
                }}
              >
                <MenuItem key={0} value="ru">
                  ru
                </MenuItem>
                <MenuItem key={1} value="en">
                  en
                </MenuItem>
              </Select>
            </FormControl>
          )}
          <TextField
            disabled={isDisabled}
            label={t("Title")}
            value={header}
            onChange={(e) => setHeader(e.target.value.slice(0, 36))}
            style={{ maxWidth: "40ch", marginTop: "12px" }}
          />
          <TextField
            multiline
            disabled={isDisabled}
            label={t("Notification")}
            value={notification}
            onChange={(e) => setNotification(e.target.value.slice(0, 500))}
            style={{ maxWidth: "90ch", marginTop: "12px" }}
            rows={7}
            variant="outlined"
          />
          <TextField
            disabled={isDisabled || pushType === 1 || pushType === 4}
            label={t("Click link")}
            value={notificationUrl}
            onChange={(e) => setNotificationUrl(e.target.value.slice(0, 80))}
            style={{ maxWidth: "90ch", marginTop: "12px" }}
            error={notificationUrlError}
          />
          <TextField
            disabled={isDisabled || pushType === 1 || pushType === 4}
            label={t("Image link")}
            value={notificationImageUrl}
            onChange={(e) =>
              setNotificationImageUrl(e.target.value.slice(0, 80))
            }
            style={{ maxWidth: "90ch", marginTop: "12px" }}
            error={notificationImageUrlError}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={
            isDisabled ||
            (recipientType === 0 && recipientUser.trim() === "") ||
            (recipientType === 1 && recipientGroup.trim() === "") ||
            header.trim() === "" ||
            notification.trim() === ""
          }
          onClick={handleSendPress}
          variant="contained"
          color="primary"
        >
          {t("Send")}
        </Button>
        <Button
          disabled={isDisabled}
          onClick={handleClearPress}
          variant="contained"
          color="primary"
        >
          {t("Reset parameters")}
        </Button>
        <Button onClick={handleClose} variant="contained" color="primary">
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
