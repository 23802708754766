import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    RemoveCircle as RemoveIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next"
import { SettingsChannelsIoPolarities, SettingsChannelsIoTypes } from "../../enums/SettingsMenuTypes";

export default function SettingsChannelsIosTable({ data, handleRemoveLogicChannel,
    handleEditNumber, handleEditName, handleEditPolarity, handleEditIsWireless,
    handleEditType, handleEditPolarityStructure, handleEditNcStructure, handleEditWires}) {
    const { t } = useTranslation();

    var keys = ["#", t("Number"), t("Name"), t("Default polarity"), t("Wireless"), t("Type"), t("Wires"), t("Polarity structure"), t("NC structure"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(io => (
                    <TableRow key={io.Id}>
                        <TableCell className={styles.cell}>
                            {io.Id}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditNumber(io)}
                        >
                            {io.Number}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditName(io)}
                        >
                            {io.Name}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditPolarity(io)}
                        >
                            {SettingsChannelsIoPolarities[io.DefaultPolarity]}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditIsWireless(io)}
                        >
                            {io.IsWireless ? t("Yes") : t("No")}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditType(io)}
                        >
                            {t(SettingsChannelsIoTypes[io.Type])}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditWires(io)}
                        >
                            {JSON.stringify(io.Wires)}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditPolarityStructure(io)}
                        >
                            {io.PolarityStructureId}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditNcStructure(io)}
                        >
                            {io.NcStructureId}
                        </TableCell>
                        <TableCell className={styles.cell}>
                            <IconButton
                                color="secondary"
                                onClick={(e) => { handleRemoveLogicChannel(io); }}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
