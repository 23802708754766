const OrderRequestStatuses = {
    1: "Opened",
    2: "In work",
    3: "Delayed",
    4: "Closed",
    5: "Closed by user",
    6: "Cancelled by user"
};
Object.freeze(OrderRequestStatuses);

const OrderRequestStatusesByName = {
    "Opened": 1,
    "In work": 2,
    "Delayed": 3,
    "Closed": 4,
    "Closed by user": 5,
    "Cancelled by user": 6
};
Object.freeze(OrderRequestStatusesByName);

export { OrderRequestStatuses, OrderRequestStatusesByName }