import React, { useState, useEffect } from "react";

import Chat from "../components/Chat/Chat"

import queryString from "query-string"
import {
    useLocation
} from "react-router-dom";

export default function ChatPage() {
    const location = useLocation();
    const [username, setUsername] = useState("");

    useEffect(() => {
        var params = queryString.parse(location.search);
        if (params.login) {
            setUsername(params.login);
        } else {
            console.log("Login is not set");
        }
    }, [location]);

    return (
        <>
            <Chat username={username} mountEventsMode={false} />
        </>);
}
