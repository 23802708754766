const ArticleTypes = {
    1: "News",
    2: "Offer"
};
Object.freeze(ArticleTypes);

const ArticleTypesByName = {
    "News": 1,
    "Offer": 2
};
Object.freeze(ArticleTypesByName);

export {
    ArticleTypes,
    ArticleTypesByName
}