import React, { useState } from "react";

import { Grid, Button } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import {
  DialogActionTypes,
  useDialogContext,
} from "../../context/DialogContext";
import SettingsLogicsTable from "../../components/Tables/SettingsLogicsTable";
import SettingsLogicsChannelsTable from "../../components/Tables/SettingsLogicsChannelsTable";
import LoadingOverlay from "react-loading-overlay-ts";

export default function Logics({ data, setData, setError }) {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t, i18n } = useTranslation();
  const [chosenLogic, setChosenLogic] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddLogicClick = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        let newLogic = { Id: null, Name: name, Channels: null };
        saveLogics(newLogic);
      },
      initialValue: "",
      textLabel: t("Name"),
      title: t("Add logic"),
    });
  };

  const handleRemoveLogic = (logic) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove logic?", { title: logic.Name }),
      handleConfirm: () => {
        backend.bckSettingsRemoveLogics(logic.Id).then((json) => {
          if (json.code === 0) {
            setData((prev) => {
              let nextLogics = { ...prev.Logics };
              delete nextLogics[logic.Id];
              let next = { ...prev, Logics: nextLogics };
              return next;
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const handleShowLogicChannels = (logic) => {
    setChosenLogic(logic);
  };

  const saveLogics = (newLogic) => {
    setIsLoading(true);
    backend.bckSettingsCreateOrUpdateLogics(newLogic).then((json) => {
      setIsLoading(false);
      if (json.code === 0) {
        let parsedContent = JSON.parse(json.content);
        let responseLogic = parsedContent.Logics?.[0];
        if (responseLogic) {
          setData((prev) => {
            let nextLogics = { ...prev.Logics };
            nextLogics[responseLogic.Id] = responseLogic;
            let nextTranslations = { ...prev.Translations };
            if (parsedContent.Translations) {
              Object.keys(parsedContent.Translations).forEach((l) => {
                let newLang = { ...nextTranslations[l] };
                parsedContent.Translations[l].forEach(
                  (t) => (newLang[t.Id] = t)
                );
                nextTranslations[l] = newLang;
              });
            }
            let next = {
              ...prev,
              Logics: nextLogics,
              Translations: nextTranslations,
            };
            if (chosenLogic != null) setChosenLogic(responseLogic);
            return next;
          });
        }
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleAddLogicChannelClick = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (number) => {
        let newChannels = [...chosenLogic.Channels];
        newChannels.push({
          LogicId: chosenLogic.Id,
          ChannelId: parseInt(number),
        });
        let newLogic = { ...chosenLogic, Channels: newChannels };
        saveLogics(newLogic);
        setChosenLogic(newLogic);
      },
      initialValue: "",
      textLabel: t("Number"),
      title: t("Add logic"),
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 4);
      },
    });
  };

  const handleRemoveLogicChannel = (logicChannel) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove logic channel?", { number: logicChannel.Number }),
      handleConfirm: () => {
        let newChannels = [...chosenLogic.Channels];
        let index = newChannels.indexOf(logicChannel);
        newChannels.splice(index, 1);
        let newLogic = { ...chosenLogic, Channels: newChannels };
        saveLogics(newLogic);
        setChosenLogic(newLogic);
      },
    });
  };

  const handleBackClick = () => {
    setChosenLogic(null);
  };

  const handleEditLogicName = (logic) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        let newLogic = { ...logic, Name: name };
        saveLogics(newLogic);
      },
      initialValue: logic.Name,
      textLabel: t("Name"),
      title: t("Name"),
    });
  };

  const handleEditChannelId = (channel) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (number) => {
        let newChannel = { ...channel, ChannelId: number };
        let newChannels = [...chosenLogic.Channels];
        newChannels[newChannels.indexOf(channel)] = newChannel;
        let newLogic = { ...chosenLogic, Channels: newChannels };
        saveLogics(newLogic);
        setChosenLogic(newLogic);
      },
      initialValue: channel.ChannelId.toString(),
      textLabel: t("Number"),
      title: t("Number"),
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 4);
      },
    });
  };

  const saveChannelTranslation = (channel, textFieldName, text) => {
    let newChannel = { ...channel };
    if (!newChannel[textFieldName]) newChannel[textFieldName] = {};
    newChannel[textFieldName][i18n.language] = text;
    let newChannels = [...chosenLogic.Channels];
    newChannels[newChannels.indexOf(channel)] = newChannel;
    let newLogic = { ...chosenLogic, Channels: newChannels };
    saveLogics(newLogic);
    setChosenLogic(newLogic);
  };

  const handleEditName = (channel) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (text) => {
        saveChannelTranslation(channel, "NameTranslationText", text);
      },
      initialValue: channel.NameTranslationText
        ? channel.NameTranslationText[i18n.language]
        : data.Translations[i18n.language][channel.NameTranslationId]?.Text,
      textLabel: t("Name"),
      title: t("Name"),
    });
  };

  const handleEditDescription = (channel) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (text) => {
        saveChannelTranslation(channel, "DescriptionTranslationText", text);
      },
      initialValue: channel.DescriptionTranslationText
        ? channel.DescriptionTranslationText[i18n.language]
        : data.Translations[i18n.language][channel.DescriptionTranslationId]
            ?.Text,
      textLabel: t("Description"),
      title: t("Description"),
    });
  };

  return (
    <LoadingOverlay active={isLoading} spinner>
      {chosenLogic === null ? (
        <>
          <Grid item>
            <Button
              onClick={handleAddLogicClick}
              variant="contained"
              color="primary"
            >
              {t("Add logic")}
            </Button>
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "70vh",
              marginTop: "16px",
            }}
          >
            <SettingsLogicsTable
              data={Object.values(data.Logics)}
              handleEditLogicName={handleEditLogicName}
              handleShowLogicChannels={handleShowLogicChannels}
              handleRemoveLogic={handleRemoveLogic}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {chosenLogic !== null ? (
        <>
          <Grid item container direction="row" spacing={2}>
            <Grid item>
              <Button
                onClick={handleBackClick}
                variant="contained"
                color="primary"
              >
                {t("Back")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleAddLogicChannelClick}
                variant="contained"
                color="primary"
              >
                {t("Add logic channel")}
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              maxHeight: "70vh",
              marginTop: "16px",
            }}
          >
            <SettingsLogicsChannelsTable
              settingInfo={data}
              data={chosenLogic.Channels}
              handleRemoveLogicChannel={handleRemoveLogicChannel}
              handleEditChannelId={handleEditChannelId}
              handleEditName={handleEditName}
              handleEditDescription={handleEditDescription}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </LoadingOverlay>
  );
}
