import React, { useState, useRef } from "react";

import { Grid, Typography, ListItem, IconButton, Button } from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  ArrowForwardIos as BulletIcon,
  AccountTree as GoToPrototypeIcon,
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import Menu from "./Menu";
import {
  DialogActionTypes,
  useDialogContext,
} from "../../context/DialogContext";
import { SettingsMenuTypesByName } from "../../enums/SettingsMenuTypes";

export default function Menus({ data, setData, setError }) {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t } = useTranslation();
  const [expandedMenu, setExpandedMenu] = useState({});
  const [selectedMenu, setSelectedMenu] = useState(null);
  const parentRefs = useRef({});

  const createOrUpdateMenu = (menuJson, handleSuccess) => {
    backend.bckSettingsCreateOrUpdateMenu(menuJson).then((json) => {
      if (json.code === 0) {
        let parsedContent = JSON.parse(json.content);
        let menu = parsedContent.Menus?.[0];
        if (menu) {
          handleSuccess(menu);
        }
        setData((prev) => {
          let nextTranslations = { ...prev.Translations };
          if (parsedContent.Translations) {
            Object.keys(parsedContent.Translations).forEach((l) => {
              let newLang = { ...nextTranslations[l] };
              parsedContent.Translations[l].forEach((t) => (newLang[t.Id] = t));
              nextTranslations[l] = newLang;
            });
          }
          let next = { ...prev, Translations: nextTranslations };
          return next;
        });
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleCreateMenuOpen = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        let newElement = {
          Id: null,
          Name: name,
          Type: SettingsMenuTypesByName.Composite,
          Children: [],
        };
        const handleSuccess = (menu) => {
          newElement.Id = menu.Id;
          setData((prev) => {
            let newMenus = { ...prev.Menus, [menu.Id]: newElement };
            let newData = { ...data, Menus: newMenus };
            return newData;
          });
        };
        createOrUpdateMenu(newElement, handleSuccess);
      },
      initialValue: "",
      textLabel: t("Title"),
      title: t("Add menu"),
    });
  };

  const handleCreateChildMenuOpen = (parent) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        let newElement = {
          ParentId: parent.Id,
          Name: name,
          Type: SettingsMenuTypesByName.Instance,
        };
        const handleSuccess = (menu) => {
          newElement.Id = menu.Id;
          setData((prev) => {
            let newMenus = { ...prev.Menus };
            newMenus[parent.Id].Children.push(newElement);
            let newData = { ...data, Menus: newMenus };
            return newData;
          });
        };
        createOrUpdateMenu(newElement, handleSuccess);
      },
      initialValue: "",
      textLabel: t("Title"),
      title: t("Add menu"),
    });
  };

  const removeMenu = (menu, handleSuccess) => {
    backend.bckSettingsRemoveMenu(menu.Id).then((json) => {
      if (json.code === 0) {
        handleSuccess();
      } else if (json.code === 3) {
        setError(t(json.message));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleRemoveMenu = (menu) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove menu?", { title: menu.Name }),
      handleConfirm: () => {
        const handleSuccess = () => {
          setData((prev) => {
            let newMenus = { ...prev.Menus };
            delete newMenus[menu.Id];
            let newData = { ...data, Menus: newMenus };
            return newData;
          });
        };
        removeMenu(menu, handleSuccess);
      },
    });
  };

  const handleRemoveChildMenu = (parent, menu) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove menu?", { title: menu.Name }),
      handleConfirm: () => {
        const handleSuccess = () => {
          setData((prev) => {
            let newMenus = { ...prev.Menus };
            let targetIndex = parent.Children.findIndex(
              (c) => c.Id === menu.Id
            );
            if (targetIndex >= 0) {
              parent.Children.splice(targetIndex, 1);
            }
            let newData = { ...data, Menus: newMenus };
            return newData;
          });
        };
        removeMenu(menu, handleSuccess);
      },
    });
  };

  const handleMenuSave = (newMenu) => {
    const handleSuccess = (menu) => {
      setSelectedMenu(menu);
      setData((prev) => {
        let newMenus = { ...prev.Menus };
        let targetMenu = newMenus[menu.Id];
        if (targetMenu) {
          newMenus[menu.Id] = menu;
        } else {
          let keys = Object.keys(newMenus);
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = newMenus[key];
            if (!value.Children) continue;

            let foundIndex = value.Children.findIndex((c) => c.Id === menu.Id);
            if (foundIndex >= 0) {
              value.Children.splice(foundIndex, 1);
              break;
            }
          }

          newMenus[menu.ParentId].Children.push(menu);
        }
        let newData = { ...data, Menus: newMenus };
        return newData;
      });
    };
    createOrUpdateMenu(newMenu, handleSuccess);
  };

  return (
    <>
      <Grid item>
        <Button
          onClick={handleCreateMenuOpen}
          variant="contained"
          color="primary"
        >
          {t("Add menu")}
        </Button>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "70vh",
          marginTop: "16px",
          width: "100%",
        }}
      >
        <div style={{ overflowY: "auto" }}>
          {data && data.Menus ? (
            Object.values(data.Menus)
              .filter((s) => s)
              .map((s) => (
                <div key={s.Id} ref={(el) => (parentRefs.current[s.Id] = el)}>
                  <ListItem
                    button
                    key={s.Id}
                    onClick={() => {
                      if (s.Id === expandedMenu.Id || !s.Children) {
                        setExpandedMenu({});
                      } else {
                        setExpandedMenu(s);
                      }
                      setSelectedMenu(s);
                    }}
                  >
                    <Typography
                      style={
                        s.Id === selectedMenu?.Id ? { fontWeight: "bold" } : {}
                      }
                    >{`${s.Name}`}</Typography>
                    {s.Id === expandedMenu.Id ? (
                      <ExpandLess />
                    ) : s.Children ? (
                      <ExpandMore />
                    ) : (
                      <></>
                    )}
                    {s.Children ? (
                      <IconButton
                        color="primary"
                        onClick={(e) => {
                          handleCreateChildMenuOpen(s);
                          e.stopPropagation();
                        }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                    <IconButton
                      color="secondary"
                      disabled={s.Children && s.Children.length > 0}
                      onClick={(e) => {
                        handleRemoveMenu(s);
                        e.stopPropagation();
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </ListItem>
                  {data && data.Menus && s.Id === expandedMenu.Id ? (
                    s.Children.filter((c) => c)
                      .sort((a, b) => (a.Id > b.Id ? 1 : -1))
                      .sort((a, b) =>
                        !a.Order || !b.Order ? 0 : a.Order > b.Order ? 1 : -1
                      )
                      .map((c) => (
                        <ListItem
                          button
                          key={c.Id}
                          onClick={() => {
                            setSelectedMenu(c);
                          }}
                        >
                          <BulletIcon />
                          <Typography
                            style={
                              c.Id === selectedMenu.Id
                                ? { fontWeight: "bold" }
                                : {}
                            }
                          >{`${c.Name}`}</Typography>
                          {c.PrototypeId ? (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                let proto = data.Menus[c.PrototypeId];
                                if (proto) setSelectedMenu(proto);
                                let div = parentRefs.current[c.PrototypeId];
                                if (div) div.scrollIntoView();
                                e.stopPropagation();
                              }}
                            >
                              <GoToPrototypeIcon />
                            </IconButton>
                          ) : (
                            <></>
                          )}
                          <IconButton
                            color="secondary"
                            onClick={(e) => {
                              handleRemoveChildMenu(s, c);
                              e.stopPropagation();
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </ListItem>
                      ))
                  ) : (
                    <></>
                  )}
                </div>
              ))
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            marginLeft: "16px",
            width: "100%",
            height: "70vh",
            overflowY: "auto",
          }}
        >
          {selectedMenu ? (
            <Menu
              menu={selectedMenu}
              handleSave={handleMenuSave}
              settingInfo={data}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
