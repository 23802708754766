import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";

import { GenerateInteger } from "../../helpers/RandomGenerator";

import AdvancedTablePagination from "../../components/AdvancedTablePagination";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import OpenManualRequestsTable from "../../components/Tables/OpenManualRequestsTable";
import { OpenManualRequestStatuses } from "../../enums/OpenManualRequestStatuses";
import { SettingName, settingRepositoryLoad } from "../../settingRepository";
import { useNavigate } from "react-router";
import {
  DialogActionTypes,
  useDialogContext,
} from "../../context/DialogContext";

const initialRequestParams = {
  id: null,
  title: null,
  login: null,
  status: null,
  pageNumber: 0,
  pageSize: 10,
};

export default function OpenManualRequests() {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [requestParams, setRequestParams] = useState(initialRequestParams);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchLogin, setSearchLogin] = useState("");
  const [searchStatus, setSearchStatus] = useState(-1);
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  useEffect(() => {
    const refreshTable = (id, title, login, status, pageNumber, pageSize) => {
      ++updatesLockCounter.current;
      var requestId = GenerateInteger();
      lastRequestId.current = requestId;
      backend
        .bckOpenManualRequestsList(
          id,
          title,
          login,
          status === -1 ? null : status,
          pageNumber + 1,
          pageSize
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === requestId) {
            if (json.code === 0) {
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.list;
                } else {
                  return prev;
                }
              });
              setTotalRows((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.count;
                } else {
                  return prev;
                }
              });
            }
          }
        });
    };

    refreshTable(
      requestParams.id,
      requestParams.title,
      requestParams.login,
      requestParams.status,
      requestParams.pageNumber,
      requestParams.pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestParams]);

  useEffect(() => {
    let loadedUserEmail = settingRepositoryLoad(SettingName.USER_MAIL, "");
    setCurrentUserEmail(loadedUserEmail);
    setRequestParams({ ...initialRequestParams });
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  const handleSearchPress = () => {
    setRequestParams({
      ...requestParams,
      title: searchTitle,
      login: searchLogin,
      status: searchStatus,
    });
  };

  const handleSearchResetPress = () => {
    setRequestParams({
      ...initialRequestParams,
      pageNumber: requestParams.pageNumber,
      pageSize: requestParams.pageSize,
    });
    setSearchLogin("");
    setSearchStatus(-1);
    setSearchTitle("");
  };

  const handleChangePage = (event, newPage) => {
    setRequestParams({
      ...requestParams,
      pageNumber: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    var newPageSize = parseInt(event.target.value, 10);
    setRequestParams({
      ...requestParams,
      pageNumber: 0,
      pageSize: newPageSize,
    });
  };

  const gotoOpenManualRequestFull = (request, viewOnly) => {
    navigate(
      `/app/openManualRequests/full?requestId=${request.id}&viewOnly=${viewOnly}`
    );
  };

  const handleAssignRequest = (request) => {
    backend
      .bckOpenManualRequestCreateOrUpdate({ id: request.id, status: 3 })
      .then((json) => {
        if (json.code === 0) {
          gotoOpenManualRequestFull(request, false);
        }
      });
  };

  const handleShowTitle = (request) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleOutputDialogOpen,
      text: request.title,
      textLabel: "",
      title: t("Title"),
      noTextField: true,
    });
  };

  const handleShowRequestAddress = (request) => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${request.latitude},${request.longitude}`
    );
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Typography variant="h5" color="textSecondary">
          {t("Open manuals requests")}
        </Typography>
        <Grid
          container
          direction="column"
          style={{ paddingTop: "12px" }}
          spacing={2}
        >
          <Grid container item alignContent="center">
            <Grid item>
              <TextField
                label={t("Title")}
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
                style={{ marginRight: "12px" }}
              />
            </Grid>
            <Grid item>
              <TextField
                label={t("Login")}
                value={searchLogin}
                onChange={(e) => setSearchLogin(e.target.value)}
                style={{ marginRight: "12px" }}
              />
            </Grid>
            <Grid item>
              <FormControl style={{ marginRight: "12px", minWidth: "25ch" }}>
                <InputLabel>{t("Status")}</InputLabel>
                <Select
                  value={searchStatus}
                  label={t("Status")}
                  onChange={(e) => setSearchStatus(e.target.value)}
                >
                  <MenuItem key={-1} value={-1}>
                    <em>{t("Any (mas)")}</em>
                  </MenuItem>
                  {Object.keys(OpenManualRequestStatuses).map((id) => (
                    <MenuItem key={id} value={id}>
                      {t(OpenManualRequestStatuses[id])}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item>
              <Button
                onClick={handleSearchPress}
                variant="contained"
                color="primary"
              >
                {t("Search")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSearchResetPress}
                variant="contained"
                color="primary"
              >
                {t("Reset parameters")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <AdvancedTablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          totalRows={totalRows}
          pageSize={requestParams.pageSize}
          pageNumber={requestParams.pageNumber}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <OpenManualRequestsTable
            data={tableData}
            handleAssignRequest={handleAssignRequest}
            handleShowRequestAddress={handleShowRequestAddress}
            handleShowTitle={handleShowTitle}
            handleViewRequest={(request) =>
              gotoOpenManualRequestFull(request, true)
            }
            handleContinueRequest={(request) =>
              gotoOpenManualRequestFull(request, false)
            }
            userEmail={currentUserEmail}
          />
        </AdvancedTablePagination>
      </Paper>
    </>
  );
}
