import React from "react";
import {
    Drawer,
    Badge,
    IconButton
} from "@mui/material";
import classNames from "classnames";
import styles from "./styles.module.css";
import SidebarLink from "./SidebarLink/SidebarLink";
import {
    ArrowBack as ArrowBackIcon
} from "@mui/icons-material";

export default function StyledDrawer({ isPermanent, isSidebarOpened, content, structure, handleOpen, handleClose }) {
    return (
        <Drawer
            variant={isPermanent ? "permanent" : "temporary"}
            className={classNames(styles.drawer, {
                [styles.drawerOpen]: isSidebarOpened,
                [styles.drawerClose]: !isSidebarOpened,
            })}
            open={isSidebarOpened}
        >
            <div className={classNames(styles.drawer, {
                [styles.drawerOpen]: isSidebarOpened,
                [styles.drawerClose]: !isSidebarOpened,
            })}>
                {
                    isPermanent ? <></> :
                        <div className={styles.mobileBackButton}>
                            <IconButton onClick={() => handleClose()}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                }
                <div className={styles.fakeToolbar} />
                {content}
                {structure.map(link => (
                    <SidebarLink
                        key={link.id}
                        isSidebarOpened={isSidebarOpened}
                        onClick={() => link.onClick ? link.onClick() : (link.children ? handleOpen() : handleClose())}
                        icon={link.showBadge ?
                            <Badge badgeContent={"!"} color="primary" overlap="rectangular" anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}>
                                {link.icon}
                            </Badge> : link.icon}
                        label={link.label}
                        link={link.link}
                        children={link.children}
                    />
                ))}
            </div>
        </Drawer>
    );
}
