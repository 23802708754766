const TechsupportRequestTags = {
    16: "Clone",
    1: "Factory fault",
    2: "Construction fault",
    3: "CAN",
    4: "Crawling",
    5: "Autostart",
    6: "Algorithms",
    7: "Application / Network service",
    8: "Pandora Specialist",
    9: "Alarm Studio",
    10: "Configurations",
    11: "Mounting maps",
    12: "Manual",
    13: "Human factor",
    14: "Feedback",
    15: "Other"
};
Object.freeze(TechsupportRequestTags);

export { TechsupportRequestTags }