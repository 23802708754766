import React, { useState } from "react";
import clsx from 'clsx';
import {
    TableRow,
    TableCell,
    IconButton,
    Tooltip,
    Chip
} from "@mui/material";

import {
    Image as ImageIcon,
    Delete as DeleteIcon,
    Publish as UploadIcon,
    ReportProblem as WarningIcon,
    ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

import ReactFileReader from "react-file-reader";

import styles from "../hoverableStyles.module.css"
import expandedStyles from "../../expandedStyles.module.css";

import { useTranslation } from "react-i18next"
import { yearsStringToSpanString } from "../../../helpers/StringUtils"
import { CarModelTypes } from "../../../enums/CarModelTypes";

export default function CarModelsTableRow({ model, handleShowCarModelImage, buildModelEquipments, handleRemoveModel, handleUploadImage, handleTitleClick, handleYearsClick, handleTypeClick, handleUploadMountingMap, handleDeleteMountingMap }) {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <TableRow key={model.id}>
                <TableCell>
                    <IconButton
                        className={clsx(expandedStyles.expand, {
                            [expandedStyles.expandOpen]: isExpanded,
                        })}
                        onClick={() => {
                            if (isExpanded) {
                                setIsExpanded(false);
                            }
                            else {
                                setIsExpanded(true);
                            }
                        }}
                        color="primary">
                        <ExpandMoreIcon />
                    </IconButton>
                </TableCell>
                <TableCell className={styles.cell}>{model.id}</TableCell>
                <TableCell
                    className={styles.hoverableCell}
                    onClick={() => handleTitleClick(model)}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {
                            model.anyEquipments ? <></> : <Tooltip title={t("There is no equipments for this model")}>
                                <WarningIcon style={{ color: '#EBB734' }} />
                            </Tooltip>
                        }
                        <>{model.name}</>
                    </div>
                </TableCell>
                <TableCell
                    className={styles.hoverableCell}
                    onClick={() => handleYearsClick(model)}
                >
                    {yearsStringToSpanString(model.years)}
                </TableCell>
                <TableCell
                    className={styles.hoverableCell}
                    onClick={() => handleTypeClick(model)}
                >
                    {t(CarModelTypes[model.type])}
                </TableCell>
                <TableCell style={{ maxWidth: "100ch" }}
                >
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {
                            model?.mountingMaps ? model.mountingMaps.map(mm =>
                                <Chip key={mm.id}
                                    style={{ margin: "4px" }}
                                    label={mm.name} onClick={() => window.open(mm.url, '_blank')}
                                    onDelete={() => handleDeleteMountingMap(model, mm)}
                                />
                            ) : <></>
                        }
                        <ReactFileReader handleFiles={(files) => handleUploadMountingMap(model, files[0])} fileTypes={[".pdf"]}>
                            <Chip style={{ margin: "4px" }} label="+" variant="outlined" clickable />
                        </ReactFileReader>
                    </div>
                </TableCell>
                <TableCell className={styles.cell}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <IconButton
                            style={{ color: 'red' }}
                            onClick={() => handleRemoveModel(model)} >
                            <DeleteIcon />
                        </IconButton>
                        <ReactFileReader handleFiles={(files) => handleUploadImage(model, files[0])} fileTypes={["*.*"]}>
                            <IconButton color="primary">
                                <UploadIcon />
                            </IconButton>
                        </ReactFileReader>
                        <IconButton
                            disabled={!model.imageUrl}
                            color="primary"
                            onClick={() => handleShowCarModelImage(model)} >
                            <ImageIcon />
                        </IconButton>
                    </div>
                </TableCell>
            </TableRow>
            {
                isExpanded ? <TableRow>
                    <TableCell colSpan={7}>
                        {buildModelEquipments(model)}
                    </TableCell>
                </TableRow> : <></>
            }
        </>
    );
}
