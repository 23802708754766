const ResponseCode = {
    "OK": 0,
    "CRITICAL_ERROR": 1,
    "AUTH_FAILURE": 2,
    "INVALID_PARAMS": 3,
    "NOT_CONFIRMED_USER_LOGIN": 4,
    "TOKEN_EXPIRED": 5,
    "USER_NOT_FOUND": 6,
    "USER_NO_FCM": 7,
    "TECHSUPPORT_REQUEST_ALREADY_CLOSED": 8,
    "TECHSUPPORT_REQUEST_TOO_MANY": 9,
    "ACCESS_DENIED": 10,
    "API_USER_LOGIN": 11
};
Object.freeze(ResponseCode);

export { ResponseCode }