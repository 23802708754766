import React, { useState, useCallback } from "react";
import clsx from "clsx";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  IconButton,
  Button,
  Link,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  WhereToVote as QuestionsIcon,
  Map as MapIcon,
  PlayArrow as ContinueIcon,
  Pause as PauseIcon,
  NotInterested as ConnectionTypeNone,
  Bluetooth as ConnectionTypeBluetooth,
  Usb as ConnectionTypeUsb,
  Info as InfoIcon,
  HeadsetMic as UsergroupIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { formatDate, formatElapsedTime } from "../../helpers/StringUtils";
import AdvancedAvatarBadge from "../AdvancedAvatarBadge";
import {
  useDialogContext,
  DialogActionTypes,
} from "../../context/DialogContext";
import { useBackend } from "../../context/BackendContext";
import styles from "../expandedStyles.module.css";

export default function FastMountsTableComponent({
  data,
  handleMountQuestionsOpen,
  handleCommentOnMount,
  handleContinueMount,
  handlePauseMount,
  handleShowMountAddress,
  handleExpanded,
  handleCollapsed,
  handleShowUserInfo,
  handleDeviceIdClick,
  handleShowDevicePackage,
  handleUserEmailClick,
  handleUsergroupClick,
}) {
  const { t } = useTranslation();
  const dialogDispatch = useDialogContext();
  const backend = useBackend();

  const [configLoadInProgress, setConfigLoadInProgress] = useState(false);
  const [expandedId, setExpandedId] = useState(-1);

  const statuses = {
    1: () => t("Mount in progress"),
    2: () => t("Mount finished"),
    3: () => t("Mount interrupted"),
  };

  let compareFirmwareVersions = (mount) => {
    return mount.firmware.trim().endsWith(mount.latestFirmware.trim());
  };

  var keys = [
    "",
    "#",
    t("Status"),
    t("Mount started"),
    t("Installer"),
    "   ",
    " ",
    t("Phone"),
    "  ",
    t("Alarm"),
    "    ",
    t("Car"),
    t("Configuration"),
    t("Mount time"),
    t("Check"),
  ];

  const openSettingsEditor = (alarmName, data, asDummyObject) => {
    let dxsObject = {};
    if (asDummyObject) {
      dxsObject = {
        code: "",
        id_car: "",
        year: "",
        car_type: "",
        reg_number: "",
        installer: "",
        owner: "",
        install_date: "",
        comment: "",
        settings: data,
      };
    } else {
      dxsObject = JSON.parse(data);
    }

    dialogDispatch({
      type: DialogActionTypes.SettingsEditorDialogOpen,
      data: dxsObject,
      alarmName: alarmName,
    });
  };

  const getQuestionButtonStyle = (mount) => {
    if (mount.state !== 2) {
      return { color: "#A3A3A3" };
    }

    var yesCount = 0;
    for (let i = 0; i < mount.answers.length; i++) {
      if (mount.answers[i].value === 1) {
        yesCount++;
      }
    }

    if (mount.questions !== null && mount.questions.length > 0) {
      var rate = yesCount / mount.questions.length;
      if (rate < 0.5) {
        return { color: "#FF0000" };
      } else if (rate < 0.8) {
        return { color: "#EBB734" };
      } else {
        return { color: "#00AA00" };
      }
    } else {
      return { color: "#00AA00" };
    }
  };

  const getMountTime = (mount) => {
    if (mount.mountTime !== null && mount.mountTime !== 0) {
      return mount.mountTime;
    }

    var startDate = new Date(mount.startDate);
    var currentDate;
    if (mount.state === 3) {
      currentDate = new Date(mount.updateDate);
    } else {
      currentDate = new Date();
    }
    return ((currentDate.getTime() - startDate.getTime()) / 1000) | 0;
  };

  const getRowStyle = (mount) => {
    if (mount.state === 1) {
      var mountTime = getMountTime(mount);
      if (mountTime > 240 * 60) {
        return { backgroundColor: "#ffa9a9" };
      }
      if (mountTime > 120 * 60) {
        return { backgroundColor: "#fdff8f" };
      }

      var updateDate = new Date(mount.updateDate);
      var currentDate = new Date();
      currentDate.setTime(
        currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000
      );
      var timeSinceLastUpdate = ((currentDate - updateDate) / 1000) | 0;
      if (timeSinceLastUpdate > 120 * 60) {
        return { backgroundColor: "#ffa9a9" };
      }
      if (timeSinceLastUpdate > 60 * 60) {
        return { backgroundColor: "#fdff8f" };
      }
    }
    return {};
  };

  const loadAndOpenConfiguration = useCallback(
    (alarmName, configId, asDummyObject) => {
      setConfigLoadInProgress(true);
      backend.bckConfigurationsGetDxsData(configId).then((json) => {
        if (json.content !== null && json.content !== undefined) {
          openSettingsEditor(alarmName, json.content, asDummyObject);
        }
        setConfigLoadInProgress(false);
      });
    },
    [backend]
  );

  return (
    <Table size="small">
      <TableHead>
        <TableRow key="header">
          {keys.map((key, i) => (
            <TableCell key={i} style={{ fontWeight: "bold" }}>
              {key}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((mount) => (
          <React.Fragment key={mount.id}>
            <TableRow style={getRowStyle(mount)}>
              <TableCell>
                <IconButton
                  className={clsx(styles.expand, {
                    [styles.expandOpen]: expandedId === mount.id,
                  })}
                  onClick={() => {
                    if (expandedId === mount.id) {
                      setExpandedId(-1);
                      handleCollapsed();
                    } else {
                      setExpandedId(mount.id);
                      handleExpanded();
                    }
                  }}
                  aria-expanded={expandedId === mount.id}
                  aria-label="show more"
                  color="primary"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </TableCell>
              <TableCell>{mount.id}</TableCell>
              <TableCell style={{ minWidth: "140px" }}>
                {statuses[mount.state]()}
                <br />
                <IconButton
                  disabled={mount.state !== 3}
                  color="primary"
                  onClick={() => handleContinueMount(mount.id)}
                >
                  <ContinueIcon />
                </IconButton>
                <IconButton
                  disabled={mount.state !== 1}
                  color="primary"
                  onClick={() => handlePauseMount(mount.id)}
                >
                  <PauseIcon />
                </IconButton>
              </TableCell>
              <TableCell>{formatDate(mount.startDate)}</TableCell>
              <TableCell>
                <div
                  style={{
                    width: "32ch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AdvancedAvatarBadge
                    avatarKey={0}
                    isUserOnline={mount.isUserOnline}
                    userId={mount.userId}
                    userMail={mount.userMail}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "12px",
                    }}
                  >
                    <Link
                      style={{
                        fontSize: "inherit",
                        wordWrap: "break-word",
                        rightMargin: "auto",
                        textAlign: "left",
                      }}
                      onClick={() => handleUserEmailClick(mount)}
                    >
                      {mount.userMail}
                    </Link>
                    <Typography variant="body1" noWrap>
                      {mount.userName}
                    </Typography>
                    <Typography variant="body1" noWrap>
                      {mount.userPhone}
                    </Typography>
                  </div>
                </div>
              </TableCell>
              <TableCell>
                <IconButton
                  color="primary"
                  onClick={() => {
                    handleShowUserInfo(mount);
                  }}
                >
                  <InfoIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={!mount.hasUsergroup}
                  onClick={() => {
                    handleUsergroupClick(mount);
                  }}
                >
                  <UsergroupIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton
                  disabled={
                    mount.coordinates === null ||
                    mount.coordinates.trim() === "" ||
                    mount.coordinates === "0.0 ; 0.0" ||
                    mount.addressId === null
                  }
                  color="primary"
                  onClick={() => {
                    handleShowMountAddress(mount);
                  }}
                >
                  <MapIcon />
                </IconButton>
              </TableCell>
              <TableCell>{mount.clientInfo}</TableCell>
              <TableCell>
                {mount.connectionType === 1 ? (
                  <ConnectionTypeBluetooth />
                ) : mount.connectionType === 2 ? (
                  <ConnectionTypeUsb />
                ) : (
                  <ConnectionTypeNone />
                )}
              </TableCell>
              <TableCell>
                <Link
                  style={{ fontSize: "inherit" }}
                  onClick={() => handleDeviceIdClick(mount)}
                >
                  {mount.deviceId}
                </Link>
                <br />
                {mount.alarmName}
                <br />
                {compareFirmwareVersions(mount) ? (
                  <Typography style={{ color: "#00AA00" }}>
                    {mount.firmware}
                  </Typography>
                ) : (
                  <Typography style={{ color: "#AA0000" }}>
                    {mount.firmware}({mount.latestFirmware})
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                <IconButton
                  color="primary"
                  onClick={() => {
                    handleShowDevicePackage(mount.deviceId);
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                {mount.carBrand}
                <br />
                {mount.carModel}
                <br />
                {mount.carEquipment}
              </TableCell>
              <TableCell>{mount.configName}</TableCell>
              <TableCell>{formatElapsedTime(getMountTime(mount))}</TableCell>
              <TableCell>
                <IconButton
                  disabled={mount.state !== 2}
                  style={getQuestionButtonStyle(mount)}
                  onClick={() => {
                    handleMountQuestionsOpen(mount);
                  }}
                >
                  <QuestionsIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            {expandedId === mount.id ? (
              <TableRow>
                <TableCell colSpan={12}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Button
                        onClick={() =>
                          window.open(
                            `/frontend/files/downloadMountingMap?name=${mount.mountingMapUrl}`,
                            `_blank`
                          )
                        }
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={
                          mount.mountingMapUrl === null ||
                          mount.mountingMapUrl.trim() === ""
                        }
                      >
                        {t("Mounting map")}
                      </Button>
                      <Button
                        onClick={() =>
                          loadAndOpenConfiguration(
                            mount.alarmName,
                            mount.configId,
                            false
                          )
                        }
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={
                          mount.configId === null || configLoadInProgress
                        }
                        style={{ marginLeft: "12px" }}
                      >
                        {t("Source configuration")}
                      </Button>
                      <Button
                        onClick={() =>
                          openSettingsEditor(mount.alarmName, mount.data, true)
                        }
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={
                          mount.data === null || mount.data.trim() === ""
                        }
                        style={{ marginLeft: "12px" }}
                      >
                        {t("Mount configuration")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <b>{t("Installer feedback")}: </b>
                      {mount.feedback}
                    </Grid>
                    <Grid item>
                      <b>{t("Manager feedback")}: </b>
                      {mount.comment}
                      <br />
                      <Button
                        onClick={() => {
                          handleCommentOnMount(mount);
                        }}
                        size="small"
                        variant="contained"
                        color="primary"
                      >
                        {mount.comment === null || mount.comment.trim() === ""
                          ? t("Add")
                          : t("Change")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Accordion style={{ background: "#F3F3F3" }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{t("Mount log")}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container direction="column">
                            {mount.mountingLog.map((log, count) => (
                              <Typography key={count}>
                                {`${((log.time / 60) >> 0)
                                  .toFixed(0)
                                  .toString()
                                  .padStart(1, "0")}:${(log.time % 60)
                                  .toFixed(0)
                                  .toString()
                                  .padStart(2, "0")}: ${log.title}`}
                              </Typography>
                            ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
}
