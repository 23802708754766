import React, { useState, useEffect, useCallback, useRef } from "react";

import { Grid, Typography, Paper, Button } from "@mui/material";

import UpdateChannelUsersTable from "../components/Tables/UpdateChannelUsersTable";

import { useTranslation } from "react-i18next";
import { useInterval } from "../useInterval";

import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";

export default function ManageUpdateChannelUsers() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const location = useLocation();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [channelId, setChannelId] = useState(null);
  const [updateChannelUsers, setUpdateChannelUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(100);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const updatesLockCounter = useRef(0);

  const refreshTable = useCallback(
    (channelId, pageNumber, pageSize) => {
      backend
        .bckUpdateChannelListUsers(channelId, pageNumber + 1, pageSize)
        .then((json) => {
          if (json.code === 0) {
            setTotalRows((prev) => {
              if (updatesLockCounter.current === 0) {
                return json.content.count;
              } else {
                return prev;
              }
            });
            setUpdateChannelUsers((prev) => {
              if (updatesLockCounter.current === 0) {
                return json.content.list;
              } else {
                return prev;
              }
            });
          } else if (json.code === ResponseCode.ACCESS_DENIED) {
            setError(t("AUTH_ERROR"));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
    },
    [t, backend]
  );

  useEffect(() => {
    var params = queryString.parse(location.search);
    if (params.channelId) {
      setChannelId(params.channelId);
      refreshTable(params.channelId, 0, 10);
    } else {
      setError("Request ID is not set");
    }
  }, [location]);

  useInterval(() => {
    if (updatesLockCounter.current === 0) {
      refreshTable(channelId, pageNumber, pageSize);
    }
  }, 2000);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    refreshTable(channelId, newPage, pageSize);
  };

  const handleChangeRowsPerPage = (event) => {
    var newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPageNumber(0);
    refreshTable(channelId, 0, newPageSize);
  };

  const handleAdd = () => {
    dialogDispatch({
      type: DialogActionTypes.SelectUserDialogOpen,
      handleConfirm: (selectedUser) => {
        if (
          updateChannelUsers.some((user) => user.login === selectedUser.login)
        ) {
          setError(t("User already exists", { user: selectedUser.login }));
        } else {
          setError("");
          backend
            .bckUpdateChannelAddUser(channelId, selectedUser.id)
            .then((json) => {
              if (json.code === 0) {
              } else if (json.code === 3) {
                setError(t(json.message));
              } else {
                setError(t("REQUEST_ERROR"));
              }
            });
        }
      },
    });
  };

  const handleDeleteClick = (user) => {
    backend.bckUpdateChannelRemoveUser(channelId, user.id).then((json) => {
      if (json.code === 0) {
      } else if (json.code === 3) {
        setError(t(json.message));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("Manage update channel users")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          <Button
            onClick={handleAdd}
            variant="contained"
            color="primary"
            style={{ width: "32ch", marginTop: "16px" }}
          >
            {t("Add")}
          </Button>
          <AdvancedTablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            totalRows={totalRows}
            pageSize={pageSize}
            pageNumber={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          >
            <UpdateChannelUsersTable
              data={updateChannelUsers}
              handleDeleteClick={handleDeleteClick}
            />
          </AdvancedTablePagination>
        </Grid>
      </Paper>
    </>
  );
}
