import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    RemoveCircle as RemoveIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next"

export default function SettingsAlarmMenusTable({ data, settingInfo, handleEditAlarm, handleEditRootMenu, handleEditRootStructure, handleEditChannels, handleRemoveAlarmMenu }) {
    const { t } = useTranslation();

    var keys = ["#", t("Alarm"), t("Menu"), t("Structure"), t("Channel"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(alarmMenu => (
                    <TableRow key={alarmMenu.Id}>
                        <TableCell className={styles.cell}>{alarmMenu.Id}</TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditAlarm(alarmMenu)}
                        >
                            {`${settingInfo.Alarms[alarmMenu.AlarmId].Name} (${alarmMenu.AlarmId})`}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditRootMenu(alarmMenu)}
                        >
                            {`${settingInfo.Menus[alarmMenu.RootMenuId].Name} (${alarmMenu.RootMenuId})`}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditRootStructure(alarmMenu)}
                        >
                            {`${settingInfo.Structures[alarmMenu.RootStructureId].Name} (${alarmMenu.RootStructureId})`}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditChannels(alarmMenu)}
                        >
                            {alarmMenu.ChannelsId ? `${settingInfo.Channels[alarmMenu.ChannelsId]?.Name} (${alarmMenu.ChannelsId})` : ""}
                        </TableCell>                        
                        <TableCell className={styles.cell} style={{ display: "flex", flexDirection: "row" }}>
                            <IconButton
                                color="secondary"
                                onClick={() => handleRemoveAlarmMenu(alarmMenu)}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
