const ChatMountEventTypes = {
    "ERROR": 0,
    "SESSION_START": 1,
    "SESSION_STOP": 2,
    "DEVICE_INFORMATION_REQUEST": 3,
    "DEVICE_INFORMATION_RESPONSE": 4,
    "DEVICE_SETTINGS_REQUEST": 5,
    "DEVICE_SETTINGS_IN_PROGRESS": 6,
    "DEVICE_SETTINGS_RESPONSE": 7,
    "WRITE_SETTINGS_REQUEST": 8,
    "WRITE_SETTINGS_IN_PROGRESS": 9,
    "WRITE_SETTINGS_RESPONSE": 10,
    "REBOOT_REQUEST": 11,
    "PROCESSING_START": 12,
    "PROCESSING_STOP": 13,
    "MOUNT_SYNCHRONIZATION_REQUEST": 14
};
Object.freeze(ChatMountEventTypes);

export { ChatMountEventTypes }