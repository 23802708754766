import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Checkbox,
  MenuItem,
  ListItemText,
  FormControlLabel,
  Paper,
  Select,
} from "@mui/material";

// components
import LogsTable from "../components/Tables/LogsTable";
import { useInterval } from "../useInterval";
import { GenerateInteger } from "../helpers/RandomGenerator";
import { LanguageConverter } from "../enums/LanguageConverter";

import { useTranslation } from "react-i18next";

import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { generateUserInfoString } from "../helpers/UserInfoStringGenerator";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoadBool,
  settingRepositoryLoadInt,
  settingRepositorySave,
} from "../settingRepository";

export default function Logs({
  noFiltersMode,
  customHeight,
  customDeviceIdQuery,
}) {
  const { t, i18n } = useTranslation();
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const location = useLocation();
  const [error, setError] = useState("");
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalLogs, setTotalLogs] = useState(100);
  const [userQuery, setUserQuery] = useState("");
  const [alarmQuery, setAlarmQuery] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [processTypeQuery, setProcessTypeQuery] = useState([]);
  const [eventTypeQuery, setEventTypeQuery] = useState([]);
  const [userQueryInput, setUserQueryInput] = useState("");
  const [alarmQueryInput, setAlarmQueryInput] = useState("");
  const [deviceIdInput, setDeviceIdInput] = useState("");
  const [processTypeQueryInput, setProcessTypeQueryInput] = useState([]);
  const [searchLanguageInput, setSearchLanguageInput] = useState(-1);
  const [searchLanguage, setSearchLanguage] = useState("");
  const [searchCityInput, setSearchCityInput] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [possibleEvents, setPossibleEvents] = useState([]);
  const [possibleEventsFiltered, setPossibleEventsFiltered] = useState([]);
  const [searchEventInput, setSearchEventInput] = useState([]);
  const [autoUpdateChecked, setAutoUpdateChecked] = useState(true);
  const latestFirmwares = useRef({});
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const processTypes = [
    { name: t("SETTINGS"), id: 1 },
    { name: t("CRAWLING"), id: 3 },
    { name: t("CLONE"), id: 4 },
    { name: t("FIRMWARE"), id: 2 },
    { name: t("SIGNAL_SERVICE_LOGS"), id: 5 },
    { name: t("MOUNT_FEEDBACK"), id: 6 },
    { name: t("SYSTEM_CHECK"), id: 7 },
    { name: t("FUEL_SENSOR"), id: 8 },
  ];

  const openSettingsEditor = (alarmName, data) => {
    let dxsObject = {
      code: "",
      id_car: "",
      year: "",
      car_type: "",
      reg_number: "",
      installer: "",
      owner: "",
      install_date: "",
      comment: "",
      settings: data,
    };

    dialogDispatch({
      type: DialogActionTypes.SettingsEditorDialogOpen,
      data: dxsObject,
      alarmName: alarmName,
    });
  };

  const openOutputDialog = (text) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleOutputDialogOpen,
      title: "",
      text: text,
      textLabel: "",
      noTextField: true,
    });
  };

  const handleShowUserInfo = (log) => {
    backend.bckUsersGetInfo(log.userId).then((json) => {
      if (json.code === 0) {
        let info = json.content;
        openOutputDialog(generateUserInfoString(t, info));
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleUsergroupClick = (log) => {
    backend.bckLogsGetUsergroupOwners(log.id).then((json) => {
      if (json.code === 0) {
        dialogDispatch({
          type: DialogActionTypes.UsergroupListDialogOpen,
          list: json.content,
        });
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleViewClick = (log) => {
    if (log.type === t("SETTINGS")) {
      openSettingsEditor(log.deviceName, log.info);
    } else if (log.type === t("CLONE")) {
      backend
        .bckLogsGetCloneInfo(log.info, LanguageConverter[i18n.language])
        .then((json) => {
          let r = json.content;
          if (json.code === 0) {
            let displayString = `${t("Status")}: ${r.status}
${t("Car")}: ${
              r.carBrand ? `${r.carBrand} ${r.carModel} ${r.carEquipment}` : ""
            }
${t("Algorithm")}: ${r.algoName}
${t("Retries")}: ${r.cloneAttempts ? r.cloneAttempts : t("Unknown")}`;
            if (r.isRepeatPrevious !== null) {
              displayString += `
${t("Clone differences")}: ${
                r.isRepeatPrevious ? t("Not present") : t("Present")
              }`;
            }
            openOutputDialog(displayString);
          } else if (json.code === ResponseCode.ACCESS_DENIED) {
            setError(t("AUTH_ERROR"));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
    } else {
      openOutputDialog(log.info);
    }
  };

  const loadLatestFirmwareVersion = useCallback(
    (name) => {
      backend.bckDevicesLatestFirmwareVersion(name).then((json) => {
        if ("content" in json) {
          if (json.content === null) json.content = "";
          latestFirmwares.current[name] = json.content;
          setTableData((tableData) => {
            const newTable = [...tableData];
            newTable.forEach((r) => {
              if (r.deviceName === name) r.latestFirmware = json.content;
            });
            return newTable;
          });
        }
      });
    },
    [backend]
  );

  const refreshTable = useCallback(
    (
      userQuery,
      alarmQuery,
      processTypeQuery,
      eventTypeQuery,
      filterLang,
      searchCity,
      deviceId,
      pageNumber,
      pageSize,
      isInitializing
    ) => {
      const getLatestFirmwareVersion = (version, alarmName) => {
        if (alarmName in latestFirmwares.current) {
          var latest = latestFirmwares.current[alarmName];
          if (version === latest) return version;
          return latest;
        } else {
          latestFirmwares.current[alarmName] = t("Loading");
          loadLatestFirmwareVersion(alarmName);
          return t("Loading");
        }
      };

      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckLogsList(
          userQuery,
          alarmQuery,
          processTypeQuery,
          eventTypeQuery,
          filterLang,
          searchCity,
          customDeviceIdQuery ? customDeviceIdQuery : deviceId,
          LanguageConverter[i18n.language],
          pageNumber + 1,
          pageSize
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              json.content.rows.forEach((r) => {
                r.latestFirmware = getLatestFirmwareVersion(
                  r.firmware,
                  r.deviceName
                );
              });
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.rows;
                } else {
                  return prev;
                }
              });
              setTotalLogs((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.total;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }

          if (isInitializing) {
            setIsInitializing(false);
            setIsLoading(false);
          }
        });
    },
    [i18n.language, t, customDeviceIdQuery, loadLatestFirmwareVersion, backend]
  );

  const resetSearchParameters = () => {
    setUserQuery("");
    setUserQueryInput("");
    setAlarmQuery("");
    setAlarmQueryInput("");
    setDeviceId("");
    setDeviceIdInput("");
    setSearchEventInput([]);
    setPossibleEventsFiltered([]);
    setEventTypeQuery([]);
    setProcessTypeQuery([]);
    setProcessTypeQueryInput([]);
    setSearchLanguage("");
    setSearchLanguageInput(-1);
    setSearchCity("");
    setSearchCityInput("");
    setPageNumber(0);
  };

  const updatePossibleEvents = () => {
    backend
      .bckLogsGetPossibleLogTypes(LanguageConverter[i18n.language])
      .then((json) => {
        if (json.code === 0) {
          setPossibleEvents(json.content);
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
  };

  useEffect(() => {
    updatePossibleEvents();
    return () => {
      lastRequestId.current = null;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isInitializing) {
      updatePossibleEvents();
      resetSearchParameters();
      refreshTable("", "", [], [], "", "", "", 0, 10, false);
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    if (isInitializing) {
      setAutoUpdateChecked(
        settingRepositoryLoadBool(SettingName.AUTO_UPDATE_LOGS, true)
      );

      let pageSize = settingRepositoryLoadInt(SettingName.LOGS_PAGE_SIZE, 10);
      setPageSize(pageSize);

      var params = queryString.parse(location.search);
      if (params.login) {
        setUserQueryInput(params.login);
        setUserQuery(params.login);
        refreshTable(params.login, "", [], [], "", "", "", 0, pageSize, false);
      } else if (params.deviceId) {
        setDeviceIdInput(params.deviceId);
        setDeviceId(params.deviceId);
        refreshTable(
          "",
          "",
          [],
          [],
          "",
          "",
          params.deviceId,
          0,
          pageSize,
          false
        );
      } else {
        refreshTable("", "", [], [], "", "", "", 0, pageSize, true);
      }

      setIsInitializing(false);
    }
  }, [isInitializing, refreshTable, location]);

  useInterval(() => {
    if (updatesLockCounter.current === 0 && autoUpdateChecked) {
      refreshTable(
        userQuery,
        alarmQuery,
        processTypeQuery,
        eventTypeQuery,
        searchLanguage,
        searchCity,
        deviceId,
        pageNumber,
        pageSize,
        isInitializing
      );
    }
  }, 2000);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    refreshTable(
      userQuery,
      alarmQuery,
      processTypeQuery,
      eventTypeQuery,
      searchLanguage,
      searchCity,
      deviceId,
      newPage,
      pageSize,
      isInitializing
    );
  };

  const handleChangeRowsPerPage = (event) => {
    var newRowsPerPage = parseInt(event.target.value, 10);
    settingRepositorySave(SettingName.LOGS_PAGE_SIZE, newRowsPerPage);
    setPageSize(newRowsPerPage);
    setPageNumber(0);
    refreshTable(
      userQuery,
      alarmQuery,
      processTypeQuery,
      eventTypeQuery,
      searchLanguage,
      searchCity,
      deviceId,
      0,
      newRowsPerPage,
      isInitializing
    );
  };

  const handleSearchPress = () => {
    setUserQuery(userQueryInput);
    setAlarmQuery(alarmQueryInput);
    setProcessTypeQuery(processTypeQueryInput);
    setEventTypeQuery(searchEventInput);
    setSearchCity(searchCityInput);
    setDeviceId(deviceIdInput);

    var newSearchLanguage;
    if (searchLanguageInput === -1) {
      newSearchLanguage = "";
      setSearchLanguage("");
    } else {
      newSearchLanguage = searchLanguageInput;
      setSearchLanguage(searchLanguageInput);
    }

    setPageNumber(0);
    refreshTable(
      userQueryInput,
      alarmQueryInput,
      processTypeQueryInput,
      searchEventInput,
      newSearchLanguage,
      searchCityInput,
      deviceIdInput,
      0,
      pageSize,
      isInitializing
    );
  };

  const handleSearchResetPress = () => {
    resetSearchParameters();
    refreshTable("", "", [], [], "", "", "", 0, pageSize, isInitializing);
  };

  const handleProcessTypeChange = (event) => {
    setProcessTypeQueryInput(event.target.value);
    let newPossible = possibleEvents.filter(
      (e) => event.target.value.findIndex((t) => t === e.groupId) > -1
    );
    let filteredSelected = searchEventInput.filter(
      (i) => newPossible.findIndex((np) => np.id === i) > -1
    );
    setSearchEventInput(filteredSelected);
    setPossibleEventsFiltered(newPossible);
  };

  const handleEventChange = (event) => {
    setSearchEventInput(event.target.value);
  };

  const handleShowLogAddress = (log) => {
    let action = {
      type: DialogActionTypes.AddressDialogOpen,
      mapDisabled: false,
      handleOpenMap: () =>
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${log.coordinates.replace(
            ";",
            ","
          )}`
        ),
      text: t("Loading"),
    };
    if (log.addressId === null || log.addressId === undefined) {
      action.text = t("Address not detected");
      dialogDispatch(action);
    } else {
      backend.bckAddressGetLocationString(log.addressId).then((json) => {
        if (json.content !== null) {
          action.text = json.content;
        } else {
          action.text = t("Address not detected");
        }
        dialogDispatch(action);
      });
    }
  };

  const updateFilters = () => {
    setUserQueryInput(userQuery);
    setAlarmQueryInput(alarmQuery);
    setProcessTypeQueryInput(processTypeQuery);
    setSearchLanguageInput(searchLanguage === "" ? -1 : searchLanguage);
    setSearchCityInput(searchCity);
    setPageNumber(0);
  };

  const handleDeviceIdClick = (log) => {
    updateFilters();
    setDeviceIdInput(log.deviceId);
    setDeviceId(log.deviceId);
    setPageNumber(0);
    refreshTable(
      userQueryInput,
      alarmQuery,
      processTypeQueryInput,
      eventTypeQuery,
      searchLanguage,
      searchCityInput,
      log.deviceId,
      0,
      pageSize,
      isInitializing
    );
  };

  const handleUserEmailClick = (log) => {
    updateFilters();
    setUserQueryInput(log.user.email);
    setUserQuery(log.user.email);
    setPageNumber(0);
    refreshTable(
      log.user.email,
      alarmQuery,
      processTypeQueryInput,
      eventTypeQuery,
      searchLanguage,
      searchCityInput,
      deviceIdInput,
      0,
      pageSize,
      isInitializing
    );
  };

  const handleShowDevicePackage = (deviceId) => {
    if (deviceId) {
      dialogDispatch({
        type: DialogActionTypes.FactoryPackageDialogOpen,
        deviceId: deviceId,
      });
    }
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: customHeight ? customHeight : "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Typography color="secondary">{error}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper
            style={{
              overflow: "auto",
              padding: "24px",
              maxHeight: customHeight ? customHeight : "85vh",
            }}
          >
            {noFiltersMode ? (
              <></>
            ) : (
              <div>
                <Typography variant="h5" color="textSecondary">
                  {t("Mount logs table")}
                </Typography>
                <Grid
                  container
                  direction="column"
                  style={{ paddingTop: "12px" }}
                >
                  <Grid item>
                    <TextField
                      label={t("User")}
                      value={userQueryInput}
                      onChange={(e) => setUserQueryInput(e.target.value)}
                      style={{ marginRight: "12px", marginBottom: "12px" }}
                    />
                    <TextField
                      label={t("Device ID")}
                      value={deviceIdInput}
                      onChange={(e) => {
                        let filteredValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        setDeviceIdInput(filteredValue.substr(0, 12));
                      }}
                      style={{ marginRight: "12px", marginBottom: "12px" }}
                    />
                    <TextField
                      label={t("Device")}
                      value={alarmQueryInput}
                      onChange={(e) => setAlarmQueryInput(e.target.value)}
                      style={{ marginRight: "12px", marginBottom: "12px" }}
                    />
                    <FormControl
                      size="small"
                      style={{
                        minWidth: "25ch",
                        marginRight: "12px",
                        marginBottom: "12px",
                      }}
                    >
                      <InputLabel>{t("Process type")}</InputLabel>
                      <Select
                        multiple
                        label={t("Process type")}
                        value={processTypeQueryInput}
                        onChange={handleProcessTypeChange}
                        renderValue={(selected) =>
                          selected
                            .map(
                              (s) =>
                                processTypes.filter((pt) => pt.id === s)[0].name
                            )
                            .join(", ")
                            .slice(0, 20)
                        }
                      >
                        {processTypes.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            <Checkbox
                              checked={
                                processTypeQueryInput.indexOf(type.id) > -1
                              }
                            />
                            <ListItemText primary={type.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      size="small"
                      style={{
                        minWidth: "25ch",
                        marginRight: "12px",
                        marginBottom: "12px",
                      }}
                    >
                      <InputLabel>{t("Event")}</InputLabel>
                      <Select
                        multiple
                        label={t("Event")}
                        value={searchEventInput}
                        onChange={handleEventChange}
                        disabled={possibleEventsFiltered.length === 0}
                        renderValue={(selected) =>
                          selected
                            .map(
                              (s) =>
                                possibleEventsFiltered.filter(
                                  (pt) => pt.id === s
                                )[0].name
                            )
                            .join(", ")
                            .slice(0, 20)
                        }
                      >
                        {possibleEventsFiltered.map((eventType) => (
                          <MenuItem key={eventType.id} value={eventType.id}>
                            <Checkbox
                              checked={
                                searchEventInput.indexOf(eventType.id) > -1
                              }
                            />
                            <ListItemText
                              primary={eventType.name}
                              style={{ maxWidth: "40ch" }}
                              primaryTypographyProps={{
                                style: { whiteSpace: "normal" },
                              }}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      label={t("Address")}
                      value={searchCityInput}
                      onChange={(e) => setSearchCityInput(e.target.value)}
                      style={{ marginRight: "12px", marginBottom: "12px" }}
                    />
                    <FormControl
                      style={{
                        marginRight: "12px",
                        minWidth: "12ch",
                        marginBottom: "12px",
                      }}
                    >
                      <InputLabel>{t("Language")}</InputLabel>
                      <Select
                        label={t("Language")}
                        value={searchLanguageInput}
                        onChange={(e) => setSearchLanguageInput(e.target.value)}
                      >
                        <MenuItem key={-1} value={-1}>
                          <em>{t("Any (mas)")}</em>
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          RU
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          EN
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item>
                      <Button
                        onClick={handleSearchPress}
                        variant="contained"
                        color="primary"
                      >
                        {t("Search")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={handleSearchResetPress}
                        variant="contained"
                        color="primary"
                        disabled={
                          userQuery === "" &&
                          searchCity === "" &&
                          alarmQuery === "" &&
                          eventTypeQuery === "" &&
                          processTypeQuery.length === 0 &&
                          searchLanguageInput === -1
                        }
                      >
                        {t("Reset parameters")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={autoUpdateChecked}
                            onChange={() => {
                              setAutoUpdateChecked(!autoUpdateChecked);
                              settingRepositorySave(
                                SettingName.AUTO_UPDATE_LOGS,
                                !autoUpdateChecked
                              );
                            }}
                            color="primary"
                          />
                        }
                        label={t("Auto update")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            <AdvancedTablePagination
              rowsPerPageOptions={[10, 20, 50, 100, 500]}
              totalRows={totalLogs}
              pageSize={pageSize}
              pageNumber={pageNumber}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              <LogsTable
                data={tableData}
                handleShowLogAddress={handleShowLogAddress}
                handleDeviceIdClick={handleDeviceIdClick}
                handleUserEmailClick={handleUserEmailClick}
                handleShowDevicePackage={handleShowDevicePackage}
                handleShowUserInfo={handleShowUserInfo}
                handleViewClick={handleViewClick}
                handleUsergroupClick={handleUsergroupClick}
              />
            </AdvancedTablePagination>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
