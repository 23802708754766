import React from "react";

import {
    Button,
    IconButton,
    Paper,
    Typography
} from "@mui/material";

import {
    Delete as DeleteIcon,
    Edit as EditIcon
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import CarMountingPointGuideEditorImage from "./CarMountingPointGuideEditorImage";
import LanguageSelect from "../../components/LanguageSelect";
import { LanguageConverterInverse } from "../../enums/LanguageConverter";

export default function CarMountingPointGuideEditor({ guide, handleMoveImageLeft, handleMoveImageRight, handleUploadImage, handleDeleteImage, handleAddImage, handleEditSubtitle, handleEditDescription, handleEditType, handleEditLanguage, handleDelete }) {
    const { t } = useTranslation();

    return <Paper style={{ padding: "16px" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div><b>{t("Type") + ": "}</b></div>
                <div>{guide.type.name}</div>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={handleEditType} >
                    <EditIcon />
                </IconButton>
            </div>
            <IconButton
                style={{ color: 'red' }}
                size="small"
                onClick={handleDelete}
            >
                <DeleteIcon />
            </IconButton>
        </div>

        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div><b>{t("Subtitle") + ": "}</b></div>
            <div>{guide.subtitle}</div>
            <IconButton
                color="primary"
                size="small"
                onClick={handleEditSubtitle} >
                <EditIcon />
            </IconButton>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={{ paddingRight: "16px" }}><b>{t("Language") + ": "}</b></div>
            <LanguageSelect
                label=""
                value={LanguageConverterInverse[guide.language].toUpperCase()}
                onChange={(e) => { handleEditLanguage(e) }}
            />
        </div>        
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div><b>{t("Description") + ": "}</b></div>
            <IconButton
                color="primary"
                size="small"
                onClick={handleEditDescription} >
                <EditIcon />
            </IconButton>
        </div>
        <Typography component="pre">{guide.description}</Typography>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: "16px" }}>
            <div style={{ paddingRight: "16px" }}><b>{t("Images") + ": "}</b></div>
            <Button onClick={handleAddImage} variant="contained" color="primary">{t("Add")}</Button>
        </div>
        <div style={{ display: "flex", flexDirection: "row", maxWidth: "100%", overflowX: "auto", paddingBottom: "16px" }}>
            {
                guide.images.map((image, i) =>
                    <div key={image.id} style={{ marginRight: "16px" }}>
                        <CarMountingPointGuideEditorImage key={image.id} image={image}
                            handleMoveLeft={i === 0 ? null : handleMoveImageLeft}
                            handleMoveRight={i === guide.images.length - 1 ? null : handleMoveImageRight}
                            handleDelete={handleDeleteImage}
                            handleUpload={handleUploadImage} />
                    </div>
                )
            }
        </div>
    </Paper>;
}
