import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Themes from "./themes";
import { DialogProvider } from "./context/DialogContext";

// eslint-disable-next-line
import i18n from "./i18n";
import { BackendProvider } from "./context/BackendContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <LayoutProvider>
          <UserProvider>
            <BackendProvider>
              <ThemeProvider theme={Themes.default}>
                <DialogProvider>
                  <CssBaseline />
                  <App />
                </DialogProvider>
              </ThemeProvider>
            </BackendProvider>
          </UserProvider>
        </LayoutProvider>
      </LocalizationProvider>
    </DndProvider>
  </React.StrictMode>
);
