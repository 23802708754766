import React, { useState, useEffect } from "react";

import {
    Typography,
    IconButton,
    Button,
    MenuItem,
    Table,
    TableRow,
    TableBody,
    TableCell
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SettingsStructureTypes, SettingsStructureTypesByName } from "../../enums/SettingsStructureTypes"
import { useDialogContext, DialogActionTypes } from "../../context/DialogContext";

import {
    Edit as EditIcon,
    RemoveCircle as RemoveIcon,
    AddCircle as AddCircleIcon
} from "@mui/icons-material";
import { getFlatStructures } from "./Misc";

const AvailableFieldsPrototype = {
    StartByte: false,
    StartBit: false,
    LengthByte: false,
    LengthBit: false,
    Divider: false,
    PrototypeId: false,
    Count: false,
    OptionStructs: false,
    Option2Value: false
};

const AvailableFieldsMapper = {
    [SettingsStructureTypesByName.UInt]: { ...AvailableFieldsPrototype, StartByte: true },
    [SettingsStructureTypesByName.UShort]: { ...AvailableFieldsPrototype, StartByte: true },
    [SettingsStructureTypesByName.UShortAsFloat]: { ...AvailableFieldsPrototype, StartByte: true, Divider: true },
    [SettingsStructureTypesByName.UByte]: { ...AvailableFieldsPrototype, StartByte: true, LengthBit: true },
    [SettingsStructureTypesByName.UByteWithBits]: { ...AvailableFieldsPrototype, StartByte: true, LengthBit: true },
    [SettingsStructureTypesByName.UByteAsFloat]: { ...AvailableFieldsPrototype, StartByte: true, Divider: true },
    [SettingsStructureTypesByName.SByte]: { ...AvailableFieldsPrototype, StartByte: true },
    [SettingsStructureTypesByName.Bit]: { ...AvailableFieldsPrototype, StartByte: true, StartBit: true },
    [SettingsStructureTypesByName.InvertedBit]: { ...AvailableFieldsPrototype, StartByte: true, StartBit: true },
    [SettingsStructureTypesByName.UByteOption]: { ...AvailableFieldsPrototype, StartByte: true, Option2Value: true },
    [SettingsStructureTypesByName.BitOption]: { ...AvailableFieldsPrototype, StartByte: true, StartBit: true },
    [SettingsStructureTypesByName.ShortTime]: { ...AvailableFieldsPrototype, StartByte: true },
    [SettingsStructureTypesByName.DmsByte]: { ...AvailableFieldsPrototype, StartByte: true },
    [SettingsStructureTypesByName.CharString]: { ...AvailableFieldsPrototype, StartByte: true, LengthByte: true },
    [SettingsStructureTypesByName.BitFromInt]: { ...AvailableFieldsPrototype, StartByte: true, StartBit: true },
    [SettingsStructureTypesByName.Virtual]: { ...AvailableFieldsPrototype, OptionStructs: true, Option2Value: true },
    [SettingsStructureTypesByName.Structure]: { ...AvailableFieldsPrototype },
    [SettingsStructureTypesByName.UByteSiren]: { ...AvailableFieldsPrototype, StartByte: true },
    [SettingsStructureTypesByName.Instance]: { ...AvailableFieldsPrototype, StartByte: true, PrototypeId: true },
    [SettingsStructureTypesByName.Array]: { ...AvailableFieldsPrototype, StartByte: true, LengthByte: true, LengthBit: true, PrototypeId: true, Count: true },
    [SettingsStructureTypesByName.Union]: { ...AvailableFieldsPrototype, OptionStructs: true, Option2Value: true },
    [SettingsStructureTypesByName.UIntAsFloat]: { ...AvailableFieldsPrototype, StartByte: true, Divider: true },
    [SettingsStructureTypesByName.SShort]: { ...AvailableFieldsPrototype, StartByte: true, LengthBit: true },
    [SettingsStructureTypesByName.IpAddress]: { ...AvailableFieldsPrototype, StartByte: true }
};

export default function Structure({ settingInfo, structure, handleSave }) {
    const dialogDispatch = useDialogContext();
    const [localStructure, setLocalStructure] = useState({});
    const [availableFields, setAvailableFields] = useState({ ...AvailableFieldsPrototype });
    const { t } = useTranslation();

    useEffect(() => {
        setLocalStructure({ ...structure });
    }, [structure]);

    useEffect(() => {
        setAvailableFields(AvailableFieldsMapper[localStructure.Mapper]);
    }, [localStructure])

    const areStructuresDifferent = (structure1, structure2) => {
        return JSON.stringify(structure1) !== JSON.stringify(structure2);
    };

    const handleEditName = () => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: (name) => {
                setLocalStructure((prev) => ({ ...prev, Name: name }));
            },
            initialValue: localStructure.Name,
            textLabel: t("Name"),
            title: t("Name")
        });
    };

    const handleEditParentId = () => {
        dialogDispatch({
            type: DialogActionTypes.SimpleSelectDialogOpen,
            initialValue: localStructure.ParentId,
            label: "Parent ID",
            title: "Parent ID",
            menuItems: Object.keys(settingInfo.Structures).map(k => <MenuItem key={parseInt(k)} value={parseInt(k)}>
                {`${settingInfo.Structures[k].Name} (${k})`}
            </MenuItem>),
            handleConfirm: (value) => {
                setLocalStructure((prev) => ({ ...prev, ParentId: value }));
            }
        });
    };

    const handleEditPrototypeId = () => {
        let flatStructures = getFlatStructures(Object.values(settingInfo.Structures));
        dialogDispatch({
            type: DialogActionTypes.AutocompleteDialogOpen,
            initialValue: flatStructures[localStructure.PrototypeId] ?? flatStructures[1],
            label: "Prototype ID",
            title: "Prototype ID",
            options: Object.values(flatStructures),
            getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
            multiple: false,
            handleConfirm: (value) => {
                if (value) {
                    setLocalStructure((prev) => ({ ...prev, PrototypeId: value.Id }));
                }
            }
        });
    };

    const handleRemoveOption2ValueOption = (o) => {
        setLocalStructure((prev) => {
            let next = { ...prev };
            let nextOption2Value = { ...next.Option2Value };
            delete nextOption2Value[o];
            next.Option2Value = nextOption2Value;
            return next;
        });
    };

    const handleRemoveOption2ValueValue = (o, index) => {
        setLocalStructure((prev) => {
            let next = { ...prev };
            let nextOption2Value = { ...next.Option2Value };
            let nextOption2ValueValue = [...nextOption2Value[o]];
            nextOption2ValueValue.splice(index, 1);
            nextOption2Value[o] = nextOption2ValueValue;
            next.Option2Value = nextOption2Value;
            return next;
        });
    };

    const handleAddOption2ValueValue = (o) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: (value) => {
                let parsed = parseInt(value);
                setLocalStructure((prev) => {
                    let next = { ...prev };
                    let nextOption2Value = { ...next.Option2Value };
                    let nextOption2ValueValue = [...nextOption2Value[o]];
                    nextOption2ValueValue.push(parsed);
                    nextOption2Value[o] = nextOption2ValueValue;
                    next.Option2Value = nextOption2Value;
                    return next;
                });
            },
            initialValue: "0",
            textLabel: t("Value"),
            title: t("Value"),
            onChange: (e) => {
                let filteredValue = e.target.value.replace(/[^0-9]/g, '');
                return filteredValue.substr(0, 4);
            }
        });
    };

    const handleAddOption2ValueOption = () => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: (option) => {
                let parsed = parseInt(option);
                setLocalStructure((prev) => {
                    let next = { ...prev };
                    let nextOption2Value = { ...next.Option2Value };
                    nextOption2Value[parsed] = [];
                    next.Option2Value = nextOption2Value;
                    return next;
                });
            },
            initialValue: "0",
            textLabel: t("Option"),
            title: t("Option"),
            onChange: (e) => {
                let filteredValue = e.target.value.replace(/[^0-9]/g, '');
                return filteredValue.substr(0, 4);
            }
        });
    };

    const mapperMenuItems = Object.keys(SettingsStructureTypes).map(i =>
        <MenuItem key={parseInt(i)} value={parseInt(i)}>
            {`${SettingsStructureTypes[i]} (${i})`}
        </MenuItem>
    );

    const handleEditMapper = () => {
        dialogDispatch({
            type: DialogActionTypes.SimpleSelectDialogOpen,
            initialValue: localStructure.Mapper,
            label: "Mapper",
            title: "Mapper",
            menuItems: mapperMenuItems,
            handleConfirm: (value) => {
                setLocalStructure((prev) => ({ ...prev, Mapper: value }));
            }
        });
    };

    const handleEditOptionStructs = () => {
        let flatStructures = getFlatStructures(Object.values(settingInfo.Structures));
        if (!localStructure.OptionStructs) {
            setLocalStructure((prev) => ({ ...prev, OptionStructs: [] }));
        }
        dialogDispatch({
            type: DialogActionTypes.AutocompleteDialogOpen,
            initialValue: localStructure.OptionStructs?.map(os => flatStructures[os]),
            title: "OptionStructs",
            label: "OptionStructs",
            options: Object.values(flatStructures),
            getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
            multiple: true,
            handleConfirm: (newOptionStructs) => {
                setLocalStructure((prev) => ({ ...prev, OptionStructs: newOptionStructs.map(nos => nos.Id) }));
            }
        });
    };

    const handleEditNumberValue = (valueName) => {
        dialogDispatch({
            type: DialogActionTypes.SimpleTextDialogOpen,
            handleConfirm: (value) => {
                let parsed = parseInt(value);
                setLocalStructure((prev) => {
                    let next = { ...prev };
                    next[valueName] = parsed
                    return next;
                });
            },
            initialValue: "0",
            textLabel: t("Value"),
            title: t("Value"),
            onChange: (e) => {
                let filteredValue = e.target.value.replace(/[^0-9]/g, '');
                return filteredValue.substr(0, 6);
            }
        });
    };

    return (
        <>
            <Button
                onClick={() => handleSave(localStructure)}
                disabled={!areStructuresDifferent(localStructure, structure)}
                variant="contained"
                color="primary"
                style={{ marginBottom: "16px" }}
            >
                {t("Save")}
            </Button>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>ID: </b>{localStructure.Id}</Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>Parent ID: </b>{localStructure?.ParentId ? `${settingInfo.Structures[localStructure.ParentId].Name} (${localStructure.ParentId})` : ""}</Typography>
                <IconButton
                    color="primary"
                    disabled={structure.Children && structure.Children.length > 0}
                    onClick={handleEditParentId}>
                    <EditIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>Name: </b>{localStructure.Name}</Typography>
                <IconButton
                    color="primary"
                    onClick={handleEditName}>
                    <EditIcon />
                </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography><b>Mapper: </b>{localStructure?.Mapper ? `${SettingsStructureTypes[localStructure.Mapper]} (${localStructure.Mapper})` : ""}</Typography>
                <IconButton
                    color="primary"
                    disabled={structure.Children && structure.Children.length > 0}
                    onClick={handleEditMapper}>
                    <EditIcon />
                </IconButton>
            </div>
            {
                availableFields?.StartByte ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography><b>StartByte: </b>{localStructure.StartByte}</Typography>
                    <IconButton
                        color="primary"
                        disabled={structure.Children && structure.Children.length > 0}
                        onClick={() => handleEditNumberValue("StartByte")}>
                        <EditIcon />
                    </IconButton>
                </div> : <></>
            }
            {
                availableFields?.StartBit ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography><b>StartBit: </b>{localStructure.StartBit}</Typography>
                    <IconButton
                        color="primary"
                        disabled={structure.Children && structure.Children.length > 0}
                        onClick={() => handleEditNumberValue("StartBit")}>
                        <EditIcon />
                    </IconButton>
                </div> : <></>
            }
            {
                availableFields?.LengthByte ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography><b>LengthByte: </b>{localStructure.LengthByte}</Typography>
                    <IconButton
                        color="primary"
                        disabled={structure.Children && structure.Children.length > 0}
                        onClick={() => handleEditNumberValue("LengthByte")}>
                        <EditIcon />
                    </IconButton>
                </div> : <></>
            }
            {
                availableFields?.LengthBit ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography><b>LengthBit: </b>{localStructure.LengthBit}</Typography>
                    <IconButton
                        color="primary"
                        disabled={structure.Children && structure.Children.length > 0}
                        onClick={() => handleEditNumberValue("LengthBit")}>
                        <EditIcon />
                    </IconButton>
                </div> : <></>
            }
            {
                availableFields?.Divider ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography><b>Divider: </b>{localStructure.Divider}</Typography>
                    <IconButton
                        color="primary"
                        disabled={structure.Children && structure.Children.length > 0}
                        onClick={() => handleEditNumberValue("Divider")}>
                        <EditIcon />
                    </IconButton>
                </div> : <></>
            }
            {
                availableFields?.PrototypeId ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography><b>PrototypeId: </b>{localStructure.PrototypeId}</Typography>
                    <IconButton
                        color="primary"
                        disabled={structure.Children && structure.Children.length > 0}
                        onClick={handleEditPrototypeId}>
                        <EditIcon />
                    </IconButton>
                </div> : <></>
            }
            {
                availableFields?.Count ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography><b>Count: </b>{localStructure.Count}</Typography>
                    <IconButton
                        color="primary"
                        disabled={structure.Children && structure.Children.length > 0}
                        onClick={() => handleEditNumberValue("Count")} >
                        <EditIcon />
                    </IconButton>
                </div> : <></>
            }
            {
                availableFields?.OptionStructs ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Typography><b>OptionStructs: </b>{JSON.stringify(localStructure.OptionStructs)}</Typography>
                    <IconButton
                        color="primary"
                        onClick={handleEditOptionStructs} >
                        <EditIcon />
                    </IconButton>
                </div> : <></>
            }
            {
                availableFields?.Option2Value ? <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography><b>Option2Value: </b>{JSON.stringify(localStructure.Option2Value)}</Typography>
                    <Table size="small">
                        <TableBody>
                            {
                                localStructure?.Option2Value ? Object.keys(localStructure.Option2Value).map(o =>
                                    <TableRow key={o}>
                                        <TableCell>
                                            <IconButton
                                                color="secondary"
                                                onClick={(e) => { handleRemoveOption2ValueOption(o) }}
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                            <b>{o}</b>
                                        </TableCell>
                                        {
                                            localStructure.Option2Value[o].map((o2v, i) => {
                                                return <TableCell>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={(e) => { handleRemoveOption2ValueValue(o, i) }}
                                                    >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                    {o2v}
                                                </TableCell>
                                            })
                                        }
                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                onClick={(e) => { handleAddOption2ValueValue(o); }}
                                            >
                                                <AddCircleIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ) : <></>
                            }
                            <TableRow>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={(e) => { handleAddOption2ValueOption(); }}
                                    >
                                        <AddCircleIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div> : <></>
            }
        </>);
}
