import React, { useState } from "react";

import { Grid, Button, CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import ReactFileReader from "react-file-reader";
import { saveFile } from "../../helpers/FileSaver";

export default function MenuJson() {
  const backend = useBackend();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleJsonFileSelect = (files) => {
    if (files[0].size > 32 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    setIsLoading(true);
    let reader = new FileReader();
    reader.onloadend = function (e) {
      try {
        let json = JSON.parse(reader.result);
        backend.bckSettingsRestore(json).then((json) => {
          setIsLoading(false);
          saveFile(
            window.URL.createObjectURL(
              new Blob([JSON.stringify(json)], { type: "text/json" })
            ),
            "RestoreResponse.json"
          );
        });
      } catch {
        alert(t("Unknown JSON file format"));
      }
    };
    reader.readAsText(files[0], "UTF-8");
  };

  const handleGenerateClick = () => {
    setIsLoading(true);
    backend.bckSettingsGenerateSettingsEditorInfo(true).then((json) => {
      setIsLoading(false);
      if (json.content) {
        let parsed = JSON.parse(json.content);
        saveFile(
          window.URL.createObjectURL(
            new Blob([JSON.stringify(parsed)], { type: "text/json" })
          ),
          "settings.json"
        );
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress size={32} />
      ) : (
        <>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateClick}
            >
              {t("Generate")}
            </Button>
          </Grid>
          <Grid item>
            <ReactFileReader
              handleFiles={handleJsonFileSelect}
              fileTypes={[".json"]}
            >
              <Button variant="contained" color="primary">
                {t("Restore")}
              </Button>
            </ReactFileReader>
          </Grid>
        </>
      )}
    </>
  );
}
