import React, { useState, useEffect } from "react";

import {
  Tabs,
  Tab,
  Typography,
  Paper,
  Button,
  Grid,
  Switch,
  IconButton,
} from "@mui/material";
//import ChipInput from 'material-ui-chip-input'

import { Edit as EditIcon } from "@mui/icons-material";

import SelectPreferencesDialog from "../components/Dialogs/SelectPreferencesDialog";
import Widget from "../components/Widget/Widget";
import EditUsergroup from "../components/EditUsergroup";

import { UserRolesIntToString } from "../enums/UserRoles";

import ReactFileReader from "react-file-reader";
import { useTranslation } from "react-i18next";
import { UserPermissions } from "../enums/UserRoles";

import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { PushTypes } from "../enums/PushTypes";
import AdvancedAvatarBadge from "../components/AdvancedAvatarBadge";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { useBackend } from "../context/BackendContext";

export default function ManageAccount() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const { t } = useTranslation();
  const location = useLocation();
  const [error, setError] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [userName, setUserName] = useState("");
  const [userMail, setUserMail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userRole, setUserRole] = useState(1);
  const [userId, setUserId] = useState(0);
  const [userCompany, setUserCompany] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [initialUserPushTypes, setInitialUserPushTypes] = useState([]);
  const [userPushTypes, setUserPushTypes] = useState([]);
  const [isUserOnline, setIsUserOnline] = useState(false);
  const [login, setLogin] = useState("");
  const [defaultVisibility, setDefaultVisibility] = useState(false);
  const [allowEditInfo, setAllowEditInfo] = useState(false);
  const [showGroupEditor, setShowGroupEditor] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [preferredBrands, setPreferredBrands] = useState([]);
  const [preferredModels, setPreferredModels] = useState([]);
  const [isSelectPreferencesOpened, setIsSelectPreferencesOpened] =
    useState(false);
  const [avatarKey, setAvatarKey] = useState(0);

  useEffect(() => {
    let params = queryString.parse(location.search);
    let newLogin = "";
    let searchMode = false;
    let permissions =
      JSON.parse(localStorage.getItem("permissions") || "[]") || [];
    if (params.login === undefined) {
      newLogin = localStorage.getItem("userMail");
      setAllowEditInfo(true);
      searchMode = true;
    } else {
      newLogin = params.login;
      if (!permissions.includes(UserPermissions.USER_INFO_EDIT_ANY)) {
        setAllowEditInfo(false);
      } else {
        setAllowEditInfo(true);
      }
      searchMode = false;
    }

    if (
      permissions.includes(UserPermissions.GET_OWN_USERGROUP) ||
      permissions.includes(UserPermissions.GET_ANY_USERGROUP)
    ) {
      setShowGroupEditor(true);
    } else {
      setShowGroupEditor(false);
    }

    setSearchMode(searchMode);

    setLogin(newLogin);
    backend.bckUsersGetFullInfo(newLogin).then((json) => {
      if (json.code === 0) {
        var content = json.content;
        setUserId(content.id);
        setUserName(content.fullname);
        setUserMail(content.email);
        setUserPhone(content.phone);
        setUserCompany(content.company);
        setUserAddress(content.address);
        setUserRole(content.role);
        setDefaultVisibility(content.useUsergroupVisibility);
        setIsUserOnline(content.isOnline);
        setPreferredBrands(content.preferredBrands);
        setPreferredModels(content.preferredModels);
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });

    if (searchMode) {
      backend.bckUsersGetPushRules().then((json) => {
        if (json.code === 0) {
          setUserPushTypes(json.content.types);
          setInitialUserPushTypes(json.content.types);
        } else if (json.code === 3) {
          setError(json.message);
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
    }
  }, [location, t]);

  const handleSetTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSelectPreferencesClose = () => {
    setIsSelectPreferencesOpened(false);
  };

  const handleSelectPreferencesConfirm = (
    newPreferredBrands,
    newPreferredModels
  ) => {
    setPreferredBrands(newPreferredBrands);
    setPreferredModels(newPreferredModels);
    backend
      .bckUsersSetPreferences(
        newPreferredBrands.map((npb) => npb.id),
        newPreferredModels.map((npm) => npm.id)
      )
      .then((json) => {
        if (json.code === 0) {
        } else if (json.code === 3) {
          setError(json.message);
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
    setIsSelectPreferencesOpened(false);
  };

  const handleEditFullname = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newFullname) => {
        localStorage.setItem("userName", newFullname);
        window.dispatchEvent(new Event("storage"));
        setUserName(newFullname);

        backend.bckUsersEditFullname(userId, newFullname).then((json) => {
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
      initialValue: userName,
      textLabel: t("Full name"),
      title: t("Full name"),
      maxLength: 100,
    });
  };

  const handleEditPhone = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newPhone) => {
        localStorage.setItem("userPhone", newPhone);
        window.dispatchEvent(new Event("storage"));
        setUserPhone(newPhone);

        backend.bckUsersEditPhone(userId, newPhone).then((json) => {
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
      initialValue: userPhone,
      textLabel: t("Phone"),
      title: t("Phone"),
      maxLength: 32,
    });
  };

  const handleEditCompany = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newCompany) => {
        localStorage.setItem("userCompany", newCompany);
        setUserCompany(newCompany);

        backend.bckUsersEditCompany(userId, newCompany).then((json) => {
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
      initialValue: userCompany,
      textLabel: t("Company"),
      title: t("Company"),
      maxLength: 200,
    });
  };

  const handleEditAddress = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newAddress) => {
        localStorage.setItem("userAddress", newAddress);
        setUserAddress(newAddress);

        backend.bckUsersEditAddress(userId, newAddress).then((json) => {
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
      initialValue: userAddress,
      textLabel: t("Address"),
      title: t("Address"),
      maxLength: 200,
    });
  };

  const handleEditPushTypes = () => {
    setInitialUserPushTypes(userPushTypes);
    backend.bckUsersSetPushRules(userPushTypes).then((json) => {
      if (json.code === 0) {
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const onPushTypesChanged = (key, value) => {
    if (value) {
      if (userPushTypes.every((upt) => upt !== key)) {
        setUserPushTypes((prev) => [...prev, key]);
      }
    } else {
      if (!userPushTypes.every((upt) => upt !== key)) {
        setUserPushTypes((prev) => {
          var newa = [...prev];
          newa.splice(
            prev.findIndex((v) => v === key),
            1
          );
          return newa;
        });
      }
    }
  };

  const handleEditPreferences = () => {
    setIsSelectPreferencesOpened(true);
  };

  const handleEditAvatar = (files) => {
    let file = files[0];
    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    backend.bckFilesUploadAvatar(file).then((json) => {
      if (json.code === 0) {
        setAvatarKey((prev) => ++prev);
      } else {
        setError(json.message);
      }
    });
  };

  const handleChangePassword = () => {
    dialogDispatch({
      type: DialogActionTypes.ChangePasswordDialogOpen,
    });
  };

  return (
    <>
      <Paper style={{ overflow: "auto", padding: "12px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <AdvancedAvatarBadge
              isUserOnline={isUserOnline}
              userId={userId}
              userMail={userMail}
              avatarKey={avatarKey}
            />
            {searchMode ? (
              <ReactFileReader
                handleFiles={handleEditAvatar}
                fileTypes={[".jpeg", ".jpg", ".jpe", ".png", ".pns"]}
              >
                <IconButton
                  color="primary"
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    width: "24px",
                    height: "24px",
                  }}
                >
                  <EditIcon style={{ width: "16px", height: "16px" }} />
                </IconButton>
              </ReactFileReader>
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
            }}
          >
            <Typography variant="h3">
              {userName} ({userMail})
            </Typography>
            <Typography variant="h3">{userPhone}</Typography>
          </div>
        </div>
        <Button
          style={{
            marginTop: "16px",
            backgroundColor: "#3CD4A0",
            color: "#FFFFFF",
          }}
          size="small"
          className="px-2"
          variant="contained"
        >
          {t(UserRolesIntToString[userRole])}
        </Button>
      </Paper>
      <Tabs value={tabValue} onChange={handleSetTab}>
        <Tab label={t("General information")} value={0} />
        {showGroupEditor ? <Tab label={t("Usergroup")} value={1} /> : []}
        {searchMode ? <Tab label={t("PUSH preferences")} value={2} /> : []}
        <Tab label={t("Preferences")} value={3} />
      </Tabs>
      {((tabValue) => {
        switch (tabValue) {
          case 0:
            return (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Widget noBodyPadding noHeader>
                    <Typography color="secondary">{error}</Typography>
                    <Grid
                      container
                      direction="column"
                      style={{ paddingLeft: "12px", marginTop: "16px" }}
                    >
                      <Typography>
                        <b>{t("Account information")}</b>
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{
                            width: "16ch",
                            textAlign: "right",
                            paddingRight: "16px",
                          }}
                        >
                          <b>{t("Full name")}:</b>
                        </Typography>
                        <Typography
                          style={{ width: "64ch", paddingRight: "16px" }}
                        >
                          {userName}
                        </Typography>
                        <Button
                          disabled={!allowEditInfo}
                          onClick={handleEditFullname}
                          color="primary"
                          variant="contained"
                        >
                          {t("Change")}
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{
                            width: "16ch",
                            textAlign: "right",
                            paddingRight: "16px",
                          }}
                        >
                          <b>Email:</b>
                        </Typography>
                        <Typography
                          style={{ width: "64ch", paddingRight: "16px" }}
                        >
                          {userMail}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{
                            width: "16ch",
                            textAlign: "right",
                            paddingRight: "16px",
                          }}
                        >
                          <b>{t("Phone")}:</b>
                        </Typography>
                        <Typography
                          style={{ width: "64ch", paddingRight: "16px" }}
                        >
                          {userPhone}
                        </Typography>
                        <Button
                          disabled={!allowEditInfo}
                          onClick={handleEditPhone}
                          color="primary"
                          variant="contained"
                        >
                          {t("Change")}
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{
                            width: "16ch",
                            textAlign: "right",
                            paddingRight: "16px",
                          }}
                        >
                          <b>{t("Company")}:</b>
                        </Typography>
                        <Typography
                          style={{ width: "64ch", paddingRight: "16px" }}
                        >
                          {userCompany}
                        </Typography>
                        <Button
                          disabled={!allowEditInfo}
                          onClick={handleEditCompany}
                          color="primary"
                          variant="contained"
                        >
                          {t("Change")}
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{
                            width: "16ch",
                            textAlign: "right",
                            paddingRight: "16px",
                          }}
                        >
                          <b>{t("Address")}:</b>
                        </Typography>
                        <Typography
                          style={{ width: "64ch", paddingRight: "16px" }}
                        >
                          {userAddress}
                        </Typography>
                        <Button
                          disabled={!allowEditInfo}
                          onClick={handleEditAddress}
                          color="primary"
                          variant="contained"
                        >
                          {t("Change")}
                        </Button>
                      </div>
                      {searchMode ? (
                        <>
                          <Typography style={{ marginTop: "36px" }}>
                            <b>{t("Security")}</b>
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                              marginBottom: "16px",
                            }}
                          >
                            <Typography
                              style={{
                                width: "16ch",
                                textAlign: "right",
                                paddingRight: "16px",
                              }}
                            >
                              <b>{t("Password")}:</b>
                            </Typography>
                            <Typography
                              style={{ width: "64ch", paddingRight: "16px" }}
                            >
                              ******
                            </Typography>
                            <Button
                              onClick={handleChangePassword}
                              color="primary"
                              variant="contained"
                            >
                              {t("Change")}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div style={{ marginBottom: "16px" }}></div>
                      )}
                    </Grid>
                  </Widget>
                </Grid>
              </Grid>
            );
          case 1:
            return (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Widget noBodyPadding noHeader>
                    <div style={{ paddingLeft: "16px" }}>
                      <EditUsergroup
                        searchMode={searchMode}
                        defaultUserVisibility={defaultVisibility}
                        setDefaultUserVisibility={setDefaultVisibility}
                        userLogin={login}
                      />
                    </div>
                  </Widget>
                </Grid>
              </Grid>
            );
          case 2:
            return (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Widget noBodyPadding noHeader>
                    <div style={{ paddingLeft: "16px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "left",
                          marginTop: "8px",
                          marginBottom: "16px",
                        }}
                      >
                        <div>
                          {Object.keys(PushTypes)
                            .map((k) => parseInt(k))
                            .map((k) => (
                              <div key={k}>
                                <Switch
                                  color="primary"
                                  checked={
                                    !userPushTypes.every((upt) => upt !== k)
                                  }
                                  onChange={(e) =>
                                    onPushTypesChanged(k, e.target.checked)
                                  }
                                />
                                {t(PushTypes[k])}
                              </div>
                            ))}
                          <Button
                            disabled={
                              !allowEditInfo ||
                              initialUserPushTypes === userPushTypes
                            }
                            onClick={handleEditPushTypes}
                            color="primary"
                            variant="contained"
                          >
                            {t("Save")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Widget>
                </Grid>
              </Grid>
            );
          default:
            return (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Widget noBodyPadding noHeader>
                    <div style={{ paddingLeft: "16px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "left",
                          marginTop: "8px",
                          marginBottom: "16px",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                          }}
                        >
                          <Typography style={{ paddingRight: "16px" }}>
                            <b>{t("Preferred brands")}:</b>
                          </Typography>
                          {/*<ChipInput*/}
                          {/*    inputValue=""*/}
                          {/*    value={preferredBrands.map(o => o.name)}*/}
                          {/*    placeholder={t("Preferred brands")}*/}
                          {/*    style={{ marginRight: "16px" }}*/}
                          {/*/>*/}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                          }}
                        >
                          <Typography style={{ paddingRight: "16px" }}>
                            <b>{t("Preferred models")}:</b>
                          </Typography>
                          {/*<ChipInput*/}
                          {/*    inputValue=""*/}
                          {/*    value={preferredModels.map(o => `${o.brandName} ${o.name}`)}*/}
                          {/*    placeholder={t("Preferred models")}*/}
                          {/*    style={{ marginRight: "16px" }}*/}
                          {/*/>*/}
                        </div>
                        <Button
                          disabled={!allowEditInfo}
                          onClick={handleEditPreferences}
                          style={{ width: "32ch", marginBottom: "16px" }}
                          color="primary"
                          variant="contained"
                        >
                          {t("Edit preferences")}
                        </Button>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "16px",
                          }}
                        >
                          <Typography style={{ paddingRight: "16px" }}>
                            <b>{t("Preferred brands (detected)")}:</b>
                          </Typography>
                          {/*<ChipInput*/}
                          {/*    inputValue=""*/}
                          {/*    value={preferredBrandsDetected.map(o => o.name)}*/}
                          {/*    placeholder={t("Preferred brands (detected)")}*/}
                          {/*    style={{ marginRight: "16px" }}*/}
                          {/*/>*/}
                        </div>
                      </div>
                    </div>
                  </Widget>

                  <SelectPreferencesDialog
                    isOpened={isSelectPreferencesOpened}
                    defaultPreferredBrands={preferredBrands}
                    defaultPreferredModels={preferredModels}
                    handleClose={handleSelectPreferencesClose}
                    handleConfirm={handleSelectPreferencesConfirm}
                  />
                </Grid>
              </Grid>
            );
        }
      })(tabValue)}
    </>
  );
}
