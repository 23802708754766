import React, { useState, useEffect, useRef, useCallback } from "react";

import { Grid, Typography, Button, Paper } from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import CloneTipsTable from "../components/Tables/CloneTipsTable";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import LoadingOverlay from "react-loading-overlay-ts";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { ResponseCode } from "../enums/ResponseCode";

export default function CloneTips() {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useState({
    pageNumber: 0,
    pageSize: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(100);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshTable = useCallback(
    (pageNumber, pageSize) => {
      setIsLoading(true);
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend.bckCloneTipsList(pageNumber + 1, pageSize).then((json) => {
        setIsLoading(false);
        --updatesLockCounter.current;
        if (lastRequestId.current === id) {
          if (json.code === 0) {
            setTotalRows(json.content.count);
            setTableData((prev) => {
              if (updatesLockCounter.current === 0) {
                return json.content.list;
              } else {
                return prev;
              }
            });
          } else if (json.code === ResponseCode.ACCESS_DENIED) {
            setError(t("AUTH_ERROR"));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        }
      });
    },
    [t, backend]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    refreshTable(searchParams.pageNumber, searchParams.pageSize);
  }, [searchParams]);

  const handleChangePage = (event, newPage) => {
    setSearchParams({ ...searchParams, pageNumber: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    var newRowsPerPage = parseInt(event.target.value, 10);
    setSearchParams({
      ...searchParams,
      pageNumber: 0,
      pageSize: newRowsPerPage,
    });
  };

  const handleAddCloneTip = () => {
    setIsLoading(true);
    backend.bckCloneTipsCreateOrUpdate({ id: null }).then((json) => {
      setIsLoading(false);
      if (json.content) {
        setTableData((prev) => {
          let next = [{ id: json.content, text: {} }, ...prev];
          return next;
        });
      }
    });
  };

  const handleSave = (cloneTip, editedCloneTip, callback) => {
    backend.bckCloneTipsCreateOrUpdate(editedCloneTip).then((json) => {
      if (json.content) {
        let next = [...tableData];
        let targetIndex = next.indexOf(cloneTip);
        next[targetIndex] = editedCloneTip;
        setTableData(next);
        callback();
      }
    });
  };

  const handleRemove = (cloneTip) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove clone tip?", { id: cloneTip.id }),
      handleConfirm: () => {
        setIsLoading(true);
        backend.bckCloneTipsRemove(cloneTip.id).then((json) => {
          setIsLoading(false);
          if (json.code === 0) {
            setTableData((prev) => {
              let foundIndex = prev.findIndex((x) => x.id === cloneTip.id);
              if (foundIndex !== -1) {
                prev.splice(foundIndex, 1);
              }
              return [...prev];
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  return (
    <LoadingOverlay active={isLoading} spinner>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("Clone tips table")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          <Grid item container spacing={2}>
            <Grid item>
              <Button
                onClick={handleAddCloneTip}
                variant="contained"
                color="primary"
              >
                {t("Add clone tip")}
              </Button>
            </Grid>
          </Grid>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            <AdvancedTablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              totalRows={totalRows}
              pageSize={searchParams.pageSize}
              pageNumber={searchParams.pageNumber}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              <CloneTipsTable
                data={tableData}
                handleSave={handleSave}
                handleRemove={handleRemove}
              />
            </AdvancedTablePagination>
          </div>
        </Grid>
      </Paper>
    </LoadingOverlay>
  );
}
