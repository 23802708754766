import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Paper,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import SettingsEditor from "../SettingsEditor/SettingsEditor";
import { hexToBytes } from "../../helpers/StringUtils";
import FileSaver from "file-saver";
import { EncodeUTF16LE } from "../../helpers/StringUtils";
import { getSettingsInfo } from "../SettingsEditor/SettingsInfoCache";
import { useBackend } from "../../context/BackendContext";

export default function SettingsEditorDialog({
  alarmName,
  data,
  isOpened,
  handleClose,
}) {
  const backend = useBackend();
  const [downloadMode, setDownloadMode] = useState(false);
  const [chosenAlarm, setChosenAlarm] = useState(null);
  const [dxsFile, setDxsFile] = useState(null);
  const [dxsData, setDxsData] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setDxsFile(data);
    if (
      data !== null &&
      data !== undefined &&
      data.settings !== null &&
      data.settings !== undefined &&
      data.settings.trim() !== ""
    ) {
      try {
        setDxsData(hexToBytes(data.settings));
      } catch {
        alert("Hex to byte parsing error");
        setDxsData(null);
      }
    }
  }, [data]);

  useEffect(() => {
    if (alarmName) {
      setDownloadMode(true);
      getSettingsInfo(backend, false).then((info) => {
        let alarm = Object.values(info.Alarms).find(
          (a) => a.Name === alarmName || a.Title === alarmName
        );
        if (!alarm) {
          let regexMatches = alarmName.match(
            /(\w+[-_]?[0-9a-zA-Z]+\w?)(\sb64)?(\s\w)?\s*r*(\d*)/i
          );
          if (regexMatches.length > 2) {
            let shortName = regexMatches[1];
            alarm = Object.values(info.Alarms).find(
              (a) => a.Name === shortName || a.Title === shortName
            );
          }
        }
        if (alarm) {
          let alarmMenu = Object.values(info.AlarmMenus).find(
            (am) => am.AlarmId === alarm.Id
          );
          if (alarmMenu) {
            setChosenAlarm(alarm.Id);
            setDownloadMode(false);
          }
        }
      });
    }
  }, [alarmName, i18n.language]);

  const handleDownloadData = (name, info) => {
    const infoData = new Blob([EncodeUTF16LE(JSON.stringify(info))], {
      type: "text/plain;charset=UTF-16LE;",
    });
    FileSaver.saveAs(infoData, name);
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={!downloadMode}
    >
      <DialogTitle id="alert-dialog-title">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {t("Settings editor")}
          <Button
            variant="contained"
            onClick={() => handleClose()}
            color="primary"
            autoFocus
          >
            {t("Close")}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        {chosenAlarm && dxsFile && dxsData ? (
          <Paper
            style={{
              overflow: "auto",
              width: "100%",
              height: "85vh",
            }}
          >
            <SettingsEditor
              chosenAlarm={chosenAlarm}
              settingsData={dxsFile}
              data={dxsData}
            />
          </Paper>
        ) : (
          <></>
        )}
        {downloadMode ? (
          <div style={{ width: "0.8", height: "0.8" }}>
            <Typography>{t("Settings editor unavailable")}</Typography>
            <Button
              onClick={() => handleDownloadData(`config.dxs`, dxsFile)}
              size="small"
              variant="contained"
              color="primary"
              disabled={dxsFile === null || dxsFile === undefined}
            >
              {t("Download")}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
