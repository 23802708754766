import React, { useState, useEffect } from "react";
import {
    Slider,
    Typography
} from "@mui/material";

export default function SettingSlider({ defaultValue, valueLabelFormat, ...props }) {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue])

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    return <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
        <Typography style={{ textAlign: "right", fontWeight: "bold", minWidth: "4ch", marginLeft: "16px", marginTop: "4px", marginBottom: "4px" }}>
            {valueLabelFormat(value)}
        </Typography>
        <Slider
            valueLabelFormat={valueLabelFormat}
            value={value}
            onChange={handleChange}
            {...props}
        />
    </div>
}
