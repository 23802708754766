import React from "react";
import {
    Paper,
    Typography,
} from "@mui/material";
import classnames from "classnames";

import styles from "./styles.module.css";

export default function Widget({
    children,
    title,
    noBodyPadding,
    bodyClass,
    header,
    noHeader,
    ...props
}) {
    return (
        <div className={styles.widgetWrapper}>
            <Paper className={styles.paper} styles={{ root: styles.widgetRoot }}>
                {noHeader ? <></> :
                    <div className={styles.widgetHeader}>
                        {header ? (
                            header
                        ) : (
                            <React.Fragment>
                                <Typography variant="h5" color="textSecondary">
                                    {title}
                                </Typography>
                            </React.Fragment>
                        )}
                    </div>
                }
                <div
                    className={classnames(styles.widgetBody, {
                        [styles.noPadding]: noBodyPadding,
                        [bodyClass]: bodyClass,
                    })}
                >
                    {children}
                </div>
            </Paper>
        </div>
    );
}
