import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";

export default function SelectAddressObjectDialog({
  isOpened,
  countriesList,
  handleConfirm,
  handleClose,
}) {
  const { t } = useTranslation();
  const backend = useBackend();
  const [statesList, setStatesList] = useState([]);
  const [localitiesList, setLocalitiesList] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [locality, setLocality] = useState("");

  useEffect(() => {
    if (isOpened) {
      setCountry("");
      setState("");
      setLocality("");
    }
  }, [isOpened]);

  const handleConfirmInner = (e) => {
    if (locality) handleConfirm(locality, 3);
    else if (state) handleConfirm(state, 2);
    else handleConfirm(country, 1);
  };

  const onCountryChanged = (event, value) => {
    setCountry(value);
    backend.bckAddressGetStates(value).then((json) => {
      if (json.code === 0) {
        setStatesList(json.content);
      }
    });
  };

  const onStateChanged = (event, value) => {
    setState(value);
    backend.bckAddressGetLocalities(value).then((json) => {
      if (json.code === 0) {
        setLocalitiesList(json.content);
      }
    });
  };

  const onLocalityChanged = (event, value) => {
    setLocality(value);
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{t("Address")}</DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: "8px" }}>
          <Autocomplete
            options={countriesList}
            onChange={onCountryChanged}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Country")}
                placeholder={t("Country")}
              />
            )}
            noOptionsText={t("No options")}
          />
        </div>
        <div style={{ marginBottom: "8px" }}>
          <Autocomplete
            disabled={!country}
            options={statesList}
            onChange={onStateChanged}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("State")}
                placeholder={t("State")}
              />
            )}
            noOptionsText={t("No options")}
          />
        </div>
        <div>
          <Autocomplete
            disabled={!state}
            options={localitiesList}
            onChange={onLocalityChanged}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Locality")}
                placeholder={t("Locality")}
              />
            )}
            noOptionsText={t("No options")}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Cancel")}
        </Button>
        <Button
          disabled={!(country || state || locality)}
          onClick={handleConfirmInner}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
