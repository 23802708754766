const ArticleElementFilling = {
    1: "WRAP",
    2: "FILL"
};
Object.freeze(ArticleElementFilling);

const ArticleElementFillingByName = {
    "WRAP": 1,
    "FILL": 2
};
Object.freeze(ArticleElementFillingByName);

export {
    ArticleElementFilling,
    ArticleElementFillingByName
}