import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from "@mui/material";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useTranslation } from "react-i18next";

export default function CKEditorDialog({ title, initialValue, isOpened, handleConfirm, handleClose }) {
    const { t } = useTranslation();
    const [textValue, setTextValue] = useState("");

    useEffect(() => {
        if (isOpened) {
            setTextValue(initialValue ? initialValue : "");
        }
    }, [isOpened, initialValue]);

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <CKEditor
                    editor={Editor}
                    data={textValue}
                    onChange={(event, editor) => {
                        setTextValue(editor.getData());
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button onClick={() => { handleClose(); handleConfirm(textValue); }} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
