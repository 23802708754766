import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    Delete as DeleteIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next"

export default function DevicesAliasesTable({ data, alarms, handleEditAlarmBase, handleEditAlarmAlias, handleRemoveAlias }) {
    const { t } = useTranslation();

    var keys = ["#", t("Alarm"), t("Alias"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(alias => (
                    <TableRow key={alias.id}>
                        <TableCell className={styles.cell}>{alias.id}</TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditAlarmBase(alias)}
                        >
                            {
                                `${alarms.filter(a => a.id === alias.alarmIdBase)[0]?.name ?? ""}${alias.alarmIdBase ? "(" + alias.alarmIdBase + ")" : ""}`
                            }
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditAlarmAlias(alias)}
                        >
                            {
                                `${alarms.filter(a => a.id === alias.alarmIdAlias)[0]?.name ?? ""}${alias.alarmIdAlias ? "(" + alias.alarmIdAlias + ")" : ""}`
                            }
                        </TableCell>
                        <TableCell className={styles.cell} style={{ display: "flex", flexDirection: "row" }}>
                            <IconButton
                                style={{ color: 'red' }}
                                onClick={() => handleRemoveAlias(alias)} >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
