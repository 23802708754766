import React, { Children, useEffect } from "react";
import classnames from "classnames";
import Header from "../Header/Header";
import Sidebar from "../Sidebar";
import styles from "./styles.module.css"
import { useLayoutState } from "../../context/LayoutContext";
import { useLocation } from "react-router";

function Layout({ headerText, routes, headerSetter, children }) {
    const layoutState = useLayoutState();
    const location = useLocation();

    useEffect(() => {
        const routeEntry = routes.find((r) => r.path === location.pathname);
        if (routeEntry !== undefined && routeEntry !== null && routeEntry.header !== null) {
            headerSetter(routeEntry.header);
        }
    }, [location, routes, headerSetter]);

    return (
        <div className={styles.root}>
            <>
                <Header headerText={headerText} />
                <Sidebar />
                <div className={classnames(styles.content, {
                    [styles.contentShift]: layoutState.isSidebarOpened,
                })}
                >
                    <div className={styles.fakeToolbar} />
                    {Children.map(children, (child, i) =>
                        <div key={i}>
                            {child}
                        </div>
                    )}
                </div>
            </>
        </div>
    );
}

export default Layout;
