import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select
} from "@mui/material";

import { useTranslation } from "react-i18next";

export default function SimpleSelectDialog({ title, initialValue, label, menuItems, isOpened, handleConfirm, handleClose }) {
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState("");

    useEffect(() => {
        setSelectedValue(initialValue);
    }, [isOpened, initialValue]);

    const onSelectChange = (e) => {
        setSelectedValue(e.target.value);
    };

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth style={{ marginTop: "6px", minWidth: "48ch" }}>
                    <InputLabel>{label}</InputLabel>
                    <Select
                        label={label}
                        value={selectedValue}
                        onChange={onSelectChange}
                    >
                        {menuItems}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={selectedValue === undefined || selectedValue === null || !selectedValue || selectedValue === 0} onClick={() => { handleClose(); handleConfirm(selectedValue); }} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
