import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";
import { useInterval } from "../useInterval";
import { TechsupportRequestStatuses } from "../enums/TechsupportRequestStatuses";

import TechsupportRequestsTable from "../components/Tables/TechsupportRequestsTable";
import AdvancedTablePagination from "../components/AdvancedTablePagination";

import { useTranslation } from "react-i18next";
import { TechsupportRequestTags } from "../enums/TechsupportRequestTags";
import { generateUserInfoString } from "../helpers/UserInfoStringGenerator";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoad,
  settingRepositoryLoadBool,
  settingRepositoryLoadInt,
  settingRepositorySave,
} from "../settingRepository";
import { useNavigate } from "react-router";

export default function TechsupportRequests() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchUserInput, setSearchUserInput] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchStatusInput, setSearchStatusInput] = useState(-1);
  const [searchStatus, setSearchStatus] = useState("");
  const [totalRows, setTotalRows] = useState(100);
  const [autoUpdateChecked, setAutoUpdateChecked] = useState(true);
  const [tagsInput, setTagsInput] = useState([]);
  const [tags, setTags] = useState([]);
  const [tableUserName, setTableUserName] = useState("");
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const requestStatuses = [
    { id: 1, name: TechsupportRequestStatuses[1] },
    { id: 2, name: TechsupportRequestStatuses[2] },
    { id: 3, name: TechsupportRequestStatuses[3] },
    { id: 4, name: TechsupportRequestStatuses[4] },
    { id: 5, name: TechsupportRequestStatuses[5] },
    { id: 6, name: TechsupportRequestStatuses[6] },
  ];

  const handleShowUserInfo = (request) => {
    backend.bckUsersGetInfo(request.senderId).then((json) => {
      if (json.code === 0) {
        let info = json.content;
        dialogDispatch({
          type: DialogActionTypes.SimpleOutputDialogOpen,
          text: generateUserInfoString(t, info),
          textLabel: "",
          title: t("Description"),
          noTextField: true,
        });
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleUsergroupClick = (request) => {
    backend.bckUsersGetUsergroupOwners(request.senderId).then((json) => {
      if (json.code === 0) {
        dialogDispatch({
          type: DialogActionTypes.UsergroupListDialogOpen,
          list: json.content,
        });
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const openOutputDialog = (request) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleOutputDialogOpen,
      text: request.description,
      textLabel: "",
      title: t("Description"),
      noTextField: true,
    });
  };

  const handleShowRequestAddress = (request) => {
    let action = {
      type: DialogActionTypes.AddressDialogOpen,
      mapDisabled: false,
      handleOpenMap: () =>
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${request.coordinates.replace(
            ";",
            ","
          )}`
        ),
      text: t("Loading"),
    };
    if (request.addressId === null || request.addressId === undefined) {
      action.text = t("Address not detected");
      dialogDispatch(action);
    } else {
      backend.bckAddressGetLocationString(request.addressId).then((json) => {
        if (json.content !== null) {
          action.text = json.content;
        } else {
          action.text = t("Address not detected");
        }
        dialogDispatch(action);
      });
    }
  };

  const refreshTable = useCallback(
    (backend, userQuery, status, tags, pageNumber, pageSize) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckTechsupportRequestsList(
          userQuery,
          status,
          tags,
          pageNumber + 1,
          pageSize
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.rows;
                } else {
                  return prev;
                }
              });
              setTotalRows((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.total;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }

          if (isLoading) setIsLoading(false);
        });
    },
    [t, isLoading]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useInterval(() => {
    if (updatesLockCounter.current === 0 && autoUpdateChecked) {
      refreshTable(
        backend,
        searchUser,
        searchStatus,
        tags,
        pageNumber,
        pageSize
      );
    }
  }, 2000);

  useEffect(() => {
    if (isInitializing) {
      setAutoUpdateChecked(
        settingRepositoryLoadBool(
          SettingName.AUTO_UPDATE_TECHSUPPORT_REQUESTS,
          true
        )
      );

      let pageSize = settingRepositoryLoadInt(
        SettingName.TECHSUPPORT_REQUESTS_PAGE_SIZE,
        10
      );
      setPageSize(pageSize);

      setTableUserName(settingRepositoryLoad(SettingName.USER_NAME, ""));
      resetSearchParams();
      refreshTable(backend, "", "", tags, 0, pageSize);
      setIsInitializing(false);
    }
  }, [backend, isInitializing, refreshTable, tags]);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    refreshTable(backend, searchUser, searchStatus, tags, newPage, pageSize);
  };

  const handleChangeRowsPerPage = (event) => {
    var newPageSize = parseInt(event.target.value, 10);
    settingRepositorySave(
      SettingName.TECHSUPPORT_REQUESTS_PAGE_SIZE,
      newPageSize
    );
    setPageSize(newPageSize);
    setPageNumber(0);
    refreshTable(backend, searchUser, searchStatus, tags, 0, newPageSize);
  };

  const resetSearchParams = () => {
    setSearchUserInput("");
    setSearchUser("");
    setSearchStatusInput(-1);
    setSearchStatus("");
    setTagsInput([]);
    setTags([]);
    setPageNumber(0);
  };

  const handleSearchPress = () => {
    var newSearchUser = "";
    if (searchUserInput.trim() === "") {
      setSearchUser("");
    } else {
      newSearchUser = searchUserInput;
      setSearchUser(searchUserInput);
    }

    var newSearchStatus = "";
    if (searchStatusInput === -1) {
      setSearchStatus("");
    } else {
      newSearchStatus = searchStatusInput;
      setSearchStatus(searchStatusInput);
    }

    setTags(tagsInput);

    setPageNumber(0);
    refreshTable(
      backend,
      newSearchUser,
      newSearchStatus,
      tagsInput,
      0,
      pageSize
    );
  };

  const handleSearchResetPress = () => {
    resetSearchParams();
    refreshTable(backend, "", "", [], 0, pageSize);
  };

  const gotoFullTechsupportPage = (request, viewOnly) => {
    navigate(
      `/app/techsupportRequests/fullRequest?requestId=${request.id}&viewOnly=${viewOnly}`
    );
  };

  const assignRequest = (techRequest) => {
    ++updatesLockCounter.current;
    var id = GenerateInteger();
    lastRequestId.current = id;
    techRequest.status = 2;
    backend.bckTechsupportRequestsAssign(techRequest.id).then((json) => {
      --updatesLockCounter.current;
      refreshTable(
        backend,
        searchUser,
        searchStatus,
        tags,
        pageNumber,
        pageSize
      );
      if (json.code === 0) {
        gotoFullTechsupportPage(techRequest, false);
      } else if (json.code === ResponseCode.ACCESS_DENIED) {
        setError(t("AUTH_ERROR"));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleAssignRequest = (techRequest) => {
    var requestConfirm =
      !tableUserName ||
      (techRequest.handlerName && techRequest.handlerName !== tableUserName);
    if (requestConfirm) {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Techsupport request switch confirmation"),
        handleConfirm: () => assignRequest(techRequest),
      });
    } else {
      assignRequest(techRequest);
    }
  };

  const onTagsChanged = (...params) => {
    setTagsInput(params[1]);
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Typography color="secondary">{error}</Typography>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Typography variant="h5" color="textSecondary">
          {t("Techsupport requests table")}
        </Typography>
        <Grid
          container
          direction="column"
          style={{ paddingTop: "12px" }}
          spacing={2}
        >
          <Grid container item alignContent="center">
            <Grid item>
              <TextField
                label={t("User")}
                value={searchUserInput}
                onChange={(e) => setSearchUserInput(e.target.value)}
                style={{ marginRight: "12px" }}
              />
            </Grid>
            <Grid item>
              <FormControl style={{ marginRight: "12px", minWidth: "25ch" }}>
                <InputLabel>{t("Status")}</InputLabel>
                <Select
                  value={searchStatusInput}
                  label={t("Status")}
                  onChange={(e) => setSearchStatusInput(e.target.value)}
                >
                  <MenuItem key={-1} value={-1}>
                    <em>{t("Any (mas)")}</em>
                  </MenuItem>
                  {requestStatuses.map((rs) => (
                    <MenuItem key={rs.id} value={rs.id}>
                      {t(rs.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Autocomplete
                style={{ marginRight: "12px", width: "48ch" }}
                multiple
                size="small"
                value={tagsInput}
                options={Object.keys(TechsupportRequestTags).map((k) =>
                  parseInt(k)
                )}
                onChange={onTagsChanged}
                getOptionLabel={(option) => t(TechsupportRequestTags[option])}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Tags")}
                    placeholder={t("Tags")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item>
              <Button
                onClick={handleSearchPress}
                variant="contained"
                color="primary"
              >
                {t("Search")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSearchResetPress}
                disabled={
                  searchStatusInput === -1 &&
                  searchUserInput.trim() === "" &&
                  (!tagsInput || tagsInput.length === 0)
                }
                variant="contained"
                color="primary"
              >
                {t("Reset parameters")}
              </Button>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={autoUpdateChecked}
                    onChange={() => {
                      setAutoUpdateChecked(!autoUpdateChecked);
                      settingRepositorySave(
                        SettingName.AUTO_UPDATE_TECHSUPPORT_REQUESTS,
                        !autoUpdateChecked
                      );
                    }}
                    color="primary"
                  />
                }
                label={t("Auto update")}
              />
            </Grid>
          </Grid>
        </Grid>
        <AdvancedTablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          totalRows={totalRows}
          pageSize={pageSize}
          pageNumber={pageNumber}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <TechsupportRequestsTable
            data={tableData}
            userName={tableUserName}
            handleShowRequestAddress={handleShowRequestAddress}
            handleShowDescription={openOutputDialog}
            handleAssignRequest={handleAssignRequest}
            handleViewRequest={(request) =>
              gotoFullTechsupportPage(request, true)
            }
            handleContinueRequest={(request) =>
              gotoFullTechsupportPage(request, false)
            }
            handleShowUserInfo={handleShowUserInfo}
            handleUsergroupClick={handleUsergroupClick}
          />
        </AdvancedTablePagination>
      </Paper>
    </>
  );
}
