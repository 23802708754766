import React from "react";

import {
    Paper,
    Typography
} from "@mui/material";

import { useTranslation } from "react-i18next";
import CarMountingPointGuideImage from "./CarMountingPointGuideImage";

export default function CarMountingPointGuide({ guide }) {
    const { t } = useTranslation();

    return <Paper style={{ padding: "16px" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography variant="h5">{guide.type.name}</Typography>
            </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography variant="h6">{guide.subtitle}</Typography>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography><b>{t("Description") + ": "}</b></Typography>
        </div>
        <Typography component="pre">{guide.description}</Typography>
        <div style={{ display: "flex", flexDirection: "row", maxWidth: "100%", overflowX: "auto", paddingBottom: "16px" }}>
            {
                guide.images.map((image, i) =>
                    <div key={image.id} style={{ marginRight: "16px" }}>
                        <CarMountingPointGuideImage key={image.id} image={image} />
                    </div>
                )
            }
        </div>
    </Paper>;
}
