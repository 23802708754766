const SubsystemTypes = {
    1: "BLUETOOTH",
    2: "ALARM_BASE",
    3: "CHARGER_BASE",
    4: "WATCH_BASE",
    5: "WATCH_INTERFACE",
    6: "TRINKET",
    7: "NAVIGATION_MODULE",
    8: "BLUETOOTH_WATCHES",
    9: "FUEL_SENSOR",
    10: "BMW_BYPASS_MAIN"
};
Object.freeze(SubsystemTypes);

export { SubsystemTypes }