const ArticleElementTypes = {
    1: "TEXT",
    2: "LINK",
    3: "IMAGE",
    4: "BUTTON",
    5: "VIDEO"
};
Object.freeze(ArticleElementTypes);

const ArticleElementTypesByName = {
    "TEXT": 1,
    "LINK": 2,
    "IMAGE": 3,
    "BUTTON": 4,
    "VIDEO": 5
};
Object.freeze(ArticleElementTypesByName);

export {
    ArticleElementTypes,
    ArticleElementTypesByName
}