import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

// components
import { GenerateInteger } from "../helpers/RandomGenerator";
import { useInterval } from "../useInterval";

import { useTranslation } from "react-i18next";
import FeedbacksTable from "../components/Tables/FeedbacksTable";
import { ClientTypes } from "../enums/ClientTypes";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoadBool,
  settingRepositoryLoadInt,
  settingRepositorySave,
} from "../settingRepository";

export default function FeedbacksPage() {
  const backend = useBackend();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchUserInput, setSearchUserInput] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchDeviceInput, setSearchDeviceInput] = useState(-1);
  const [searchDevice, setSearchDevice] = useState("");
  const [searchLangInput, setSearchLangInput] = useState(-1);
  const [searchLang, setSearchLang] = useState("");
  const [totalRows, setTotalRows] = useState(100);
  const [autoUpdateChecked, setAutoUpdateChecked] = useState(true);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshTable = useCallback(
    (
      backend,
      searchUser,
      searchDevice,
      searchLang,
      pageNumber,
      pageSize,
      isInitializing
    ) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckFeedbackList(
          true,
          searchUser,
          searchDevice,
          searchLang,
          pageNumber + 1,
          pageSize
        )
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.list;
                } else {
                  return prev;
                }
              });
              setTotalRows((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.count;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }

          if (isInitializing) {
            setIsInitializing(false);
            setIsLoading(false);
          }
        });
    },
    [t]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useInterval(() => {
    if (updatesLockCounter.current === 0 && autoUpdateChecked) {
      refreshTable(
        backend,
        searchUser,
        searchDevice,
        searchLang,
        pageNumber,
        pageSize,
        isInitializing
      );
    }
  }, 2000);

  useEffect(() => {
    if (!isInitializing) {
      refreshTable(
        backend,
        searchUser,
        searchDevice,
        searchLang,
        pageNumber,
        pageSize,
        isInitializing
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isInitializing) {
      setAutoUpdateChecked(
        settingRepositoryLoadBool(SettingName.AUTO_UPDATE_FEEDBACKS, true)
      );

      let pageSize = settingRepositoryLoadInt(
        SettingName.FEEDBACKS_PAGE_SIZE,
        10
      );
      setPageSize(pageSize);

      resetSearchParams();
      refreshTable(backend, "", "", "", 0, pageSize, true);
      setIsInitializing(false);
    }
  }, [backend, isInitializing, refreshTable]);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    refreshTable(
      backend,
      searchUser,
      searchDevice,
      searchLang,
      newPage,
      pageSize,
      isInitializing
    );
  };

  const handleChangeRowsPerPage = (event) => {
    var newPageSize = parseInt(event.target.value, 10);
    settingRepositorySave(SettingName.FEEDBACKS_PAGE_SIZE, newPageSize);
    setPageSize(newPageSize);
    setPageNumber(0);
    refreshTable(
      backend,
      searchUser,
      searchDevice,
      searchLang,
      pageNumber,
      newPageSize,
      isInitializing
    );
  };

  const resetSearchParams = () => {
    setSearchUserInput("");
    setSearchUser("");
    setSearchDeviceInput(-1);
    setSearchDevice("");
    setSearchLangInput(-1);
    setSearchLang("");
    setPageNumber(0);
  };

  const handleSearchPress = () => {
    var newSearchUser = "";
    if (searchUserInput.trim() === "") {
      setSearchUser("");
    } else {
      newSearchUser = searchUserInput;
      setSearchUser(searchUserInput);
    }

    var newSearchDevice = "";
    if (searchDeviceInput === -1) {
      setSearchDevice("");
    } else {
      newSearchDevice = searchDeviceInput;
      setSearchDevice(searchDeviceInput);
    }

    var newSearchLang = "";
    if (searchLangInput === -1) {
      setSearchLang("");
    } else {
      newSearchLang = searchLangInput;
      setSearchLang(searchLangInput);
    }

    setPageNumber(0);
    refreshTable(
      backend,
      newSearchUser,
      newSearchDevice,
      newSearchLang,
      0,
      pageSize,
      isInitializing
    );
  };

  const handleSearchResetPress = () => {
    resetSearchParams();
    refreshTable(backend, "", "", "", 0, pageSize, isInitializing);
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Typography color="secondary">{error}</Typography>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <div>
          <Typography variant="h5" color="textSecondary">
            {t("Feedbacks table")}
          </Typography>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ paddingTop: "12px" }}
          >
            <Grid item>
              <TextField
                label={t("User")}
                value={searchUserInput}
                onChange={(e) => setSearchUserInput(e.target.value)}
                style={{ marginRight: "12px" }}
              />
              <FormControl style={{ marginRight: "12px", minWidth: "25ch" }}>
                <InputLabel>{t("Client type")}</InputLabel>
                <Select
                  label={t("Client type")}
                  value={searchDeviceInput}
                  onChange={(e) => setSearchDeviceInput(e.target.value)}
                >
                  <MenuItem key={-1} value={-1}>
                    <em>{t("Any (mas)")}</em>
                  </MenuItem>
                  {Object.keys(ClientTypes).map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {ClientTypes[parseInt(key)]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl style={{ marginRight: "12px", minWidth: "12ch" }}>
                <InputLabel>{t("Language")}</InputLabel>
                <Select
                  label={t("Language")}
                  value={searchLangInput}
                  onChange={(e) => setSearchLangInput(e.target.value)}
                >
                  <MenuItem key={-1} value={-1}>
                    <em>{t("Any (mas)")}</em>
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    RU
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    EN
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item>
                <Button
                  onClick={handleSearchPress}
                  variant="contained"
                  color="primary"
                >
                  {t("Search")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleSearchResetPress}
                  disabled={
                    searchUserInput.trim() === "" &&
                    searchDeviceInput === -1 &&
                    searchLangInput === -1
                  }
                  variant="contained"
                  color="primary"
                >
                  {t("Reset parameters")}
                </Button>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={autoUpdateChecked}
                      onChange={() => {
                        setAutoUpdateChecked(!autoUpdateChecked);
                        settingRepositorySave(
                          SettingName.AUTO_UPDATE_FEEDBACKS,
                          !autoUpdateChecked
                        );
                      }}
                      color="primary"
                    />
                  }
                  label={t("Auto update")}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <AdvancedTablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          totalRows={totalRows}
          pageSize={pageSize}
          pageNumber={pageNumber}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        >
          <FeedbacksTable data={tableData} />
        </AdvancedTablePagination>
      </Paper>
    </>
  );
}
