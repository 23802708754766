const getFlatStructures = (srcList) => {
    if (!srcList || srcList.length === 0) return [];
    console.log(srcList);

    let flatStructures = srcList.reduce((prev, cur, i) => {
        let flatChildren = cur.Children?.reduce((p, c, i, a) => ({ ...p, [c.Id]: c }), {});
        if (flatChildren) {
            let deepChildren = getFlatStructures(cur.Children);
            prev = { ...prev, [cur.Id]: cur, ...deepChildren };
        } else {
            prev = { ...prev, [cur.Id]: cur };
        }
        return prev;
    }, {});
    return flatStructures;
};

const generateTranslationString = (textField, idField, language, translations, addId) => {
    let text = textField && textField[language] ? textField[language] : translations[language][idField]?.Text;
    let id = "";
    if (addId) {
        id = idField ? `(${idField})` : "";
    }
    return `${text ?? ""}${id}`        
};

const generateTranslationStringTag = (textField, idField, language, translations) => {
    return <>
        {generateTranslationString(textField, idField, language, translations, true)}
    </>
};

export {
    getFlatStructures,
    generateTranslationString,
    generateTranslationStringTag
}
