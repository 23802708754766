import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Tooltip
} from "@mui/material";

import {
    Image as ImageIcon,
    ArrowForward as ShowModelsIcon,
    Delete as DeleteIcon,
    Publish as UploadIcon,
    ReportProblem as WarningIcon
} from "@mui/icons-material";

import ReactFileReader from "react-file-reader";
import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next"

export default function CarBrandsTable({ data, handleShowCarBrandImage, handleShowBrandModels, handleRemoveBrand, handleUploadImage, handleTitleClick }) {
    const { t } = useTranslation();

    var keys = ["#", t("Car brand"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(brand => (
                    <TableRow key={brand.id}>
                        <TableCell className={styles.cell}>{brand.id}</TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleTitleClick(brand)}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {
                                    brand.anyModels ? <></> : <Tooltip title={t("There is no models for this brand")}>
                                        <WarningIcon style={{ color: '#EBB734' }} />
                                    </Tooltip>
                                }
                                <>{brand.name}</>
                            </div>
                        </TableCell>
                        <TableCell className={styles.cell} style={{ display: "flex", flexDirection: "row" }}>
                            <IconButton
                                style={{ color: 'red' }}
                                onClick={() => handleRemoveBrand(brand)} >
                                <DeleteIcon />
                            </IconButton>
                            <ReactFileReader handleFiles={(files) => handleUploadImage(brand, files[0])} fileTypes={["*.*"]}>
                                <IconButton color="primary">
                                    <UploadIcon />
                                </IconButton>
                            </ReactFileReader>
                            <IconButton
                                disabled={!brand.imageUrl}
                                color="primary"
                                onClick={() => handleShowCarBrandImage(brand) } >
                                <ImageIcon />
                            </IconButton>
                            <IconButton
                                color="primary"
                                onClick={() => handleShowBrandModels(brand)} >
                                <ShowModelsIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
