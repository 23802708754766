import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography
} from "@mui/material";

import PackageSearcher from "../PackageSearcher"

export default function FactoryPackageDialog({ deviceId, isOpened, handleClose }) {
    const [error, setError] = useState("");

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogContent>
                <Typography color="secondary">{error}</Typography>
                <PackageSearcher deviceId={deviceId} setError={setError} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
