import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Button,
    Badge,
    Tooltip
} from "@mui/material";

import {
    Delete as DeleteIcon,
    Person as PersonIcon,
    Visibility as VisibilityIcon,
    ReportProblem as WarningIcon
} from "@mui/icons-material";

import { UserRolesIntToString, UserRolesStringToInt } from "../../enums/UserRoles";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../helpers/StringUtils";

export default function UsersTable({ data, onPressDelete, onPressChangeRole, onPressChangeVisibility }) {
    const { t } = useTranslation();
    const keys = [t("Full name"), t("Email"), t("Login"), t("Role"), "", t("Registration date"), t("Last online") ];

    const getVisibilityButtonStyle = (role) => {
        if (role === UserRolesStringToInt.ADMINISTRATOR || role === UserRolesStringToInt.ADMIN_PANEL_MANAGER || role === UserRolesStringToInt.TECHSUPPORT_MANAGER
            || role === UserRolesStringToInt.NOT_CONFIRMED || role === UserRolesStringToInt.API_USER) {
            return { color: 'gray' };
        } else {
            return { color: 'black' };
        }
    };

    const nonDeletableRoles = [
        UserRolesStringToInt.ADMINISTRATOR,
        UserRolesStringToInt.ADMIN_PANEL_MANAGER,
        UserRolesStringToInt.TECHSUPPORT_MANAGER,
        UserRolesStringToInt.DELETED
    ];

    const nonDemotableRoles = [
        UserRolesStringToInt.ADMINISTRATOR,
        UserRolesStringToInt.ADMIN_PANEL_MANAGER,
        UserRolesStringToInt.TECHSUPPORT_MANAGER
    ];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(({ fullName, email, login, role, registrationDate, lastLoginDate, anyPendingUsergroupMembers, useGroupVisibility, deletedDate }) => (
                    <TableRow key={login}>
                        <TableCell>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    role === UserRolesStringToInt.DELETED ? <Tooltip title={t("User was deleted", { date: deletedDate })}>
                                        <WarningIcon style={{ color: '#EBB734' }} />
                                    </Tooltip> : <></>
                                }
                                {fullName}
                            </div>
                        </TableCell>
                        <TableCell>{email}</TableCell>
                        <TableCell>{login}</TableCell>
                        <TableCell>
                            <Button
                                style={{ backgroundColor: (role === UserRolesStringToInt.NOT_CONFIRMED ? "#ffc83d" : (role === UserRolesStringToInt.DELETED ? "red" : "#3CD4A0")), color: "#FFFFFF" }}
                                size="small"
                                className="px-2"
                                variant="contained"
                            >
                                {t(UserRolesIntToString[role])}
                            </Button>
                        </TableCell>
                        <TableCell>
                            <IconButton
                                disabled={nonDeletableRoles.some(r => r === role)}
                                style={nonDeletableRoles.some(r => r === role) ? { color: 'gray' } : { color: 'red' }}
                                onClick={() => onPressDelete(login)} >
                                <DeleteIcon />
                            </IconButton>
                            <IconButton
                                disabled={nonDemotableRoles.some(r => r === role)}
                                style={nonDemotableRoles.some(r => r === role) ? { color: 'gray' } : { color: 'black' }}
                                onClick={(event) => onPressChangeRole(event, login)} >
                                <PersonIcon />
                            </IconButton>
                            <IconButton
                                disabled={role === UserRolesStringToInt.ADMINISTRATOR || role === UserRolesStringToInt.ADMIN_PANEL_MANAGER || role === UserRolesStringToInt.TECHSUPPORT_MANAGER || role === UserRolesStringToInt.NOT_CONFIRMED || role === UserRolesStringToInt.API_USER}
                                style={getVisibilityButtonStyle(role)}
                                onClick={(event) => onPressChangeVisibility(event, login)} >
                                {
                                    useGroupVisibility && anyPendingUsergroupMembers ? <Badge badgeContent={"!"} color="primary" anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}>
                                        <VisibilityIcon />
                                    </Badge> : <VisibilityIcon />
                                }
                            </IconButton>
                        </TableCell>
                        <TableCell>{formatDate(registrationDate)}</TableCell>
                        <TableCell>{formatDate(lastLoginDate)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
