import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';

export default function ChipsArray({ style, placeholder, value, onChange }) {
    return <Autocomplete
        multiple
        value={value ?? []}
        options={value ?? []}
        freeSolo
        inputValue={""}
        style={style}
        onChange={onChange}
        renderInput={(params) => (
            <TextField {...params} label={placeholder} placeholder={placeholder} />
        )}
    />
}
