import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Tooltip
} from "@mui/material";

import {
    Delete as DeleteIcon,
    ArrowForward as ShowFullInfoIcon,
    ReportProblem as WarningIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next";
import { SubsystemTypes } from "../../enums/SubsystemTypes";
import { formatDate } from "../../helpers/StringUtils";

export default function SubsystemsTable({ data, handleRemoveSubsystem, handleShowFullInfo, handleCreationTimeClick }) {
    const { t } = useTranslation();

    var keys = ["#", t("Name"), t("Title"), t("Type"), t("Creation time"), t("Last firmware date"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(subsystem => (
                    <TableRow key={subsystem.id}>
                        <TableCell className={styles.cell}>{subsystem.id}</TableCell>
                        <TableCell className={styles.cell}>{subsystem.name}</TableCell>
                        <TableCell className={styles.cell}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {
                                    subsystem.anyPublishedFirmwares ? <></> : <Tooltip title={t("Subsystem has no published firmwares")}>
                                        <WarningIcon style={{ color: '#EBB734' }} />
                                    </Tooltip>
                                }
                                <>{subsystem.title}</>
                            </div>
                        </TableCell>
                        <TableCell className={styles.cell}>{t(SubsystemTypes[subsystem.type])}</TableCell>
                        <TableCell className={styles.hoverableCell}
                            onClick={() => handleCreationTimeClick(subsystem)}
                        >
                            {formatDate(subsystem.creationTime)}
                        </TableCell>
                        <TableCell className={styles.cell}>
                            {formatDate(subsystem.lastFirmwareDate)}
                        </TableCell>
                        <TableCell className={styles.cell} style={{ display: "flex", flexDirection: "row" }}>
                            <IconButton
                                style={{ color: 'red' }}
                                onClick={() => handleRemoveSubsystem(subsystem)} >
                                <DeleteIcon />
                            </IconButton>
                            <IconButton
                                color="primary"
                                onClick={() => handleShowFullInfo(subsystem)} >
                                <ShowFullInfoIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
