import React, { useState } from "react";
import {
    Typography,
    Button,
    Tabs,
    Tab,
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem
} from "@mui/material";
import styles from "./styles.module.css"
import icon from "./images/icon.svg";
import { useTranslation } from "react-i18next";
import {
    Language as LanguageIcon,
    ArrowDropDown as LanguageDropIcon,
    Menu as DrawerIcon
} from "@mui/icons-material";
import { useNavigate } from "react-router";

export default function MainAppBar({ tabValue, tabs, onDrawerButtonClick, onLogInClick }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [languageMenu, setLanguageMenu] = useState(null);

    return (
        <AppBar position="sticky">
            <Toolbar style={{ display: "flex", flexGrow: 1, justifyContent: "space-between", color: "black", background: "white" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <object type="image/svg+xml" data={icon} width="40" height="40" className={styles.appBarLogoIcon}>...</object>
                    <div className={styles.appBarDrawerButton}>
                        <IconButton
                            color="inherit"
                            onClick={onDrawerButtonClick}
                        >
                            <DrawerIcon />
                        </IconButton>
                    </div>
                    <Typography className={styles.pandoraSpecLogoText} variant="h6" component="div" style={{ marginLeft: "16px", marginRight: "16px" }}
                        onClick={(e) => { navigate("/main") }}
                    >
                        Pandora Spec
                    </Typography>
                    <Tabs className={styles.appBarTab}
                        indicatorColor="primary"
                        value={tabValue}
                        variant="scrollable"
                        scrollButtons={false}
                    >
                        <Tab style={{ display: "none" }} value={0} />
                        {
                            tabs.map(t => <Tab key={t.id} value={t.id} label={t.label}
                                onClick={() => navigate(t.link)}
                            />)
                        }
                    </Tabs>
                </div>
                <div>
                    <IconButton
                        color="inherit"
                        className={styles.headerMenuButton}
                        onClick={e => setLanguageMenu(e.currentTarget)}
                    >
                        <LanguageIcon />
                        {i18n.language.toUpperCase()}
                        <LanguageDropIcon />
                    </IconButton>
                    <Menu
                        id="language-menu"
                        open={Boolean(languageMenu)}
                        anchorEl={languageMenu}
                        onClose={() => setLanguageMenu(null)}
                        className={styles.headerMenu}
                        styles={{ paper: styles.profileMenu }}
                        disableAutoFocusItem
                    >
                        <MenuItem onClick={() => { i18n.changeLanguage("ru"); setLanguageMenu(null); }}
                            style={i18n.language.toUpperCase() === "RU" ? { textDecoration: "underline" } : {}}>
                            Русский
                        </MenuItem>
                        <MenuItem onClick={() => { i18n.changeLanguage("en"); setLanguageMenu(null); }}
                            style={i18n.language.toUpperCase() === "EN" ? { textDecoration: "underline" } : {}}>
                            English
                        </MenuItem>
                    </Menu>
                    <Button className={styles.appBarLoginButton}
                        color="inherit" style={{ position: "relative", marginRight: "16px" }}
                        onClick={onLogInClick}>
                        {t("Log in")}
                    </Button>
                </div>
            </Toolbar>
        </AppBar>
    );
}
