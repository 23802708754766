import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { SketchPicker } from 'react-color';

export default function ColorDialog({ isOpened, title, initialValue, handleConfirm, handleClose }) {
    const { t } = useTranslation();
    const [colorValue, setColorValue] = useState({
    });

    useEffect(() => {
        if (isOpened) {
            setColorValue(initialValue ? initialValue : {});
        }
    }, [isOpened, initialValue]);

    const handleChange = (color) => {
        setColorValue(color.rgb);
    };

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <SketchPicker
                    color={colorValue}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={colorValue === undefined || colorValue === null} onClick={() => { handleClose(); handleConfirm(colorValue); }} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog >
    );
}
