import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";

import Widget from "../components/Widget/Widget";
import EditUsergroup from "../components/EditUsergroup";

import { useTranslation } from "react-i18next";
import queryString from "query-string";

import { useLocation } from "react-router-dom";
import { useBackend } from "../context/BackendContext";

export default function UsergroupPage() {
  const backend = useBackend();
  const { t } = useTranslation();
  const location = useLocation();
  const [error, setError] = useState("");
  const [login, setLogin] = useState("");
  const [searchMode, setSearchMode] = useState(false);
  const [defaultVisibility, setDefaultVisibility] = useState(false);

  useEffect(() => {
    var params = queryString.parse(location.search);
    let newLogin = "";
    if (params.login === undefined) {
      newLogin = localStorage.getItem("userMail");
      setSearchMode(true);
    } else {
      newLogin = params.login;
      setSearchMode(false);
    }
    setLogin(newLogin);

    if (params.defaultVisibility === undefined) {
      backend.bckUsersGetFullInfo(newLogin).then((json) => {
        if (json.code === 0) {
          var content = json.content;
          setDefaultVisibility(content.useUsergroupVisibility);
        } else if (json.code === 3) {
          setError(json.message);
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
    } else {
      setDefaultVisibility(params.defaultVisibility === "true");
    }
  }, [location, t, backend]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            noBodyPadding
            header={
              <React.Fragment>
                <Typography variant="h5" color="textSecondary">
                  {login === ""
                    ? t("Usergroup")
                    : t("Usergroup") + ` (${login})`}
                </Typography>
              </React.Fragment>
            }
          >
            <Typography color="secondary">{error}</Typography>
            <div style={{ paddingLeft: "16px" }}>
              <EditUsergroup
                searchMode={searchMode}
                defaultUserVisibility={defaultVisibility}
                userLogin={login}
                usePageNumberSave
              />
            </div>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
