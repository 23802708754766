import React from "react";
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    IconButton,
} from "@mui/material";

import {
    Delete as DeleteIcon,
    Publish as UploadIcon,
    GetApp as DownloadIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"
import ReactFileReader from "react-file-reader";

import { useTranslation } from "react-i18next";
import LanguageSelect from "../LanguageSelect";
import { LanguageConverter, LanguageConverterInverse } from "../../enums/LanguageConverter";

export default function CarMountingSchemesTable({ data, handleDelete, handleUpload, handleDownload, handleEditDescription, handleEditOrder, handleEditLanguage, readOnly }) {
    const { t } = useTranslation();

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    {
                        readOnly ? <></> : <TableCell style={{ fontWeight: 'bold' }}>ID</TableCell>
                    }                    
                    {
                        readOnly ? <></> : <TableCell>{t("Order")}</TableCell>
                    }      
                    {
                        readOnly ? <></> : <TableCell>{t("Language")}</TableCell>
                    }  
                    <TableCell>
                        {t("Description")}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(scheme => (
                    <TableRow key={scheme.id}>
                        <TableCell className={styles.cell} style={{ width: "16ch" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                {
                                    readOnly ? <></> : <IconButton
                                        style={{ color: 'red' }}
                                        onClick={() => handleDelete(scheme)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                                {
                                    readOnly ? <></> : <ReactFileReader handleFiles={(files) => handleUpload(scheme, files[0])} fileTypes={["application/pdf"]}>
                                        <IconButton
                                            color="primary"
                                        >
                                            <UploadIcon />
                                        </IconButton>
                                    </ReactFileReader>
                                }
                                <IconButton
                                    color="primary"
                                    disabled={!scheme.name}
                                    onClick={() => handleDownload(scheme)}
                                >
                                    <DownloadIcon />
                                </IconButton>
                            </div>
                        </TableCell>
                        {
                            readOnly ? <></> : <TableCell className={styles.cell} style={{ width: "16ch" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div>
                                        {scheme.id}
                                    </div>
                                </div>
                            </TableCell>
                        }
                        {
                            readOnly ? <></> : <TableCell
                                className={styles.hoverableCell}
                                style={{ width: "16ch" }}
                                onClick={() => handleEditOrder(scheme)}
                            >
                                <div>
                                    {scheme.order}
                                </div>
                            </TableCell>
                        }
                        {
                            readOnly ? <></> : <TableCell className={styles.cell}>
                                <LanguageSelect label="" value={LanguageConverterInverse[scheme.language].toUpperCase()}
                                    onChange={(e) => { handleEditLanguage(scheme, e) }} />
                            </TableCell>
                        }
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={readOnly ? null : () => handleEditDescription(scheme)}
                        >
                            <div>
                                {scheme.description}
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
