const ArticleStatuses = {
    1: "Published",
    2: "Draft",
    3: "Archived"
};
Object.freeze(ArticleStatuses);

const ArticleStatusesByName = {
    "Published": 1,
    "Draft": 2,
    "Archived": 3
};
Object.freeze(ArticleStatusesByName);

export {
    ArticleStatuses,
    ArticleStatusesByName
}