import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
} from "@mui/material";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    AddCircle as AddCircleIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next"

export default function QuestionsTable({ data, editAllowed,
    onCreatePartClick, onEditPartClick, onDeletePartClick,
    onCreateSubpartClick, onEditSubpartClick, onDeleteSubpartClick,
    onCreateQuestionClick, onEditQuestionClick, onDeleteQuestionClick }) {

    const { t } = useTranslation();

    var keys = ["#", t("Instructions"), t("Check question"), t("Answers")];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>
                        <IconButton
                            disabled={!editAllowed}
                            style={!editAllowed ? { color: 'gray' } : { color: 'black' }}
                            onClick={onCreatePartClick}
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </TableCell>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    data.map(part =>
                        <React.Fragment key={`part-${part.id}`}>
                            <TableRow key={`part-${part.id}`} style={{ backgroundColor: "#F3F3F3" }}>
                                <TableCell colSpan={2} style={{ minWidth: '250px' }}>
                                    <IconButton
                                        disabled={!editAllowed}
                                        style={!editAllowed ? { color: 'gray' } : { color: 'black' }}
                                        onClick={() => { onCreateSubpartClick(part) }}
                                    >
                                        <AddCircleIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={!editAllowed}
                                        style={!editAllowed ? { color: 'gray' } : { color: 'red' }}
                                        onClick={() => { onDeletePartClick(part) }} >
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                        disabled={!editAllowed}
                                        style={!editAllowed ? { color: 'gray' } : { color: 'black' }}
                                        onClick={() => { onEditPartClick(part) }} >
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell colSpan={3} align="center">
                                    <h2>
                                        {part.name}
                                    </h2>
                                </TableCell>
                            </TableRow>
                            {
                                part.subparts.map(subpart =>
                                    <React.Fragment key={`subpart-${subpart.id}`}>
                                        <TableRow key={`subpart-${subpart.id}`} style={{ backgroundColor: "#A3A3A3" }}>
                                            <TableCell colSpan={2}>
                                                <IconButton
                                                    disabled={!editAllowed}
                                                    style={!editAllowed ? { color: 'gray' } : { color: 'black' }}
                                                    onClick={() => { onCreateQuestionClick(subpart) }}
                                                >
                                                    <AddCircleIcon />
                                                </IconButton>
                                                <IconButton
                                                    disabled={!editAllowed}
                                                    style={!editAllowed ? { color: 'gray' } : { color: 'red' }}
                                                    onClick={() => { onDeleteSubpartClick(subpart) }} >
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton
                                                    disabled={!editAllowed}
                                                    style={!editAllowed ? { color: 'gray' } : { color: 'black' }}
                                                    onClick={() => { onEditSubpartClick(subpart) }} >
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell colSpan={3}>
                                                <h3>
                                                    {subpart.name}
                                                </h3>
                                            </TableCell>
                                        </TableRow>
                                        {
                                            subpart.questions.map(question =>
                                                <TableRow key={`questions-${question.id}`}>
                                                    <TableCell>
                                                        <IconButton
                                                            disabled={true}
                                                            style={{ color: 'gray', visibility: 'hidden' }}
                                                        >
                                                            <AddCircleIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            disabled={!editAllowed}
                                                            style={!editAllowed ? { color: 'gray' } : { color: 'red' }}
                                                            onClick={() => { onDeleteQuestionClick(question) }} >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            disabled={!editAllowed}
                                                            style={!editAllowed ? { color: 'gray' } : { color: 'black' }}
                                                            onClick={() => { onEditQuestionClick(question) }} >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell>{question.id}</TableCell>
                                                    <TableCell>{question.algorithm}</TableCell>
                                                    <TableCell>{question.expectedResult}</TableCell>
                                                    <TableCell>{question.answers.map(a => a.answer).join(", ")}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            }
                        </React.Fragment>
                    )
                }
            </TableBody>
        </Table>
    );
}
