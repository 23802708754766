import React, { useState, useEffect, Children } from "react";
import { useTranslation } from "react-i18next";
import {
    ExitToApp as LoginIcon,
    LocalTaxi as CarsIcon,
    Settings as ConfigurationsIcon,
    Pageview as PackageSearchIcon,
} from "@mui/icons-material";
import LoginDialog from "../../components/Dialogs/LoginDialog/LoginDialog";
import StyledDrawer from "../../components/StyledDrawer/StyledDrawer";
import MainAppBar from "./MainAppBar";
import { toggleSidebar, useLayoutDispatch } from "../../context/LayoutContext";

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
    );
}

function Main({ location, children }) {
    const { t } = useTranslation();
    const layoutDispatch = useLayoutDispatch();
    const [isLoginDialogOpened, setIsLoginDialogOpened] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    let tabs = [
        {
            id: 1,
            label: t("Automobiles"),
            link: "/main/vehicles",
            icon: <CarsIcon />
        },
        {
            id: 2,
            label: t("Configurations"),
            link: "/main/configurations",
            icon: <ConfigurationsIcon />
        },
        {
            id: 3,
            label: t("Package search"),
            link: "/main/packageSearch",
            icon: <PackageSearchIcon />
        }
    ];

    let drawerStructure = [
        {
            id: 0,
            label: t("Log in"),
            onClick: () => {
                setIsDrawerOpen(false);
                setIsLoginDialogOpened(true);
            },
            icon: <LoginIcon />
        },
        ...tabs
    ];

    useEffect(() => {
        if (location) {
            let activeTab = tabs.find(t => location &&
                (location.pathname === t.link || location.pathname.indexOf(t.link) !== -1));
            if (activeTab) {
                setTabValue(activeTab.id);
            } else {
                setTabValue(0);
            }
        }
    }, [location])

    useEffect(() => {
        if (!localStorage.getItem("clientId")) localStorage.setItem("clientId", uuidv4());
    }, [])

    const handleLoginDialogClose = (e) => {
        setIsLoginDialogOpened(false);
    };

    return (
        <>
            <MainAppBar
                onLogInClick={(e) => setIsLoginDialogOpened(true)}
                onDrawerButtonClick={(e) => setIsDrawerOpen(true)}
                tabValue={tabValue}
                tabs={tabs}
            />

            <StyledDrawer
                isPermanent={false}
                isSidebarOpened={isDrawerOpen}
                structure={drawerStructure}
                handleClose={() => setIsDrawerOpen(false)}
                handleOpen={() => toggleSidebar(layoutDispatch, true)}
                content={
                    <>
                    </>
                }
            />

            {Children.map(children, (child, i) =>
                <div key={i}>
                    {child}
                </div>
            )}

            <LoginDialog
                isOpened={isLoginDialogOpened}
                handleClose={handleLoginDialogClose}
                props
            />
        </>
    );
}

export default Main;
