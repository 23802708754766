import React, { useState, useRef } from "react";

import { Grid, Typography, ListItem, IconButton, Button } from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  ArrowForwardIos as BulletIcon,
  AccountTree as GoToPrototypeIcon,
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import Structure from "./Structure";
import {
  DialogActionTypes,
  useDialogContext,
} from "../../context/DialogContext";
import { SettingsStructureTypesByName } from "../../enums/SettingsStructureTypes";

export default function Structures({ data, setData, setError }) {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t } = useTranslation();
  const [expandedStructure, setExpandedStructure] = useState({});
  const [selectedStructure, setSelectedStructure] = useState(null);
  const parentRefs = useRef({});

  const createOrUpdateStructure = (
    id,
    name,
    parentId,
    startByte,
    startBit,
    mapper,
    lengthByte,
    lengthBit,
    divider,
    prototypeId,
    count,
    optionStructs,
    option2Value,
    handleSuccess
  ) => {
    backend
      .bckSettingsCreateOrUpdateStructure(
        id,
        name,
        parentId,
        startByte,
        startBit,
        mapper,
        lengthByte,
        lengthBit,
        divider,
        prototypeId,
        count,
        optionStructs,
        option2Value
      )
      .then((json) => {
        if (json.code === 0) {
          let structureId = json.content;
          handleSuccess(structureId);
        } else if (json.code === 3) {
          setError(json.message);
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
  };

  const handleCreateStructureOpen = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        const handleSuccess = (structureId) => {
          let newElement = {
            Id: structureId,
            Name: name,
            Mapper: SettingsStructureTypesByName.Structure,
            Children: [],
          };
          setData((prev) => {
            let newStructures = {
              ...prev.Structures,
              [structureId]: newElement,
            };
            let newData = { ...data, Structures: newStructures };
            return newData;
          });
        };
        createOrUpdateStructure(
          null,
          name,
          null,
          null,
          null,
          SettingsStructureTypesByName.Structure,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          handleSuccess
        );
      },
      initialValue: "",
      textLabel: t("Title"),
      title: t("Add structure"),
    });
  };

  const handleCreateChildStructureOpen = (parent) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        const handleSuccess = (structureId) => {
          let newElement = {
            Id: structureId,
            ParentId: parent.Id,
            Name: name,
            Mapper: SettingsStructureTypesByName.UByte,
            StartByte: 0,
          };
          setData((prev) => {
            let newStructures = { ...prev.Structures };
            newStructures[parent.Id].Children.push(newElement);
            let newData = { ...data, Structures: newStructures };
            return newData;
          });
        };
        createOrUpdateStructure(
          null,
          name,
          parent.Id,
          0,
          null,
          SettingsStructureTypesByName.UByte,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          handleSuccess
        );
      },
      initialValue: "",
      textLabel: t("Title"),
      title: t("Add structure"),
    });
  };

  const removeStructure = (structure, handleSuccess) => {
    backend.bckSettingsRemoveStructure(structure.Id).then((json) => {
      if (json.code === 0) {
        handleSuccess();
      } else if (json.code === 3) {
        setError(t(json.message));
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleRemoveStructure = (structure) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove structure?", { title: structure.Name }),
      handleConfirm: () => {
        const handleSuccess = () => {
          setSelectedStructure(null);
          setData((prev) => {
            let newStructures = { ...prev.Structures };
            delete newStructures[structure.Id];
            let newData = { ...data, Structures: newStructures };
            return newData;
          });
        };
        removeStructure(structure, handleSuccess);
      },
    });
  };

  const handleRemoveChildStructure = (parent, structure) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove structure?", { title: structure.Name }),
      handleConfirm: () => {
        const handleSuccess = () => {
          setSelectedStructure(null);
          setData((prev) => {
            let newStructures = { ...prev.Structures };
            let targetIndex = parent.Children.findIndex(
              (c) => c.Id === structure.Id
            );
            if (targetIndex >= 0) {
              parent.Children.splice(targetIndex, 1);
            }
            let newData = { ...data, Structures: newStructures };
            return newData;
          });
        };
        removeStructure(structure, handleSuccess);
      },
    });
  };

  const handleStructureSave = (newStructure) => {
    const handleSuccess = () => {
      setSelectedStructure(newStructure);
      setData((prev) => {
        let newStructures = { ...prev.Structures };
        let targetStructure = newStructures[newStructure.Id];
        if (targetStructure) {
          newStructures[newStructure.Id] = newStructure;
        } else {
          let keys = Object.keys(newStructures);
          for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = newStructures[key];
            if (!value.Children) continue;

            let foundIndex = value.Children.findIndex(
              (c) => c.Id === newStructure.Id
            );
            if (foundIndex >= 0) {
              value.Children.splice(foundIndex, 1);
              break;
            }
          }

          newStructures[newStructure.ParentId].Children.push(newStructure);
        }
        let newData = { ...data, Structures: newStructures };
        return newData;
      });
    };
    createOrUpdateStructure(
      newStructure.Id,
      newStructure.Name,
      newStructure.ParentId,
      newStructure.StartByte,
      newStructure.StartBit,
      newStructure.Mapper,
      newStructure.LengthByte,
      newStructure.LengthBit,
      newStructure.Divider,
      newStructure.PrototypeId,
      newStructure.Count,
      newStructure.OptionStructs,
      newStructure.Option2Value,
      handleSuccess
    );
  };

  return (
    <>
      <Grid item>
        <Button
          onClick={handleCreateStructureOpen}
          variant="contained"
          color="primary"
        >
          {t("Add structure")}
        </Button>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "70vh",
          marginTop: "16px",
        }}
      >
        <div style={{ overflowY: "auto" }}>
          {data && data.Structures ? (
            Object.values(data.Structures)
              .filter((s) => s)
              .map((s) => (
                <div key={s.Id} ref={(el) => (parentRefs.current[s.Id] = el)}>
                  <ListItem
                    button
                    key={s.Id}
                    onClick={() => {
                      if (s.Id === expandedStructure.Id || !s.Children) {
                        setExpandedStructure({});
                      } else {
                        setExpandedStructure(s);
                      }
                      setSelectedStructure(s);
                    }}
                  >
                    <Typography
                      style={
                        s.Id === selectedStructure?.Id
                          ? { fontWeight: "bold" }
                          : {}
                      }
                    >{`${s.Name}`}</Typography>
                    {s.Id === expandedStructure.Id ? (
                      <ExpandLess />
                    ) : s.Children ? (
                      <ExpandMore />
                    ) : (
                      <></>
                    )}
                    {s.Children ? (
                      <IconButton
                        color="primary"
                        onClick={(e) => {
                          handleCreateChildStructureOpen(s);
                          e.stopPropagation();
                        }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                    <IconButton
                      color="secondary"
                      disabled={s.Children && s.Children.length > 0}
                      onClick={(e) => {
                        handleRemoveStructure(s);
                        e.stopPropagation();
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </ListItem>
                  {data && data.Structures && s.Id === expandedStructure.Id ? (
                    s.Children.filter((c) => c).map((c) => (
                      <ListItem
                        button
                        key={c.Id}
                        onClick={() => {
                          setSelectedStructure(c);
                        }}
                      >
                        <BulletIcon />
                        <Typography
                          style={
                            c.Id === selectedStructure?.Id
                              ? { fontWeight: "bold" }
                              : {}
                          }
                        >{`${c.Name}`}</Typography>
                        {c.PrototypeId ? (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              let proto = data.Structures[c.PrototypeId];
                              if (proto) setSelectedStructure(proto);
                              let div = parentRefs.current[c.PrototypeId];
                              if (div) div.scrollIntoView();
                              e.stopPropagation();
                            }}
                          >
                            <GoToPrototypeIcon />
                          </IconButton>
                        ) : (
                          <></>
                        )}
                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            handleRemoveChildStructure(s, c);
                            e.stopPropagation();
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </ListItem>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              ))
          ) : (
            <></>
          )}
        </div>
        <div style={{ marginLeft: "16px" }}>
          {selectedStructure ? (
            <Structure
              structure={selectedStructure}
              handleSave={handleStructureSave}
              settingInfo={data}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
