import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell
} from "@mui/material";

import { useTranslation } from "react-i18next"

import { formatDate } from "../../helpers/StringUtils"

export default function AutohelperLogsTable({ data }) {
    const { t } = useTranslation();

    var keys = ["#", t("Action"), t("User"), t("Device"), t("Data"), t("Time")];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(log => (
                    <TableRow key={log.id}>
                        <TableCell>{log.id}</TableCell>
                        <TableCell>{`${log.actionName} (${log.actionId})`}</TableCell>
                        <TableCell>{log.userLogin}</TableCell>
                        <TableCell>{log.deviceId}</TableCell>
                        <TableCell>{log.data}</TableCell>
                        <TableCell>{formatDate(log.time)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
