import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell
} from "@mui/material";

import { useTranslation } from "react-i18next"

export default function UsergroupListDialog({ list, isOpened, handleClose }) {
    const { t } = useTranslation();

    var keys = [t("Login"), t("Full name"), t("Email")];
    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t("Usergroup list dialog header")}</DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {keys.map(key => (
                                <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map(user => (
                            <TableRow key={user.login}>
                                <TableCell>{user.login}</TableCell>
                                <TableCell>{user.fullName}</TableCell>
                                <TableCell>{user.email}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
