import React, { useState, useEffect, useCallback, useRef } from "react";

import {
  Typography,
  Paper,
  TextField,
  IconButton,
  CircularProgress,
  Button,
} from "@mui/material";

import {
  FolderOpen as UploadFileIcon,
  Image as UploadImageIcon,
  Send as SendIcon,
  Refresh as ReconnectIcon,
  SettingsRemote as RemoteMountIcon,
} from "@mui/icons-material";

import { useInterval } from "../../useInterval";
import { useTranslation } from "react-i18next";
import { GenerateInteger } from "../../helpers/RandomGenerator";

import "react-chat-elements/dist/main.css";
import "./ChatOverride.css";
import { MessageList } from "react-chat-elements";

import ChatRoomHeader from "../../components/ChatRoomHeader";

import * as timeago from "timeago.js";
import timeagoRu from "../../timeagoRu";
import timeagoEn from "../../timeagoEn";

import ReactFileReader from "react-file-reader";

import { generateUserInfoString } from "../../helpers/UserInfoStringGenerator";
import FullScreenImageDialog from "../../components/Dialogs/FullScreenImageDialog";
import { saveFile } from "../../helpers/FileSaver";
import {
  useDialogContext,
  DialogActionTypes,
} from "../../context/DialogContext";
import { useBackend } from "../../context/BackendContext";
import { ResponseCode } from "../../enums/ResponseCode";
import { ChatMountEventTypes } from "../../enums/ChatMountEventTypes";
import SendMountEventDialog from "../Dialogs/SendMountEventDialog";
import FileDndContainer from "../FileDndContainer";

let writingTimeout = null;

export default function Chat({ username, customHeight, headless, fullWidth }) {
  const maxMessageLength = 500;

  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [webSocket, setWebSocket] = useState(null);
  const [chatReady, setChatReady] = useState(false);
  const [token, setToken] = useState(null);
  const [isWriting, setIsWriting] = useState(false);
  const [usersWriting, setUsersWriting] = useState([]);
  const [fullScreenImageOpened, setFullScreenImageOpened] = useState(false);
  const [fullScreenImageUrl, setFullScreenImageUrl] = useState("");
  const [fullScreenImageName, setFullScreenImageName] = useState("");
  const [mountEventDialogOpened, setMountEventDialogOpened] = useState(false);
  const [lastDeviceId, setLastDeviceId] = useState("");
  const messagesEndRef = useRef();
  const myLogin = useRef("");
  const [roomData, setRoomData] = useState({});
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const handleMountEventDialogClose = useCallback(() => {
    setMountEventDialogOpened(false);
  }, []);

  const handleMountEventDialogConfirm = useCallback(
    (type, deviceId, dxsData) => {
      let settings = null;
      if (type === ChatMountEventTypes.WRITE_SETTINGS_REQUEST) {
        let dxsObj = null;
        try {
          dxsObj = JSON.parse(dxsData);
        } catch {}
        if (dxsObj) {
          settings = dxsObj["settings"];
        }
      }
      let sendRequest = {
        type: "send",
        content: {
          messageContent: {
            type: "mount_event",
            body: "Mount event",
            mountEventTypeId: type,
            deviceId: deviceId,
            settings: settings,
          },
          receiverLogin: username,
        },
      };
      setLastDeviceId(deviceId);
      webSocket.send(JSON.stringify(sendRequest));
    },
    []
  );

  const refreshRoomData = useCallback(() => {
    ++updatesLockCounter.current;
    var id = GenerateInteger();
    lastRequestId.current = id;
    backend.bckChatGetRooms(username, false, 1, 1).then((json) => {
      --updatesLockCounter.current;
      if (lastRequestId.current === id) {
        if (json.code === 0) {
          setRoomData((prev) => {
            if (updatesLockCounter.current === 0) {
              return json.content.list[0];
            } else {
              return prev;
            }
          });
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      }
    });
  }, [t, backend, username]);

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useInterval(() => {
    if (updatesLockCounter.current === 0) {
      refreshRoomData();
    }
  }, 3000);

  const handleConnect = useCallback(
    (token) => {
      let subscribeRequest = {
        type: "subscribe",
        token: token,
        content: {
          ownerLogin: username,
        },
      };
      webSocket.send(JSON.stringify(subscribeRequest));
      setChatMessages([]);
    },
    [webSocket, username]
  );

  const handleWritingStart = useCallback(() => {
    let writingRequest = {
      type: "writing_start",
      content: {
        userLogin: myLogin.current,
        receiverLogin: username,
      },
    };
    webSocket.send(JSON.stringify(writingRequest));
  }, [webSocket, username]);

  const handleWritingEnd = useCallback(() => {
    let writingRequest = {
      type: "writing_end",
      content: {
        userLogin: myLogin.current,
        receiverLogin: username,
      },
    };
    webSocket.send(JSON.stringify(writingRequest));
  }, [webSocket, username]);

  useEffect(() => {
    if (webSocket && username && token) {
      handleConnect(token);
    }
  }, [webSocket, username, token, handleConnect]);

  const handleMessageKeyDown = (e) => {
    if (chatReady) {
      if (message) {
        if (e.keyCode === 13) {
          if (!e.shiftKey) {
            e.preventDefault();
            handleSend();
          }
        } else if (e.keyCode === 9) {
          e.preventDefault();
          let point = e.target.selectionStart;
          setMessage(
            (prev) =>
              prev.substr(0, point) +
              "\t" +
              prev.substr(point, prev.length - point)
          );
          setMessage((prev) => prev.substr(0, maxMessageLength));
        }
      }

      if (!isWriting) {
        handleWritingStart();
        setIsWriting(true);
      }
      window.clearTimeout(writingTimeout);
      writingTimeout = window.setTimeout(() => {
        handleWritingEnd(token);
        setIsWriting(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (i18n.language === "ru") {
      timeago.register("en_US", timeagoRu);
    } else {
      timeago.register("en_US", timeagoEn);
    }
  }, [i18n.language]);

  const appendMessage = useCallback(
    (myLogin, message) => {
      let sendDate = new Date(message.sendDate);

      let uiType = message.messageContent.type;
      let data = null;
      let text = message.messageContent.body;
      if (message.messageContent.type === "image") {
        uiType = "photo";
      } else if (message.messageContent.type === "mount_event") {
        uiType = "text";
        let eventHeader = "Mount event";
        let eventText = message.messageContent.deviceId
          ? `ID: ${message.messageContent.deviceId}`
          : "";
        switch (message.messageContent.mountEventTypeId) {
          case ChatMountEventTypes.ERROR:
            eventHeader = t("Error");
            eventText = message.messageContent.body;
            break;
          case ChatMountEventTypes.SESSION_START:
            eventHeader = t("Remote mount session started");
            if (message.messageContent.deviceId) {
              setLastDeviceId(message.messageContent.deviceId);
            }
            break;
          case ChatMountEventTypes.SESSION_STOP:
            eventHeader = t("Remote mount session stopped");
            break;
          case ChatMountEventTypes.DEVICE_INFORMATION_REQUEST:
            eventHeader = t("Device information requested");
            break;
          case ChatMountEventTypes.DEVICE_INFORMATION_RESPONSE:
            eventHeader = t("Device information received");
            eventText = `ID: ${message.messageContent.deviceId}\r\n${t(
              "Name"
            )}: ${message.messageContent.deviceName}\r\n${t(
              "Firmware version"
            )}: ${message.messageContent.firmware}`;
            if (message.messageContent.deviceId) {
              setLastDeviceId(message.messageContent.deviceId);
            }
            break;
          case ChatMountEventTypes.DEVICE_SETTINGS_REQUEST:
            eventHeader = t("Device settings requested");
            break;
          case ChatMountEventTypes.DEVICE_SETTINGS_IN_PROGRESS:
            eventHeader = t("Device settings reading in progress");
            break;
          case ChatMountEventTypes.DEVICE_SETTINGS_RESPONSE:
            eventHeader = t("Device settings received");
            eventText = (
              <>
                <Button
                  variant="contained"
                  onClick={() => {
                    let deviceName = message.messageContent.deviceName;
                    let settings = message.messageContent.settings;
                    if (deviceName && settings) {
                      let dxsObject = {
                        code: "",
                        id_car: "",
                        year: "",
                        car_type: "",
                        reg_number: "",
                        installer: "",
                        owner: "",
                        install_date: "",
                        comment: "",
                        settings: settings,
                      };

                      dialogDispatch({
                        type: DialogActionTypes.SettingsEditorDialogOpen,
                        data: dxsObject,
                        alarmName: deviceName,
                      });
                    }
                  }}
                >
                  {t("View")}
                </Button>
                <div></div>
              </>
            );
            break;
          case ChatMountEventTypes.WRITE_SETTINGS_REQUEST:
            eventHeader = t("Write settings request sent");
            break;
          case ChatMountEventTypes.WRITE_SETTINGS_IN_PROGRESS:
            eventHeader = t("Write settings request in progress");
            break;
          case ChatMountEventTypes.WRITE_SETTINGS_RESPONSE:
            eventHeader = t("Settings written to device");
            break;
          case ChatMountEventTypes.REBOOT_REQUEST:
            eventHeader = t("Reboot request");
            break;
          case ChatMountEventTypes.PROCESSING_START:
            eventHeader = t("Processing start");
            break;
          case ChatMountEventTypes.PROCESSING_STOP:
            eventHeader = t("Processing stop");
            break;
          case ChatMountEventTypes.MOUNT_SYNCHRONIZATION_REQUEST:
            eventHeader = t("Mount synchronization request");
            break;
          default:
            eventHeader = t("Unknown mount event");
            break;
        }
        text = (
          <div
            style={{ border: "solid 1px", borderRadius: "4px", padding: "8px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <RemoteMountIcon />
              <b>{eventHeader}</b>
            </div>
            {eventText ? (
              <>
                <hr />
                <div>{eventText}</div>
              </>
            ) : (
              <></>
            )}
          </div>
        );
      }

      if (
        message.messageContent.type === "image" ||
        message.messageContent.type === "file"
      ) {
        data = {
          uri: `frontend/Files/downloadChatFile?fileId=${message.messageContent.fileId}`,
          status: {
            click: false,
          },
        };
      }

      let obj = {
        id: message.id,
        senderId: message.senderId,
        position: message.senderLogin === myLogin ? "right" : "left",
        title: message.senderLogin,
        type: uiType,
        date: sendDate,
        text: text,
        status: message.isRead ? "read" : "sent",
        data: data,
      };
      setChatMessages((messages) => [...messages, obj].slice(-100));
    },
    [dialogDispatch, t]
  );

  useEffect(() => {
    if (!messagesEndRef || !messagesEndRef.current) return;
    messagesEndRef.current.scrollIntoView({ behavior: "auto" });
  }, [messagesEndRef, chatMessages]);

  const markMessages = useCallback((markEvent) => {
    setChatMessages((messages) =>
      messages.map((msg) => {
        if (
          msg.status !== "read" &&
          markEvent.ids.some((id) => id === msg.id)
        ) {
          var newMsg = { ...msg };
          newMsg.status = "read";
          return newMsg;
        } else {
          return msg;
        }
      })
    );
  }, []);

  const messageCallback = useCallback(
    (event) => {
      let wsObj = null;
      try {
        wsObj = JSON.parse(event.data);
      } catch {
        setError("Unknown server response");
        return;
      }

      setError("");
      switch (wsObj.type) {
        case "send":
          appendMessage(myLogin.current, wsObj.content);
          break;
        case "error":
          setError(wsObj.message);
          break;
        case "auth_failure":
          setError("Authorization error");
          break;
        case "subscribe_success":
          let lMyLogin = wsObj.content.userLogin;
          myLogin.current = lMyLogin;
          setChatReady(true);
          backend
            .bckChatGetRoomMessages(username, true, 1, 100)
            .then((json) => {
              if (json.code === 0) {
                json.content.list
                  .sort((a, b) => {
                    let aDate = new Date(a.sendDate);
                    let bDate = new Date(b.sendDate);
                    return aDate > bDate ? 1 : aDate === bDate ? 0 : -1;
                  })
                  .forEach((msg) => {
                    appendMessage(lMyLogin, msg);
                  });
              }
            });
          refreshRoomData();
          break;
        case "unsubscribe_success":
          setChatReady(false);
          break;
        case "mark":
          markMessages(wsObj.content);
          break;
        case "writing_start":
          let writingStartUser = wsObj.content.userLogin;
          setUsersWriting((users) => {
            if (users.some((u) => u === writingStartUser)) return users;
            return [...users, writingStartUser];
          });
          break;
        case "writing_end":
          let writingEndUser = wsObj.content.userLogin;
          setUsersWriting((users) => {
            if (!users.some((u) => u === writingEndUser)) return users;
            return [...users.filter((u) => u !== writingEndUser)];
          });
          break;
        default:
          break;
      }
    },
    [username, appendMessage, markMessages, refreshRoomData, backend]
  );

  useEffect(() => {
    if (webSocket) {
      webSocket.onmessage = messageCallback;
    }
  }, [webSocket, messageCallback]);

  const initializeWs = () => {
    backend.bckInfraGetChatSocket().then((json) => {
      if (json.code === 0) {
        setToken(json.content.token);
        var webSocket = new WebSocket(
          json.content.address,
          encodeURIComponent(json.content.token)
        );
        webSocket.onerror = function (event) {
          setError(event.data);
        };
        webSocket.onopen = function (event) {
          setWebSocket(webSocket);
        };
        webSocket.onclose = function (event) {
          setError("Connection closed");
        };
      } else {
        setError("Error retrieving chat data");
      }
    });
  };

  useEffect(() => {
    initializeWs();
  }, []);

  useEffect(() => {
    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
  }, [webSocket]);

  const handleSend = () => {
    if (message) {
      var notReadMessages = chatMessages
        .filter((msg) => msg.title !== myLogin.current && msg.status !== "read")
        .map((msg) => msg.id);
      if (notReadMessages.length > 0 && webSocket && username && token) {
        let markRequest = {
          type: "mark",
          token: token,
          content: {
            ids: notReadMessages,
          },
        };
        webSocket.send(JSON.stringify(markRequest));
      }

      let sendRequest = {
        type: "send",
        content: {
          messageContent: {
            type: "text",
            body: message,
          },
          receiverLogin: username,
        },
      };
      webSocket.send(JSON.stringify(sendRequest));
      setMessage("");
    }
  };

  const handleUploadImageOrFile = (file, isImage) => {
    if (file.size > 32 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    setChatReady(false);
    backend.bckFilesUploadChatFile(file.name, file).then((json) => {
      if (json.code === 0) {
        let fileId = json.content;
        let sendRequest = {
          type: "send",
          content: {
            messageContent: {
              type: isImage ? "image" : "file",
              body: file.name,
              fileId: fileId,
            },
            receiverLogin: username,
          },
        };
        webSocket.send(JSON.stringify(sendRequest));
        setMessage("");
      } else {
        setError(json.message);
      }
      setChatReady(true);
    });
  };

  const handleUploadImageSelect = (files) => {
    handleUploadImageOrFile(files[0], true);
  };

  const handleUploadFileSelect = (files) => {
    handleUploadImageOrFile(files[0], false);
  };

  const onDropFile = (info) => {
    let file = info.files[0];
    let isImage = file.type.startsWith("image");
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: isImage
        ? t("Send image?", { filename: file.name })
        : t("Send file?", { filename: file.name }),
      handleConfirm: () => {
        handleUploadImageOrFile(file, isImage);
      },
    });
  };

  const handleMessageClick = (message, i, e) => {
    if (message.type === "photo" || message.type === "file") {
      let parentElement = e.target.parentElement;
      while (parentElement) {
        if (
          parentElement.classList &&
          parentElement.classList.contains("rce-container-mbox")
        ) {
          if (message.type === "photo") {
            setFullScreenImageUrl(message.data.uri);
            setFullScreenImageName(message.text);
            setFullScreenImageOpened(true);
          } else {
            saveFile(message.data.uri, message.text);
          }
          break;
        }
        parentElement = parentElement.parentNode;
      }
    }
  };

  const handleFullScreenImageClose = () => {
    setFullScreenImageOpened(false);
  };

  const openOutputDialog = (text) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleOutputDialogOpen,
      title: null,
      text: text,
      textLabel: "",
      noTextField: true,
    });
  };

  const handleShowUserInfo = (room) => {
    let info = room.userInfo;
    openOutputDialog(generateUserInfoString(t, info));
  };

  const handleShowUserLogs = (room) => {
    window.open(`#/app/events?login=${room.login}`, "_blank");
  };

  const handleReconnectClick = () => {
    setError(null);
    initializeWs();
  };

  const handleClickFavourite = useCallback((room) => {
    let newValue = !room.isFavourite;
    backend
      .bckChatMarkFavouriteRoom(room.id, newValue)
      .then(() => refreshRoomData());
  }, []);

  const handleSendMountEventClick = () => {
    setMountEventDialogOpened(true);
  };

  return (
    <>
      <Paper
        style={{
          overflow: "auto",
          padding: "24px",
          height: customHeight ? customHeight : "87vh",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          maxWidth: fullWidth ? null : "120ch",
        }}
      >
        {headless ? (
          <div />
        ) : (
          <div>
            <ChatRoomHeader
              room={roomData}
              fioMode={true}
              handleShowUserInfo={handleShowUserInfo}
              handleShowUserLogs={handleShowUserLogs}
              handleClickFavourite={handleClickFavourite}
              handleSendMountEventClick={handleSendMountEventClick}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography color="secondary">{error}</Typography>
              {error ? (
                <IconButton onClick={handleReconnectClick}>
                  <ReconnectIcon color="primary" />
                </IconButton>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        {webSocket ? (
          <>
            <FileDndContainer
              onDrop={onDropFile}
              style={{
                overflow: "hidden",
                flexGrow: 1,
                width: "100%",
                maxWidth: "120ch",
                margin: "auto",
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                  width: null,
                  height: null,
                  overflow: "hidden scroll",
                  marginBottom: "-1px",
                }}
              >
                <div
                  style={{
                    margin: "32px",
                    overflow: "hidden",
                  }}
                >
                  <MessageList
                    dataSource={chatMessages}
                    onClick={handleMessageClick}
                  />
                  <div ref={messagesEndRef} />
                </div>
              </div>
            </FileDndContainer>
            <Typography
              variant="caption"
              align="right"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {usersWriting.length > 2
                ? t("Chat room users writing", { users: usersWriting.length })
                : usersWriting.length > 1
                ? t("Chat room users writing", {
                    users: usersWriting.join(", "),
                  })
                : usersWriting.length > 0
                ? t("Chat room user writing", { users: usersWriting[0] })
                : " "}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "12px",
              }}
            >
              <ReactFileReader
                disabled={!chatReady}
                handleFiles={handleUploadImageSelect}
                fileTypes={[".jpeg", ".jpg", ".jpe", ".png", ".pns"]}
              >
                <IconButton
                  disabled={!chatReady}
                  style={!chatReady ? { color: "gray" } : { color: "blue" }}
                >
                  <UploadImageIcon />
                </IconButton>
              </ReactFileReader>
              <ReactFileReader
                disabled={!chatReady}
                handleFiles={handleUploadFileSelect}
                fileTypes={[]}
              >
                <IconButton
                  disabled={!chatReady}
                  style={!chatReady ? { color: "gray" } : { color: "blue" }}
                >
                  <UploadFileIcon />
                </IconButton>
              </ReactFileReader>
              <TextField
                label={t("Message")}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{ minWidth: "32ch", marginRight: "12px", flexGrow: 1 }}
                maxRows={3}
                inputProps={{ maxLength: maxMessageLength }}
                onKeyDown={handleMessageKeyDown}
                multiline
                autoFocus
              />
              <IconButton
                onClick={handleSend}
                disabled={!chatReady || !message}
                color="primary"
                style={{ width: "48px", height: "48px", padding: 0 }}
              >
                <SendIcon style={{ width: "40px", height: "40px" }} />
              </IconButton>
            </div>
          </>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={64} style={{ margin: "auto" }} />
          </div>
        )}
      </Paper>

      <FullScreenImageDialog
        handleClose={handleFullScreenImageClose}
        isOpened={fullScreenImageOpened}
        imageUrl={fullScreenImageUrl}
        imageName={fullScreenImageName}
      />

      <SendMountEventDialog
        isOpened={mountEventDialogOpened}
        initialDeviceId={lastDeviceId}
        handleClose={handleMountEventDialogClose}
        handleConfirm={handleMountEventDialogConfirm}
      />
    </>
  );
}
