import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  Button,
  TextField,
  Tabs,
  Tab,
  Avatar,
  Autocomplete,
} from "@mui/material";

import FastMounts from "./FastMounts";
import Logs from "./Logs";
import Chat from "../components/Chat/Chat";

import { useTranslation } from "react-i18next";

import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import TechsupportRequestSessionsTable from "../components/Tables/TechsupportRequestSessionsTable";

import { GenerateInteger } from "../helpers/RandomGenerator";
import { useInterval } from "../useInterval";

import { formatDate } from "../helpers/StringUtils";
import { TechsupportRequestTags } from "../enums/TechsupportRequestTags";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { useBackend } from "../context/BackendContext";
import { ResponseCode } from "../enums/ResponseCode";
import { StyledBadge } from "../components/StyledBadge/StyledBadge";

export default function TechsupportRequestFull({ headerSetter }) {
  const { t, i18n } = useTranslation();
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [requestId, setRequestId] = useState(0);
  const [requestStatus, setRequestStatus] = useState(0);
  const [userFio, setUserFio] = useState("");
  const [userId, setUserId] = useState("");
  const [userMail, setUserMail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [alarmModel, setAlarmModel] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [viewOnlyMode, setViewOnlyMode] = useState(false);
  const [issueText, setIssueText] = useState("");
  const [configData, setConfigData] = useState("");
  const [sessions, setSessions] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [commentError, setCommentError] = useState(false);
  const [commentHelperText, setCommentHelperText] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [isApiUserOnline, setIsApiUserOnline] = useState(false);
  const [lastOnline, setLastOnline] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsError, setTagsError] = useState(false);
  const [tagsHelperText, setTagsHelperText] = useState("");
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshIsApiUserOnline = useCallback(
    (backend) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend.bckUsersIsApiUserOnline(userId).then((json) => {
        --updatesLockCounter.current;
        if (lastRequestId.current === id) {
          if (json.code === 0) {
            setIsApiUserOnline(json.content);
          } else if (json.code === ResponseCode.ACCESS_DENIED) {
            setError(t("AUTH_ERROR"));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        }
      });
    },
    [t, userId]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useInterval(() => {
    if (updatesLockCounter.current === 0) {
      refreshIsApiUserOnline(backend);
    }
  }, 4000);

  useEffect(() => {
    setCommentError(false);
    setCommentHelperText("");
  }, [i18n.language, commentText]);

  useEffect(() => {
    var params = queryString.parse(location.search);
    if (params.requestId) {
      setRequestId(params.requestId);
    } else {
      setError("Request ID is not set");
    }

    if (params.viewOnly === "true") {
      setViewOnlyMode(true);
    } else {
      setViewOnlyMode(false);
    }
  }, [location]);

  useEffect(() => {
    if (requestId) {
      setIsLoading(true);
      headerSetter(t("Request") + ` #${requestId}`);
      backend.bckTechsupportRequestsGetFullInfo(requestId).then((json) => {
        setIsLoading(false);
        if (json.code === 0) {
          var request = json.content;
          setUserId(request.senderId);
          setUserFio(request.requesterName);
          setUserMail(request.senderMail);
          setUserPhone(request.requesterPhone);
          setAlarmModel(request.alarmName);
          setDeviceId(request.deviceId);
          setIssueText(request.description);
          setConfigData(request.data);
          setCommentText("");
          setRequestStatus(request.status);
          setSessions(request.sessions);
          setIsApiUserOnline(request.isSenderOnline);
          setLastOnline(request.senderLastOnline);
          setTags(request.tags);
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
    }
  }, [requestId, headerSetter, t]);

  const handleSetTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const openSettingsEditor = () => {
    let dxsObject = {
      code: "",
      id_car: "",
      year: "",
      car_type: "",
      reg_number: "",
      installer: "",
      owner: "",
      install_date: "",
      comment: "",
      settings: configData,
    };

    dialogDispatch({
      type: DialogActionTypes.SettingsEditorDialogOpen,
      data: dxsObject,
      alarmName: alarmModel,
    });
  };

  const handleCloseRequest = (isFinal) => {
    if (!commentText) {
      setCommentError(true);
      setCommentHelperText(t("Field must be populated"));
      return;
    }
    setCommentError(false);

    if (!tags || tags.length === 0) {
      setTagsError(true);
      setTagsHelperText(t("Field must be populated"));
      return;
    }
    if (tags.length > 3) {
      setTagsError(true);
      setTagsHelperText(t("Specify no more than 3 tags"));
      return;
    }
    setTagsError(false);

    setRequestStatus(isFinal ? 4 : 3);
    backend
      .bckTechsupportRequestsClose(requestId, commentText, isFinal, tags)
      .then((json) => {
        if (json.code === 0) {
          navigate(-1);
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
  };

  const onTagsChanged = (...params) => {
    setTags(params[1]);
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Typography color="secondary">{error}</Typography>
      <Paper style={{ overflow: "auto", padding: "12px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
            invisible={!isApiUserOnline}
          >
            <Avatar
              style={{ height: "48px", width: "48px" }}
              onClick={() =>
                window.open(
                  `/frontend/Files/DownloadAvatar?userId=${userId}`,
                  "_blank"
                )
              }
              src={`/frontend/Files/DownloadAvatar?userId=${userId}`}
            >
              {userMail ? userMail[0].toUpperCase() : "X"}
            </Avatar>
          </StyledBadge>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
            }}
          >
            <Typography variant="h3">
              {userFio} ({userMail})
            </Typography>
            <Typography variant="h3">{userPhone}</Typography>
            {isApiUserOnline ? (
              <></>
            ) : (
              <Typography variant="caption">
                {`${t("Last online")} ${formatDate(lastOnline)}`}
              </Typography>
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: "12px",
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginRight: "24px", minWidth: "24ch" }}>
            <Typography style={{ marginTop: "12px" }}>
              <b>{t("Device")}</b>
              <br />
              {alarmModel} ({deviceId})
            </Typography>
            <Button
              variant="contained"
              color="primary"
              disabled={!configData}
              onClick={() => openSettingsEditor()}
            >
              {t("Configuration")}
            </Button>
          </div>
          <div style={{ marginRight: "24px", minWidth: "52ch" }}>
            <TextField
              label={t("Issue")}
              margin="dense"
              value={issueText === undefined ? "" : issueText}
              multiline
              rows="4"
              fullWidth
            />
          </div>
          <div
            style={{
              display: "flex",
              marginRight: "24px",
              flexFlow: "column",
              flexGrow: 1,
            }}
          >
            <Autocomplete
              style={{ width: "100%", marginTop: "7px", marginRight: "16px" }}
              disabled={requestStatus !== 2}
              multiple
              size="small"
              value={tags}
              options={Object.keys(TechsupportRequestTags).map((k) =>
                parseInt(k)
              )}
              onChange={onTagsChanged}
              getOptionLabel={(option) => t(TechsupportRequestTags[option])}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Tags")}
                  placeholder={t("Tags")}
                  variant="outlined"
                  error={tagsError}
                  helperText={tagsHelperText}
                />
              )}
            />
            <TextField
              style={{ width: "100%", marginBottom: "16px", flexGrow: 1 }}
              disabled={requestStatus !== 2}
              label={t("Operator comment")}
              margin="dense"
              value={commentText === undefined ? "" : commentText}
              onChange={(e) => setCommentText(e.target.value)}
              variant="outlined"
              error={commentError}
              helperText={commentHelperText}
              multiline
              rows="2"
              fullWidth
            />
          </div>
          <div style={{ display: "flex", flexFlow: "column" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "24px", width: "24ch" }}
              disabled={viewOnlyMode || requestStatus !== 2}
              onClick={() => {
                handleCloseRequest(true);
              }}
            >
              {t("Close request")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "12px", width: "24ch" }}
              disabled={viewOnlyMode || requestStatus !== 2}
              onClick={() => {
                handleCloseRequest(false);
              }}
            >
              {t("Delay")}
            </Button>
          </div>
        </div>
      </Paper>
      <Tabs value={tabValue} onChange={handleSetTab}>
        <Tab label={t("Mount events")} />
        <Tab label={t("Fast mounts")} />
        <Tab label={t("Chat page")} />
        <Tab label={t("Request history")} />
      </Tabs>
      {deviceId ? (
        tabValue === 0 ? (
          <Logs
            noFiltersMode={true}
            customHeight="62vh"
            customDeviceIdQuery={deviceId}
          />
        ) : tabValue === 1 ? (
          <FastMounts
            noFiltersMode={true}
            customHeight="62vh"
            customSearchDeviceId={deviceId}
          />
        ) : tabValue === 2 ? (
          <Chat username={userMail} customHeight="62vh" headless />
        ) : (
          <Paper
            style={{ overflow: "auto", padding: "24px", maxHeight: "62vh" }}
          >
            <TechsupportRequestSessionsTable data={sessions} />
          </Paper>
        )
      ) : (
        <></>
      )}
    </>
  );
}
