import React, { useState, useEffect } from "react";

import { Grid, Typography, Paper, Tabs, Tab } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import Structures from "./Structures";
import Menus from "./Menus";
import Logics from "./Logics";
import MenuJson from "./MenuJson";
import Channels from "./Channels";
import AlarmMenus from "./AlarmMenus";
import WireEditor from "./WireEditor";
import { getSettingsInfo } from "../../components/SettingsEditor/SettingsInfoCache";

export default function MenuEditor() {
  const backend = useBackend();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    getSettingsInfo(backend, true).then((newData) => {
      setData(newData);
    });
  }, []);

  const handleSetTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
      <Grid container direction="column" spacing={2}>
        <Typography variant="h5" color="textSecondary">
          {t("Menu editor")}
        </Typography>
        <Typography color="secondary">{error}</Typography>
        <Tabs value={tabValue} onChange={handleSetTab}>
          <Tab label={t("Structures")} />
          <Tab label={t("Menus")} />
          <Tab label={t("Logics")} />
          <Tab label={t("Channels")} />
          <Tab label={t("Alarm menus")} />
          <Tab label={t("Wires")} />
          <Tab label="JSON" />
        </Tabs>
        {tabValue === 0 ? (
          <Structures data={data} setData={setData} setError={setError} />
        ) : (
          <></>
        )}
        {tabValue === 1 ? (
          <Menus data={data} setData={setData} setError={setError} />
        ) : (
          <></>
        )}
        {tabValue === 2 ? (
          <Logics data={data} setData={setData} setError={setError} />
        ) : (
          <></>
        )}
        {tabValue === 3 ? (
          <Channels data={data} setData={setData} setError={setError} />
        ) : (
          <></>
        )}
        {tabValue === 4 ? (
          <AlarmMenus data={data} setData={setData} setError={setError} />
        ) : (
          <></>
        )}
        {tabValue === 5 ? (
          <WireEditor data={data} setData={setData} setError={setError} />
        ) : (
          <></>
        )}
        {tabValue === 6 ? <MenuJson /> : <></>}
      </Grid>
    </Paper>
  );
}
