import { ArticleElementTypesByName } from "../../enums/Articles/ArticleElementTypes";

const ElementFields = {
    Text: 1,
    Fillings: 2,
    SizeStyle: 3,
    ColorStyle: 4,
    Margins: 5,
    Paddings: 6,
    Gravity: 7,
    TextAlign: 8,
    UrlImage: 9,
    Sizes: 10,
    TextStyle: 11,
    UrlText: 12,
    AlternativeUrl: 13
}
Object.freeze(ElementFields);

const ElementTypesAndRequiredFields = {
    [ArticleElementTypesByName.BUTTON]: [
        "widthFilling", "heightFilling", "text"
    ],
    [ArticleElementTypesByName.IMAGE]: [
        "width", "height", "url"
    ],
    [ArticleElementTypesByName.LINK]: [
        "widthFilling", "heightFilling", "text", "url"
    ],
    [ArticleElementTypesByName.TEXT]: [
        "widthFilling", "heightFilling", "text"
    ],
    [ArticleElementTypesByName.VIDEO]: [
        "width", "height", "url", "alternativeUrl"
    ]
}
Object.freeze(ElementTypesAndRequiredFields);

const ElementTypesAndFields = {
    [ArticleElementTypesByName.BUTTON]: [
        ElementFields.UrlText, ElementFields.Fillings, ElementFields.Text, ElementFields.SizeStyle, ElementFields.ColorStyle,
        ElementFields.TextAlign, ElementFields.Gravity, ElementFields.Margins, ElementFields.Paddings
    ],
    [ArticleElementTypesByName.IMAGE]: [
        ElementFields.UrlImage, ElementFields.Sizes, ElementFields.Gravity, ElementFields.Text,
        ElementFields.SizeStyle, ElementFields.ColorStyle, ElementFields.Margins, ElementFields.Paddings
    ],
    [ArticleElementTypesByName.LINK]: [
        ElementFields.UrlText, ElementFields.Fillings, ElementFields.Text, ElementFields.SizeStyle, ElementFields.TextStyle,
        ElementFields.TextAlign, ElementFields.Gravity, ElementFields.Margins, ElementFields.Paddings
    ],
    [ArticleElementTypesByName.TEXT]: [
        ElementFields.Fillings, ElementFields.Text, ElementFields.SizeStyle, ElementFields.ColorStyle,
        ElementFields.TextStyle, ElementFields.TextAlign, ElementFields.Gravity, ElementFields.Margins, ElementFields.Paddings
    ],
    [ArticleElementTypesByName.VIDEO]: [
        ElementFields.UrlImage, ElementFields.AlternativeUrl, ElementFields.Sizes, ElementFields.Gravity, ElementFields.Text,
        ElementFields.SizeStyle, ElementFields.ColorStyle, ElementFields.Margins, ElementFields.Paddings
    ]
}
Object.freeze(ElementTypesAndFields);

export { ElementFields, ElementTypesAndFields, ElementTypesAndRequiredFields }