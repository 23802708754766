const TechsupportRequestStatuses = {
    1: "Opened",
    2: "In work",
    3: "Delayed",
    4: "Closed",
    5: "Closed by user",
    6: "Cancelled by user"
};
Object.freeze(TechsupportRequestStatuses);

export { TechsupportRequestStatuses }