import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField
} from "@mui/material";

import { useTranslation } from "react-i18next"

export default function AddressDialog({ text, isOpened, isOpenMapDisabled, handleOpenMap, handleClose }) {
    const { t } = useTranslation();
    const [textValue, setTextValue] = useState("");

    useEffect(() => {
        setTextValue(text);
    }, [text]);

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{t("Address")}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label={t("Address")}
                    value={textValue === undefined ? "" : textValue}
                    variant="outlined"
                    multiline
                    rows="7"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    {t("Cancel")}
                </Button>
                <Button disabled={isOpenMapDisabled} onClick={handleOpenMap} color="primary" autoFocus>
                    {t("Map")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
