import React, { useState, useEffect, useCallback } from "react";
import { Grid, CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";

import CreateQuestionPartDialog from "../components/Dialogs/CreateQuestionPartDialog";
import CreateQuestionSubpartDialog from "../components/Dialogs/CreateQuestionSubpartDialog";
import CreateQuestionDialog from "../components/Dialogs/CreateQuestionDialog";
import QuestionsTable from "../components/Tables/QuestionsTable";
import { LanguageConverter } from "../enums/LanguageConverter";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { useBackend } from "../context/BackendContext";

export default function Questions() {
  const { t, i18n } = useTranslation();
  const dialogDispatch = useDialogContext();
  const backend = useBackend();

  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [parts, setParts] = useState(null);
  const [subparts, setSubparts] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [questionsModel, setQuestionsModel] = useState([]);
  const [createPartDialogOpened, setCreatePartDialogOpened] = useState(false);
  const [createPartDialogPart, setCreatePartDialogPart] = useState({});
  const [createSubpartDialogOpened, setCreateSubpartDialogOpened] =
    useState(false);
  const [createSubpartDialogSubpart, setCreateSubpartDialogSubpart] = useState(
    {}
  );
  const [createQuestionDialogOpened, setCreateQuestionDialogOpened] =
    useState(false);
  const [createQuestionDialogQuestion, setCreateQuestionDialogQuestion] =
    useState({});

  const handleCreatePartDialogClose = () => {
    setCreatePartDialogOpened(false);
  };

  const handleCreatePartDialogConfirm = (id, name) => {
    backend
      .bckQuestionsCreateOrUpdatePart(
        id,
        name,
        LanguageConverter[i18n.language]
      )
      .then((json) => {
        setIsInitializing(true);
      });
    setCreatePartDialogOpened(false);
  };

  const handlePartCreateClick = () => {
    setCreatePartDialogPart(() => {
      var partStub = {};
      partStub.name = "";
      partStub.id = null;
      return partStub;
    });
    setCreatePartDialogOpened(true);
  };

  const handlePartEditClick = (part) => {
    setCreatePartDialogPart(part);
    setCreatePartDialogOpened(true);
  };

  const handlePartDeleteClick = (part) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete part?", { part: part.name }),
      handleConfirm: () => {
        backend.bckQuestionsDeletePart(part.id).then(() => {
          setIsInitializing(true);
        });
      },
    });
  };

  const handleSubpartCreateClick = (part) => {
    setCreateSubpartDialogSubpart(() => {
      var subpartStub = {};
      subpartStub.name = "";
      subpartStub.id = null;
      subpartStub.parentPartId = part.id;
      return subpartStub;
    });
    setCreateSubpartDialogOpened(true);
  };

  const handleCreateSubpartDialogClose = () => {
    setCreateSubpartDialogOpened(false);
  };

  const handleCreateSubpartDialogConfirm = (id, name, parentPartId) => {
    backend
      .bckQuestionsCreateOrUpdateSubpart(
        id,
        name,
        parentPartId,
        LanguageConverter[i18n.language]
      )
      .then(() => {
        setIsInitializing(true);
      });
    setCreateSubpartDialogOpened(false);
  };

  const handleSubpartEditClick = (subpart) => {
    setCreateSubpartDialogSubpart(subpart);
    setCreateSubpartDialogOpened(true);
  };

  const handleSubpartDeleteClick = (subpart) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete subpart?", { subpart: subpart.name }),
      handleConfirm: () => {
        backend.bckQuestionsDeleteSubpart(subpart.id).then(() => {
          setIsInitializing(true);
        });
      },
    });
  };

  const handleCreateQuestionDialogClose = () => {
    setCreateQuestionDialogOpened(false);
  };

  const handleCreateQuestionDialogConfirm = (
    id,
    algorithm,
    expectedResult,
    answers,
    parentSubpartId
  ) => {
    backend
      .bckQuestionsCreateOrUpdateQuestion(
        id,
        algorithm,
        expectedResult,
        answers,
        parentSubpartId,
        LanguageConverter[i18n.language]
      )
      .then(() => {
        setIsInitializing(true);
      });
    setCreateQuestionDialogOpened(false);
  };

  const handleQuestionCreateClick = (subpart) => {
    setCreateQuestionDialogQuestion(() => {
      var questionStub = {};
      questionStub.algorithm = "";
      questionStub.expectedResult = "";
      questionStub.parentSubpartId = subpart.id;
      return questionStub;
    });
    setCreateQuestionDialogOpened(true);
  };

  const handleQuestionDeleteClick = (question) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete question?", { question: question.id }),
      handleConfirm: () => {
        backend.bckQuestionsDeleteQuestion(question.id).then(() => {
          setIsInitializing(true);
        });
      },
    });
  };

  const handleQuestionEditClick = (question) => {
    setCreateQuestionDialogQuestion(question);
    setCreateQuestionDialogOpened(true);
  };

  useEffect(() => {
    if (parts === null || subparts === null || questions === null) return;
    setQuestionsModel(
      parts.map((p) => {
        var partView = {};
        partView.id = p.id;
        partView.name = p.name;
        partView.subparts = subparts
          .filter((sp) => sp.parentPartId === p.id)
          .map((sp) => {
            var subpartView = {};
            subpartView.id = sp.id;
            subpartView.name = sp.name;
            subpartView.parentPartId = p.id;
            subpartView.questions = questions
              .filter((q) => q.parentSubpartId === sp.id)
              .map((q) => {
                var questionView = {};
                questionView.id = q.id;
                questionView.algorithm = q.algorithm;
                questionView.expectedResult = q.expectedResult;
                questionView.answers = q.answers;
                questionView.parentSubpartId = sp.id;
                return questionView;
              });
            return subpartView;
          });
        return partView;
      })
    );
    setIsLoading(false);
  }, [parts, subparts, questions]);

  const initQuestions = useCallback(
    (backend) => {
      backend
        .bckQuestionsListParts(LanguageConverter[i18n.language])
        .then((json) => {
          setParts(json.content);
        });
      backend
        .bckQuestionsListSubparts(LanguageConverter[i18n.language])
        .then((json) => {
          setSubparts(json.content);
        });
      backend
        .bckQuestionsListQuestions(LanguageConverter[i18n.language])
        .then((json) => {
          setQuestions(json.content);
        });
    },
    [i18n.language]
  );

  useEffect(() => {
    initQuestions(backend);
    if (isInitializing) {
      setIsInitializing(false);
    }
  }, [isInitializing, initQuestions]);

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <QuestionsTable
        data={questionsModel}
        editAllowed={true}
        onCreatePartClick={handlePartCreateClick}
        onEditPartClick={handlePartEditClick}
        onDeletePartClick={handlePartDeleteClick}
        onCreateSubpartClick={handleSubpartCreateClick}
        onEditSubpartClick={handleSubpartEditClick}
        onDeleteSubpartClick={handleSubpartDeleteClick}
        onCreateQuestionClick={handleQuestionCreateClick}
        onEditQuestionClick={handleQuestionEditClick}
        onDeleteQuestionClick={handleQuestionDeleteClick}
      />

      <CreateQuestionPartDialog
        handleClose={handleCreatePartDialogClose}
        handleConfirm={handleCreatePartDialogConfirm}
        isOpened={createPartDialogOpened}
        part={createPartDialogPart}
      />

      <CreateQuestionSubpartDialog
        handleClose={handleCreateSubpartDialogClose}
        handleConfirm={handleCreateSubpartDialogConfirm}
        isOpened={createSubpartDialogOpened}
        parts={parts}
        subpart={createSubpartDialogSubpart}
      />

      <CreateQuestionDialog
        handleClose={handleCreateQuestionDialogClose}
        handleConfirm={handleCreateQuestionDialogConfirm}
        isOpened={createQuestionDialogOpened}
        subparts={subparts}
        question={createQuestionDialogQuestion}
      />
    </>
  );
}
