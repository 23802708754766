const ArticleElementColor = {
    1: "TEXT_PRIMARY",
    2: "PRIMARY",
    3: "ACCENT",
    4: "SECONDARY",
    5: "SURFACE",
    6: "ATTENTION"
};
Object.freeze(ArticleElementColor);

const ArticleElementColorByName = {
    "TEXT_PRIMARY": 1,
    "PRIMARY": 2,
    "ACCENT": 3,
    "SECONDARY": 4,
    "SURFACE": 5,
    "ATTENTION": 6
};
Object.freeze(ArticleElementColorByName);

export {
    ArticleElementColor,
    ArticleElementColorByName
}