import React, { useState, useEffect } from "react";

import {
    Switch,
    FormControlLabel
} from "@mui/material";

export default function LabeledSwitch({ onTitle, offTitle, onChange, defaultChecked, ...props }) {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(defaultChecked);
    }, [defaultChecked]);

    const toggleChecked = (e) => {
        setChecked((prev) => !prev);
        onChange(e);
    };

    return (
        <FormControlLabel
            control={<Switch {...props} size="small" checked={checked} onChange={toggleChecked} />}
            label={checked ? onTitle : offTitle}
        />
    );
}
