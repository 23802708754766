import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import {
  Map as MapIcon,
  Add as CreationTimeIcon,
  Create as UpdateTimeIcon,
  Visibility as ViewIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";

import { formatDate } from "../../helpers/StringUtils";

import AdvancedAvatarBadge from "../AdvancedAvatarBadge";
import { OpenManualRequestStatuses } from "../../enums/OpenManualRequestStatuses";

export default function OpenManualRequestsTable({
  data,
  userEmail,
  handleViewRequest,
  handleContinueRequest,
  handleAssignRequest,
  handleShowRequestAddress,
  handleShowTitle,
}) {
  const { t } = useTranslation();

  var keys = [
    "#",
    t("Status"),
    t("Time"),
    " ",
    t("Control"),
    t("User"),
    t("Vehicle"),
    "",
    t("Title"),
  ];

  return (
    <Table size="small">
      <TableHead>
        <TableRow key="header">
          {keys.map((key, i) => (
            <TableCell key={i} style={{ fontWeight: "bold" }}>
              {key}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data ? (
          data.map((request) => (
            <React.Fragment key={request.id}>
              <TableRow>
                <TableCell>{request.id}</TableCell>
                <TableCell style={{ width: "12ch" }}>
                  {t(OpenManualRequestStatuses[request.status])}
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CreationTimeIcon />
                    {formatDate(request.creationTime)}
                  </div>
                  {request.lastUpdateTime ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <UpdateTimeIcon />
                      {formatDate(request.lastUpdateTime)}
                    </div>
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell style={{ width: "64px" }}>
                  <IconButton
                    color="primary"
                    onClick={() => handleViewRequest(request)}
                  >
                    <ViewIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {request.status === 3 ? (
                    userEmail === null || request.handlerEmail !== userEmail ? (
                      <Typography>
                        {request.handlerName}
                        <br />
                        {request.handlerEmail}
                      </Typography>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleContinueRequest(request);
                        }}
                      >
                        {t("Continue")}
                      </Button>
                    )
                  ) : request.status === 2 || request.status === 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={request.status === 1}
                      onClick={() => handleAssignRequest(request)}
                    >
                      {t("Start work")}
                    </Button>
                  ) : (
                    <Typography>
                      {request.handlerName}
                      <br />
                      {request.handlerEmail}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <AdvancedAvatarBadge
                      avatarKey={0}
                      isUserOnline={false}
                      userId={request?.authorId}
                      userMail={request?.authorEmail}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "12px",
                      }}
                    >
                      <Typography variant="h6">
                        {request.authorEmail}
                      </Typography>
                      <Typography
                        variant="body2"
                        noWrap
                        style={{
                          fontStyle: "italic",
                          textOverflow: "ellipsis",
                          maxWidth: "32ch",
                        }}
                      >
                        {request.authorName}
                      </Typography>
                      <Typography
                        variant="body2"
                        noWrap
                        style={{
                          fontStyle: "italic",
                          textOverflow: "ellipsis",
                          maxWidth: "32ch",
                        }}
                      >
                        {request.authorPhone}
                      </Typography>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  {`${request.brandText ?? "-"} (${request.brandId ?? "-"})`}
                  <br />
                  {`${request.modelText ?? "-"} (${request.modelId ?? "-"})`}
                  <br />
                  {`${request.equipmentText ?? "-"} (${
                    request.equipmentId ?? "-"
                  })`}
                </TableCell>
                <TableCell>
                  <IconButton
                    disabled={
                      request.latitude === null || request.longitude === null
                    }
                    color="primary"
                    onClick={() => {
                      handleShowRequestAddress(request);
                    }}
                  >
                    <MapIcon />
                  </IconButton>
                </TableCell>
                <TableCell
                  style={{
                    minWidth: "32ch",
                    maxWidth: "32ch",
                    wordWrap: "break-word",
                  }}
                >
                  {request.title === null ||
                  request.title === undefined ||
                  request.title.length < 100 ? (
                    request.title
                  ) : (
                    <Button
                      onClick={() => {
                        handleShowTitle(request);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {t("Title")}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))
        ) : (
          <></>
        )}
      </TableBody>
    </Table>
  );
}
