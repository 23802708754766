const SettingsMenuTypes = {
    1: "Composite",
    2: "SwitchMenuItem",
    3: "IntegerMenuItem",
    4: "FloatAsIntMenuItem",
    5: "OptionMenuItem",
    6: "TextMenuItem",
    7: "SirenRingtoneMenuItem",
    8: "VehicleCanCodeMenuItem",
    9: "TimeMenuItem",
    10: "SystemOutputMenuItem",
    11: "SystemInputMenuItem",
    12: "SystemOutputSwitchMenuItem",
    13: "AutoStartScheduleMenuItem",
    14: "VoltageCalibrationMenuItem",
    15: "Inputs",
    16: "Outputs",
    17:	"Array",
    18:	"Title",
    19:	"TimeChannel_channels",
    20:	"Instance",
    21:	"Phones",
};
Object.freeze(SettingsMenuTypes);

const SettingsMenuTypesByName = {
    "Composite": 1,
    "SwitchMenuItem": 2,
    "IntegerMenuItem": 3,
    "FloatAsIntMenuItem": 4,
    "OptionMenuItem": 5,
    "TextMenuItem": 6,
    "SirenRingtoneMenuItem": 7,
    "VehicleCanCodeMenuItem": 8,
    "TimeMenuItem": 9,
    "SystemOutputMenuItem": 10,
    "SystemInputMenuItem": 11,
    "SystemOutputSwitchMenuItem": 12,
    "AutoStartScheduleMenuItem": 13,
    "VoltageCalibrationMenuItem": 14,
    "Inputs": 15,
    "Outputs": 16,
    "Array": 17,
    "Title": 18,
    "TimeChannel_channels": 19,
    "Instance": 20,
    "Phones": 21
};
Object.freeze(SettingsMenuTypesByName);

const SettingsMenuSirenRingtoneType = {
    0: "TONE",
    1: "MELODY"
};
Object.freeze(SettingsMenuSirenRingtoneType);

const SettingsMenuSirenRingtoneEventId = {
    1: "ARM",
    2: "DISARM",
    3: "WARNING",
    4: "ALARM"
};
Object.freeze(SettingsMenuSirenRingtoneEventId);

const SettingsMenuTextItemType = {
    1: "TEXT",
    2: "NUMBER",
    3: "PHONE"
};
Object.freeze(SettingsMenuTextItemType);

const SettingsChannelsIoPolarities = {
    1: "MINUS",
    2: "PLUS",
    3: "UNDEF"
};
Object.freeze(SettingsMenuTypes);

const SettingsChannelsIoPolaritiesByName = {
    "MINUS": 1,
    "PLUS": 2,
    "UNDEF": 3
};
Object.freeze(SettingsChannelsIoPolaritiesByName);

const SettingsChannelsIoTypes = {
    1: "Input",
    2: "Output"
};
Object.freeze(SettingsChannelsIoTypes);

const SettingsChannelsIoTypesByName = {
    "Input": 1,
    "Output": 2
};
Object.freeze(SettingsChannelsIoTypesByName);

export {
    SettingsMenuTypes,
    SettingsMenuTypesByName,
    SettingsMenuSirenRingtoneType,
    SettingsMenuSirenRingtoneEventId,
    SettingsMenuTextItemType,
    SettingsChannelsIoPolarities,
    SettingsChannelsIoPolaritiesByName,
    SettingsChannelsIoTypes,
    SettingsChannelsIoTypesByName
}