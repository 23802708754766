import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button,
} from "@mui/material";
import {
  Add as CreationTimeIcon,
  Create as UpdateTimeIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";

import { formatDate } from "../../helpers/StringUtils";
import { OpenManualStatuses } from "../../enums/OpenManualStatuses";

export default function OpenManualsTable({
  data,
  shortMode,
  handleEditManual,
}) {
  const { t } = useTranslation();

  var keys = ["#", t("Time"), t("Status"), t("Vehicle"), t("Title"), ""];

  return (
    <Table size="small">
      {shortMode ? (
        <></>
      ) : (
        <TableHead>
          <TableRow key="header">
            {keys.map((key, i) => (
              <TableCell key={i} style={{ fontWeight: "bold" }}>
                {key}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {data ? (
          data.map((manual) => (
            <React.Fragment key={manual.id}>
              <TableRow>
                {shortMode ? <></> : <TableCell>{manual.id}</TableCell>}
                {shortMode ? (
                  <></>
                ) : (
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CreationTimeIcon />
                      {formatDate(manual.creationTime)}
                    </div>
                    {manual.lastUpdateTime ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <UpdateTimeIcon />
                        {formatDate(manual.lastUpdateTime)}
                      </div>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                )}
                {shortMode ? (
                  <></>
                ) : (
                  <TableCell>{t(OpenManualStatuses[manual.status])}</TableCell>
                )}
                {shortMode ? (
                  <></>
                ) : (
                  <TableCell>
                    {`${manual.brandTitle ?? "-"} (${manual.brandId ?? "-"})`}
                    <br />
                    {`${manual.modelTitle ?? "-"} (${manual.modelId ?? "-"})`}
                    <br />
                    {`${manual.equipmentTitle ?? "-"} (${
                      manual.equipmentId ?? "-"
                    })`}
                  </TableCell>
                )}
                <TableCell
                  style={{
                    minWidth: "32ch",
                    maxWidth: "32ch",
                    wordWrap: "break-word",
                  }}
                >
                  {manual.title}
                </TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <Button
                    onClick={() => {
                      handleEditManual(manual);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {t("View")}
                  </Button>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))
        ) : (
          <></>
        )}
      </TableBody>
    </Table>
  );
}
