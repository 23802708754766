import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton,
    Typography,
    Switch
} from "@mui/material";

import {
    Publish as UploadIcon,
    Delete as DeleteIcon,
    GetApp as DownloadIcon,
    CheckCircle as ActualIcon,
    Edit as EditIcon,
    FileCopy as CopyIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next"

import { formatDate } from "../../helpers/StringUtils"

import ReactFileReader from "react-file-reader";

import styles from "./hoverableStyles.module.css"

export default function UpdateChannelFilesTable({ data, updateChannels,
    handleUploadSelect, handleDeleteClick, handleDownloadClick, handlePublishChanged,
    handleVersionClick, handleDescriptionClick, handleCopyClick }) {

    const { t } = useTranslation();
    var keys = ["ID", t("Is actual version"), t("Version"), t("Filename"), "", t("Description"), t("Update channel"), t("Creation time"), t("Is published")];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map((key, i) => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>
                            {key}
                            { i === 2 || i === 5 ? <EditIcon /> : <></>}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(row => (
                    <TableRow key={row.id}>
                        <TableCell className={styles.cell}>{row.id}</TableCell>
                        <TableCell className={styles.cell} style={{ textAlign: "center" }}>
                            <ActualIcon style={row.isActual ? { color: "#00AA00" } : null} />
                        </TableCell>
                        <TableCell className={styles.hoverableCell} onClick={() => handleVersionClick(row)}>{row.version}({row.versionInt})</TableCell>
                        <TableCell>
                            {
                                row.filename ?
                                    <>
                                        <Typography>{row.filename}</Typography>
                                    </> : <></>
                            }
                        </TableCell>
                        <TableCell className={styles.cell}>
                            {
                                row.filename ?
                                    <>
                                        <IconButton color="primary" onClick={() => handleDownloadClick(row.id)}>
                                            <DownloadIcon />
                                        </IconButton>
                                        {
                                            row.isPublished ?
                                                <></> :
                                                <IconButton color="secondary" onClick={() => handleDeleteClick(row.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                        }
                                    </> :
                                    <ReactFileReader handleFiles={(files) => handleUploadSelect(row.id, files[0])} fileTypes={["*.*"]}>
                                        <IconButton color="primary">
                                            <UploadIcon />
                                        </IconButton>
                                    </ReactFileReader>
                            }
                        </TableCell>
                        <TableCell className={styles.hoverableCell} onClick={() => handleDescriptionClick(row)}>
                            <pre style={{ maxHeight: "7ch", maxWidth: "64ch", overflowX: "hidden", overflowY: "hidden" }}>
                                {row.description}
                            </pre>
                        </TableCell>
                        <TableCell className={styles.cell}>
                            {updateChannels ? updateChannels.find(c => c.id === row.updateChannelId)?.name : row.updateChannelId}
                            <IconButton color="primary"
                                disabled={!row.filename}
                                onClick={() => handleCopyClick(row)}>
                                <CopyIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell className={styles.cell}>{formatDate(row.creationTime)}</TableCell>
                        <TableCell className={styles.cell}>
                            <Switch color="primary"
                                disabled={!row.filename}
                                checked={row.isPublished}
                                onChange={e => handlePublishChanged(row.id, e.target.checked)} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
