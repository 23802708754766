import React, { useState, useEffect, useRef, useCallback } from "react";

import { Grid, Typography, Button, Paper } from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import OrderRequestTopicsTable from "../components/Tables/OrderRequestTopicsTable";
import { useBackend } from "../context/BackendContext";
import LoadingOverlay from "react-loading-overlay-ts";
import { LanguageConverter } from "../enums/LanguageConverter";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { ResponseCode } from "../enums/ResponseCode";

export default function OrderRequestTopics() {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [tableData, setTableData] = useState([]);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const sortRows = (rows) => {
    return rows.sort((a, b) =>
      (a.order ?? a.id) > (b.order ?? b.id) ? 1 : -1
    );
  };

  const refreshTable = useCallback(
    (lang) => {
      setIsLoading(true);
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend.bckOrderRequestsListTopics(lang).then((json) => {
        setIsLoading(false);
        --updatesLockCounter.current;
        if (lastRequestId.current === id) {
          if (json.code === 0) {
            setTableData((prev) => {
              if (updatesLockCounter.current === 0) {
                return sortRows(json.content.list);
              } else {
                return prev;
              }
            });
          } else if (json.code === ResponseCode.ACCESS_DENIED) {
            setError(t("AUTH_ERROR"));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        }
      });
    },
    [t, backend]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    refreshTable(LanguageConverter[i18n.language]);
  }, [i18n.language]);

  const handleAddTopic = () => {
    backend.bckOrderRequestsCreateOrUpdateTopic({ id: null }).then((json) => {
      if (json.content) {
        setTableData((prev) => {
          let next = [json.content, ...prev];
          return sortRows(next);
        });
      }
    });
  };

  const handleClickDescription = (topic) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newDescription) => {
        let newObject = { ...topic, description: newDescription };

        setTableData((prev) => {
          let next = [...prev];
          let foundIndex = next.findIndex((x) => x.id === topic.id);
          next[foundIndex] = newObject;
          return next;
        });

        backend.bckOrderRequestsCreateOrUpdateTopic(newObject).then((json) => {
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
      initialValue: topic.description,
      textLabel: t("Description"),
      title: t("Description"),
      lines: 5,
    });
  };

  const handleClickOrder = (topic) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newOrderStr) => {
        let newOrder = parseInt(newOrderStr);
        let newObject = { ...topic, order: newOrder };

        setTableData((prev) => {
          let next = [...prev];
          let foundIndex = next.findIndex((x) => x.id === topic.id);
          next[foundIndex] = newObject;
          return sortRows(next);
        });

        backend.bckOrderRequestsCreateOrUpdateTopic(newObject).then((json) => {
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
      initialValue: topic.order ? topic.order.toString() : "0",
      textLabel: t("Order"),
      title: t("Order"),
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 4);
      },
    });
  };

  const handleClickTitle = (topic) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newTitle) => {
        let newObject = { ...topic, title: newTitle };

        setTableData((prev) => {
          let next = [...prev];
          let foundIndex = next.findIndex((x) => x.id === topic.id);
          next[foundIndex] = newObject;
          return next;
        });

        backend.bckOrderRequestsCreateOrUpdateTopic(newObject).then((json) => {
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
      initialValue: topic.title,
      textLabel: t("Title"),
      title: t("Title"),
    });
  };

  const handleClickDelete = (topic) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete topic?", { id: topic.id }),
      handleConfirm: () => {
        setTableData((prev) => {
          let next = [...prev];
          let targetIndex = next.findIndex((r) => r.id === topic.id);
          next.splice(targetIndex, 1);
          return next;
        });
        backend.bckOrderRequestsRemoveTopic(topic.id).then((json) => {
          if (json.code === 0) {
          } else {
            setError(json.message);
          }
        });
      },
    });
  };

  return (
    <LoadingOverlay active={isLoading} spinner>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("Order request topics table")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          <Grid item container spacing={2}>
            <Grid item>
              <Button
                onClick={handleAddTopic}
                variant="contained"
                color="primary"
              >
                {t("Add topic")}
              </Button>
            </Grid>
          </Grid>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            <OrderRequestTopicsTable
              data={tableData}
              handleClickDescription={handleClickDescription}
              handleClickOrder={handleClickOrder}
              handleClickTitle={handleClickTitle}
              handleClickDelete={handleClickDelete}
            />
          </div>
        </Grid>
      </Paper>
    </LoadingOverlay>
  );
}
