import React from "react";

import {
    IconButton
} from "@mui/material";

import {
    Edit as EditIcon,
    LinkOff as DuplicateIcon,
    Link as LinkIcon
} from "@mui/icons-material";

export default function MenuEditDuplicate({ duplicateDisabled, handleEditClick, handleDuplicateClick, handleLinkClick }) {
    return (
        <>
            <IconButton
                color="primary"
                onClick={handleEditClick}
            >
                <EditIcon />
            </IconButton>
            <IconButton
                color="primary"
                onClick={handleLinkClick}
            >
                <LinkIcon />
            </IconButton>
            <IconButton
                disabled={duplicateDisabled}
                color="primary"
                onClick={handleDuplicateClick}
            >
                <DuplicateIcon />
            </IconButton>
        </>);
}
