import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  IconButton,
  Snackbar,
  Menu,
  MenuItem,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";

import Widget from "../../components/Widget/Widget";
import UsersTable from "../../components/Tables/UsersTable";
import CreateUserDialog from "../../components/Dialogs/CreateUserDialog";
import { useInterval } from "../../useInterval";
import {
  Refresh as RefreshIcon,
  AddCircle as AddCircleIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import {
  UserRolesIntToString,
  UserRolesStringToInt,
} from "../../enums/UserRoles";
import {
  useDialogContext,
  DialogActionTypes,
} from "../../context/DialogContext";
import AdvancedTablePagination from "../../components/AdvancedTablePagination";
import { useBackend } from "../../context/BackendContext";
import { GenerateInteger } from "../../helpers/RandomGenerator";
import { ResponseCode } from "../../enums/ResponseCode";
import {
  SettingName,
  settingRepositoryLoadInt,
  settingRepositorySave,
} from "../../settingRepository";
import { useNavigate } from "react-router";

export default function ManageUsers() {
  const navigate = useNavigate();
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t } = useTranslation();
  const [isInitializing, setIsInitializing] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menuTarget, setMenuTarget] = useState("");
  const [error, setError] = useState("");
  const [table, setTable] = useState([]);
  const [isUserCreateOpened, setUserCreateOpened] = useState(false);
  const [isSnackOpened, setSnackOpened] = useState(false);
  const [searchLogin, setSearchLogin] = useState("");
  const [searchLoginInput, setSearchLoginInput] = useState("");
  const [searchRoles, setSearchRoles] = useState([]);
  const [searchRolesInput, setSearchRolesInput] = useState([]);
  const [totalUsers, setTotalUsers] = useState(100);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshTable = useCallback(
    (searchLogin, searchRoles, pageNumber, pageSize) => {
      var id = GenerateInteger();
      lastRequestId.current = id;
      ++updatesLockCounter.current;
      backend
        .bckUsersListAll(searchLogin, searchRoles, pageNumber + 1, pageSize)
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTotalUsers((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.count;
                } else {
                  return prev;
                }
              });
              setTable((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.list;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }

          if (isInitializing) {
            setIsInitializing(false);
            setIsLoading(false);
          }
        });
    },
    [t, backend]
  );

  const handleSearch = () => {
    setPageNumber(0);
    settingRepositorySave(SettingName.USERS_PAGE, 0);
    setSearchLogin(searchLoginInput);
    setSearchRoles(searchRolesInput);
    refreshTable(searchLoginInput, searchRolesInput, 0, pageSize);
  };

  const handleSearchLoginChange = (e) => {
    let newValue = e.target.value.slice(0, 48);
    setSearchLoginInput(newValue);
  };

  const onSearchRolesChanged = (...params) => {
    setSearchRolesInput(params[1]);
  };

  const handleChangePage = (event, newPage) => {
    settingRepositorySave(SettingName.USERS_PAGE, newPage);
    setPageNumber(newPage);
    refreshTable(searchLogin, searchRoles, newPage, pageSize, isInitializing);
  };

  const handleChangeRowsPerPage = (event) => {
    var newPageSize = parseInt(event.target.value, 10);
    settingRepositorySave(SettingName.USERS_PAGE_SIZE, newPageSize);
    setPageSize(newPageSize);
    setPageNumber(0);
    settingRepositorySave(SettingName.USERS_PAGE, 0);
    refreshTable(searchLogin, searchRoles, 0, newPageSize, isInitializing);
  };

  useEffect(() => {
    if (isInitializing) {
      setIsInitializing(false);

      let pageSize = settingRepositoryLoadInt(SettingName.USERS_PAGE_SIZE, 10);
      setPageSize(pageSize);

      let pageNumber = settingRepositoryLoadInt(SettingName.USERS_PAGE, 0);
      setPageNumber(pageNumber);

      refreshTable("", [], pageNumber, pageSize);
    }
  }, [isInitializing, refreshTable]);

  useInterval(() => {
    if (updatesLockCounter.current === 0) {
      refreshTable(searchLogin, searchRoles, pageNumber, pageSize);
    }
  }, 2000);

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    if (!!error) {
      setSnackOpened(true);
    }
  }, [error]);

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleSnackClosed = () => {
    setError(null);
    setSnackOpened(false);
  };

  const handleUserCreate = () => {
    setUserCreateOpened(false);
  };

  const handleUserCreateClose = () => {
    setUserCreateOpened(false);
  };

  const onButtonDelete = (login) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete user?", { user: login }),
      handleConfirm: () => {
        ++updatesLockCounter.current;
        setTable((prev) => {
          var foundIndex = prev.findIndex((x) => x.login === login);
          prev.splice(foundIndex, 1);
          return prev;
        });

        backend.bckUsersDeleteUser(login).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const onButtonChangeRole = (event, login) => {
    setMenuAnchor(event.currentTarget);
    setMenuTarget(login);
  };

  const onMenuSelected = (role) => () => {
    setMenuAnchor(null);
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Change user role?", { user: menuTarget }),
      handleConfirm: () => {
        ++updatesLockCounter.current;
        setTable((prev) => {
          var foundIndex = prev.findIndex((x) => x.login === menuTarget);
          prev[foundIndex].role = role;
          return prev;
        });
        backend.bckUsersSetRole(menuTarget, role).then((json) => {
          --updatesLockCounter.current;
          if (json.code === 0) {
          } else if (json.code === 3) {
            setError(json.message);
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const onPressChangeVisibility = (event, login) => {
    navigate(`/app/manageAccount?login=${login}`);
  };

  const handleClear = () => {
    setSearchLogin("");
    setSearchLoginInput("");
    setSearchRoles([]);
    setSearchRolesInput([]);
    refreshTable("", [], pageNumber, pageSize);
  };

  return isLoading ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            noBodyPadding
            header={
              <React.Fragment>
                <Typography variant="h5" color="textSecondary">
                  {t("Users table")}
                </Typography>
                <div>
                  <IconButton onClick={() => setUserCreateOpened(true)}>
                    <AddCircleIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setIsLoading(true);
                      setIsInitializing(true);
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </div>
              </React.Fragment>
            }
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexWrap: "wrap",
                marginLeft: "16px",
              }}
            >
              <TextField
                label={t("Search by login/phone")}
                value={searchLoginInput}
                onChange={handleSearchLoginChange}
                style={{
                  minWidth: "30ch",
                  maxWidth: "30ch",
                  marginTop: "12px",
                  marginRight: "12px",
                  marginBottom: "16px",
                }}
              />
              <div style={{ marginBottom: "16px" }}>
                <Autocomplete
                  style={{
                    marginRight: "12px",
                    marginTop: "8px",
                    width: "48ch",
                  }}
                  multiple
                  value={searchRolesInput}
                  options={Object.keys(UserRolesIntToString).map((k) =>
                    parseInt(k)
                  )}
                  onChange={onSearchRolesChanged}
                  getOptionLabel={(option) => t(UserRolesIntToString[option])}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Role")}
                      placeholder={t("Role")}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div style={{ marginBottom: "16px" }}>
                <Button
                  onClick={handleSearch}
                  style={{ marginRight: "16px" }}
                  variant="contained"
                  color="primary"
                >
                  {t("Search")}
                </Button>
                <Button
                  onClick={handleClear}
                  style={{ marginRight: "16px" }}
                  variant="contained"
                  color="primary"
                >
                  {t("Reset parameters")}
                </Button>
              </div>
            </div>

            <AdvancedTablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              totalRows={totalUsers}
              pageSize={pageSize}
              pageNumber={pageNumber}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              <UsersTable
                data={table}
                onPressDelete={onButtonDelete}
                onPressChangeRole={onButtonChangeRole}
                onPressChangeVisibility={onPressChangeVisibility}
              />
            </AdvancedTablePagination>
          </Widget>
        </Grid>
      </Grid>

      <CreateUserDialog
        isUserCreateOpened={isUserCreateOpened}
        handleUserCreate={handleUserCreate}
        handleUserCreateClose={handleUserCreateClose}
      />

      <Menu
        keepMounted
        open={menuAnchor !== null}
        onClose={handleMenuClose}
        anchorEl={menuAnchor}
      >
        <MenuItem style={{ display: "none" }} />
        {Object.keys(UserRolesStringToInt).map((k) => (
          <MenuItem key={k} onClick={onMenuSelected(UserRolesStringToInt[k])}>
            {t(k)}
          </MenuItem>
        ))}
      </Menu>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isSnackOpened}
        autoHideDuration={6000}
        onClose={handleSnackClosed}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{error}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleSnackClosed}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
}
