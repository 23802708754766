import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Typography,
    Grid
} from "@mui/material";

import { useTranslation } from "react-i18next"

export default function CreateQuestionDialog({ isOpened, subparts, question, handleConfirm, handleClose }) {
    const { t } = useTranslation();
    const [parentSubpartId, setParentSubpartId] = useState(-1);
    const [algorithm, setAlgorithm] = useState("");
    const [expectedResult, setExpectedResult] = useState("");
    const [subpartsList, setSubpartsList] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [newAnswerText, setNewAnswerText] = useState("");
    const [newAnswerValue, setNewAnswerValue] = useState("");

    const handleAddAnswer = () => {
        var newValueInt = parseInt(newAnswerValue);
        if (answers.findIndex((a) => a.value === newValueInt) !== -1) {
            alert(t("Answer already exists"));
            return;
        }
        var newAnswer = {};
        newAnswer.answer = newAnswerText;
        newAnswer.value = newValueInt;
        setAnswers((prev) => { prev.push(newAnswer); return [...prev]; });
        setNewAnswerText("");
        setNewAnswerValue(0);
    };

    const handleDeleteAnswer = (answerValue) => {
        var id = answers.findIndex((a) => a.value === answerValue);
        if (id === -1) return;
        setAnswers((prev) => {
            prev.splice(id, 1);
            return [...prev];
        });
    }

    useEffect(() => {
        setAlgorithm(question.algorithm);
        setExpectedResult(question.expectedResult);
        setParentSubpartId(question.parentSubpartId);
        if (question.answers === undefined || question.answers === null) {
            setAnswers([]);
        } else {
            setAnswers(question.answers.slice(0));
        }
    }, [question]);

    useEffect(() => {
        var listElements = subparts.map(subpart => <option key={subpart.id} value={subpart.id}>{subpart.name}</option>)
        if (listElements.length === 0) {
            setParentSubpartId(-2);
            setSubpartsList(<option key={-2} value={-2}>{t("No available subparts")}</option>);
        } else {
            listElements.unshift(<option key={-1} value={-1}></option>)
            setSubpartsList(listElements);
        }
    }, [subparts, t]);

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{t("Checklist question")}</DialogTitle>
            <DialogContent>
                <FormControl>
                    <InputLabel htmlFor="car-equipment-select-label">{t("Parent subpart")}</InputLabel>
                    <Select
                        native
                        label={t("Parent subpart")}
                        value={parentSubpartId}
                        onChange={(e) => setParentSubpartId(e.target.value)}
                        inputProps={{
                            id: "car-equipment-select-label",
                        }}
                    >
                        {subpartsList}
                    </Select>
                </FormControl>
                <TextField
                    autoFocus
                    margin="dense"
                    id="algorithm"
                    label={t("Algorithm")}
                    value={algorithm}
                    onChange={e => setAlgorithm(e.target.value)}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows="7"
                    style={{ "marginTop": "12px" }}
                />
                <TextField
                    margin="dense"
                    id="result"
                    label={t("Expected result")}
                    value={expectedResult}
                    onChange={e => setExpectedResult(e.target.value)}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows="7"
                    style={{ "marginTop": "12px" }}
                />
                <Typography style={{ "marginTop": "12px" }}>{t("Answers")}</Typography>
                <Grid container direction="row" spacing={2} alignItems="center">
                    <Grid item xs>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="newAnswerText"
                            label={t("Answer")}
                            value={newAnswerText}
                            onChange={e => setNewAnswerText(e.target.value)}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="newAnswerValue"
                            label={t("Value")}
                            value={newAnswerValue}
                            type="number"
                            onChange={e => setNewAnswerValue(e.target.value)}
                            fullWidth
                            />
                    </Grid>
                    <Grid item xs>
                        <Button disabled={newAnswerText === undefined || newAnswerText === null || newAnswerText.trim() === ""
                            || newAnswerValue === undefined || newAnswerValue === null || newAnswerValue < 0}
                            onClick={handleAddAnswer} color="primary" variant="contained" style={{ "marginTop": "12px" }}>
                            {t("Add")}
                        </Button>
                    </Grid>
                </Grid>
                <>
                {
                    answers.map(a =>
                        <Grid key={a.value} container direction="row" spacing={2}>
                            <Grid item xs>
                                <TextField
                                    margin="dense"
                                    label={t("Answer")}
                                    value={a.answer}
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    margin="dense"
                                    label={t("Value")}
                                    value={a.value}
                                    type="number"
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs>
                                <Button onClick={() => handleDeleteAnswer(a.value)} color="primary" variant="contained" style={{ "marginTop": "12px" }}>
                                    {t("Delete")}
                                </Button>
                            </Grid>
                        </Grid>
                    )
                    }
                </>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button disabled={algorithm === undefined || algorithm === null || algorithm.trim() === ""
                    || expectedResult === undefined || expectedResult === null || expectedResult.trim() === ""
                    || parentSubpartId < 0}
                    onClick={() => { handleConfirm(question.id, algorithm, expectedResult, answers, parentSubpartId); }} color="primary" autoFocus>
                    {t("Save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
