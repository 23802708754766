import React from "react";
import {
    Typography,
    IconButton,
    Tooltip
} from "@mui/material";

import {
    Info as InfoIcon,
    FormatListBulleted as LogsIcon,
    Bookmark as FavouriteIcon,
    SettingsRemote as RemoteMountIcon,
    Check as NotReadIcon,
    DoneAll as ReadIcon
} from "@mui/icons-material";

import { formatDate } from "../helpers/StringUtils";
import { useTranslation } from "react-i18next";

import AdvancedAvatarBadge from "./AdvancedAvatarBadge";

export default function ChatRoomHeader({ room, fioMode, handleShowUserInfo, handleShowUserLogs, handleClickFavourite, handleSendMountEventClick }) {
    const { t } = useTranslation();

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", flexWrap: 0 }}>
            <div style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
                <div>
                    <AdvancedAvatarBadge
                        avatarKey={0}
                        isUserOnline={room?.isOnline}
                        userId={room?.ownerId}
                        userMail={room?.login}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "12px" }}>
                    {
                        fioMode ?
                            <>
                                <Typography variant="h6">{room ? room.fio : ""}</Typography>
                                {
                                    room && room.lastOnline ?
                                        <Typography variant="caption">
                                            {`${t("Last online")} ${formatDate(room.lastOnline)}`}
                                        </Typography> : <></>
                                }
                                <Typography variant="body2" noWrap style={{ fontStyle: 'italic', textOverflow: 'ellipsis', maxWidth: "32ch" }}>{room && room.login ? room.login : ""}</Typography>
                            </>
                            :
                            <>
                                <Typography variant="h6">{room ? room.login : ""}</Typography>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Typography variant="body2" noWrap style={{
                                        fontStyle: 'italic',
                                        textOverflow: "ellipsis",
                                        maxWidth: "64ch",
                                        paddingRight: "12px"
                                    }}>
                                        {room && room.lastMessage ? `${room.lastMessageSenderLogin}: ${room.lastMessage}` : ""}
                                    </Typography>
                                    {
                                        room && room.lastMessage ? <>
                                            {
                                                room.isLastMessageRead ? <ReadIcon style={{ color: "rgb(79, 195, 247)" }} /> : <NotReadIcon />
                                            }
                                        </> : <></>
                                    }
                                </div>
                            </>
                    }
                </div>
            </div>
            {
                fioMode ?
                    <div>
                        <IconButton
                            color="primary"
                            disabled={!room || !room.userInfo}
                            onClick={handleSendMountEventClick}
                        >
                            <Tooltip title={t("Send mount event")}>
                                <RemoteMountIcon />
                            </Tooltip>
                        </IconButton>
                        <IconButton
                            color="primary"
                            disabled={!room || !room.userInfo}
                            onClick={() => handleShowUserInfo(room)}
                        >
                            <Tooltip title={t("User info tooltip")}>
                                <InfoIcon />
                            </Tooltip>
                        </IconButton>
                        <IconButton
                            color="primary"
                            disabled={!room || !room.login}
                            onClick={() => handleShowUserLogs(room)}
                        >
                            <Tooltip title={t("User logs tooltip")}>
                                <LogsIcon />
                            </Tooltip>
                        </IconButton>
                        <IconButton color={room.isFavourite ? "primary" : "default"} onClick={(e) => {
                            e.stopPropagation();
                            handleClickFavourite(room);
                        }
                        }>
                            <FavouriteIcon />
                        </IconButton>
                    </div>
                    : <></>
            }
        </div>
    );
}
