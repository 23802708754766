import React from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { saveFile } from "../../helpers/FileSaver";

export default function FullScreenImageDialog({ imageUrl, imageName, isOpened, handleClose }) {
    const { t } = useTranslation();

    const handleConfirm = (e) => {
        saveFile(imageUrl, imageName);
        handleClose();
    };

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullScreen={true}
        >
            <DialogContent style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                <span style={{ display: "inline-block", height: "100%", verticalAlign: "middle" }}></span>
                <img src={imageUrl} style={{ verticalAlign: "middle" }} alt={t("Loading")} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    {t("Download")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
