import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  Button,
  TextField,
  Divider,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Edit as EditIcon,
  AddCircle as AddFileIcon,
  RemoveCircle as RemoveFileIcon,
  Upload as UploadFileIcon,
  PictureAsPdf as PdfIcon,
} from "@mui/icons-material";

import {
  DialogActionTypes,
  useDialogContext,
} from "../../context/DialogContext";
import { useBackend } from "../../context/BackendContext";
import AdvancedAvatarBadge from "../../components/AdvancedAvatarBadge";
import { LanguageConverter } from "../../enums/LanguageConverter";

import ImageUpload from "../../images/ImageUpload.jpg";

import ReactFileReader from "react-file-reader";

export default function OpenManualRequestFull({ headerSetter }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [requestId, setRequestId] = useState(0);
  const [viewOnlyMode, setViewOnlyMode] = useState(false);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [request, setRequest] = useState({});

  useEffect(() => {
    let params = queryString.parse(location.search);
    if (params.requestId) {
      setRequestId(params.requestId);
    }

    if (params.viewOnly === "true") {
      setViewOnlyMode(true);
    } else {
      setViewOnlyMode(false);
    }
  }, [location]);

  useEffect(() => {
    backend.bckCarsListBrands(false, true, false).then((json) => {
      let sortedElements = json.content.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      setBrands(sortedElements);
    });
  }, [backend]);

  useEffect(() => {
    if (request.brandId && request.brandId !== -1) {
      backend.bckCarsListModels(request.brandId, false, true).then((json) => {
        let sortedElements = json.content.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        setModels(sortedElements);
      });
    } else {
      setModels([]);
    }
    setEquipments([]);
  }, [backend, request.brandId]);

  useEffect(() => {
    if (request.modelId && request.modelId !== -1) {
      backend
        .bckCarsListEquipments(
          request.modelId,
          null,
          LanguageConverter[i18n.language]
        )
        .then((json) => {
          let sortedElements = json.content.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          setEquipments(sortedElements);
        });
    } else {
      setEquipments([]);
    }
  }, [backend, request.modelId, i18n.language]);

  useEffect(() => {
    if (requestId) {
      setIsLoading(true);
      headerSetter(t("Request") + ` #${requestId}`);
      backend
        .bckOpenManualRequestsList(requestId, null, null, null, 1, 1)
        .then((json) => {
          setIsLoading(false);
          if (json.code === 0) {
            let request = json.content.list[0];
            setRequest(request);
          }
        });
    }
  }, [backend, requestId, headerSetter, t]);

  const updateRequest = useCallback(
    (newRequest) => {
      return backend
        .bckOpenManualRequestCreateOrUpdate({ ...newRequest, id: requestId })
        .then((json) => {
          if (json.code === 0) {
            setRequest((prev) => ({ ...prev, ...newRequest }));
          }
        });
    },
    [backend, requestId]
  );

  const handleEditTitle = useCallback(() => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (title) => {
        updateRequest({ title: title });
      },
      initialValue: request.title,
      textLabel: t("Title"),
      title: t("Title"),
    });
  }, [dialogDispatch, request.title, t, updateRequest]);

  const handleEditDescription = useCallback(() => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (description) => {
        updateRequest({ description: description });
      },
      initialValue: request.description,
      textLabel: t("Description"),
      title: t("Description"),
      lines: 4,
    });
  }, [dialogDispatch, request.description, t, updateRequest]);

  const handleSetBrand = useCallback(
    (e) => {
      updateRequest({ brandId: e.target.value, modelId: -1, equipmentId: -1 });
    },
    [updateRequest]
  );

  const handleSetModel = useCallback(
    (e) => {
      updateRequest({ modelId: e.target.value, equipmentId: -1 });
    },
    [updateRequest]
  );

  const handleSetEquipment = useCallback(
    (e) => {
      updateRequest({ equipmentId: e.target.value });
    },
    [updateRequest]
  );

  const updateFile = useCallback(
    (newFile) => {
      backend.bckOpenManualRequestFileCreateOrUpdate(newFile).then((json) => {
        if (json.code === 0) {
          setRequest((prev) => {
            let newFiles = [...prev.files];
            let foundIndex = newFiles.findIndex((x) => x.id === newFile.id);
            newFiles[foundIndex] = newFile;
            return { ...prev, files: newFiles };
          });
        }
      });
    },
    [backend]
  );

  const handleEditFileDescription = useCallback(
    (file) => {
      dialogDispatch({
        type: DialogActionTypes.SimpleTextDialogOpen,
        handleConfirm: (description) => {
          let newFile = { ...file, description };
          updateFile(newFile);
        },
        initialValue: file.description,
        textLabel: t("Description"),
        title: t("Description"),
        lines: 4,
      });
    },
    [t, dialogDispatch, updateFile]
  );

  const handleAddFile = useCallback(() => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Add new file?"),
      handleConfirm: () => {
        let newOrder = Math.max(...request.files.map((f) => f.order)) + 1;
        backend
          .bckOpenManualRequestFileCreateOrUpdate({
            requestId: requestId,
            order: newOrder,
          })
          .then((json) => {
            if (json.code === 0) {
              setRequest((prev) => {
                let newFiles = [
                  ...prev.files,
                  {
                    id: json.content,
                    requestId: requestId,
                    order: newOrder,
                    description: "",
                  },
                ];
                return { ...prev, files: newFiles };
              });
            }
          });
      },
    });
  }, [requestId, backend, dialogDispatch, request.files, t]);

  const handleDeleteFile = useCallback(
    (file) => {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Delete file?"),
        handleConfirm: () => {
          backend.bckOpenManualRequestFileRemove(file.id).then((json) => {
            if (json.code === 0) {
              setRequest((prev) => {
                let newFiles = [...prev.files];
                let foundIndex = newFiles.findIndex((x) => x.id === file.id);
                newFiles.splice(foundIndex, 1);
                return { ...prev, files: newFiles };
              });
            }
          });
        },
      });
    },
    [backend, dialogDispatch, t]
  );

  const handleUploadFileAccepted = useCallback(
    (fileRecord, file) => {
      if (file.size > 32 * 1024 * 1024) {
        alert(t("File is too large"));
        return;
      }

      backend
        .bckFilesUploadOpenManualRequestFile(fileRecord.id, file)
        .then((json) => {
          if (json.code === 0) {
            setRequest((prev) => {
              let newFiles = [...prev.files];
              let foundIndex = newFiles.findIndex(
                (x) => x.id === fileRecord.id
              );
              let newFile = { ...newFiles[foundIndex], url: json.content.url };
              newFiles[foundIndex] = newFile;
              return { ...prev, files: newFiles };
            });
          }
        });
    },
    [backend, t]
  );

  const handleUploadFile = useCallback(
    (fileRecord, file) => {
      if (fileRecord.url) {
        dialogDispatch({
          type: DialogActionTypes.ConfirmationDialogOpen,
          userMessage: t("Overwrite file?"),
          handleConfirm: () => {
            handleUploadFileAccepted(fileRecord, file);
          },
        });
      } else {
        handleUploadFileAccepted(fileRecord, file);
      }
    },
    [dialogDispatch, handleUploadFileAccepted, t]
  );

  const changeStatus = useCallback(
    (status) => {
      dialogDispatch({
        type: DialogActionTypes.SimpleTextDialogOpen,
        handleConfirm: (comment) => {
          return backend
            .bckOpenManualRequestCreateOrUpdate({
              id: requestId,
              status: status,
              comment: comment,
            })
            .then((json) => {
              if (json.code === 0) {
                navigate(-1);
              }
            });
        },
        initialValue: "",
        textLabel: t("Comment"),
        title: t("Comment"),
        lines: 4,
      });
    },
    [backend, dialogDispatch, navigate, requestId, t]
  );

  const checkApprovalConditions = useCallback(
    (onSuccess) => {
      let errorMessages = [];
      if (!request.title) errorMessages.push(t("Request title is empty"));
      if (!request.description)
        errorMessages.push(t("Request description is empty"));
      if (!request.brandId)
        errorMessages.push(t("Request brand is not specified"));
      if (!request.modelId)
        errorMessages.push(t("Request model is not specified"));
      if (!request.equipmentId)
        errorMessages.push(t("Request equipment is not specified"));
      if (request.files.some((f) => !f.filename))
        errorMessages.push(t("There are file records without uploaded files"));
      if (request.files.some((f) => !f.description))
        errorMessages.push(t("There are file records without description"));

      if (errorMessages.length > 0) {
        dialogDispatch({
          type: DialogActionTypes.SimpleOutputDialogOpen,
          title: t("Request is not finished"),
          text: errorMessages.map((str) => `-${str}\r\n`).join(""),
          textLabel: "",
          noTextField: true,
        });
      } else {
        onSuccess();
      }
    },
    [
      dialogDispatch,
      request.brandId,
      request.description,
      request.equipmentId,
      request.files,
      request.modelId,
      request.title,
      t,
    ]
  );

  const handleApprove = useCallback(() => {
    checkApprovalConditions(() => {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Approve request?"),
        handleConfirm: () => {
          return backend
            .bckOpenManualRequestCreateOrUpdate({
              id: requestId,
              status: 4,
            })
            .then((json) => {
              if (json.code === 0) {
                navigate(-1);
              }
            });
        },
      });
    });
  }, [
    backend,
    checkApprovalConditions,
    dialogDispatch,
    navigate,
    requestId,
    t,
  ]);

  const handleToRevision = useCallback(() => {
    changeStatus(6);
  }, [changeStatus]);

  const handleDecline = useCallback(() => {
    changeStatus(5);
  }, [changeStatus]);

  const handleCopyToManuals = useCallback(() => {
    checkApprovalConditions(() => {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Copy to open manuals") + "?",
        handleConfirm: () => {
          return backend
            .bckOpenManualCreateFromRequest(requestId)
            .then((json) => {
              if (json.code === 0) {
                navigate(`/app/openManuals/full?manualId=${json.content}`);
              }
            });
        },
      });
    });
  }, [
    backend,
    checkApprovalConditions,
    dialogDispatch,
    navigate,
    requestId,
    t,
  ]);

  return isLoading || request.id === undefined || request.id === null ? (
    <Grid
      container
      spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "80vh" }}
    >
      <CircularProgress size={170} />
    </Grid>
  ) : (
    <>
      <Paper style={{ overflow: "auto", padding: "12px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <AdvancedAvatarBadge
            avatarKey={0}
            isUserOnline={false}
            userId={request.authorId}
            userMail={request.authorEmail}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "12px",
            }}
          >
            <Typography variant="h3">
              {request.authorName} ({request.authorEmail})
            </Typography>
            <Typography variant="h3">{request.authorPhone}</Typography>
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            variant="contained"
            color="primary"
            style={{
              marginRight: "24px",
              marginBottom: "8px",
              width: "24ch",
            }}
            onClick={handleCopyToManuals}
          >
            {t("Copy to open manuals")}
          </Button>
        </div>
        <div
          style={{
            marginTop: "12px",
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginRight: "24px", flexGrow: 1, minWidth: "52ch" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                label={t("Title")}
                margin="dense"
                value={request.title ?? ""}
                disabled
                fullWidth
              />
              <IconButton
                color="primary"
                onClick={handleEditTitle}
                disabled={viewOnlyMode}
              >
                <EditIcon />
              </IconButton>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                label={t("Description")}
                margin="dense"
                value={request.description ?? ""}
                disabled
                multiline
                rows="4"
                fullWidth
              />
              <IconButton
                color="primary"
                onClick={handleEditDescription}
                disabled={viewOnlyMode}
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>
          <div style={{ display: "flex", flexFlow: "column" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                marginRight: "24px",
                marginBottom: "8px",
                width: "24ch",
              }}
              disabled={viewOnlyMode || request.status !== 3}
              onClick={handleApprove}
            >
              {t("Approve")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                marginRight: "24px",
                marginBottom: "8px",
                width: "24ch",
              }}
              disabled={viewOnlyMode || request.status !== 3}
              onClick={handleToRevision}
            >
              {t("To revision")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "24px", width: "24ch" }}
              disabled={viewOnlyMode || request.status !== 3}
              onClick={handleDecline}
            >
              {t("Decline")}
            </Button>
          </div>
        </div>
        {request.comment ? (
          <Typography>
            <b>{t("Comment")}: </b>
            {request.comment}
          </Typography>
        ) : (
          <></>
        )}
        <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <TextField
              label={t("Car brand")}
              margin="dense"
              value={request.brandText ?? ""}
              style={{ marginBottom: "8px" }}
              disabled
            />
            <TextField
              label={t("Car model")}
              margin="dense"
              value={request.modelText ?? ""}
              style={{ marginBottom: "8px" }}
              disabled
            />
            <TextField
              label={t("Equipment")}
              margin="dense"
              value={request.equipmentText ?? ""}
              disabled
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "8px",
              marginRight: "8px",
              flex: 1,
            }}
          >
            <FormControl
              size="small"
              margin="dense"
              style={{ marginBottom: "8px" }}
            >
              <InputLabel>{t("Car brand")}</InputLabel>
              <Select
                value={
                  request.brandId && request.brandId !== -1
                    ? request.brandId
                    : ""
                }
                label={t("Car brand")}
                onChange={handleSetBrand}
              >
                {brands.map((brand) => (
                  <MenuItem key={brand.id} value={brand.id}>
                    {brand.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {models && models.length > 0 ? (
              <FormControl
                size="small"
                margin="dense"
                style={{ marginBottom: "8px" }}
              >
                <InputLabel>{t("Car model")}</InputLabel>
                <Select
                  value={
                    request.modelId && request.modelId !== -1
                      ? request.modelId
                      : ""
                  }
                  label={t("Car model")}
                  onChange={handleSetModel}
                >
                  {models.map((model) => (
                    <MenuItem key={model.id} value={model.id}>
                      {model.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <></>
            )}
            {equipments && equipments.length > 0 ? (
              <FormControl size="small" margin="dense">
                <InputLabel>{t("Equipment")}</InputLabel>
                <Select
                  value={
                    request.equipmentId && request.equipmentId !== -1
                      ? request.equipmentId
                      : ""
                  }
                  label={t("Equipment")}
                  onChange={handleSetEquipment}
                >
                  {equipments.map((equipment) => (
                    <MenuItem key={equipment.id} value={equipment.id}>
                      {equipment.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 2,
            }}
          >
            <TextField
              label={t("Email")}
              margin="dense"
              value={request.authorEmail ?? ""}
              style={{ marginBottom: "8px" }}
              disabled
            />
            <TextField
              label={t("Full name")}
              margin="dense"
              value={request.authorName ?? ""}
              style={{ marginBottom: "8px" }}
              disabled
            />
            <TextField
              label={t("Phone")}
              margin="dense"
              value={request.authorPhone ?? ""}
              disabled
            />
          </div>
        </div>
        <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography variant="h3">{t("Files")}</Typography>
          {request.files.map((f) => (
            <div
              key={f.id}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <div>
                <IconButton
                  color="secondary"
                  onClick={() => handleDeleteFile(f)}
                  disabled={viewOnlyMode}
                >
                  <RemoveFileIcon />
                </IconButton>
              </div>
              <div
                style={{
                  width: "24ch",
                  height: "24ch",
                  flexShrink: 0,
                  maxHeight: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  if (f.url) window.open(f.url, `_blank`);
                }}
              >
                {f.url ? (
                  f.url.endsWith(".pdf") ? (
                    <PdfIcon
                      style={{
                        fontSize: 120,
                        width: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  ) : (
                    <img
                      src={f.url}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      alt=""
                    />
                  )
                ) : (
                  <img
                    src={ImageUpload}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    alt=""
                  />
                )}
              </div>
              <div>
                <ReactFileReader
                  handleFiles={(files) => handleUploadFile(f, files[0])}
                  fileTypes={["image/png", "image/jpeg", "application/pdf"]}
                >
                  <IconButton color="primary" disabled={viewOnlyMode}>
                    <UploadFileIcon />
                  </IconButton>
                </ReactFileReader>
              </div>
              <TextField
                label={t("Description")}
                margin="dense"
                value={f.description ?? ""}
                disabled
                multiline
                rows="3"
                fullWidth
              />
              <IconButton
                color="primary"
                onClick={() => handleEditFileDescription(f)}
                disabled={viewOnlyMode}
              >
                <EditIcon />
              </IconButton>
            </div>
          ))}
          <div>
            <IconButton
              color="primary"
              onClick={handleAddFile}
              disabled={viewOnlyMode}
            >
              <AddFileIcon />
            </IconButton>
          </div>
        </div>
      </Paper>
    </>
  );
}
