import React from "react";

import { Grid, Button } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useBackend } from "../../context/BackendContext";
import {
  DialogActionTypes,
  useDialogContext,
} from "../../context/DialogContext";
import SettingsWireColorsTable from "../../components/Tables/SettingsWireColorsTable";

export default function WireColors({ data, setData, setError }) {
  const backend = useBackend();
  const dialogDispatch = useDialogContext();
  const { t } = useTranslation();

  const saveWireColor = (newWireColor) => {
    backend.bckSettingsCreateOrUpdateWireColor(newWireColor).then((json) => {
      if (json.code === 0) {
        newWireColor.Id = json.content;
        setData((prev) => {
          let newWireColors = { ...prev.WireColors };
          newWireColors[newWireColor.Id] = newWireColor;
          let next = { ...prev, WireColors: newWireColors };
          return next;
        });
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleAddWireColor = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        let newWireColor = {
          Id: null,
          Name: name,
        };
        saveWireColor(newWireColor);
      },
      initialValue: "",
      textLabel: t("Name"),
      title: t("Add wire color"),
    });
  };

  const handleRemoveWireColor = (wireColor) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Remove wire color?", { title: wireColor.Name }),
      handleConfirm: () => {
        backend.bckSettingsRemoveWireColor(wireColor.Id).then((json) => {
          if (json.code === 0) {
            setData((prev) => {
              let newWireColors = { ...prev.WireColors };
              delete newWireColors[wireColor.Id];
              let next = { ...prev, WireColors: newWireColors };
              return next;
            });
          } else if (json.code === 3) {
            setError(t(json.message));
          } else {
            setError(t("REQUEST_ERROR"));
          }
        });
      },
    });
  };

  const updateWireColor = (wireColor, callback) => {
    let newWireColor = { ...wireColor };
    callback(newWireColor);
    saveWireColor(newWireColor);
  };

  const handleEditName = (wireColor) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (name) => {
        updateWireColor(wireColor, (newWireColor) => {
          newWireColor.Name = name;
        });
      },
      initialValue: wireColor.Name,
      textLabel: t("Name"),
      title: t("Name"),
    });
  };

  const handleEditColorN = (wireColor, n) => {
    let fieldName = `ColorId${n}`;
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: wireColor[fieldName]
        ? data.Colors[wireColor[fieldName]]
        : Object.values(data.Colors)[0],
      label: t("Color"),
      title: t("Color"),
      options: Object.values(data.Colors),
      getOptionLabel: (option) => `${option?.Name} (${option?.Id})`,
      multiple: false,
      handleConfirm: (value) => {
        updateWireColor(wireColor, (newWireColor) => {
          newWireColor[fieldName] = value.Id;
        });
      },
    });
  };

  const handleEditColor1 = (wireColor) => {
    handleEditColorN(wireColor, 1);
  };

  const handleEditColor2 = (wireColor) => {
    handleEditColorN(wireColor, 2);
  };

  const handleEditColor3 = (wireColor) => {
    handleEditColorN(wireColor, 3);
  };

  return (
    <>
      <Grid item>
        <Button
          onClick={handleAddWireColor}
          variant="contained"
          color="primary"
        >
          {t("Add wire color")}
        </Button>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "70vh",
          marginTop: "16px",
        }}
      >
        <SettingsWireColorsTable
          settingInfo={data}
          data={Object.values(data.WireColors)}
          handleEditColor1={handleEditColor1}
          handleEditColor2={handleEditColor2}
          handleEditColor3={handleEditColor3}
          handleEditName={handleEditName}
          handleRemoveWireColor={handleRemoveWireColor}
        />
      </div>
    </>
  );
}
