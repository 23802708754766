import React from "react";

import { Paper } from "@mui/material";

import ImageUpload from "../../images/ImageUpload.jpg";
import {
  useDialogContext,
  DialogActionTypes,
} from "../../context/DialogContext";

export default function CarMountingPointGuideImage({ image }) {
  const dialogDispatch = useDialogContext();
  const url = image.url
    ? image.url
    : `https://specialist.alarmtrade.ru/uploads/cars/mounting_point_guides/${image.name}`;

  return (
    <Paper style={{ padding: "16px", width: "192px" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ width: "100%", maxHeight: "100%" }}>
          {image.name ? (
            <img
              src={url}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              alt=""
              onClick={() => {
                dialogDispatch({
                  type: DialogActionTypes.ImageViewerOpen,
                  image: {
                    src: url,
                    alt: "",
                  },
                });
              }}
            />
          ) : (
            <img
              src={ImageUpload}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              alt=""
            />
          )}
        </div>
      </div>
    </Paper>
  );
}
