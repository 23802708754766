import React from "react";

import {
    Avatar
} from "@mui/material";
import { useDialogContext, DialogActionTypes } from "../context/DialogContext";
import { StyledBadge } from "./StyledBadge/StyledBadge";

export default function AdvancedAvatarBadge({ avatarKey, userId, userMail, isUserOnline }) {
    const dialogDispatch = useDialogContext();

    return (<>
        <StyledBadge anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
            variant="dot"
            invisible={!isUserOnline}>
            <Avatar
                key={avatarKey}
                style={{ height: "48px", width: "48px" }}
                onClick={() => {
                    dialogDispatch({
                        type: DialogActionTypes.ImageViewerOpen,
                        image: {
                            src: userId ? `/frontend/Files/DownloadAvatar?userId=${userId}` : "",
                            alt: ""
                        }
                    });
                }}
                src={userId ? `/frontend/Files/DownloadAvatar?userId=${userId}` : ""}>
                {userMail ? userMail[0].toUpperCase() : 'X'}
            </Avatar>
        </StyledBadge>
    </>);
}
