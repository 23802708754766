import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    RemoveCircle as RemoveIcon
} from "@mui/icons-material";

import styles from "./hoverableStyles.module.css"

import { useTranslation } from "react-i18next"

export default function SettingsWiresTable({ data, settingInfo, handleEditName, handleEditTitle, handleEditColor, handleEditPin, handleRemoveWire }) {
    const { t, i18n } = useTranslation();

    var keys = ["#", t("Name"), t("Title"), t("Color"), t("Pin number"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(wire => (
                    <TableRow key={wire.Id}>
                        <TableCell className={styles.cell}>{wire.Id}</TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditName(wire)}
                        >
                            {wire.Name}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditTitle(wire)}
                        >
                            {wire.TitleTranslationText ? wire.TitleTranslationText[i18n.language] : settingInfo.Translations[i18n.language][wire.TitleTranslationId]?.Text}
                            {wire.TitleTranslationId ? `(${wire.TitleTranslationId})` : ""}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditColor(wire)}
                        >
                            {wire.ColorId && settingInfo ? `${Object.values(settingInfo.WireColors).find(wc => wc.Id === wire.ColorId).Name} (${wire.ColorId})` : ""}
                        </TableCell>
                        <TableCell
                            className={styles.hoverableCell}
                            onClick={() => handleEditPin(wire)}
                        >
                            {wire.PinNumber}
                        </TableCell>
                        <TableCell className={styles.cell} style={{ display: "flex", flexDirection: "row" }}>
                            <IconButton
                                color="secondary"
                                onClick={() => handleRemoveWire(wire)}
                            >
                                <RemoveIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
