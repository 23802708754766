import React, { useState, useEffect, useRef, useCallback } from "react";

import { Grid, Typography, Button, Paper, TextField } from "@mui/material";

import { GenerateInteger } from "../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import TranslationsTable from "../components/Tables/TranslationsTable";
import AdvancedTablePagination from "../components/AdvancedTablePagination";
import { useBackend } from "../context/BackendContext";
import LoadingOverlay from "react-loading-overlay-ts";
import { ResponseCode } from "../enums/ResponseCode";

export default function Translations() {
  const backend = useBackend();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchIdInput, setSearchIdInput] = useState("");
  const [searchTextInput, setSearchTextInput] = useState("");
  const [searchParams, setSearchParams] = useState({
    searchId: "",
    searchText: "",
    pageNumber: 0,
    pageSize: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(100);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshTable = useCallback(
    (searchId, text, pageNumber, pageSize) => {
      setIsLoading(true);
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckTranslationsList(searchId, text, pageNumber + 1, pageSize)
        .then((json) => {
          setIsLoading(false);
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              setTotalRows(json.content.count);
              setTableData((prev) => {
                if (updatesLockCounter.current === 0) {
                  return json.content.list;
                } else {
                  return prev;
                }
              });
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }
        });
    },
    [t, backend]
  );

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    refreshTable(
      searchParams.searchId,
      searchParams.searchText,
      searchParams.pageNumber,
      searchParams.pageSize
    );
  }, [searchParams]);

  const handleChangePage = (event, newPage) => {
    setSearchParams({ ...searchParams, pageNumber: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    var newRowsPerPage = parseInt(event.target.value, 10);
    setSearchParams({
      ...searchParams,
      pageNumber: 0,
      pageSize: newRowsPerPage,
    });
  };

  const handleAddTranslation = () => {
    backend.bckTranslationsCreateOrUpdate({ id: null }).then((json) => {
      if (json.content) {
        setTableData((prev) => {
          let next = [{ id: json.content, text: {} }, ...prev];
          return next;
        });
      }
    });
  };

  const handleSave = (translation, editedTranslation, callback) => {
    backend.bckTranslationsCreateOrUpdate(editedTranslation).then((json) => {
      if (json.content) {
        setTableData((prev) => {
          let next = [...prev];
          let target = next.find((t) => t.id === translation.id);
          target.text = {
            ...target.text,
            ...editedTranslation.text,
          };
          callback();
          return next;
        });
      }
    });
  };

  const handleSearchPress = () => {
    setSearchParams({
      ...searchParams,
      searchId: searchIdInput,
      searchText: searchTextInput,
      pageNumber: 0,
    });
  };

  return (
    <LoadingOverlay active={isLoading} spinner>
      <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh" }}>
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("Translations table")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          <Grid item container direction="row">
            <Grid item>
              <TextField
                label={t("Text")}
                value={searchTextInput}
                onChange={(e) => setSearchTextInput(e.target.value)}
                style={{ marginRight: "12px" }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="ID"
                value={searchIdInput}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setSearchIdInput(e.target.value);
                  }
                }}
                style={{ marginRight: "12px" }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Button
                onClick={handleSearchPress}
                variant="contained"
                color="primary"
              >
                {t("Search")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleAddTranslation}
                variant="contained"
                color="primary"
              >
                {t("Add translation")}
              </Button>
            </Grid>
          </Grid>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            <AdvancedTablePagination
              rowsPerPageOptions={[10, 20, 50, 100]}
              totalRows={totalRows}
              pageSize={searchParams.pageSize}
              pageNumber={searchParams.pageNumber}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              <TranslationsTable data={tableData} handleSave={handleSave} />
            </AdvancedTablePagination>
          </div>
        </Grid>
      </Paper>
    </LoadingOverlay>
  );
}
