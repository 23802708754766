import React from "react";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    IconButton
} from "@mui/material";

import {
    ArrowForward as ShowUsersIcon
} from "@mui/icons-material";

import { useTranslation } from "react-i18next"
import { UpdateChannelAccessLevels } from "../../enums/UpdateChannelAccessLevels";
import { UpdateChannelTypes } from "../../enums/UpdateChannelTypes";
import { ClientTypes } from "../../enums/ClientTypes";

import styles from "./hoverableStyles.module.css"

export default function UpdateChannelsTable({ data, handleShowUsersClick, handleRequirementsClick }) {
    const { t } = useTranslation();
    var keys = ["ID", t("Name/Title"), t("Client type"), t("Channel type"), t("Access level"), t("Requirements"), ""];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    {keys.map(key => (
                        <TableCell key={key} style={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(row => (
                    <TableRow key={row.id}>
                        <TableCell className={styles.cell}>{row.id}</TableCell>
                        <TableCell className={styles.cell}>{row.name}</TableCell>
                        <TableCell className={styles.cell}>{ClientTypes[row.clientType]}</TableCell>
                        <TableCell className={styles.cell}>{UpdateChannelTypes[row.channelType]}</TableCell>
                        <TableCell className={styles.cell}>{UpdateChannelAccessLevels[row.accessLevel]}</TableCell>
                        <TableCell className={styles.hoverableCell} onClick={() => handleRequirementsClick(row)}>
                            <pre style={{ maxHeight: "7ch", maxWidth: "64ch", overflowX: "hidden", overflowY: "hidden" }}>
                                {row.requirements}
                            </pre>
                        </TableCell>
                        <TableCell className={styles.cell}>
                            <IconButton
                                color="primary"
                                onClick={() => handleShowUsersClick(row)}>
                                <ShowUsersIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
