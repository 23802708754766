import React, { useState, useEffect } from "react";
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TablePagination
} from "@mui/material";

import { useTranslation } from "react-i18next";

export default function ChartTable({ data }) {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(0);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);

    useEffect(() => {
        setCurrentPage(0);
    }, [data]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        let newRowsPerPage = parseInt(event.target.value, 10);
        setCurrentRowsPerPage(newRowsPerPage);
        setCurrentPage(0);
    };

    return (
        <>
            <div style={{ width: "100%", overflow: "auto" }}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                count={data.length - 1}
                                rowsPerPage={currentRowsPerPage}
                                page={currentPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={t("Page size")}
                                />
                        </TableRow>
                        <TableRow>
                            {
                                data[0].map((cell, j) => <TableCell key={j} style={{ fontWeight: 'bold' }}>{cell}</TableCell>)
                            }
                        </TableRow>
                        {
                            data.slice(currentPage * currentRowsPerPage + 1, (currentPage + 1) * currentRowsPerPage + 1).map((row, i) =>
                                <TableRow key={i}>
                                    {
                                        row.map((cell, j) => <TableCell key={j} style={j === 0 ? { fontWeight: 'bold' } : {}}>{cell}</TableCell>)
                                    }
                                </TableRow>)
                        }
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50, 100]}
                                count={data.length - 1}
                                rowsPerPage={currentRowsPerPage}
                                page={currentPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={t("Page size")}
                            />
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </>
    );
}
