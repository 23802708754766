import React, { useState } from "react";
import { Typography, Link, Button } from "@mui/material";

import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";
import { EncodeUTF16LE } from "../../helpers/StringUtils";
import { useBackend } from "../../context/BackendContext";

export default function ConfigurationView({ data }) {
  const backend = useBackend();
  const { t } = useTranslation();
  const [showDescription, setShowDescription] = useState(false);

  const onDownloadConfiguration = (configId) => {
    backend.bckConfigurationsGetAdditionalInfo(configId).then((json) => {
      if (json.content && json.content.dxsData) {
        const infoData = new Blob([EncodeUTF16LE(json.content.dxsData)], {
          type: "text/plain;charset=UTF-16LE;",
        });
        FileSaver.saveAs(
          infoData,
          `${data.carBrandName} ${data.carModelName} ${data.carEquipmentName} ${data.name}.dxs`
        );
      }
    });
  };

  return (
    <>
      <Typography
        variant="h4"
        style={{ marginBottom: "8px" }}
      >{`${data.alarmName} | ${data.carBrandName} ${data.carModelName} ${data.carEquipmentName}`}</Typography>
      <Typography
        variant="h5"
        style={{ marginBottom: "8px" }}
      >{`${data.name}`}</Typography>
      <Typography variant="body1">{data.shortDescription}</Typography>
      <div style={{ marginBottom: "8px" }}>
        <Link
          component="button"
          onClick={() => {
            setShowDescription(!showDescription);
          }}
        >
          <Typography variant="body1">
            {showDescription ? "\u2B9D" : "\u2B9F"}
            {t("Full description")}
          </Typography>
        </Link>
        {showDescription ? (
          <Typography variant="body1">
            <div dangerouslySetInnerHTML={{ __html: data.fullDescription }} />
          </Typography>
        ) : (
          <></>
        )}
      </div>
      <div>
        <Button
          size="small"
          variant="contained"
          color="primary"
          style={{ marginRight: "8px" }}
          onClick={(e) => onDownloadConfiguration(data.id)}
        >
          {t("Mount configuration")}
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!data.mountingMapUrl}
          onClick={() => window.open(`${data.mountingMapUrl}`, `_blank`)}
        >
          {t("Mounting map")}
        </Button>
      </div>
    </>
  );
}
