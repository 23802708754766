const DeviceTypes = {
    0: "UNKNOWN",
    1: "ALARM",
    2: "TRINKET",
    3: "BEACON",
    4: "NAVIGATION_MODULE",
    5: "TOKEN",
    6: "BLOCK_RELAY",
    7: "IMMOBILIZER",
    8: "PANDORA_BAND",
    9: "RMD_RHM",
    10: "BMW_BYPASS",
    11: "GPS_RECEIVER",
    12: "SIREN",
    13: "DMS_DOOR_SENSOR",
    14: "HM_HOOD_LOCK_CONTROL_MODULE",
    15: "IMMOBILZER_BYPASS",
    16: "VOLUME_SENSOR",
    17: "CHARGER",
    18: "CLOCK",
    19: "BLUETOOTH",
    20: "FUEL_SENSOR"
};
Object.freeze(DeviceTypes);

export { DeviceTypes }