const SettingsStructureTypes = {
    1: "UInt",
    2: "UShort",
    3: "UShortAsFloat",
    4: "UByte",
    5: "UByteWithBits",
    6: "UByteAsFloat",
    7: "SByte",
    8: "Bit",
    9: "InvertedBit",
    10: "UByteOption",
    11: "BitOption",
    12: "ShortTime",
    13: "DmsByte",
    14: "CharString",
    15: "BitFromInt",
    16: "Virtual",
    17: "Structure",
    18: "UByteSiren",
    19: "Instance",
    20: "Array",
    21: "Union",
    22: "UIntAsFloat",
    23: "SShort",
    24: "IpAddress"
};
Object.freeze(SettingsStructureTypes);

const SettingsStructureTypesByName = {
    "UInt": 1,
    "UShort": 2,
    "UShortAsFloat": 3,
    "UByte": 4,
    "UByteWithBits": 5,
    "UByteAsFloat": 6,
    "SByte": 7,
    "Bit": 8,
    "InvertedBit": 9,
    "UByteOption": 10,
    "BitOption": 11,
    "ShortTime": 12,
    "DmsByte": 13,
    "CharString": 14,
    "BitFromInt": 15,
    "Virtual": 16,
    "Structure": 17,
    "UByteSiren": 18,
    "Instance": 19,
    "Array": 20,
    "Union": 21,
    "UIntAsFloat": 22,
    "SShort": 23,
    "IpAddress": 24
};
Object.freeze(SettingsStructureTypesByName);

export { SettingsStructureTypes, SettingsStructureTypesByName }