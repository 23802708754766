import React, { useCallback } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Divider,
    Typography
} from "@mui/material";

import { useTranslation } from "react-i18next";

export default function MountQuestionsDialog({ isOpened, questions, mountId, answers, handleClose }) {
    const { t } = useTranslation(); 
    const getAnswer = useCallback((questionId) => {
        var answer = answers.find(a => a.id === questionId);
        if (answer === undefined) return <font color="#AAAA00">{t("No answer")}</font>;
        else if (answer.value === 0) return <font color="#AA0000">{answer.answer}</font>;
        else return <font color="#00AA00">{answer.answer}</font>;
    }, [answers, t]);

    return (
        <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{t("Mount checklist", { mountId })}</DialogTitle>
            <DialogContent>
                <Divider />
                {
                    questions === undefined || questions === null || questions.length === 0 ? <Typography style={{ paddingTop: "12px" }}>{t("No questions in checklist")}</Typography> :
                    questions.map(q => <React.Fragment key={q.id}>
                        <b><i>{t("Algorithm")}: </i></b>{q.algorithm}<br />
                        <b><i>{t("Result")}: </i></b>{q.expectedResult}<br />
                        <b><i>{t("Answer")}: {getAnswer(q.id)}</i></b><br />
                        <Divider style={{ marginTop: "12px" }} />
                    </React.Fragment>)
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
